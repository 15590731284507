import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { FundsTransferCriterion } from '@models/profileConfiguration/model';

export interface FundsTransferCriterionState extends EntityState<FundsTransferCriterion, string> {}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'FundsTransferCriterion' })
export class FundsTransferCriterionStore extends EntityStore<FundsTransferCriterionState> {
  public constructor() {
    super();
  }
}
