export const crossroadsURLs: string[] = [
  'localhost',

  'crossroads-d1-pux.tasconline.com', // D1
  'crossroads-d2-pux.tasconline.com', // D2
  'crossroads-d3-pux.tasconline.com', // D3
  'crossroads-d4-pux.tasconline.com', // D4
  'crossroads-d5-pux.tasconline.com', // D5
  'crossroads-d6-pux.tasconline.com', // D6
  'crossroads-d7-pux.tasconline.com', // D7
  'crossroads-d8-pux.tasconline.com', // D8

  'crossroads-t1-pux.tasconline.com', // T1
  'crossroads-t2-pux.tasconline.com', // T2
  'crossroads-t3-pux.tasconline.com', // T3
  'crossroads-t4-pux.tasconline.com', // T4
  'crossroads-t5-pux.tasconline.com', // T5

  'crossroads-l1-pux.tasconline.com', // L1
  'crossroads-l2-pux.tasconline.com', // L2
  'crossroads-l3-pux.tasconline.com', // L3
  'crossroads-l4-pux.tasconline.com', // L4

  'crossroads-s1-pux.uba-stage.com', // S1
  'crossroads-s2-pux.tasconline.com', // S2
  'rr-stg-aL5iUf66F.crossroadshealth.com', // S2 alternate
  'crossroads-s3-pux.uba-stage.com', // S3
  'crossroads-s4-pux.uba-stage.com', // S4
  'crossroads-s5-pux.uba-stage.com', // S5

  'rightrefund.crossroadshealth.com', // Prod
];
