import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { BenefitPlan } from '@models/configuration/model';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { PayrollSchedule } from 'src/app/shared/models/uba/profileConfiguration/model';
import { Dates } from 'src/app/shared/utils';

import { PayrollScheduleState, PayrollScheduleStore } from './payroll-schedule.store';

@Injectable({
  providedIn: 'root',
})
export class PayrollScheduleQuery extends QueryEntity<PayrollScheduleState> {
  public constructor(protected store: PayrollScheduleStore) {
    super(store);
  }

  public selectAllWhenLoaded(): Observable<PayrollSchedule[]> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectAll()),
      );
  }

  public selectByPayrollAndBenefitPlan(payrollId: string, benefitPlan: BenefitPlan): Observable<PayrollSchedule> {
    return this.selectLoading()
      .pipe(
          filter((isLoading) => !isLoading),
          switchMap(() => this.selectAll({
            filterBy: (payrollSchedule) => payrollSchedule.payrollId === payrollId && payrollSchedule.parentId === benefitPlan.id,
          })),
          map((payrollSchedules) => {
            if (!payrollSchedules.length) {
              return null;
            }
            if (benefitPlan.scheduleEndDate) {
              return payrollSchedules.reduce((prev, curr) => Dates.isAfter(curr.startDate, prev.startDate) ? curr : prev);
            }
            return payrollSchedules[0];
          }),
      );
  }
}
