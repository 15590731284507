import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { SupportRequest } from 'src/app/shared/models/uba/support/model';

export interface SupportRequestDetailState extends EntityState<SupportRequest, string> {}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'SupportRequestDetail'})
export class SupportRequestDetailStore extends EntityStore<SupportRequestDetailState> {
  public constructor() {
    super();
  }
}
