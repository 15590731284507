import { Injectable } from '@angular/core';
import { Order, QueryConfig, QueryEntity } from '@datorama/akita';

import { SupportRequestState, SupportRequestStore } from './support-request.store';

@Injectable({
  providedIn: 'root',
})
@QueryConfig({
  sortBy: 'created',
  sortByOrder: Order.DESC,
})
export class SupportRequestQuery extends QueryEntity<SupportRequestState> {
  public constructor(protected store: SupportRequestStore) {
    super(store);
  }
}
