import './app/raygun/raygun.setup';

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { akitaConfig, enableAkitaProdMode, persistState } from '@datorama/akita';
import rg4js from 'raygun4js';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

akitaConfig({
  resettable: true,
});

persistState({
  include: ['CurrentUser', 'Payout'],
  storage: sessionStorage,
});

if (environment.production) {
  enableProdMode();
  enableAkitaProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((error) => {
    // tslint:disable-next-line: no-console
    console.error(error);
    rg4js('send', { error });
  });
