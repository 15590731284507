import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Address, AddressType, Individual, Phone, PhoneType } from '@models/profile/model';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { IndividualState, IndividualStore } from './individual.store';

@Injectable({
  providedIn: 'root',
})
export class IndividualQuery extends QueryEntity<IndividualState> {
  public constructor(protected store: IndividualStore) {
    super(store);
  }

  public getActiveId(): string {
    return super.getActiveId() as string;
  }

  public getIabAccountId(): string {
    return this.getActive().iabAccountId;
  }

  public getPhoneNumber(phoneType: PhoneType): Phone {
    const individual = this.getActive();

    return individual.phones.find((phone) => phone.phoneType === phoneType);
  }

  public getAddress(addressType: AddressType): Address {
    return this.getActive().addresses.find((address) => address.addressType === addressType);
  }

  public selectActiveWhenLoaded(): Observable<Individual> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectActive()),
      );
  }
}
