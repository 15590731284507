// AUTOGENERATED BY FUNCTION genubamodel.js
export interface CardAuthorizationCommand extends CommandBase {
  data?: CardAuthorization;
}

export interface ClassificationReasonCommand extends CommandBase {
  data?: ClassificationReason;
}

export interface RequestCommand extends CommandBase {
  data?: Request;
  attachmentURIs?: AttachmentURIs;
  substantiationLinks?: SubstantiationLinks;
}

export interface RequestAttachmentCommand extends CommandBase {
  data?: RequestAttachment;
}

export interface RequestCardPaymentCommand extends CommandBase {
  data?: RequestPayment;
}

export interface RequestManualPaymentCommand extends CommandBase {
  data?: RequestPayment;
  allowTryToPayout?: boolean;
}

export interface CcxSubstantiationLinkCommand extends CommandBase {
  data?: CcxSubstantiationLink;
}

export interface ClassificationReasonCommandResponse {
  processedClassificationReason?: ClassificationReason;
}

export interface RequestManualPaymentCommandResponse {
  processedRequestPayment?: RequestPayment;
}

export interface ChewdownRequestCommand extends CommandBase {
  data?: ChewdownRequest;
}

export interface CcxSubstantiationLinkCommandResponse {
  processedCcxSubstantiationLink?: CcxSubstantiationLink;
}

export interface CcxSubstantiationCommand extends CommandBase {
  data?: CcxSubstantiation;
}

export interface CcxSubstantiationCommandResponse {
  processedCcxSubstantiation?: CcxSubstantiation;
}

export interface BenefitAccount extends EntityBase {
  clientId: string;
  planId: string;
  planName: string;
  planDescription?: string;
  planStartDate: string;
  planEndDate?: string;
  hireDate: string;
  benefitEffectiveDate: string;
  eligibilityEndDate?: string;
  clientLastScheduleDate?: string;
  individualLastScheduleDate?: string;
  requestTypes?: RequestTypes;
  soaAccountId?: string;
  balance?: number;
  carryoverDeclined: boolean;
  approvedBy?: string;
  approvedDate?: string;
  payrollScheduleId?: string;
  excludeFromBilling: boolean;
  cashAccountMinimumBalance?: number;
  enrollmentSource?: EnrollmentSourceType;
  clientScheduleFirstDate?: string;
  individualScheduleFirstDate?: string;
  scheduleEndDate?: string;
  lastDayToSubmitExpenses?: string;
  coverageTier?: BenefitPlanCoverageType;
  reasonToHoldDisbursements?: string;
  finalCoverageDate?: string;
  fullCoveragePaidThroughDate?: string;
  scheduleChangeDate?: string;
  disbursableDate?: string;
  participantTerminationType?: ParticipantTerminationType;
  optForCobraCoverage?: boolean;
}

export interface CardAuthorization extends EntityBase {
  totalAmount?: number;
  approvedAmount?: number;
  transactionDateTime?: string;
  thirdPartyAuthorizationTypes?: ThirdPartyAuthorizationType[];
  cardNetworkData?: CardNetworkData;
}

export interface ClassificationTag {
  tag?: string;
  maxAmount?: number;
}

export interface CardNetworkData {
  last4digits?: string;
  settlementMessageId?: string;
  cardTransactionStatus?: CardTransactionStatusType;
  declineReasons?: DeclineReasons;
  merchantName?: string;
  merchantAddress?: string;
  merchantCategoryCode?: string;
  merchantCategoryType?: string;
  authorizationId?: string;
  cardholderName?: string;
  authorizationMethod?: string;
  thirdPartyAuthorizationTypes?: ThirdPartyAuthorizationType[];
  iiasMerchantName?: string;
  iiasResult?: string;
  matchedPendingId?: string;
  entryId?: string;
  classificationTags?: ClassificationTag[];
  accounts?: RequestAccount[];
}

export interface ClassificationReason extends EntityBase {
  benefitAccountId: string;
  lastStraw?: string;
  matched?: boolean;
  sourceAllowed?: boolean;
  adminOverride?: boolean;
  inEligibilityWindow?: InEligibilityWindowOption;
  requestTypeMatch?: boolean;
  merchantCategoryCodeMatch?: boolean;
  balance?: number;
  scheduledContributions?: boolean;
  pulledBackInDueToCarryover?: boolean;
  dependentAccess?: boolean;
  isReclassify?: boolean;
  inReconciliation?: boolean;
  xfClassificationResult?: XfClassificationResultOption;
}

export interface Request extends EntityBase {
  sourceId?: string;
  requestedAmount?: number;
  unpaidAmount?: number;
  description?: string;
  method?: RequestMethodType;
  expenseSourceId?: string;
  expenseSourceName?: string;
  incurredById?: string;
  incurredByName?: string;
  ineligibleDate?: string;
  externalId?: string;
  serviceDate?: string;
  submissionDateTime?: string;
  receivedDateTime?: string;
  serviceProvider?: string;
  paymentStatus?: PaymentStatusType;
  declineReason?: DeclineReason;
  requestType?: string;
  providerId?: string;
  cardNetworkData?: CardNetworkData;
  paymentRequests?: RequestPayments;
  externalClaimId?: string;
  planTypeRollup?: PlanRollupType;
  mileage?: number;
  mileageRate?: number;
  puxStorageOnlyServiceEndDate?: string;
  checkoutExpiresDateTime?: string;
  checkedOutBy?: string;
}

export interface RequestAttachment extends EntityBase {
  attachmentId?: string;
  requestId?: string;
}

export interface RequestPayment extends EntityBase {
  soaAccountId?: string;
  benefitAccountId?: string;
  accountType?: string;
  requestedAmount: number;
  paymentAmount: number;
  paymentStatus: PaymentStatusType;
  benefitPlanId?: string;
  verificationStatus?: VerificationStatus;
  verificationMethod?: VerificationMethod;
  declineReason?: DeclineReason;
  entryId?: string;
  paymentDateTime?: string;
  depletionOrder?: number;
  classificationTags?: ClassificationTag[];
  verificationExpiration?: string;
  isVerificationExpired?: boolean;
  chargebackType?: ChargebackType;
}

export interface RequestRequestPayment {
  requestId?: string;
  requestPaymentId?: string;
  requestParentId?: string;
  currentState?: string;
  requestPaymentStatus?: PaymentStatusType;
  requestPaymentPaymentStatus?: PaymentStatusType;
  benefitPlanId?: string;
  benefitAccountId?: string;
  soaAccountId?: string;
  verificationStatus?: VerificationStatus;
  requestPaymentDeclineReason?: DeclineReason;
  requestServiceDate?: string;
  requestSubmissionDateTime?: string;
  requestServiceProvider?: string;
  requestMethod?: RequestMethodType;
  requestDescription?: string;
  requestPaymentRequestedAmount?: number;
  requestPaymentPaymentAmount?: number;
  verificationExpiration?: string;
  isVerificationExpired?: boolean;
  entryId?: string;
  providerId?: string;
  mileage?: number;
  mileageRate?: number;
  requestPaymentChargebackType?: ChargebackType;
}

export interface RequestVerificationFlag extends EntityBase {
  sourceId?: string;
  requestedAmount?: number;
  unpaidAmount?: number;
  description?: string;
  method?: RequestMethodType;
  expenseSourceId?: string;
  expenseSourceName?: string;
  incurredById?: string;
  incurredByName?: string;
  ineligibleDate?: string;
  externalId?: string;
  serviceDate?: string;
  submissionDateTime?: string;
  receivedDateTime?: string;
  serviceProvider?: string;
  paymentStatus?: PaymentStatusType;
  externalClaimId?: string;
  declineReason?: DeclineReason;
  requestType?: string;
  cardNetworkData?: CardNetworkData;
  paymentRequests?: RequestPayments;
  verificationRequired?: boolean;
  providerId?: string;
  inDispute?: boolean;
  adminProcessingNeeded?: boolean;
  planTypeRollup?: PlanRollupType;
}

export interface RequestQueue extends EntityBase {
  sourceId?: string;
  requestedAmount?: number;
  unpaidAmount?: number;
  description?: string;
  method?: RequestMethodType;
  expenseSourceId?: string;
  expenseSourceName?: string;
  incurredById?: string;
  incurredByName?: string;
  ineligibleDate?: string;
  externalId?: string;
  serviceDate?: string;
  submissionDateTime?: string;
  receivedDateTime?: string;
  serviceProvider?: string;
  paymentStatus?: PaymentStatusType;
  declineReason?: DeclineReason;
  requestType?: string;
  cardNetworkData?: CardNetworkData;
  paymentRequests?: RequestPayments;
}

export interface RequestType {
  expenseType?: string;
  serviceType?: string;
  limit?: number;
}

export interface Attachment extends EntityBase {
  filePath?: string;
  attachmentType?: string;
  friendlyFileName?: string;
}

export interface ClassifiedBenefitAccount extends BenefitAccount {
  filePath?: string;
}

export interface RequestAccount {
  accountId?: string;
  amount?: number;
  classificationTags?: ClassificationTag[];
}

export interface ChewdownRequest {
  individualId: string;
  entryId: string;
}

export interface CcxSubstantiationLink extends EntityBase {
  individualId: string;
  transactionType?: string;
  requestId: string;
  patientFirstName?: string;
  patientLastName?: string;
  totalClaimAmount: number;
  matchedClaimAmount: number;
  typeOfMatch: TypeOfMatchType;
  serviceDate: string;
  claimId?: string;
  claimNumber: string;
  channel?: string;
  carrierId?: string;
}

export interface CcxSubstantiation extends EntityBase {
  employeeFirstName: string;
  employeeLastName: string;
  patientFirstName: string;
  patientLastName: string;
  relationshipToEmployeeCode: string;
  claimNumber: string;
  serviceBeginDate: string;
  copayAmount?: number;
  deductibleAmount?: number;
  coinsuranceAmount?: number;
  patientResponsibility?: number;
  channel: string;
  providerFacilityName?: string;
  providerFirstName?: string;
  providerLastName?: string;
  providerTaxId?: string;
  providerAddress1?: string;
  providerAddress2?: string;
  providerCity?: string;
  providerState?: string;
  providerZip?: string;
}

export interface Criteria {
  key?: string;
  value?: string;
  matchType?: MatchType;
  chainType?: ChainType;
  groupCriteria?: GroupCriteria[];
}

export interface Error {
  message?: string;
  fields?: object;
}

export interface GroupCriteria {
  key?: string;
  value?: string;
  matchType?: MatchType;
  chainType?: ChainType;
}

export interface PingResponse {
  serverTime: ServerTime;
  services?: Service[];
}

export type SearchCriteria = Criteria[];

export type individualId = string;

export type ServerTime = string;

export interface Service {
  name?: string;
  serverTime?: string;
}

export type BenefitAccounts = BenefitAccount[];

export type CardAuthorizations = CardAuthorization[];

export type DeclineReasons = string[];

export type ClassificationReasons = ClassificationReason[];

export type Requests = Request[];

export type RequestPayments = RequestPayment[];

export type RequestRequestPayments = RequestRequestPayment[];

export type RequestQueues = RequestQueue[];

export type RequestTypes = RequestType[];

export type ClassifiedBenefitAccounts = ClassifiedBenefitAccount[];

export type AttachmentURIs = string[];

export type SubstantiationLinks = SubstantiationLink[];

export interface SubstantiationLink {
  typeOfSubstantiationLink: TypeOfSubstantiationLinkType;
  cxcAutoClaimMatch?: CxcAutoClaimMatch;
  manualClaimMatch?: ManualClaimMatch;
}

export interface CxcAutoClaimMatch {
  claimId?: string;
  transactionType?: string;
  carrierId?: string;
  claimNumber: string;
  channel?: string;
  serviceDate?: string;
  totalClaimAmount?: number;
  matchedClaimAmount: number;
  patientFirstName?: string;
  patientLastName?: string;
}

export interface ManualClaimMatch {
  carrierClaimId?: string;
}

export type CcxSubstantiationLinks = CcxSubstantiationLink[];

export type CcxSubstantiations = CcxSubstantiation[];

export interface CommandBase {
  id?: string;
  eventCorrelationId?: string;
  jobId?: string;
  producerId?: string;
  sequenceId?: number;
  version?: number;
  type?: string;
  created?: string;
  createdBy?: string;
  createdById?: string;
}

export interface EntityBase {
  id?: string;
  parentId?: string;
  parentType?: ParentType;
  version?: number;
  created?: string;
  createdBy?: string;
  createdById?: string;
  updated?: string;
  updatedBy?: string;
  updatedById?: string;
  currentState?: string;
  lastTransition?: string;
  externalWorkflowId?: string;
  eventNotes?: string;
  transactionLockId?: string;
}

export enum AttachmentCommandType {
  LinkedToStored = 'LinkedToStored',
  StartToUploaded = 'StartToUploaded',
  StoredToLinked = 'StoredToLinked',
  StoredToRemoved = 'StoredToRemoved',
  UploadedToStored = 'UploadedToStored',
}

export enum CardAuthorizationCommandType {
  StartToAuthorized = 'StartToAuthorized',
  StartToSettled = 'StartToSettled',
  StartToDeclined = 'StartToDeclined',
  StartToReturned = 'StartToReturned',
  StartToError_AuthedAfterSettled = 'StartToError_AuthedAfterSettled',
  AuthorizedToAuthorized = 'AuthorizedToAuthorized',
  AuthorizedToExpired = 'AuthorizedToExpired',
  AuthorizedToError = 'AuthorizedToError',
  AuthorizedToSettled = 'AuthorizedToSettled',
  ExpiredToSettled = 'ExpiredToSettled',
  AuthorizedToDeclined = 'AuthorizedToDeclined',
  SettledToRefunded = 'SettledToRefunded',
  AuthorizedToError_CardHolderNotFound = 'AuthorizedToError_CardHolderNotFound',
  DeclinedToError_CardHolderNotFound = 'DeclinedToError_CardHolderNotFound',
  ReturnedToError_CardHolderNotFound = 'ReturnedToError_CardHolderNotFound',
}

export enum RequestAttachmentCommandType {
  StartToLinked = 'StartToLinked',
  LinkedToRemoved = 'LinkedToRemoved',
}

export enum ClassificationReasonCommandType {
  StartToActive = 'StartToActive',
  ActiveToActive = 'ActiveToActive',
  ActiveToRemoved = 'ActiveToRemoved',
}

export enum RequestCommandType {
  AnyToAny = 'AnyToAny',
  AcceptedToAccepted = 'AcceptedToAccepted',
  AcceptedToAreTherePRsRemaining = 'AcceptedToAreTherePRsRemaining',
  AcceptedToIsPaid = 'AcceptedToIsPaid',
  AcceptedToPotentialDuplicate = 'AcceptedToPotentialDuplicate',
  AcceptedToRemoved = 'AcceptedToRemoved',
  AcceptedToSubmitted = 'AcceptedToSubmitted',
  AreTherePRsRemainingToAccepted = 'AreTherePRsRemainingToAccepted',
  AreTherePRsRemainingToDenied = 'AreTherePRsRemainingToDenied',
  AwaitingCustomerInputToIsClassified = 'AwaitingCustomerInputToIsClassified',
  AwaitingCustomerInputToSubmitted = 'AwaitingCustomerInputToSubmitted',
  AwaitingCustomerInputToPotentialDuplicate = 'AwaitingCustomerInputToPotentialDuplicate',
  DeniedToAccepted = 'DeniedToAccepted',
  DraftToDraft = 'DraftToDraft',
  DraftToIsValid = 'DraftToIsValid',
  DraftToRemoved = 'DraftToRemoved',
  IsClassifiedToAccepted = 'IsClassifiedToAccepted',
  IsClassifiedToAwaitingCustomerInput = 'IsClassifiedToAwaitingCustomerInput',
  IsClassifiedToDenied = 'IsClassifiedToDenied',
  IsClassifiedToPending = 'IsClassifiedToPending',
  IsClassifiedToPendingAdmin = 'IsClassifiedToPendingAdmin',
  IsPaidToDenied = 'IsPaidToDenied',
  IsPaidToPaid = 'IsPaidToPaid',
  IsPaidToPartiallyPaid = 'IsPaidToPartiallyPaid',
  IsPaidToPending = 'IsPaidToPending',
  IsPaidToReturned = 'IsPaidToReturned',
  IsPaidToUnpaidFinalized = 'IsPaidToUnpaidFinalized',
  IsValidToDenied = 'IsValidToDenied',
  IsValidToIsPaid = 'IsValidToIsPaid',
  IsValidToPendingAdmin = 'IsValidToPendingAdmin',
  IsValidToPotentialDuplicate = 'IsValidToPotentialDuplicate',
  IsValidToSubmitted = 'IsValidToSubmitted',
  IsValidToUnpaidFinalized = 'IsValidToUnpaidFinalized',
  PaidToAccepted = 'PaidToAccepted',
  PaidToIsPaid = 'PaidToIsPaid',
  UnpaidFinalizedToIsPaid = 'UnpaidFinalizedToIsPaid',
  PaidToPaid = 'PaidToPaid',
  PaidToPartiallyPaid = 'PaidToPartiallyPaid',
  PaidToReturned = 'PaidToReturned',
  PartiallyPaidToAccepted = 'PartiallyPaidToAccepted',
  PartiallyPaidToIsPaid = 'PartiallyPaidToIsPaid',
  PartiallyPaidToPartiallyPaid = 'PartiallyPaidToPartiallyPaid',
  PartiallyPaidToReturned = 'PartiallyPaidToReturned',
  PartiallyPaidToIsReclassified = 'PartiallyPaidToIsReclassified',
  PotentialDuplicateToDenied = 'PotentialDuplicateToDenied',
  PotentialDuplicateToIsClassified = 'PotentialDuplicateToIsClassified',
  PendingAdminToDenied = 'PendingAdminToDenied',
  PendingAdminToIsValid = 'PendingAdminToIsValid',
  PendingAdminToPendingAdmin = 'PendingAdminToPendingAdmin',
  PendingSettlementToIsPaid = 'PendingSettlementToIsPaid',
  PendingSettlementToPendingSettlement = 'PendingSettlementToPendingSettlement',
  PendingSettlementToRemoved = 'PendingSettlementToRemoved',
  PendingToAccepted = 'PendingToAccepted',
  PendingToPending = 'PendingToPending',
  PendingToPotentialDuplicate = 'PendingToPotentialDuplicate',
  PendingToSubmitted = 'PendingToSubmitted',
  ReturnedToIsPaid = 'ReturnedToIsPaid',
  StartToDenied = 'StartToDenied',
  StartToDraft = 'StartToDraft',
  StartToIsValid = 'StartToIsValid',
  StartToPaid = 'StartToPaid',
  StartToPendingSettlement = 'StartToPendingSettlement',
  StartToReturned = 'StartToReturned',
  StartToSettled = 'StartToSettled',
  SubmittedToIsClassified = 'SubmittedToIsClassified',
  SubmittedToPotentialDuplicate = 'SubmittedToPotentialDuplicate',
  AddAttachments = 'AddAttachments',
  UnpaidFinalizedToIsReclassified = 'UnpaidFinalizedToIsReclassified',
  ReturnedToIsReclassified = 'ReturnedToIsReclassified',
  AcceptedToComplete = 'AcceptedToComplete',
  PendingToComplete = 'PendingToComplete',
  PartiallyPaidToComplete = 'PartiallyPaidToComplete',
  AnyToSubstantiate = 'AnyToSubstantiate',
}

export enum RequestCardPaymentCommandType {
  StartToAuthorized = 'StartToAuthorized',
  StartToIsVerificationReq = 'StartToIsVerificationReq',
  StartToReturned = 'StartToReturned',
  IsVerificationReqToPaid = 'IsVerificationReqToPaid',
  CanAutoVerifyToPaidVerified = 'CanAutoVerifyToPaidVerified',
  AreReceiptsAttachedToPaidCustomerInputReq = 'AreReceiptsAttachedToPaidCustomerInputReq',
  AreReceiptsAttachedToPaidVerificationReq = 'AreReceiptsAttachedToPaidVerificationReq',
  PaidCustomerInputReqToAreReceiptsAttached = 'PaidCustomerInputReqToAreReceiptsAttached',
  PaidCustomerInputReqToRepaid = 'PaidCustomerInputReqToRepaid',
  PaidCustomerInputReqToReturned = 'PaidCustomerInputReqToReturned',
  PaidCustomerInputReqToPaidCustomerInputReq = 'PaidCustomerInputReqToPaidCustomerInputReq',
  PaidToPaid = 'PaidToPaid',
  PaidToPaidCustomerInputReq = 'PaidToPaidCustomerInputReq',
  PaidToReturned = 'PaidToReturned',
  PaidVerifiedToPaidCustomerInputReq = 'PaidVerifiedToPaidCustomerInputReq',
  PaidVerifiedToPaidVerified = 'PaidVerifiedToPaidVerified',
  PaidVerifiedToReturned = 'PaidVerifiedToReturned',
  PaidVerificationReqToIsVerified = 'PaidVerificationReqToIsVerified',
  PaidVerificationReqToPaidVerificationReq = 'PaidVerificationReqToPaidVerificationReq',
  PaidVerificationReqToReturned = 'PaidVerificationReqToReturned',
  IsVerifiedToPaidCustomerInputReq = 'IsVerifiedToPaidCustomerInputReq',
  IsVerifiedToPaidVerified = 'IsVerifiedToPaidVerified',
  AreReceiptsAttachedToPendingCustomerInputReq = 'AreReceiptsAttachedToPendingCustomerInputReq',
  AreReceiptsAttachedToPendingVerificationReq = 'AreReceiptsAttachedToPendingVerificationReq',
  IsVerifiedToPendingCustomerInputReq = 'IsVerifiedToPendingCustomerInputReq',
  IsVerifiedToPendingVerified = 'IsVerifiedToPendingVerified',
  IsVerificationReqToPending = 'IsVerificationReqToPending',
  CanAutoVerifyToPendingVerified = 'CanAutoVerifyToPendingVerified',
  PendingCustomerInputReqToAreReceiptsAttached = 'PendingCustomerInputReqToAreReceiptsAttached',
  PendingCustomerInputReqToRemoved = 'PendingCustomerInputReqToRemoved',
  PendingCustomerInputReqToPaidCustomerInputReq = 'PendingCustomerInputReqToPaidCustomerInputReq',
  PendingCustomerInputReqToRepaid = 'PendingCustomerInputReqToRepaid',
  PendingToPaid = 'PendingToPaid',
  PendingToRemoved = 'PendingToRemoved',
  PendingVerifiedToPaidVerified = 'PendingVerifiedToPaidVerified',
  PendingVerificationReqToIsVerified = 'PendingVerificationReqToIsVerified',
  PendingVerificationReqToPaidVerificationReq = 'PendingVerificationReqToPaidVerificationReq',
  PendingVerificationReqToRemoved = 'PendingVerificationReqToRemoved',
  PendingVerifiedToRemoved = 'PendingVerifiedToRemoved',
}

export enum RequestManualPaymentCommandType {
  CanAutoPayToCustomerInputReq = 'CanAutoPayToCustomerInputReq',
  CanAutoPayToManualProcessingNeeded = 'CanAutoPayToManualProcessingNeeded',
  CanAutoPayToPaid = 'CanAutoPayToPaid',
  CanAutoPayToPaidCustomerInputReq = 'CanAutoPayToPaidCustomerInputReq',
  CanAutoPayToPaidVerificationReq = 'CanAutoPayToPaidVerificationReq',
  CanAutoPayToPendingHold = 'CanAutoPayToPendingHold',
  CanAutoPayToPendingVerified = 'CanAutoPayToPendingVerified',
  CanAutoPayToVerificationReq = 'CanAutoPayToVerificationReq',
  CanAutoPayToRemoved = 'CanAutoPayToRemoved',
  CustomerInputReqToPaidCustomerInputReq = 'CustomerInputReqToPaidCustomerInputReq',
  CustomerInputReqToRemoved = 'CustomerInputReqToRemoved',
  CustomerInputReqToVerificationReq = 'CustomerInputReqToVerificationReq',
  ManualProcessingNeededToPaid = 'ManualProcessingNeededToPaid',
  ManualProcessingNeededToPendingHold = 'ManualProcessingNeededToPendingHold',
  ManualProcessingNeededToRemoved = 'ManualProcessingNeededToRemoved',
  ManualProcessingNeededToUnpaidFinalized = 'ManualProcessingNeededToUnpaidFinalized',
  PaidCustomerInputReqToPaidVerificationReq = 'PaidCustomerInputReqToPaidVerificationReq',
  PaidCustomerInputReqToReturned = 'PaidCustomerInputReqToReturned',
  PaidToPaidCustomerInputReq = 'PaidToPaidCustomerInputReq',
  PaidToReturned = 'PaidToReturned',
  PaidVerificationReqToPaid = 'PaidVerificationReqToPaid',
  PaidVerificationReqToPaidCustomerInputReq = 'PaidVerificationReqToPaidCustomerInputReq',
  PaidVerificationReqToPendingHold = 'PaidVerificationReqToPendingHold',
  PaidVerificationReqToReturned = 'PaidVerificationReqToReturned',
  PendingHoldToPaid = 'PendingHoldToPaid',
  PendingHoldToRemoved = 'PendingHoldToRemoved',
  PendingHoldToPendingVerified = 'PendingHoldToPendingVerified',
  PendingPaymentToCanAutoPay = 'PendingPaymentToCanAutoPay',
  PendingPaymentToRemoved = 'PendingPaymentToRemoved',
  PendingVerifiedToPaid = 'PendingVerifiedToPaid',
  PendingVerifiedToRemoved = 'PendingVerifiedToRemoved',
  PendingVerifiedToPendingHold = 'PendingVerifiedToPendingHold',
  StartToCanAutoPay = 'StartToCanAutoPay',
  StartToManualProcessingNeeded = 'StartToManualProcessingNeeded',
  StartToPendingPayment = 'StartToPendingPayment',
  StartToPendingHold = 'StartToPendingHold',
  StartToReturned = 'StartToReturned',
  UnpaidFinalizedToCustomerInputReq = 'UnpaidFinalizedToCustomerInputReq',
  VerificationReqToCustomerInputReq = 'VerificationReqToCustomerInputReq',
  VerificationReqToPaid = 'VerificationReqToPaid',
  VerificationReqToPaidVerificationReq = 'VerificationReqToPaidVerificationReq',
  VerificationReqToPendingHold = 'VerificationReqToPendingHold',
  VerificationReqToPendingVerified = 'VerificationReqToPendingVerified',
  VerificationReqToRemoved = 'VerificationReqToRemoved',
}

export enum CcxSubstantiationLinkCommandType {
  StartToActive = 'StartToActive',
  ActiveToActive = 'ActiveToActive',
  ActiveToRemoved = 'ActiveToRemoved',
}

export enum CcxSubstantiationCommandType {
  StartToActive = 'StartToActive',
  ActiveToActive = 'ActiveToActive',
  ActiveToRemoved = 'ActiveToRemoved',
}

export enum AttachmentState {
  Linked = 'Linked',
  Removed = 'Removed',
  Uploaded = 'Uploaded',
  Start = 'Start',
  Stored = 'Stored',
}

export enum CardAuthorizationState {
  Authorized = 'Authorized',
  Expired = 'Expired',
  Start = 'Start',
  Error = 'Error',
  DeclinedSettlement = 'DeclinedSettlement',
  Settled = 'Settled',
  Declined = 'Declined',
  Refunded = 'Refunded',
  Error_AuthedAfterSettled = 'Error_AuthedAfterSettled',
  Error_CardHolderNotFound = 'Error_CardHolderNotFound',
  ErrorAuthedAfterSettled = 'ErrorAuthedAfterSettled',
  Returned = 'Returned',
}

export enum ClassificationReasonState {
  Active = 'Active',
  Removed = 'Removed',
}

export enum RequestState {
  Any = 'Any',
  Accepted = 'Accepted',
  AreTherePRsRemaining = 'AreTherePRsRemaining',
  AwaitingCustomerInput = 'AwaitingCustomerInput',
  Denied = 'Denied',
  Draft = 'Draft',
  Error = 'Error',
  IsClassified = 'IsClassified',
  IsPaid = 'IsPaid',
  IsValid = 'IsValid',
  Paid = 'Paid',
  PartiallyPaid = 'PartiallyPaid',
  Pending = 'Pending',
  PendingAdmin = 'PendingAdmin',
  PendingSettlement = 'PendingSettlement',
  PotentialDuplicate = 'PotentialDuplicate',
  IsReclassified = 'IsReclassified',
  Removed = 'Removed',
  Returned = 'Returned',
  Start = 'Start',
  Submitted = 'Submitted',
  UnpaidFinalized = 'UnpaidFinalized',
  CanAutoPay = 'CanAutoPay',
  CustomerInputReq = 'CustomerInputReq',
  ManualProcessingNeeded = 'ManualProcessingNeeded',
  PaidCustomerInputReq = 'PaidCustomerInputReq',
  PaidVerificationReq = 'PaidVerificationReq',
  PendingHold = 'PendingHold',
  PendingPayment = 'PendingPayment',
  PendingVerified = 'PendingVerified',
  VerificationReq = 'VerificationReq',
  Complete = 'Complete',
  Substantiate = 'Substantiate',
}

export enum RequestAttachmentState {
  Linked = 'Linked',
  Start = 'Start',
  Removed = 'Removed',
}

export enum RequestCardPaymentState {
  Authorized = 'Authorized',
  AreReceiptsAttached = 'AreReceiptsAttached',
  CanAutoVerify = 'CanAutoVerify',
  Error = 'Error',
  IsVerificationReq = 'IsVerificationReq',
  IsVerified = 'IsVerified',
  Paid = 'Paid',
  PaidCustomerInputReq = 'PaidCustomerInputReq',
  PaidVerificationReq = 'PaidVerificationReq',
  PaidVerified = 'PaidVerified',
  Pending = 'Pending',
  PendingCustomerInputReq = 'PendingCustomerInputReq',
  PendingVerificationReq = 'PendingVerificationReq',
  PendingVerified = 'PendingVerified',
  Removed = 'Removed',
  Repaid = 'Repaid',
  Returned = 'Returned',
  Start = 'Start',
}

export enum RequestManualPaymentState {
  CanAutoPay = 'CanAutoPay',
  CustomerInputReq = 'CustomerInputReq',
  Error = 'Error',
  ManualProcessingNeeded = 'ManualProcessingNeeded',
  Paid = 'Paid',
  PaidCustomerInputReq = 'PaidCustomerInputReq',
  PaidVerificationReq = 'PaidVerificationReq',
  PendingHold = 'PendingHold',
  PendingPayment = 'PendingPayment',
  PendingVerified = 'PendingVerified',
  Start = 'Start',
  Removed = 'Removed',
  UnpaidFinalized = 'UnpaidFinalized',
  Returned = 'Returned',
  VerificationReq = 'VerificationReq',
}

export enum CcxSubstantiationLinkState {
  Active = 'Active',
  Removed = 'Removed',
}

export enum CcxSubstantiationState {
  Active = 'Active',
  Removed = 'Removed',
}

export enum TypeOfMatchType {
  Manual = 'Manual',
  CxcAuto = 'CxcAuto',
}

export enum TypeOfSubstantiationLinkType {
  CxcAutoClaimMatch = 'CxcAutoClaimMatch',
  ManualClaimMatch = 'ManualClaimMatch',
}

export enum CardTransactionStatusType {
  Authorized = 'Authorized',
  Settled = 'Settled',
  Refunded = 'Refunded',
  Reversed = 'Reversed',
  Declined = 'Declined',
}

export enum ParentType {
  CLIENT = 'CLIENT',
  INDIVIDUAL = 'INDIVIDUAL',
  DEPENDENT = 'DEPENDENT',
  REQUEST = 'REQUEST',
  CCX_SUBSTANTIATION = 'CCX_SUBSTANTIATION',
}

export enum DeclineReason {
  CompleteEOBRequired = 'CompleteEOBRequired',
  DDSRequired = 'DDSRequired',
  DuplicateRequest = 'DuplicateRequest',
  EOBRequired = 'EOBRequired',
  IncorrectlySubmittedRequests = 'IncorrectlySubmittedRequests',
  IneligibleExpense = 'IneligibleExpense',
  InsufficientDocumentation = 'InsufficientDocumentation',
  LOMNRequired = 'LOMNRequired',
  NoDocumentationAttached = 'NoDocumentationAttached',
  NoEligibleAccounts = 'NoEligibleAccounts',
  MaxBenefitPaid = 'MaxBenefitPaid',
  NotEnrolled = 'NotEnrolled',
  DocumentationUnreadable = 'DocumentationUnreadable',
  PNROnDocumentation = 'PNROnDocumentation',
  PIRBTRequired = 'PIRBTRequired',
  PremiumStatementRequired = 'PremiumStatementRequired',
  RunoutEnded = 'RunoutEnded',
  ServiceDateOutsideEligibilityDate = 'ServiceDateOutsideEligibilityDate',
  ServiceMustBeRenderedPriorToRequest = 'ServiceMustBeRenderedPriorToRequest',
}

export enum RequestMethodType {
  ACH = 'ACH',
  ATM = 'ATM',
  Card = 'Card',
  Contribution = 'Contribution',
  ContributionReversal = 'ContributionReversal',
  Manual = 'Manual',
  Online = 'Online',
  Reimbursement = 'Reimbursement',
}

export enum RequestStatus {
  Accepted = 'Accepted',
  Denied = 'Denied',
  Draft = 'Draft',
  Pending = 'Pending',
  Returned = 'Returned',
}

export enum PaymentStatusType {
  Paid = 'Paid',
  PartiallyPaid = 'PartiallyPaid',
  Pending = 'Pending',
  Posted = 'Posted',
  Refunded = 'Refunded',
  Returned = 'Returned',
  Reversed = 'Reversed',
  Unpaid = 'Unpaid',
}

export enum ThirdPartyAuthorizationType {
  SigisService90Percent = 'SigisService90Percent',
  SigisServiceIiasHealthcare = 'SigisServiceIiasHealthcare',
  SigisServiceIiasRx = 'SigisServiceIiasRx',
  EdenredService = 'EdenredService',
}

export enum VerificationMethod {
  Copay = 'Copay',
  IIAS = 'IIAS',
  ManualReview = 'ManualReview',
  MccLimit = 'MccLimit',
  Recurring = 'Recurring',
  RequestTypeLimit = 'RequestTypeLimit',
  NotRequired = 'NotRequired',
  Edenred = 'Edenred',
  CARRIER_FILE = 'CARRIER_FILE',
  File = 'File',
  Merchant90Exception = 'Merchant90Exception',
}

export enum VerificationStatus {
  NotRequired = 'NotRequired',
  Required = 'Required',
  Verified = 'Verified',
  Rejected = 'Rejected',
}

export enum RequestSourceType {
  PICTURE_TO_PAY = 'PICTURE_TO_PAY',
  SAVE_FOR_LATER = 'SAVE_FOR_LATER',
  ADMIN = 'ADMIN',
  PAY_THE_PROVIDER = 'PAY_THE_PROVIDER',
  PUX = 'PUX',
  XF_CARD = 'XF_CARD',
  CLUX = 'CLUX',
  CARRIER_FILE = 'CARRIER_FILE',
  TELEFORM = 'TELEFORM',
  TRACKING_PROOF = 'TRACKING_PROOF',
}

export enum PlanRollupType {
  Simple = 'Simple',
  TieredPayouts = 'TieredPayouts',
}

export enum ChargebackType {
  Chargeback = 'Chargeback',
  Dispute = 'Dispute',
}

export enum EnrollmentSourceType {
  AdminWeb = 'AdminWeb',
  ClientWeb = 'ClientWeb',
  ParticipantWeb = 'ParticipantWeb',
  ParticipantMobile = 'ParticipantMobile',
  File = 'File',
  ApiConnex = 'ApiConnex',
}

export enum BenefitPlanCoverageType {
  Single = 'Single',
  SinglePlusOne = 'SinglePlusOne',
  SinglePlusTwo = 'SinglePlusTwo',
  Family = 'Family',
  ByMemberUnlimited = 'ByMemberUnlimited',
}

export enum ParticipantTerminationType {
  FullCoverageWithTerminatedEmployment = 'FullCoverageWithTerminatedEmployment',
  TerminatedAfterPlanEndDate = 'TerminatedAfterPlanEndDate',
}

export enum InEligibilityWindowOption {
  WithinWindow = 'WithinWindow',
  BeforeStart = 'BeforeStart',
  PastLastDateToIncur = 'PastLastDateToIncur',
  PastLastDateToSubmit = 'PastLastDateToSubmit',
}

export enum XfClassificationResultOption {
  NA = 'NA',
  PaidCorrectly = 'PaidCorrectly',
  NotPaidCorrectly = 'NotPaidCorrectly',
  PaidIncorrectly = 'PaidIncorrectly',
  NotPaidIncorrectly = 'NotPaidIncorrectly',
}

export enum ChainType {
  AND = 'AND',
  OR = 'OR',
}

export enum MatchType {
  CONTAINS = 'CONTAINS',
  EXACT = 'EXACT',
  NOT = 'NOT',
  IN = 'IN',
  NOT_IN = 'NOT_IN',
  NOT_CONTAINS = 'NOT_CONTAINS',
  STARTS_WITH = 'STARTS_WITH',
  NOT_STARTS_WITH = 'NOT_STARTS_WITH',
  LESS_THAN = 'LESS_THAN',
  LESS_THAN_EQUAL = 'LESS_THAN_EQUAL',
  GREATER_THAN = 'GREATER_THAN',
  GREATER_THAN_EQUAL = 'GREATER_THAN_EQUAL',
  IS_NULL = 'IS_NULL',
}
