import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { BenefitEnrollmentViewModel } from '../../models';
import { BenefitEnrollmentViewModelState, BenefitEnrollmentViewModelStore } from './benefit-enrollment-view-model.store';

@Injectable({
  providedIn: 'root',
})
export class BenefitEnrollmentViewModelQuery extends QueryEntity<BenefitEnrollmentViewModelState> {
  public constructor(protected store: BenefitEnrollmentViewModelStore) {
    super(store);
  }

  public hasPendingEnrollments(): Observable<boolean> {
    return this.selectAllWhenLoaded()
      .pipe(
        map((enrollments) => enrollments.some((enrollment) => enrollment.isPending)),
      );
  }

  public hasOpenEnrollments(): Observable<boolean> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectCount()),
        map((count) => !!count),
      );
  }

  public selectActiveWhenLoaded(): Observable<BenefitEnrollmentViewModel> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectActive()),
      );
  }

  public selectAllWhenLoaded(): Observable<BenefitEnrollmentViewModel[]> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectAll()),
      );
  }
}
