import { Injectable } from '@angular/core';
import { EntryState, EntryType } from '@models/account/model';
import { DestinationType, SourceType } from '@models/profileConfiguration/model';
import {
  RequestCardPaymentState,
  RequestManualPaymentState,
  RequestMethodType,
  RequestSourceType,
  RequestState,
} from '@models/request/model';
import { BrandId } from '../models/pux';

@Injectable({
  providedIn: 'root',
})
export class StatusService {
  public getBCSEntryTypes(): EntryType[] {
    return [
      EntryType.ParticipantHoldingAccountTransfer,
      EntryType.ParticipantPaymentAccountContribution,
      EntryType.ParticipantPaymentAccountContributionReversal,
      EntryType.ParticipantPaymentGroupCardFunding,
      EntryType.ParticipantPaymentGroupFunding,
      EntryType.ParticipantPaymentGroupRefund,
      EntryType.PaymentAccountClientUnremittance,
      EntryType.PaymentAccountPayeeUnremittance,
      EntryType.PaymentPlanAdminUnremittance,
      EntryType.UndesignatedParticipantPaymentGroupCardFunding,
      EntryType.UndesignatedParticipantPaymentGroupFunding,
      EntryType.UndesignatedParticipantPaymentGroupRefund,
      EntryType.UndesignatedHoldingAccountPositiveAdjustment,
      EntryType.UndesignatedHoldingAccountNegativeAdjustment,
      EntryType.DesignatedHoldingAccountPositiveAdjustment,
      EntryType.DesignatedHoldingAccountNegativeAdjustment,
      EntryType.DesignatedHoldingAccountChargeback,
      EntryType.UndesignatedHoldingAccountChargeback,
      EntryType.PaymentRejectionFee,
    ];
  }

  public getContributionEntryTypes(): EntryType[] {
    return [
      EntryType.BenefitAccountBalanceAdjustment,
      EntryType.BenefitAccountBalanceAdjustmentUnfunded,
      EntryType.BenefitAccountBalanceTransfer,
      EntryType.CardRefund,
      EntryType.CardRequestPayment,
      EntryType.CashInvestmentContribution,
      EntryType.ClientContribution,
      EntryType.DisbursementRejection,
      EntryType.FundedConversionRolloverContribution,
      EntryType.InvestmentSellParticipant,
      EntryType.ManualRefund,
      EntryType.ManualRequestReturn,
      EntryType.MyCashContribution,
      EntryType.MyCashManualRefund,
      EntryType.ParticipantContribution,
      EntryType.ParticipantHoldingAccountTransfer,
      EntryType.ParticipantInterestCreditSecondaryBureau,
      EntryType.ParticipantPaymentGroupCardFunding,
      EntryType.ParticipantPaymentGroupFunding,
      EntryType.PaymentAdjustment,
      EntryType.PayrollFundingPlanRollover,
      EntryType.PayrollPosting,
      EntryType.PodFundingPlanRollover,
      EntryType.SellOrderSettlement,
      EntryType.UndesignatedParticipantPaymentGroupCardFunding,
      EntryType.UndesignatedParticipantPaymentGroupFunding,
      EntryType.UnfundedConversionRolloverContribution,
      EntryType.DesignatedHoldingAccountChargeback,
      EntryType.UndesignatedHoldingAccountChargeback,
    ];
  }

  public getExpenditureEntryTypes(): EntryType[] {
    return [
      EntryType.BenefitAccountBalanceAdjustment,
      EntryType.BenefitAccountBalanceAdjustmentUnfunded,
      EntryType.CardRequestPayment,
      EntryType.DisbursementPreSettlement,
      EntryType.DisbursementSettlement,
      EntryType.DisburseToPayee,
      EntryType.DisburseToPaymentSource,
      EntryType.FundingRejection,
      EntryType.InvestmentBuyParticipant,
      EntryType.InvestmentSweep,
      EntryType.ManualRequestPayment,
      EntryType.ParticipantBalanceFinalization,
      EntryType.ParticipantFeeAssessment,
      EntryType.ParticipantHoldingAccountTransfer,
      EntryType.ParticipantPaymentGroupRefund,
      EntryType.ParticipantPaymentAccountContribution,
      EntryType.PaymentAdjustment,
      EntryType.PayrollFundingPlanRollover,
      EntryType.PodFundingPlanRollover,
      EntryType.PreprocessedDisbursementApproved,
      EntryType.TakeoverDisbursement,
      EntryType.UndesignatedParticipantPaymentGroupRefund,
    ];
  }

  public getActiveRequestStates(): RequestState[] {
    return [
      RequestState.Accepted,
      RequestState.AreTherePRsRemaining,
      RequestState.AwaitingCustomerInput,
      RequestState.Denied,
      RequestState.Draft,
      RequestState.IsClassified,
      RequestState.IsPaid,
      RequestState.IsValid,
      RequestState.Paid,
      RequestState.PartiallyPaid,
      RequestState.Pending,
      RequestState.PendingAdmin,
      RequestState.PendingSettlement,
      RequestState.PotentialDuplicate,
      RequestState.Returned,
      RequestState.Start,
      RequestState.Submitted,
      RequestState.UnpaidFinalized,
    ];
  }

  public getRequestPaymentStatesRequiringVerification(): Array<(RequestCardPaymentState | RequestManualPaymentState | 'Posted')> {
    return [
      RequestManualPaymentState.CustomerInputReq,
      RequestManualPaymentState.PaidCustomerInputReq,
      RequestCardPaymentState.PendingCustomerInputReq,
    ];
  }

  public getMyCashEntryTypes(): EntryType[] {
    return [
      EntryType.ATMWithdraw,
      EntryType.CardRequestPayment,
      EntryType.DisbursementRejection,
      EntryType.ManualRequestPayment,
      EntryType.MyCashBalanceAdjustment,
      EntryType.MyCashContribution,
      EntryType.MycashThresholdDisbursement,
      EntryType.ParticipantPaymentGroupRefund,
      EntryType.PreprocessedDisbursementApproved,
      EntryType.UndesignatedParticipantPaymentGroupRefund,
    ];
  }

  public getPendingEntryTypes(brand?: BrandId): EntryType[] {
    const entryTypes = [
      EntryType.BenefitAccountBalanceAdjustment,
      EntryType.CashInvestmentContribution,
      EntryType.DisburseToPayee,
      EntryType.ManualRefund,
      EntryType.MyCashBalanceAdjustment,
      EntryType.MyCashContribution,
      EntryType.MyCashManualRefund,
      EntryType.MycashThresholdDisbursement,
      EntryType.ParticipantContribution,
      EntryType.ParticipantHoldingAccountTransfer,
      EntryType.ParticipantPaymentAccountContribution,
      EntryType.ParticipantPaymentAccountContributionReversal,
      EntryType.ParticipantPaymentGroupCardFunding,
      EntryType.ParticipantPaymentGroupFunding,
      EntryType.ParticipantPaymentGroupRefund,
      EntryType.PaymentAccountClientUnremittance,
      EntryType.PaymentAccountPayeeUnremittance,
      EntryType.PaymentPlanAdminUnremittance,
      EntryType.PreprocessedDisbursementApproved,
      EntryType.UndesignatedParticipantPaymentGroupCardFunding,
      EntryType.UndesignatedParticipantPaymentGroupFunding,
      EntryType.UndesignatedParticipantPaymentGroupRefund,
      EntryType.UndesignatedHoldingAccountPositiveAdjustment,
      EntryType.UndesignatedHoldingAccountNegativeAdjustment,
      EntryType.DesignatedHoldingAccountPositiveAdjustment,
      EntryType.DesignatedHoldingAccountNegativeAdjustment,
    ];

    if (brand === BrandId.Crossroads) {
      entryTypes.push(EntryType.ClientContribution);
    }

    return entryTypes;
  }

  public getPendingEntryExpenditureTypes(): EntryType[] {
    return [
      EntryType.MycashThresholdDisbursement,
      EntryType.DisburseToPayee,
      EntryType.PreprocessedDisbursementApproved,
      EntryType.UndesignatedHoldingAccountNegativeAdjustment,
      EntryType.DesignatedHoldingAccountNegativeAdjustment,
      EntryType.ParticipantPaymentAccountContribution,
    ];
  }

  public getCardDeclineProtectionEntryTypes(): EntryType[] {
    return [
      EntryType.ParticipantAdvanceContribution,
      EntryType.ParticipantAdvanceFunding,
      EntryType.ParticipantAdvanceReplenishment,
    ];
  }

  public getManualRequestMethods(): RequestMethodType[] {
    return [
      RequestMethodType.Manual,
      RequestMethodType.Online,
      RequestMethodType.Reimbursement,
    ];
  }

  public getPayProviderTypes(): RequestSourceType[] {
    return [
      RequestSourceType.PAY_THE_PROVIDER,
      RequestSourceType.PICTURE_TO_PAY,
    ];
  }

  public getContributionSourceTypes(): SourceType[] {
    return [
      SourceType.IAB,
      SourceType.PaymentSource,
    ];
  }

  public getContributionEntryStates(): EntryState[] {
    return [
      EntryState.Entered,
      EntryState.PendingPublished,
      EntryState.Rejected,
      EntryState.Reversed,
      EntryState.Scheduled,
    ];
  }

  public getBenefitAccountDestinationTypes(): DestinationType[] {
    return [
      DestinationType.BenefitAccount,
      DestinationType.InvestmentBenefitAccount,
    ];
  }

  public getAdjustmentDestinationTypes(): DestinationType[] {
    return [
      DestinationType.BenefitAccount,
      DestinationType.IAB,
      DestinationType.UnfundedBenefitAccount,
    ];
  }
}
