import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { AccountType, FeatureAccount } from 'src/app/shared/models/uba/account/model';

import { FeatureAccountState, FeatureAccountStore } from './feature-account.store';

@Injectable({
  providedIn: 'root',
})
export class FeatureAccountQuery extends QueryEntity<FeatureAccountState> {
  public constructor(protected store: FeatureAccountStore) {
    super(store);
  }

  public selectAccounts(accountTypes: AccountType[]): Observable<FeatureAccount[]> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectAll({
          filterBy: (featureAccount) => accountTypes.includes(featureAccount.name as AccountType),
        })),
      );
  }

  public selectMyCashAccount(): Observable<FeatureAccount> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() =>  this.selectAll({
          filterBy: (featureAccount) => featureAccount.name === AccountType.IAB,
        })),
        map((featureAccounts) => featureAccounts.length ? featureAccounts[0] : null),
      );
  }
}
