import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { Payout } from '../../models';
import { PayoutState, PayoutStore } from './payout.store';

@Injectable({
  providedIn: 'root',
})
export class PayoutQuery extends QueryEntity<PayoutState> {
  public constructor(protected store: PayoutStore) {
    super(store);
  }

  public getEntryId(): string {
    return this.getValue().entryId;
  }

  public selectEntryId(): Observable<string> {
    return this.select((state) => state.entryId);
  }

  public selectActiveWhenLoaded(): Observable<Payout> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectActive()),
      );
  }
}
