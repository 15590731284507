import {Injectable} from '@angular/core';
import {EntityStore, StoreConfig} from '@datorama/akita';
import {AdhocPaymentSourceAccountState} from './add-hoc-payment-source-account.query';

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'AdhocPaymentSourceAccount'})
export class AdhocPaymentSourceAccountStore extends EntityStore<AdhocPaymentSourceAccountState> {
  public constructor() {
    super();
  }
}
