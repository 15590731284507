import { Component, ContentChild, ElementRef, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { DisplayType } from 'src/app/shared/models/pux';

@Component({
  selector: 'app-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
})
export class RadioButtonComponent {
  @Input() public displayType: DisplayType = DisplayType.Block;
  @Input() public label: string;
  @Input() public readonly: boolean;
  @ContentChild(NgControl, { read: ElementRef, static: true }) public radioButtonElement: ElementRef<HTMLInputElement>;
  public DisplayType = DisplayType;
}
