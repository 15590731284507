import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Offerings, SupportRequestTopics } from '@models/support/model';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { LookupStore } from './lookup.store';

@Injectable({
  providedIn: 'root',
})
export class LookupQuery extends QueryEntity<Offerings[]> {
  public constructor(protected store: LookupStore) {
    super(store);
  }

  public selectOfferingsWhenLoaded(): Observable<string[]> {
    return this.selectEntityWhenLoaded()
      .pipe(
        map((response) => response.map((offering) => offering.offering)),
      );
  }

  public selectTopicsWhenLoaded(selectedOffering: string): Observable<string[]> {
    return this.selectEntityWhenLoaded()
      .pipe(
        map((response) =>
          response
            .find((offering) => offering.offering === selectedOffering)
            .topics
            .map((topic) => topic.name)),
      );
  }

  public selectSubtopicsWhenLoaded(selectedOffering: string, selectedTopic: string): Observable<string[]> {
    return this.selectEntityWhenLoaded()
      .pipe(
        map((response) =>
          response
            .find((offering) => offering.offering === selectedOffering)
            ?.topics
            ?.find((topic) => topic.name === selectedTopic)
            ?.subTopics
            ?.map((subtopic) => subtopic.name),
        ),
      );
  }

  public selectProjectIdWhenLoaded(selectedOffering: string, selectedTopic: string, selectedSubtopic: string): Observable<number> {
    return this.selectEntityWhenLoaded()
      .pipe(
        map((response) =>
          response
            .find((offering) => offering.offering === selectedOffering)
            .topics
            .find((topic) => topic.name === selectedTopic)
            .subTopics
            .find((subtopic) => subtopic.name === selectedSubtopic).projectId,
        ),
      );
  }

  public selectEntityWhenLoaded(): Observable<SupportRequestTopics> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectAll()),
      );
  }
}
