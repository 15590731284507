import { formatCurrency } from '@angular/common';

export class Numbers {
  public static roundToHundredth(value: number, roundingStrategy: RoundingStrategy = RoundingStrategy.RoundToNearest): number {
    switch (roundingStrategy) {
      case RoundingStrategy.RoundDown:
        return Math.floor(100 * value) / 100;
      case RoundingStrategy.RoundToNearest:
        return Math.round(100 * value) / 100;
      case RoundingStrategy.RoundUp:
        return Math.ceil(100 * value) / 100;
    }
    throw new Error(`Unrecognized rounding strategy: ${roundingStrategy}`);
  }

  public static convertFromDollarsToNumber(value: string): number {
    value = value.replace('$', '');
    value = value.replace(',', '');
    const result = Number.parseFloat(value);
    return isNaN(result) ? null : result;
  }

  public static formatCurrency(value: number): string {
    return formatCurrency(value, 'en-US', '$', 'USD');
  }
}

export enum RoundingStrategy {
  RoundDown,
  RoundToNearest,
  RoundUp,
}
