<div class="form-group input-group" [ngClass]="{'input-group-sm': size === 'sm', 'input-group-lg': size === 'lg'}">
  <div #prepend [hidden]="!prepend?.hasChildNodes()" class="input-group-prepend">
    <ng-content select=".input-group-text.prepend"></ng-content>
  </div>
  <label #formControl [hidden]="formControl?.childNodes.length < 2">
    <span class="moveable-label" *ngIf="label">{{label}}</span>
    <ng-content select="input.form-control, textarea.form-control"></ng-content>
    <ng-content select=".action-text"></ng-content>
    <i *ngIf="inputControl?.value && clearable" (click)="onClearInput()" class="icon icon-close" aria-hidden="true"></i>
    <i *ngIf="inputControl?.valid && !clearable && showIconWhenValid" class="icon icon-check" aria-hidden="true"></i>
    <i *ngIf="!inputControl?.valid && !clearable" class="icon icon-process" aria-hidden="true"></i>
    <ng-content select="app-error"></ng-content>
  </label>
  <div #append [hidden]="!append?.hasChildNodes()" class="input-group-append">
    <ng-content select=".input-group-text.append"></ng-content>
  </div>
</div>
