import { Injectable } from '@angular/core';
import { CommandBase as ICommandBase, EntityBase as AccountEntityBase } from '@models/account/model';
import { EntityBase as CardEntityBase } from '@models/card/model';
import { EntityBase as CommunicationEntityBase } from '@models/communication/model';
import { EntityBase as ConfigurationEntityBase } from '@models/configuration/model';
import { Attachment, EntityBase as FileEntityBase } from '@models/file/model';
import { EntityBase as LookupEntityBase } from '@models/lookup/model';
import { EntityBase as PaymentAccountEntityBase } from '@models/paymentAccount/model';
import { EntityBase as ProfileEntityBase } from '@models/profile/model';
import { EntityBase as ProfileConfigurationEntityBase } from '@models/profileConfiguration/model';
import { EntityBase as RequestEntityBase } from '@models/request/model';
import { EntityBase as SecurityEntityBase } from '@models/security/model';
import { Attachment as CommentAttachments, EntityBase as SupportEntityBase, SupportRequestComments, SupportRequestCommentsCommand } from '@models/support/model';
import { IndividualQuery } from 'src/app/state';
import { v4 as uuid } from 'uuid';

import { environment } from '../../../environments/environment';
import { Clone } from './clone';
import { Dates } from './dates';
import { Transitions } from './transitions';

@Injectable({
  providedIn: 'root',
})
export class CommandFactory {
  public constructor(
    private individualQuery: IndividualQuery,
  ) { }

  // Have to disable typedef linting here b/c SonarQube warns when params with default values don't have inferred types
  // tslint:disable-next-line: typedef
  public createCommand<T extends EntityBase>(data: T, commandType: string, updateData = true): CommandBase<T> {
    const individual = this.individualQuery.getActive();
    const date: string = Dates.nowISO();
    const user: string = individual.fullName;
    const userId: string = individual.id;
    const updatedData = updateData ? this.updateData(data, commandType, date, user, userId) : data;

    return {
      id: uuid(),
      version: 1,
      producerId: environment.services.producerId,
      eventCorrelationId: uuid(),
      created: date,
      createdBy: user,
      createdById: userId,
      type: commandType,
      data: updatedData,
    };
  }

  public createReplyCommand(fileData: string, attachmentInfo?: Attachment[]): SupportRequestCommentsCommand {
    let commentAttachments: CommentAttachments[];
    if (attachmentInfo && attachmentInfo.length > 0) {
      commentAttachments = attachmentInfo.map(
        (c): Attachment => ({filePath: c.filePath, attachmentType: c.attachmentType, friendlyFileName: c.friendlyFileName}));
    }
    const contact = this.individualQuery.getActive();
    const fullName = `${contact.firstName} ${contact.lastName}`;
    let requestBody: SupportRequestComments = {commentText: `${fileData}`};
    if (attachmentInfo.length > 0) {
      requestBody = {
        commentText: `${fileData}`,
        attachments: commentAttachments,
      };
    }
    return {
      id: uuid(),
      producerId: environment.services.producerId,
      created: Dates.nowISO(),
      createdBy: fullName,
      createdById: contact.id,
      data: requestBody,
    };
  }

  private updateData<T extends EntityBase>(data: T, commandType: string, date: string, user: string, userId: string): T {
    const clone = Clone.deep(data);
    clone.lastTransition = commandType;
    clone.currentState = Transitions.toState(commandType);
    if (Transitions.fromState(commandType) === 'Start' || Transitions.fromState(commandType) === 'Set') {
      clone.created = date;
      clone.createdBy = user;
      clone.createdById = userId;
    }

    clone.updated = date;
    clone.updatedBy = user;
    clone.updatedById = userId;

    if (!clone.version) {
      clone.version = 1;
    }

    return clone;
  }
}

export interface CommandBase<T> extends ICommandBase {
  data?: T;
}

type EntityBase = AccountEntityBase
  | CardEntityBase
  | CommunicationEntityBase
  | ConfigurationEntityBase
  | FileEntityBase
  | LookupEntityBase
  | ProfileEntityBase
  | ProfileConfigurationEntityBase
  | RequestEntityBase
  | SecurityEntityBase
  | SupportEntityBase
  | PaymentAccountEntityBase;
