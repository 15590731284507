import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { BenefitPlanRequestType } from '@models/configuration/model';
import { Observable } from 'rxjs/internal/Observable';
import { filter, map, switchMap } from 'rxjs/operators';

import { BenefitPlanRequestTypeState, BenefitPlanRequestTypeStore } from './benefit-plan-request-type.store';

@Injectable({
  providedIn: 'root',
 })
export class BenefitPlanRequestTypeQuery extends QueryEntity<BenefitPlanRequestTypeState> {
  public constructor(protected store: BenefitPlanRequestTypeStore) {
    super(store);
  }

  public selectAllWhenLoaded(): Observable<BenefitPlanRequestType[]> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectAll()),
      );
  }

  public selectByBenefitPlan(benefitPlanId: string): Observable<BenefitPlanRequestType> {
    return this.selectAllWhenLoaded()
    .pipe(
      map((benefitPlanRequestTypes) => benefitPlanRequestTypes.find((benefitPlanRequestType) => benefitPlanRequestType.parentId === benefitPlanId)),
    );
  }
}
