import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { AuthenticationChallengeType } from 'src/app/auth/models/authentication-challenge.model';
import { BrowserLocalStorageService } from 'src/app/shared/services/browser-local-storage.service';

import { CurrentUserState } from './current-user.model';

function createInitialState(): CurrentUserState {
  return {
    profileId: '',
    cognitoPhoneNumber: null,
    email: '',
    rememberedEmail: null,
    accessToken: null,
    idToken: null,
    refreshToken: null,
    authenticationType: AuthenticationChallengeType.Unknown,
  };
}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'CurrentUser' })
export class CurrentUserStore extends Store<CurrentUserState> {
  private static readonly rememberUserKey = 'remember';

  public constructor(private localStorageService: BrowserLocalStorageService) {
    super(createInitialState());
    this.setRememberedEmail();
  }

  public forgetUser(): void {
    this.localStorageService.remove(CurrentUserStore.rememberUserKey);
    this.update({
      rememberedEmail: null,
    });
  }

  public rememberUser(emailAddress: string): void {
    if (!emailAddress) {
      this.forgetUser();
      return;
    }

    this.localStorageService.set(CurrentUserStore.rememberUserKey, emailAddress);
    this.update({
      rememberedEmail: emailAddress,
    });
  }

  public reset(): void {
    super.reset();
    this.setRememberedEmail();
  }

  private setRememberedEmail(): void {
    const rememberedEmail = this.localStorageService.get<string | { _value: string }>(CurrentUserStore.rememberUserKey) || null;

    if (typeof rememberedEmail === 'string') {
      this.update({ rememberedEmail });
    } else if (rememberedEmail && rememberedEmail._value) {
      /*
      * We used to store in Local Storage with a third party package. The package stored the values in an object
      * with a '_value' field. This statement checks for the third party storage method and handles it accordingly.
      */
      this.update({ rememberedEmail: rememberedEmail._value });
    }
  }
}
