import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { BenefitPlan } from 'src/app/shared/models/uba/configuration/model';

export interface BenefitPlanState extends EntityState<BenefitPlan, string> {}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'BenefitPlan' })
export class BenefitPlanStore extends EntityStore<BenefitPlanState> {
  public constructor() {
    super();
  }
}
