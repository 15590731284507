import {Injectable} from '@angular/core';
import {Order, QueryConfig, QueryEntity} from '@datorama/akita';
import { CcxSubstantiations } from '@models/request/model';
import { Observable, forkJoin, of } from 'rxjs';
import { filter, first, map, switchMap } from 'rxjs/operators';
import { CcxSubstantiationState, CcxSubstantiationStore } from './ccx-substantiation.store';
import { CcxSubstantiationLinkQuery } from '../ccx-substantiation-link';

@QueryConfig({
  sortBy: 'serviceBeginDate',
  sortByOrder: Order.ASC,
})
@Injectable({
  providedIn: 'root',
})
export class CcxSubstantiationQuery extends QueryEntity<CcxSubstantiationState> {
  public constructor(
    protected store: CcxSubstantiationStore,
    private ccxLinkQuery: CcxSubstantiationLinkQuery,
  ) {
    super(store);
  }

  public selectAllWhenLoaded(): Observable<CcxSubstantiations> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectAll()),
      );
  }

  public selectNotAssociatedToRequest(requestId: string): Observable<CcxSubstantiations> {
    return this.selectAllWhenLoaded()
      .pipe(
        switchMap((claims) => {
          return forkJoin([
            of(claims),
            this.ccxLinkQuery.selectByRequest(requestId).pipe(first()),
          ]);
        }),
        map(([claims, substantiationLinks]) => claims.filter((c) => !substantiationLinks.some((l) => l.parentId === c.id))),
      );
  }

}
