import { ErrorHandler, Injectable } from '@angular/core';

import { RaygunService } from './raygun.service';

@Injectable()
export class RaygunErrorHandler implements ErrorHandler {
  public constructor(private raygunService: RaygunService) { }

  public handleError(err: any): void {
    this.raygunService.logError(err);
  }
}
