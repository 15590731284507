import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { DocumentAction } from 'src/app/shared/models/uba/profileConfiguration/model';

import { DocumentActionState, DocumentActionStore } from './document-action.store';

@Injectable({
  providedIn: 'root',
})
export class DocumentActionQuery extends QueryEntity<DocumentActionState> {
  public constructor(protected store: DocumentActionStore) {
    super(store);
  }

  public hasSignedDocument(documentId: string): Observable<boolean> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectAll({
          filterBy: (signature) => signature.documentId === documentId,
        })),
        map((signatures) => !!signatures.length),
      );
  }
}
