import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { BenefitPlanRequestType } from '@models/configuration/model';

export interface BenefitPlanRequestTypeState extends EntityState<BenefitPlanRequestType, string> {}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'BenefitPlanRequestType' })
export class BenefitPlanRequestTypeStore extends EntityStore<BenefitPlanRequestTypeState> {
  public constructor() {
    super();
  }
}
