export const tascURLs: string[] = [
  'localhost', // Comment this line to switch to BASIC branding on localhost

  'd20b4h0y9jql2t.cloudfront.net', // D1
  'd18lz8s2rsedu3.cloudfront.net', // D2
  'd1n87p7wxg7y9p.cloudfront.net', // D3
  'd10hqzzfxzowdd.cloudfront.net', // D4
  'd3j1iosjolol4.cloudfront.net',  // D5
  'd1t87hulbt4ve1.cloudfront.net', // D6
  'd19l5nz4vnaltl.cloudfront.net', // D7
  'd1mx74kmiw6b92.cloudfront.net', // D8
  'dpi53bjyv6p43.cloudfront.net',  // D9

  'd3ee0l5jqdgwbb.cloudfront.net', // T1
  'd1pzzex2brggot.cloudfront.net', // T2
  'd3flsqejlgmdzb.cloudfront.net', // T3
  'd7hc1lmjqv7j8.cloudfront.net',  // T4
  'd16emmyf7mv6nu.cloudfront.net', // T5

  'dzj1hizd3t66u.cloudfront.net',  // O1
  'd3lomvqmn3et6t.cloudfront.net', // O2
  'd11voqcjpd335e.cloudfront.net', // O3
  'd2xwtirx25ek4i.cloudfront.net', // O4
  'd3lx0zpikqj65w.cloudfront.net', // O5
  'dt973hod4ooif.cloudfront.net',  // O6
  'd204q6t48lhxq5.cloudfront.net', // O7
  'd8uhfvxtuyjqy.cloudfront.net',  // O8

  'd236sanl2k0z74.cloudfront.net', // L1
  'd2tfbprxq5qxwm.cloudfront.net', // L2
  'd3j497gou3eny9.cloudfront.net', // L3

  'd2xdwzf1xqto4n.cloudfront.net', // S1
  'd479inrcdly6.cloudfront.net',   // S2
  'daqqz2n12dlf1.cloudfront.net',  // S3
  'd10f748kjoetgo.cloudfront.net', // S4
  'd33wcvvcn0my6s.cloudfront.net', // S5
  'acm-pux2.tasconline.com',       // S6
  'd15sjosk3osonb.cloudfront.net', // S7

  'd20kz3mip46g0q.cloudfront.net', // Prod 3
  'uba.tasconline.com',            // Prod 1
];
