import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {withTransaction} from '@datorama/akita';
import ABAValidator from 'abavalidator';
import {merge, Observable, of} from 'rxjs';
import {catchError, mapTo, reduce, switchMap} from 'rxjs/operators';
import {
  AddressOwnerType,
  BankAccount,
  BankAccountCommandType,
  BankAccountState,
  ChainType,
  CommandBase,
  MatchType,
  ParentType,
  PaymentAccountType,
  SearchCriteria,
} from 'src/app/shared/models/uba/account/model';
import {CommandFactory} from 'src/app/shared/utils/command.factory';
import {IndividualQuery, PaymentSourceAccountStore} from 'src/app/state';
import {BankAccountStore} from 'src/app/state/bank-account';

import {CoreService} from '../../models/pux/enum';
import {ErrorHandlingService} from '../error-handling.service';
import {Uri} from '../uri';
import {PaymentSourceAccountService} from './payment-source-account.service';

@Injectable({
  providedIn: 'root',
})
export class BankAccountService {
  public constructor(
    private bankAccountStore: BankAccountStore,
    private commandFactory: CommandFactory,
    private errorHandlingService: ErrorHandlingService,
    private http: HttpClient,
    private individualQuery: IndividualQuery,
    private paymentSourceAccountService: PaymentSourceAccountService,
    private paymentSourceAccountStore: PaymentSourceAccountStore,
  ) { }

  /**
   * Find bank name based on routing number.
   * @param routingNumber
   */
  public async findBankByRoutingNumber(routingNumber: string): Promise<string> {
    if (routingNumber && routingNumber.length === 9) {
      if (this.isValidRoutingNumber(routingNumber)) {
        const aBanknameWithRouting = await import('../../../../assets/json/aBanknameWithRouting.json');
        const bank = aBanknameWithRouting.default.find((o) => o.routing_number === routingNumber);
        if (bank) {
          return bank.bank_name;
        }
      } else {
        return null;
      }
    }
  }

  /**
   * Checks for valid routing number.
   * @param routingNumber
   */
  public isValidRoutingNumber(routingNumber: string): boolean {
    return ABAValidator.validate(routingNumber);
  }

  /**
   * Return the bank accounts associated with the logged on user.
   */
  public getBankAccounts(): Observable<BankAccount[]> {
    const individualId = this.individualQuery.getActiveId();
    const bankAccountCriteria: SearchCriteria = [
      {
        key: 'parentId',
        value: individualId,
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      },
      {
        key: 'parentType',
        value: ParentType.INDIVIDUAL,
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      },
      {
        key: 'paymentAccountType',
        value: [PaymentAccountType.BankAccount, PaymentAccountType.Card].join('|'),
        matchType: MatchType.IN,
      },
    ];
    const providerAccountCriteria: SearchCriteria = [
      {
        key: 'parentId',
        value: individualId,
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      },
      {
        key: 'parentType',
        value: ParentType.INDIVIDUAL,
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      },
      {
        key: 'currentState',
        value: BankAccountState.Active,
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      },
      {
        key: 'paymentAccountType',
        value: PaymentAccountType.Check,
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      },
      {
        key: 'addressOwnerType',
        value: AddressOwnerType.External,
        matchType: MatchType.EXACT,
      },
    ];

    /**
     * Added fake query params to satisfy Cypress tests. Cypress is unable to detect the difference between
     * two endpoints that have the same url but different SearchCriteria. Feel free to remove query params
     * if a fix for Cypress tests is found. Here is a link to the github issue https://github.com/cypress-io/cypress/issues/4460.
     */
    const bankAccountUri = new Uri(`/profile/${individualId}/bankAccount/search`, CoreService.Account);
    bankAccountUri.addQueryParam('intent', 'BankAccount');
    const providerUri = new Uri(`/profile/${individualId}/bankAccount/search`, CoreService.Account);
    providerUri.addQueryParam('intent', 'Provider');

    return merge(
      this.httpPost<BankAccount[]>(bankAccountUri, bankAccountCriteria),
      this.httpPost<BankAccount[]>(providerUri, providerAccountCriteria),
    ).pipe(
      reduce((accounts, results) => accounts.concat(results)),
      withTransaction((bankAccounts) => this.bankAccountStore.set(bankAccounts)),
      catchError(this.errorHandlingService.rxjsErrorHandler()),
    );
  }

  /**
   * Persist a new bank account entity to the data store.
   * @param bankAccount The new bank account to persist.
   * @param commandType Command to run for BankAccount.
   * @param nonce One time token from Braintree.
   */
  public addBankAccount(bankAccount: BankAccount, commandType: BankAccountCommandType = BankAccountCommandType.StartToActive, nonce?: string): Observable<BankAccount> {
    return this.updateBankAccount(bankAccount, commandType, nonce)
      .pipe(
        switchMap((addedBankAccount) => this.paymentSourceAccountService.getPaymentSourceAccounts().pipe(mapTo(addedBankAccount))),
        withTransaction((addedBankAccount) => this.bankAccountStore.add(addedBankAccount)),
        catchError(this.errorHandlingService.rxjsErrorHandler()),
      );
  }

  /**
   * Delete the bank account from the data store.
   * @param bankAccount The bank account to remove.
   */
  public removeBankAccount(bankAccount: BankAccount): Observable<BankAccount> {
    return this.updateBankAccount(bankAccount, BankAccountCommandType.ActiveToInactive)
      .pipe(
        withTransaction(() => {
          this.paymentSourceAccountStore.remove((account) => account.paymentAccountId === bankAccount.id);
          this.bankAccountStore.remove(bankAccount.id);
        }),
        catchError(this.errorHandlingService.rxjsErrorHandler()),
      );
  }

  private updateBankAccount(bankAccount: BankAccount, commandName: BankAccountCommandType, nonce?: string): Observable<BankAccount> {
    const relativeURL = `/profile/${bankAccount.parentId}/bankAccount/${bankAccount.id}/command/${commandName}`;
    const uri = new Uri(relativeURL, CoreService.Account);
    if (nonce) {
      uri.addQueryParam('nonce', nonce);
    }
    const command = this.commandFactory.createCommand(bankAccount, commandName);
    return this.httpPut(uri, command)
      .pipe(
        mapTo(command.data),
      );
  }

  private httpPost<T>(uri: Uri, requestBody: SearchCriteria): Observable<T> {
    return this.http.post<T>(uri.toString(), requestBody)
      .pipe(catchError(this.errorHandlingService.rxjsErrorHandler()));
  }

  private httpPut(uri: Uri, requestBody: CommandBase): Observable<void> {
    return this.http.put<void>(uri.toString(), requestBody)
      .pipe(catchError(this.errorHandlingService.rxjsErrorHandler()));
  }
}
