import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { MarginalsModule } from '../marginals/marginals.module';
import { FormControlsModule } from '../shared/components/forms/form-controls.module';
import { DirectivesModule } from '../shared/directives/directives.module';
import { SharedModule } from '../shared/shared.module';
import { TermsOfUseModule } from '../terms-of-use/terms-of-use.module';
import { LoginCodeOnlyComponent } from './components/login-code-only/login-code-only.component';
import { LoginFederatedIdPiPageComponent } from './components/login-federated-IdPi/login-federated-idpi.component';
import { LoginFederatedPageComponent } from './components/login-federated/login-federated.component';
import {
  FederatedPasswordUserNullModalComponent,
} from './components/login-form/federated-password-user-null-modal/federated-password-user-null-modal.component';
import {
  FederatedRedirectModalComponent,
} from './components/login-form/federated-redirect-modal/federated-redirect-modal.component';
import { LoginFormComponent } from './components/login-form/login-form.component';
import { LoginHeaderComponent } from './components/login-header/login-header.component';
import { LoginMFAComponent } from './components/login-mfa/login-mfa.component';
import { LogoutFederatedComponent } from './components/logout-federated/logout-federated.component';
import { LogoutComponent } from './components/logout/logout.component';
import { NewPasswordRequiredComponent } from './components/new-password-required/new-password-required.component';
import { NoBrandComponent } from './components/no-brand/no-brand.component';
import {
  UnsupportedBrowserModalComponent,
} from './components/unsupported-browser-modal/unsupported-browser-modal.component';
import { LoginPageComponent } from './containers/login-page/login-page.component';

@NgModule({
  declarations: [
    FederatedPasswordUserNullModalComponent,
    FederatedRedirectModalComponent,
    LoginFormComponent,
    LoginHeaderComponent,
    LoginMFAComponent,
    LoginPageComponent,
    LoginCodeOnlyComponent,
    LoginFederatedPageComponent,
    LoginFederatedIdPiPageComponent,
    LogoutComponent,
    LogoutFederatedComponent,
    NewPasswordRequiredComponent,
    NoBrandComponent,
    UnsupportedBrowserModalComponent,
  ],
  imports: [
    CommonModule,
    DirectivesModule,
    FormControlsModule,
    MarginalsModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    TermsOfUseModule,
  ],
})
export class LoginModule {
  public constructor(@Optional() @SkipSelf() self: LoginModule) {
    if (self) {
      throw new Error(
        'LoginModule is already loaded. Import it in the AppModule only.');
    }
  }
}
