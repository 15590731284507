import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/auth/services/authentication.service';
import { ModalComponent } from 'src/app/shared/components/forms/modal/modal.component';

@Component({
  selector: 'app-federated-password-user-null-modal',
  templateUrl: './federated-password-user-null-modal.component.html',
  styleUrls: ['./federated-password-user-null-modal.component.scss'],
})
export class FederatedPasswordUserNullModalComponent {
  @ViewChild(ModalComponent, { static: true }) public modal: ModalComponent;

  public buttonLabel: string;
  private federatedIDURL: string;
  private identityProvider: string;
  private emailAddress: string;

  public constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
  ) { }

  public open(identityProvider: string, emailAddress: string, idpOverrides?: any): void {
    this.modal.open();
    this.identityProvider = identityProvider;
    this.emailAddress = emailAddress;
    this.buttonLabel = 'Continue to your employer portal';
    this.federatedIDURL = null;
    if (idpOverrides && idpOverrides.federatedPasswordUserNullModal) {
      this.buttonLabel = idpOverrides.federatedPasswordUserNullModal.buttonLabel || this.buttonLabel;
      this.federatedIDURL = idpOverrides.federatedPasswordUserNullModal.buttonURL || this.federatedIDURL;
    }
  }

  public redirectToFederatedSignInPage(): void {
    this.authenticationService.redirectToFederatedSignInPage(this.identityProvider, this.emailAddress, this.federatedIDURL);
  }
}
