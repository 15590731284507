import { Injectable } from '@angular/core';
import { Order, QueryConfig, QueryEntity } from '@datorama/akita';
import { Attachment } from '@models/file/model';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { BillReceiptState, BillReceiptStore } from './bill-receipt.store';

@Injectable({
  providedIn: 'root',
})
@QueryConfig({
  sortBy: 'created',
  sortByOrder: Order.DESC,
})
export class BillReceiptQuery extends QueryEntity<BillReceiptState> {
  public constructor(protected store: BillReceiptStore) {
    super(store);
  }

  public selectAllWhenLoaded(): Observable<Attachment[]> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectAll()),
      );
  }
}
