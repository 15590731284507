import {Injectable} from '@angular/core';
import {QueryEntity} from '@datorama/akita';
import {Observable} from 'rxjs';
import {filter, map, switchMap} from 'rxjs/operators';
import {RaygunService} from 'src/app/raygun/raygun.service';
import {PageFilter, RequestPageType, TransactionActivityEndpointSource, TransactionCategory, TransactionStateCategory} from 'src/app/shared/models/pux/enum';
import {TransactionActivityModel} from 'src/app/shared/models/pux/model';
import {EntryState, EntryType, TransactionType} from 'src/app/shared/models/uba/account/model';
import {DestinationType, SourceType} from 'src/app/shared/models/uba/profileConfiguration/model';
import {RequestMethodType, RequestState} from 'src/app/shared/models/uba/request/model';
import {StatusService} from 'src/app/shared/services/status.service';

import {FeatureAccountQuery} from '../feature-account';
import {TransactionActivityState, TransactionActivityStore} from './transaction-activity.store';
import {sortBy} from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class TransactionActivityQuery extends QueryEntity<TransactionActivityState> {

  public constructor(
    private featureAccountQuery: FeatureAccountQuery,
    private raygunService: RaygunService,
    private statusService: StatusService,
    protected store: TransactionActivityStore,
    ) {
    super(store);
  }

  public selectByPageFilter(pageFilter: PageFilter): Observable<TransactionActivityModel[]> {
    // Special cases to hide from myCash view
    const shouldHideUndesignatedRefundFromMyCash = (tx: TransactionActivityModel) => (
      [ TransactionActivityEndpointSource.Account, TransactionActivityEndpointSource.PaymentAccountContribution ].includes(tx.dataSource) &&
      [ EntryType.UndesignatedParticipantPaymentGroupRefund, EntryType.ParticipantPaymentGroupRefund ].includes(tx.entryType) &&
      tx.transactionType === TransactionCategory.Expenditure);
    const entryTypesToHideFromMyCash = (tx: TransactionActivityModel) => (
      [ EntryType.ParticipantHoldingAccountTransfer ].includes(tx.entryType));
    const shouldHidePendingRefundFromMyCash = (tx: TransactionActivityModel) => (
      tx.dataSource === TransactionActivityEndpointSource.PendingEntry &&
      [ EntryType.ParticipantPaymentGroupRefund ].includes(tx.entryType) &&
      tx.transactionType === TransactionCategory.Expenditure);
    const specialCasesHidingFromMyCash = [ shouldHideUndesignatedRefundFromMyCash, shouldHidePendingRefundFromMyCash, entryTypesToHideFromMyCash ];

    // Special cases to hide from the contributions view
    const shouldHideFromContributions = (tx: TransactionActivityModel) => (
      [ EntryType.ParticipantPaymentGroupRefund, EntryType.UndesignatedParticipantPaymentGroupRefund ].includes(tx.entryType));

    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => {
          switch (pageFilter) {
            case PageFilter.All:
              return this.selectAll();
            case PageFilter.Contributions:
              return this.selectAll({
                filterBy: (tx) => tx.transactionType === TransactionCategory.Contribution && !shouldHideFromContributions(tx),
              });
            case PageFilter.Expenditures:
              return this.selectAll({
                filterBy: (tx) => (!tx.postingType || tx.postingType === TransactionType.DEBIT) && tx.transactionType === TransactionCategory.Expenditure,
              });
            case PageFilter.MyCash:
              // By default the MyCash page shows all MyCash and CDP transactions
              return this.featureAccountQuery.selectAll()
                .pipe(
                  switchMap((featureAccounts) => {
                    const accountIds = featureAccounts.filter((fa) => !!fa).map((fa) => fa.id);
                    return this.selectAll({
                      filterBy: (tx) => {
                        return (accountIds.includes(tx.postingAccountId)
                        || tx.fundsTransferSourceType === SourceType.IAB
                        || tx.fundsTransferDestinationType === DestinationType.IAB
                        || tx.planName === 'MyCash'
                        || (tx.dataSource === TransactionActivityEndpointSource.PendingEntry && this.statusService.getMyCashEntryTypes().includes(tx.entryType)))
                        && !specialCasesHidingFromMyCash.some((rule) => rule(tx)); }, // Hide if any of the rules pass
                    });
                  }),
                );
            case PageFilter.CardDeclineProtection:
              return this.selectAll({
                filterBy: (tx) => this.statusService.getCardDeclineProtectionEntryTypes().includes(tx.entryType),
              });
            default:
              this.raygunService.logError('Unexpected PageFilter value', { PageFilter: pageFilter });
              return this.selectAll();
          }
        }),
      );
  }

  public selectByRequest(requestId: string): Observable<TransactionActivityModel[]> {
    return this.selectAll({
      filterBy: (_transaction) => _transaction.requestId === requestId,
    });
  }

  public selectCarryoverTransactions(benefitAccountId: string): Observable<TransactionActivityModel[]> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectAll({
          filterBy: (tx) => tx.benefitAccountId === benefitAccountId
            && (tx.entryType === EntryType.PayrollFundingPlanRollover || tx.entryType === EntryType.PodFundingPlanRollover),
        })),
      );
  }

  public selectManualReimbursementRequests(requestPageType: RequestPageType): Observable<TransactionActivityModel[]> {
    return this.selectByPageFilter(PageFilter.Expenditures)
      .pipe(
        map((transactions) => transactions.filter((tx) => {
          if (!tx.requestId || tx.requestMethod !== RequestMethodType.Manual) {
            return false;
          }
          switch (requestPageType) {
            case RequestPageType.Draft:
              return tx.requestCurrentState === RequestState.Draft;
            case RequestPageType.Provider:
              return tx.requestCurrentState !== RequestState.Draft && this.statusService.getPayProviderTypes().includes(tx.requestSourceId);
            case RequestPageType.Request:
              return tx.requestCurrentState !== RequestState.Draft && !this.statusService.getPayProviderTypes().includes(tx.requestSourceId);
          }
        })),
      );
  }

  public pendingContributionsData(): Observable<{pendingContributions: TransactionActivityModel[], postedContributions: TransactionActivityModel[]}> {
    return this.selectByPageFilter(PageFilter.Contributions)
      .pipe(
        map((transactions) => {
          const pendingContributions = transactions.filter((_transaction) =>
            _transaction.transactionStateCategory === TransactionStateCategory.Pending &&
            _transaction.entryCurrentState !== EntryState.Scheduled &&
            (
              _transaction.entryAmount > 0 ||
                _transaction.postingAmount > 0 ||
                _transaction.fundsTransferAmount > 0
            ) &&
            _transaction.transactionType === TransactionCategory.Contribution);

          const postedContributions = transactions
            .filter((_transaction) =>
              _transaction.transactionStateCategory === TransactionStateCategory.Posted &&
              _transaction.transactionType === TransactionCategory.Contribution &&
              [
                EntryType.ParticipantPaymentGroupFunding,
                EntryType.ParticipantPaymentGroupCardFunding,
                EntryType.ParticipantHoldingAccountTransfer,
              ].includes(_transaction.entryType),
            );

          return ({
            pendingContributions,
            postedContributions : sortBy(postedContributions, ['entryTransactionDateTime']).reverse(),
          });
        }),
      );
  }
}
