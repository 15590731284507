import { Injectable } from '@angular/core';
import { EntityState, Order, QueryConfig, QueryEntity } from '@datorama/akita';
import { PaymentSourceAccount } from '@models/account/model';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { AdhocPaymentSourceAccountStore } from './adhoc-payment-source-account.store';

export interface AdhocPaymentSourceAccountState extends EntityState<PaymentSourceAccount, string> {}

@Injectable({providedIn: 'root'})
@QueryConfig({
  sortBy: 'created',
  sortByOrder: Order.DESC,
})
export class AddHocPaymentSourceAccountQuery extends QueryEntity<AdhocPaymentSourceAccountState> {
  public constructor(
    protected store: AdhocPaymentSourceAccountStore) {
    super(store);
  }

  public selectAllWhenLoaded(): Observable<PaymentSourceAccount[]> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectAll()),
      );
  }
}
