// AUTOGENERATED BY FUNCTION genubamodel.js
export interface BenefitPlanCommand extends CommandBase {
  data?: BenefitPlan;
  fundingSourcesAndSchedules?: BenefitPlanFundingSourcesAndSchedules;
  advancedVerification?: BenefitPlanAdvancedVerification;
  merchantCategory?: BenefitPlanMerchantCategory;
  paymentTiers?: BenefitPlanPaymentTiers;
  requestType?: BenefitPlanRequestType;
}

export interface PaymentPlanCommand extends CommandBase {
  data?: PaymentPlan;
}

export interface BenefitPlanAdvancedVerificationCommand extends CommandBase {
  data?: BenefitPlanAdvancedVerification;
  benefitPlan?: BenefitPlan;
  merchantCategory?: BenefitPlanMerchantCategory;
  requestType?: BenefitPlanRequestType;
}

export interface BenefitPlanFundingSourceAndScheduleCommand extends CommandBase {
  data?: BenefitPlanFundingSourceAndSchedule;
}

export interface PaymentPlanFundingSourceAndScheduleCommand extends CommandBase {
  data?: PaymentPlanFundingSourceAndSchedule;
}

export interface BenefitPlanMerchantCategoryCommand extends CommandBase {
  data?: BenefitPlanMerchantCategory;
  benefitPlan?: BenefitPlan;
}

export interface BenefitPlanRequestTypeCommand extends CommandBase {
  data?: BenefitPlanRequestType;
  benefitPlan?: BenefitPlan;
}

export interface CarryoverLimitCommand extends CommandBase {
  data?: CarryoverLimit;
}

export interface TaxYearLimitCommand extends CommandBase {
  data?: TaxYearLimit;
}

export interface ElectionLimitCommand extends CommandBase {
  data?: ElectionLimit;
}

export interface ServiceOfferingCommand extends CommandBase {
  data?: ServiceOffering;
}

export interface ServiceOfferingAdvancedVerificationCommand extends CommandBase {
  data?: ServiceOfferingAdvancedVerification;
}

export interface ServiceOfferingFundingSourceAndScheduleCommand extends CommandBase {
  data?: ServiceOfferingFundingSourceAndSchedule;
}

export interface ServiceOfferingMerchantCategoryCommand extends CommandBase {
  data?: ServiceOfferingMerchantCategory;
}

export interface ServiceOfferingRequestTypeCommand extends CommandBase {
  data?: ServiceOfferingRequestType;
}

export interface MerchantCategoryCodeCommand extends CommandBase {
  data?: MerchantCategoryCode;
}

export interface MileageRateCommand extends CommandBase {
  data?: MileageRate;
}

export interface PricingModelCommand extends CommandBase {
  data?: PricingModel;
  ignoreStructuralWarning?: boolean;
}

export interface PricingModelOptionCommand extends CommandBase {
  data?: PricingModelOption;
}

export interface PricingModelOptionCriteriaCommand extends CommandBase {
  data?: PricingModelOptionCriteria;
}

export interface PricingModelPriceCommand extends CommandBase {
  data?: PricingModelPrice;
}

export interface TradeCustodyPartnerCommand extends CommandBase {
  data?: TradeCustodyPartner;
}

export interface ServiceOfferingInactivationRulesCommand extends CommandBase {
  data?: ServiceOfferingInactivationRules;
}

export interface ServiceOfferingEligibilityEventCommand extends CommandBase {
  data?: ServiceOfferingEligibilityEvent;
}

export interface ServiceOfferingEligibilityModificationEventCommand extends CommandBase {
  data?: ServiceOfferingEligibilityModificationEvent;
}

export interface ServiceOfferingFeesModificationEventCommand extends CommandBase {
  data?: ServiceOfferingFeesModificationEvent;
}

export interface PlanPayeeCommand extends CommandBase {
  data?: PlanPayee[];
}

export interface VendorContactCommand extends CommandBase {
  data?: VendorContact;
}

export interface PaymentPlan extends EntityBase {
  offeringId: string;
  offeringName: string;
  name: string;
  description?: string;
  planInitiationDate?: string;
  planStartDate: string;
  planEndDate?: string;
  scheduleEndDate?: string;
  externalName: string;
  externalDescription: string;
  externalId: number;
  remainingRequirements?: string[];
  eligibilityClasses?: number[];
  renewalLeadTimeDays?: number;
  renewedFromPlanId?: string;
  hasBeenRenewed?: boolean;
  autoEnrollOnRenewal?: boolean;
  eligibilityEvents?: PaymentPlanEligibilityEvent;
  eligibilityModificationEvents?: PaymentPlanEligibilityModificationEvent;
  gracePeriodAfterElection?: number;
  gracePeriodForPayment?: number;
  proration: ProrationType;
  lossOfCoverageDateType: PlanLossOfCoverageType;
  pricingPlanFee?: boolean;
  pricingPlanAllowsFeeOverride?: boolean;
  pricingPlanFeeCalculationTypes?: PricingPlanFeeCalculationType[];
  pricingFlatFeeAmount?: number;
  pricingPercentageFee?: number;
  sendWelcomeLettersDate?: string;
  clientProvidedId?: string;
  electingParty: ElectingParty;
  inactivation: EnrollmentInactivationRulesType;
  insuranceLevel?: InsuranceLevelType;
  requireSSN: RequireSSNType;
  electionExpiryPeriod?: number;
  stateIssuance?: string;
  conversionContact?: ConversionContact;
  autoRenewal?: boolean;
  planType?: string;
  maintainEligibilityWithVendor: boolean;
}

export interface ConversionContact {
  carrierName: string;
  contactInfo: string;
}

export interface BenefitPlan extends EntityBase {
  offeringId: string;
  offeringName: string;
  name: string;
  description?: string;
  planStartDate: string;
  planEndDate?: string;
  electionScheduleType?: ElectionScheduleType;
  limitMinimum?: number;
  limitMaximum?: number;
  alwaysUseLimitMaximum?: boolean;
  payrollScheduleIds?: string[];
  payrollPostingSchedule?: PayrollPostingScheduleType;
  firstPayrollPostingDate?: FirstPayrollPostingDateType;
  autoPost?: AutoPostType;
  paymentSourceType?: PaymentSourceType;
  enrollmentStartDate?: string;
  enrollmentEndDate?: string;
  enrollmentMethods?: EnrollmentMethods;
  requireEnrollmentApproval?: boolean;
  enrollmentApprovalBeforeInitiation?: boolean;
  enrollmentApprovalBeforeInitiationMethods?: EnrollmentMethods;
  enrollmentApprovalAfterInitiation?: boolean;
  enrollmentApprovalAfterInitiationMethods?: EnrollmentMethods;
  gracePeriod: boolean;
  gracePeriodMaximumMonths?: number;
  gracePeriodMaximumDays?: number;
  hasCarryover?: boolean;
  carryoverLabel?: CarryOverLabel;
  carryoverCalculationMethod?: CarryoverCalculationMethodType;
  carryoverMaxAmount?: number;
  carryoverEndDate?: string;
  carryoverSourcePlanIds?: string[];
  carryoverDestinationPlanId?: string;
  finalExpenseDate?: string;
  groupingTag: GroupingTagType;
  planInitiationDate?: string;
  cardOrderDate?: string;
  finalizationType?: FinalizationType;
  finalizationHold?: boolean;
  sendWelcomeLettersDate?: string;
  eligibilityClasses?: number[];
  autoVerification?: boolean;
  terminationRulesType: TerminationRulesType;
  eligibilityEndDateType?: EligibilityEndDateType;
  allowCardSubmission?: boolean;
  advanceAccount?: boolean;
  allowOnlineSubmission?: boolean;
  allowPaperSubmission?: boolean;
  allowElectronicFileSubmission?: boolean;
  runOutType?: RunOutType;
  runOutDays?: number;
  terminationRunOutType?: RunOutType;
  terminationRunOutDays?: number;
  finalSubmitDate?: string;
  paymentsRequirePriorVerification?: boolean;
  cardAuthorizationOptions?: CardAuthorizationType[];
  thirdPartyAuthorization?: boolean;
  thirdPartyAuthorizationOptions?: ThirdPartyAuthorizationType[];
  remainingRequirements?: string[];
  autoEnrollOnRenewal?: boolean;
  renewalLeadTimeDays?: number;
  renewedFromPlanId?: string;
  hasBeenRenewed?: boolean;
  externalName: string;
  externalDescription: string;
  externalId: string;
  planType?: BenefitPlanType;
  externalPayeeNetworks?: ExternalPayeeNetwork[];
  hasInvestmentAccount?: boolean;
  cashInvestmentInstitutionType?: CashFinancialInstitution;
  investmentInstitutionType?: InvestmentFinancialInstitution;
  areCashDepositAccountsInterestBearing?: boolean;
  cashAccountMinimumBalance?: number;
  investmentRecordKeeperPlanId?: string;
  minimumBuyTradeAmount?: number;
  minimumSellTradeAmount?: number;
  allowSingleSignOnWithInvestmentRecordKeeper?: boolean;
  billingTerminationRunOutType?: BillingTerminationRunOutType;
  optionalAccountStatesToBill?: BillableOptionalBenefitAccountState[];
  listBillId?: string;
  paymentSourceId?: string;
  restrictCardIssuance?: boolean;
  clientIsPendingInactivation?: boolean;
  allowClientApproval?: boolean;
  allowAssociatingDependentsToBenefitAccount: boolean;
  requireSSN: boolean;
  disableCardAccessNumberOfDays?: number;
  hasTaxYearContributions?: boolean;
  showTaxYearContributions?: boolean;
  allowContributionsAfterTaxYear?: boolean;
  lastDayContributionsAllowed?: string;
  allowZeroDollarElection?: boolean;
  hasTakeoverPlan?: boolean;
  takeoverFundingType?: TakeoverFundingType;
  yearToDateScheduleDate?: string;
  secondaryBureauId?: string;
  disburseWithoutRequest?: boolean;
  scheduleEndDate?: string;
  allowExcessContributions?: boolean;
  excessContributionType?: ExcessContributionType;
  excessContributionAge?: number;
  maxExcessContribution?: number;
  isIdentityVerificationRequired?: boolean;
  employerLimitMaximum?: number;
  isSubjectToERISA?: boolean;
  regularlyScheduledHoursPerWeek?: RegularlyScheduledHoursPerWeekType[];
  unionEmployeeStatus?: UnionEmployeeStatusType;
  investmentAdvisor?: InvestmentAdvisorType;
  coverageTiers?: BenefitPlanCoverageType[];
  fullCoveragePaidThroughDate?: FullCoveragePaidThroughDateType;
  requireTermsOfUseForDisbursements?: boolean;
  disbursableDateMandatoryForDisbursements?: boolean;
  cashAccountMinimumBalanceOverride?: CashAccountMinimumBalanceOverride;
  cashAccountMinimumBalanceOverrideUpon?: CashAccountMinimumBalanceOverrideUpon[];
  allowManualRequests?: boolean;
  hideBenefitAccountActivityFromClients: boolean;
  disallowBenefitAccountInactivationDateChangesAfterPlanEndDate?: boolean;
  optForCobraCoverage?: boolean;
  allowedEnrollmentStates?: string[];
  isVerificationRequired?: boolean;
  prorationFrequency?: ElectionProrationFrequency;
  contributionExpiration?: boolean;
  contributionExpirationDays?: number;
  hasMspReporting?: boolean;
  finalizedOnDate?: string;
}

export interface CalculatePayrollDatesResponse {
  client: string[];
  participant: string[];
}

export interface BenefitPlanTotalContributions {
  employee: number;
  employer: number;
}

export interface BenefitPlanExpenditures {
  total: number;
}

export interface PaymentPlanOverview {
  offeringId: string;
  offeringName: string;
  plans: PaymentPlanOverviewItem[];
}

export interface PaymentPlanRemittance {
  total: number;
}

export interface PaymentPlanOverviewItem {
  planId: string;
  externalPlanName: string;
  accountCount: number;
}

export interface BenefitPlanAdvancedVerification extends EntityBase {
  thirdPartyVerifications?: AutoVerificationType[];
  merchantCategoryCodesLimits?: boolean;
  merchantCategoryDefaultLimit?: number;
  allowOfficeVisitCopay?: boolean;
  allowOfficeVisitCopayMultiplier?: boolean;
  officeVisitCopayMultiplier?: number;
  allowPrescriptionCopay?: boolean;
  allowPrescriptionCopayMultiplier?: boolean;
  prescriptionCopayMultiplier?: number;
  allowRequestTypesLimits?: boolean;
  requestTypesDefaultLimit?: number;
  autoVerifyBySourceTypes?: RequestSourceType[];
  officeVisitCopayAmounts?: number[];
  prescriptionCopayAmounts?: number[];
}

export interface BenefitPlanFundingSourceAndSchedule extends EntityBase, FundingSourceAndSchedule {
  recalculateRemainingPostings?: RecalculatePostingsOptionType;
  contributionPosting: ContributionPostingType;
  contributionPostingMethod: PostingMethodType;
}

export interface PaymentPlanFundingSourceAndSchedule extends EntityBase, FundingSourceAndSchedule {
  selected: boolean;
}

export interface FundingSourceAndSchedule {
  fundingSource: FundingSourceType;
  selected: boolean;
  contributionPosting?: ContributionPostingType;
  fundingPosting: FundingPostingType;
  deductionPosting?: PayrollPostingType;
  participantFundingMethods?: ParticipantFundingMethodType[];
  contributionDelayAvailable?: boolean;
  contributionDelayDays?: number;
  contributionPostingMethod?: PostingMethodType;
  contributionPostingFrequency?: ContributionFrequencyOptions;
  fundingPostingMethod: PostingMethodType;
  fundingPostingFrequency?: FundingFrequencyOptions;
  deductionPostingMethod?: PostingMethodType;
  deductionPostingFrequency?: DeductionFrequencyOptions;
  contributionAutopostDelayDays?: number;
  fundingAutopostDelayDays?: number;
  deductionAutopostDelayDays?: number;
  startDate?: string;
  endDate?: string;
  taxDeductionMethod?: TaxDeductionMethod;
}

export interface CarryoverLimit extends EntityBase {
  carryover: boolean;
  effectiveDate: string;
  applyToExistingBenefitPlans?: boolean;
  carryoverLabel?: CarryOverLabel;
  carryoverMaxAmount?: number;
  allowCarryoverCalcAmountLimit?: boolean;
  allowCarryoverCalcFullAvailableBalance?: boolean;
}

export interface FundingFrequencyOptions {
  frequencyType?: FundingPostingFrequencyType;
  frequencyOptions?: PostingFrequencyOption;
}

export interface ContributionFrequencyOptions {
  frequencyType?: ContributionPostingFrequencyType;
  frequencyOptions?: PostingFrequencyOption;
}

export interface DeductionFrequencyOptions {
  frequencyType?: DeductionPostingFrequencyType;
  frequencyOptions?: PostingFrequencyOption;
}

export interface PostingFrequencyOption {
  numberOfPostings?: number;
  postDay?: number;
  firstPostingMonth?: number;
  postDayOnLastDayOfMonth?: boolean;
  postingDates?: string[];
  firstDeductionPostingDate?: FirstDeductionPostingDateType;
  postingAmount?: number;
  dayOfWeek?: number;
}

export type BenefitPlanRequestTypes = BenefitPlanRequestType[];

export interface BenefitPlanMerchantCategory extends EntityBase {
  merchantCategorySelections?: MerchantCategorySelection[];
}

export interface BenefitPlanPaymentTier extends EntityBase {
  applyTo: PaymentTierCoverageType;
  isDeductible: boolean;
  totalAmount: number;
  tierPayout: number;
  percentDiffFromPreviousTierForDisplayOnly: number;
}

export interface TaxYearLimit extends EntityBase {
  effectiveYear: string;
  singleLimit: number;
  familyLimit: number;
}

export interface ElectionLimit extends EntityBase {
  effectiveStartDate: string;
  limit?: number;
  employeeLimit?: number;
  employerLimit?: number;
  applyToExistingBenefitPlans: boolean;
}

export interface EligibilityClass {
  classId: number;
  eligibilityEffectiveDate: EligibilityEffectiveDateType;
  name: string;
  waitingPeriod: number;
}

export type EnrollmentMethods = EnrollmentMethodType[];

export interface ClientInactivationEvent {
  clientId: string;
  scheduledInactivationDate: string;
}

export interface MerchantCategorySelection {
  code?: string;
  officeVisitCopay?: boolean;
  prescriptionCopay?: boolean;
  limit?: number;
  allowCardSwipe: boolean;
}

export interface MileageRate extends EntityBase {
  effectiveDate: string;
  rate: number;
  mileageRateType: MileageRateType;
}

export interface RequestTypeSelection {
  code?: string;
  expenseType?: string;
  serviceType?: string;
  limit?: number;
  prescriptionCopay?: boolean;
  officeVisitCopay?: boolean;
}

export interface ServiceOffering extends EntityBase {
  name: string;
  description: string;
  disbursementTypes?: DisbursementType[];
  enrollmentType: EnrollmentType;
  type?: ServiceOfferingType;
  electionLimit?: boolean;
  electionSchedule?: ElectionScheduleType;
  openEndedPlanYear?: boolean;
  planDuration?: 0 | 12;
  effectiveStartDate: string;
  effectiveEndDate?: string;
  gracePeriod?: boolean;
  gracePeriodMaximumMonths?: number;
  gracePeriodMaximumDays?: number;
  finalization?: boolean;
  finalizationType?: FinalizationType;
  runOut?: boolean;
  runOutMaximumPeriod?: number;
  thirdPartyAuthorization?: boolean;
  thirdPartyAuthorizationOptions?: ThirdPartyAuthorizationType[];
  autoVerification?: boolean;
  allowAutoEnrollOnRenewal: boolean;
  terminationRulesTypes?: TerminationRulesType[];
  runOutTypes?: RunOutType[];
  externalOfferingDescription?: string;
  participantPresentationTemplate: string;
  autoVerifyBySourceTypes?: RequestSourceType[];
  benefitPlanTypes?: BenefitPlanType[];
  paymentPlanTypes?: string[];
  externalPayeeNetworks: ExternalPayeeNetwork[];
  groupingTag: GroupingTagType;
  allowInvestmentAccount?: boolean;
  cashInvestmentInstitutionTypes?: CashFinancialInstitution[];
  investmentInstitutionTypes?: InvestmentFinancialInstitution[];
  autopostDelay?: number;
  specialDisbursementTypes?: SpecialDisbursementType[];
  allowSingleSignOnWithInvestmentRecordKeeper?: boolean;
  areCashDepositAccountsInterestBearing?: boolean;
  minimumBuyTradeAmount?: number;
  minimumSellTradeAmount?: number;
  cashAccountMinimumBalance?: number;
  secondaryBureauId?: string;
  requireQualifyingEvent?: boolean;
  restrictCardIssuance?: boolean;
  allowAssociatingDependentsToBenefitAccount?: boolean;
  requireSSN: RequireSSNType;
  hasTaxYearLimits?: boolean;
  hasDifferentTaxYearLimits?: boolean;
  specialContributionTypes?: SpecialContributionType[];
  contributionMethodTypes?: ContributionMethodType[];
  disbursementMethodTypes?: DisbursementMethodType[];
  allowDisableCardAccessForUnverifiedRequests?: boolean;
  hasTaxYearContributions?: boolean;
  showTaxYearContributions?: boolean;
  allowContributionsAfterTaxYear?: boolean;
  lastDayContributionsAllowed?: string;
  hideBenefitAccountsFromParticipantsViews?: boolean;
  allowZeroDollarElection?: boolean;
  disburseWithoutRequest?: boolean;
  allowExcessContributions?: boolean;
  excessContributionType?: ExcessContributionType;
  excessContributionAge?: number;
  maxExcessContribution?: number;
  isIdentityVerificationRequired?: boolean;
  isEligibilityRequirementsDisplayed?: boolean;
  investmentAdvisors?: InvestmentAdvisorType[];
  requireTermsOfUseForDisbursements?: boolean;
  disbursableDateMandatoryForDisbursements?: boolean;
  cashAccountMinimumBalanceOverrideOptions?: CashAccountMinimumBalanceOverride[];
  allowManualRequests?: boolean;
  hideBenefitAccountActivityFromClients?: boolean;
  disallowBenefitAccountInactivationDateChangesAfterPlanEndDate?: boolean;
  isVerificationRequired?: boolean;
  contributionExpiration?: boolean;
  allowMspReporting?: boolean;
  payeeTypes?: PlanPayeeType[];
  remittanceFrequencyTypes?: RemittanceFrequencyType[];
  electingParty?: ElectingParty;
  hasLossOfCoverage?: boolean;
  pricingPlanFee?: boolean;
  pricingPlanAllowsFeeOverride?: boolean;
  pricingPlanFeeCalculationTypes?: PricingPlanFeeCalculationType[];
  pricingFlatFeeAmount?: number;
  pricingPercentageFee?: number;
  allowProration?: boolean;
  allowClientProvidedId?: boolean;
  pricingPlanFeesEditApplyToPlanType?: PricingPlanFeesEditApplyToPlanType;
  pricingPlanFeesEditEffectiveStartDate?: string;
  electionExpiryPeriod?: number;
  electionExpiryPeriodEditableAtPlanLevel?: boolean;
  defineStateIssuance?: boolean;
  allowsAutoRenewal?: boolean;
  preventInsignificantShortageInactivation?: boolean;
}

export interface ServiceOfferingAdvancedVerification extends EntityBase {
  thirdPartyVerifications?: AutoVerificationType[];
  merchantCategoryCodesLimits?: boolean;
  merchantCategoryDefaultLimit?: number;
  allowOfficeVisitCopay?: boolean;
  allowOfficeVisitCopayMultiplier?: boolean;
  officeVisitCopayMultiplier?: number;
  allowPrescriptionCopay?: boolean;
  allowPrescriptionCopayMultiplier?: boolean;
  prescriptionCopayMultiplier?: number;
  allowRequestTypesLimits?: boolean;
  requestTypesDefaultLimit?: number;
  allowAutoVerificationRequestViaReceivedFile?: boolean;
}

export interface ServiceOfferingFundingSourceAndSchedule extends EntityBase {
  fundingSourceAndScheduleOptions?: ServiceOfferingFundingSourceAndScheduleOption[];
  clientFundingMethods?: PaymentSourceType[];
  participantFundingMethods?: ParticipantFundingMethodType[];
}

export interface ServiceOfferingFundingSourceAndScheduleOption {
  fundingSource: FundingSourceType;
  selected?: boolean;
  contributionPosting: ContributionPostingType[];
  payrollPosting: PayrollPostingType[];
  fundingPosting: FundingPostingType[];
  contributionDelayAvailable?: boolean;
  contributionDelayDays?: number;
  recalculateRemainingPostings?: RecalculatePostingsOptionType[];
  taxDeductionMethods?: TaxDeductionMethod[];
}

export interface ServiceOfferingMerchantCategory extends EntityBase {
  merchantCategorySelections?: MerchantCategorySelection[];
}

export interface MerchantCategoryCode extends EntityBase {
  name: string;
  categoryCodeId?: string;
  xformativeId?: string;
  serviceType?: string;
}

export interface PricingModel extends EntityBase {
  effectiveDate: string;
  trackingId?: string;
}

export interface PricingModelOption extends EntityBase {
  optionType: PricingOptionType;
  optionVersion: number;
  priceKeyPosition: number;
  displaySequence: number;
  additionalProperties?: PricingModelAdditionalProperties;
}

export interface PricingModelOptionCriteria extends EntityBase {
  displayName: string;
  configuration: PricingModelOptionConfig;
  priceKeyValue: string;
  displaySequence: number;
}

export interface PricingModelPrice extends EntityBase {
  priceKey: string;
  priceType: string;
  fee?: number;
  feeType?: string;
  price: number;
  feeRoundingType?: string;
}

export interface PricingModelOptionConfig {
  tier?: TierOptionConfig;
  age?: AgeOptionConfig;
  gender?: GenderOptionConfig;
  fee?: FeeOptionConfig;
  fsa?: FsaOptionConfig;
  smokingStatus?: SmokingStatusOptionConfig;
}

export interface FeeOptionConfig {
  feeType?: PricingPlanFeeCalculationType;
  sameFeeForAllConfigs?: boolean;
  value?: number;
  roundingType?: RoundingType;
}

export interface FsaOptionConfig {
  managedByTpa?: boolean;
  benefitPlanIds?: string[];
}

export interface TierOptionConfig {
  personCount?: boolean;
  tierType?: PricingModelTierType;
  individual?: PricingModelTierPersonRequiredType;
  spouse?: PricingModelTierPersonRequiredType;
  numberOfPeople?: number;
  allowMorePeople?: boolean;
  otherDependents?: PricingModelTierPersonRequiredType;
}

export interface AgeOptionConfig {
  minAge?: number;
  maxAge?: number;
}

export interface GenderOptionConfig {
  gender?: string;
}

export interface SmokingStatusOptionConfig {
  smokingStatus?: SmokingStatusType;
}

export interface TradeCustodyPartner extends EntityBase {
  name: string;
  displayName: string;
  tradeSystemType: TradeSystemType;
}

export interface ServiceOfferingInactivationRules extends EntityBase {
  effectiveDate: string;
  applyToMidYearPlans: boolean;
  inactivation: EnrollmentInactivationRulesType;
  gracePeriodAfterElection?: number;
  gracePeriodForPayment?: number;
  planConversionAvailable?: boolean;
  allowPlanOverride?: boolean;
}

export interface ServiceOfferingEligibilityEvent extends EntityBase {
  effectiveDate: string;
  applyToMidYearPlans: boolean;
  referTo: ServiceOfferingEligibilityEventReferToType;
  editableAtPlanLevel: boolean;
  eligibilityEventSelections: ServiceOfferingEligibilityEventSelection[];
}

export interface ServiceOfferingEligibilityEventSelection {
  name: string;
  duration?: number;
}

export interface ServiceOfferingEligibilityModificationEvent extends EntityBase {
  effectiveDate: string;
  applyToMidYearPlans: boolean;
  eligibilityModificationEventSelections: ServiceOfferingEligibilityModificationEventSelection[];
}

export interface ServiceOfferingEligibilityModificationEventSelection {
  name: string;
  direction?: EligibilityModificationEventDirectionType;
  duration?: number;
}

export interface ServiceOfferingFeesModificationEvent extends EntityBase {
  feeCalculationTypes: PricingPlanFeeCalculationType[];
  flatFeeAmount?: number;
  percentageFee?: number;
  effectiveDate: string;
}

export interface PaymentPlanEligibilityEvent {
  selections: PaymentPlanEligibilityEventSelection[];
}

export interface PaymentPlanEligibilityModificationEvent {
  selections: PaymentPlanEligibilityModificationEventSelection[];
}

export interface PaymentPlanEligibilityEventSelection {
  name: string;
  duration?: number;
}

export interface PlanPayee extends EntityBase {
  payeeType: PlanPayeeType;
  payeeId?: string;
  percent?: number;
  percentType?: PercentType;
  percentSubtype?: string;
}

export interface VendorContact extends EntityBase {
  contactId: string;
}

export interface PaymentPlanEligibilityModificationEventSelection {
  name: string;
  duration?: number;
  direction?: EligibilityModificationEventDirectionType;
}

export interface ExtendedPricingModel {
  pricingOptionTypes?: PricingOptionType[];
  pricingTable?: PricingTable[];
  pricingModelId?: string;
}

export interface PricingTable {
  displayNames?: string[];
  price?: PricingModelPrice;
}

export interface Criteria {
  key?: string;
  value?: string;
  matchType?: MatchType;
  chainType?: ChainType;
  groupCriteria?: GroupCriteria[];
}

export interface Error {
  message?: string;
  fields?: object;
}

export interface GroupCriteria {
  key?: string;
  value?: string;
  matchType?: MatchType;
  chainType?: ChainType;
  groupCriteria?: GroupCriteria[];
}

export interface PingResponse {
  serverTime: ServerTime;
  services?: Service[];
}

export interface PostingDatesCriteria {
  benefitPlan?: BenefitPlan;
  paymentPlan?: PaymentPlan;
  frequencyOptions?: PostingFrequencyOption;
  frequencyType?: string;
  fundingPostingType?: FundingPostingType;
}

export type SearchCriteria = Criteria[];

export interface Service {
  name?: string;
  serverTime?: string;
}

export type ServerTime = string;

export type PaymentPlans = PaymentPlan[];

export type BenefitPlans = BenefitPlan[];

export type BenefitPlanAdvancedVerifications = BenefitPlanAdvancedVerification[];

export type BenefitPlanFundingSourcesAndSchedules = BenefitPlanFundingSourceAndSchedule[];

export type PaymentPlanFundingSourcesAndSchedules = PaymentPlanFundingSourceAndSchedule[];

export type BenefitPlanMerchantCategories = BenefitPlanMerchantCategory[];

export interface BenefitPlanPaymentTierCommand extends CommandBase {
  data?: BenefitPlanPaymentTier;
}

export interface BenefitPlanPaymentTiersCommand extends CommandBase {
  data?: BenefitPlanPaymentTiersEntity;
}

export interface BenefitPlanPaymentTiersEntity extends EntityBase {
  paymentTiers?: BenefitPlanPaymentTiers;
}

export type BenefitPlanPaymentTiers = BenefitPlanPaymentTier[];

export type ClientErrors = Error[];

export type CarryoverLimits = CarryoverLimit[];

export type TaxYearLimits = TaxYearLimit[];

export type ElectionLimits = ElectionLimit[];

export type ServiceOfferings = ServiceOffering[];

export type ServiceOfferingAdvancedVerifications = ServiceOfferingAdvancedVerification[];

export type ServiceOfferingFundingSourcesAndSchedules = ServiceOfferingFundingSourceAndSchedule[];

export type ServiceOfferingMerchantCategories = ServiceOfferingMerchantCategory[];

export type ServiceOfferingRequestTypes = ServiceOfferingRequestType[];

export type MerchantCategoryCodes = MerchantCategoryCode[];

export type PricingModels = PricingModel[];

export type PricingModelOptions = PricingModelOption[];

export type PricingModelOptionCriterias = PricingModelOptionCriteria[];

export type PricingModelPrices = PricingModelPrice[];

export type TradeCustodyPartners = TradeCustodyPartner[];

export type ServiceOfferingFeesModificationEvents = ServiceOfferingFeesModificationEvent[];

export type VendorContacts = VendorContact[];

export interface BenefitPlanRequestType extends EntityBase {
  requestTypeSelections?: RequestTypeSelection[];
}

export interface PricingModelAdditionalProperties {
  ageCalculationBasedOn?: AgeCalculationBasedOnType;
}

export interface ServiceOfferingRequestType extends EntityBase {
  requestTypeSelections?: RequestTypeSelection[];
}

export interface CommandBase {
  id?: string;
  eventCorrelationId?: string;
  jobId?: string;
  producerId?: string;
  sequenceId?: number;
  version?: number;
  type?: string;
  created?: string;
  createdBy?: string;
  createdById?: string;
}

export interface EntityBase {
  id?: string;
  parentId?: string;
  parentType?: ParentType;
  version?: number;
  created?: string;
  createdBy?: string;
  createdById?: string;
  updated?: string;
  updatedBy?: string;
  updatedById?: string;
  currentState?: string;
  lastTransition?: string;
  externalWorkflowId?: string;
  eventNotes?: string;
  transactionLockId?: string;
}

export enum PaymentPlanCommandType {
  ActiveToActive = 'ActiveToActive',
  ActiveToRemoved = 'ActiveToRemoved',
  ActiveToConcluded = 'ActiveToConcluded',
  AnyToPropagatingEdits = 'AnyToPropagatingEdits',
  ConcludedToConcluded = 'ConcludedToConcluded',
  ConcludedToRemoved = 'ConcludedToRemoved',
  DraftToDraft = 'DraftToDraft',
  DraftToRemoved = 'DraftToRemoved',
  DraftToDeleted = 'DraftToDeleted',
  DraftToSetup = 'DraftToSetup',
  InitiatedToActive = 'InitiatedToActive',
  InitiatedToInitiated = 'InitiatedToInitiated',
  InitiatedToRemoved = 'InitiatedToRemoved',
  SetupToInitiated = 'SetupToInitiated',
  SetupToRemoved = 'SetupToRemoved',
  SetupToSetup = 'SetupToSetup',
  StartToDraft = 'StartToDraft',
  StartToSetup = 'StartToSetup',
}

export enum BenefitPlanCommandType {
  ActiveToActive = 'ActiveToActive',
  ActiveToGracePeriod = 'ActiveToGracePeriod',
  ActiveToInitiated = 'ActiveToInitiated',
  ActiveToReconciliation = 'ActiveToReconciliation',
  ActiveToRemoved = 'ActiveToRemoved',
  ActiveToRunOut = 'ActiveToRunOut',
  AnyToReverseCABFunding = 'AnyToReverseCABFunding',
  AddTiers = 'AddTiers',
  AnyToPropagatingDateEdits = 'AnyToPropagatingDateEdits',
  AnyToFinanciallyUnsafeDoNotUsePropagatingDateEdits = 'AnyToFinanciallyUnsafeDoNotUsePropagatingDateEdits',
  AuditAdjustmentToAuditAdjustment = 'AuditAdjustmentToAuditAdjustment',
  AuditAdjustmentToFinalizing = 'AuditAdjustmentToFinalizing',
  AuditAdjustmentToRemoved = 'AuditAdjustmentToRemoved',
  CardsOrderedToActive = 'CardsOrderedToActive',
  CardsOrderedToCardsOrdered = 'CardsOrderedToCardsOrdered',
  CardsOrderedToRemoved = 'CardsOrderedToRemoved',
  ConversionToActive = 'ConversionToActive',
  ConversionToErrorXFAuthFailure = 'ConversionToErrorXFAuthFailure',
  ConversionToErrorXFDuplicate = 'ConversionToErrorXFDuplicate',
  ConversionToErrorXFRetry = 'ConversionToErrorXFRetry',
  DraftToDraft = 'DraftToDraft',
  DraftToRemoved = 'DraftToRemoved',
  DraftToSetup = 'DraftToSetup',
  FinalizedToRemoved = 'FinalizedToRemoved',
  FinalizationPreCheckToFinalizingAccounts = 'FinalizationPreCheckToFinalizingAccounts',
  FinalizingAccountsToRemoved = 'FinalizingAccountsToRemoved',
  FinalizingAccountsToFinalizingPlan = 'FinalizingAccountsToFinalizingPlan',
  FinalizingPlanToRemoved = 'FinalizingPlanToRemoved',
  AnyToFinalizingError = 'AnyToFinalizingError',
  FinalizingPlanToFinalized = 'FinalizingPlanToFinalized',
  GracePeriodToActive = 'GracePeriodToActive',
  GracePeriodToGracePeriod = 'GracePeriodToGracePeriod',
  GracePeriodToReconciliation = 'GracePeriodToReconciliation',
  GracePeriodToRemoved = 'GracePeriodToRemoved',
  GracePeriodToRunOut = 'GracePeriodToRunOut',
  InitiatedToActive = 'InitiatedToActive',
  InitiatedToCardsOrdered = 'InitiatedToCardsOrdered',
  InitiatedToInitiated = 'InitiatedToInitiated',
  InitiatedToRemoved = 'InitiatedToRemoved',
  PendingXFToConversion = 'PendingXFToConversion',
  PendingXFToErrorXFAuthFailure = 'PendingXFToErrorXFAuthFailure',
  PendingXFToErrorXFDuplicate = 'PendingXFToErrorXFDuplicate',
  PendingXFToErrorXFRetry = 'PendingXFToErrorXFRetry',
  PropagatingDateEditsToAny = 'PropagatingDateEditsToAny',
  ReconciliationToFinalizing = 'ReconciliationToFinalizing',
  ReconciliationToFinalizationPreCheck = 'ReconciliationToFinalizationPreCheck',
  ReconciliationToReconciliation = 'ReconciliationToReconciliation',
  ReconciliationToRemoved = 'ReconciliationToRemoved',
  AnyToRolloverProcessingPreCheck = 'AnyToRolloverProcessingPreCheck',
  RolloverCompleteToFinalizationPreCheck = 'RolloverCompleteToFinalizationPreCheck',
  RolloverCompleteToFinalizingAccounts = 'RolloverCompleteToFinalizingAccounts',
  RolloverCompleteToRemoved = 'RolloverCompleteToRemoved',
  RolloverProcessingToError = 'RolloverProcessingToError',
  RolloverProcessingPreCheckToRolloverProcessing = 'RolloverProcessingPreCheckToRolloverProcessing',
  RolloverProcessingToRemoved = 'RolloverProcessingToRemoved',
  RolloverProcessingToRolloverComplete = 'RolloverProcessingToRolloverComplete',
  RunOutToActive = 'RunOutToActive',
  RunOutToReconciliation = 'RunOutToReconciliation',
  RunOutToRemoved = 'RunOutToRemoved',
  RunOutToRunOut = 'RunOutToRunOut',
  SetupToError = 'SetupToError',
  SetupToInitiated = 'SetupToInitiated',
  SetupToRemoved = 'SetupToRemoved',
  SetupToSetup = 'SetupToSetup',
  StartToDraft = 'StartToDraft',
  StartToSetup = 'StartToSetup',
  UpgradeToConversion = 'UpgradeToConversion',
  UpgradeToPendingXF = 'UpgradeToPendingXF',
}

export enum BenefitPlanPaymentTiersCommandCommandType {
  StartToActive = 'StartToActive',
}

export enum BenefitPlanPaymentTierCommandCommandType {
  StartToActive = 'StartToActive',
  ActiveToActive = 'ActiveToActive',
  ActiveToRemoved = 'ActiveToRemoved',
}

export enum BenefitPlanAdvancedVerificationCommandType {
  StartToActive = 'StartToActive',
  ActiveToActive = 'ActiveToActive',
  ActiveToRemoved = 'ActiveToRemoved',
}

export enum BenefitPlanFundingSourceAndScheduleCommandType {
  StartToActive = 'StartToActive',
  ActiveToActive = 'ActiveToActive',
  ActiveToRemoved = 'ActiveToRemoved',
}

export enum PaymentPlanFundingSourceAndScheduleCommandType {
  StartToActive = 'StartToActive',
  ActiveToActive = 'ActiveToActive',
  ActiveToRemoved = 'ActiveToRemoved',
}

export enum BenefitPlanRequestTypeCommandType {
  StartToActive = 'StartToActive',
  ActiveToActive = 'ActiveToActive',
  ActiveToRemoved = 'ActiveToRemoved',
}

export enum BenefitPlanMerchantCategoryCommandType {
  StartToActive = 'StartToActive',
  StartToPendingXF = 'StartToPendingXF',
  PendingXFToErrorXFAuthFailure = 'PendingXFToErrorXFAuthFailure',
  PendingXFToErrorXFDuplicate = 'PendingXFToErrorXFDuplicate',
  PendingXFToErrorXFRetry = 'PendingXFToErrorXFRetry',
  PendingXFToActive = 'PendingXFToActive',
  ActiveToActive = 'ActiveToActive',
  ActiveToRemoved = 'ActiveToRemoved',
}

export enum BenefitPlanPaymentTierCommandType {
  StartToActive = 'StartToActive',
  ActiveToActive = 'ActiveToActive',
  ActiveToRemoved = 'ActiveToRemoved',
}

export enum CarryoverLimitCommandType {
  StartToPending = 'StartToPending',
  PendingToActive = 'PendingToActive',
  PendingToInactive = 'PendingToInactive',
}

export enum TaxYearLimitCommandType {
  ActiveToActive = 'ActiveToActive',
  StartToActive = 'StartToActive',
}

export enum ElectionLimitCommandType {
  ActiveToActive = 'ActiveToActive',
  ActiveToInactive = 'ActiveToInactive',
  ActiveToOnHold = 'ActiveToOnHold',
  InactiveToActive = 'InactiveToActive',
  InactiveToInactive = 'InactiveToInactive',
  OnHoldToActive = 'OnHoldToActive',
  OnHoldToOnHold = 'OnHoldToOnHold',
  StartToActive = 'StartToActive',
  ActiveToRemoved = 'ActiveToRemoved',
}

export enum ServiceOfferingCommandType {
  DraftToDraft = 'DraftToDraft',
  DraftToPublished = 'DraftToPublished',
  DraftToRemoved = 'DraftToRemoved',
  InactiveToPublished = 'InactiveToPublished',
  PublishedToInactive = 'PublishedToInactive',
  PublishedToPublished = 'PublishedToPublished',
  PublishedToSunsetting = 'PublishedToSunsetting',
  PublishedToRemoved = 'PublishedToRemoved',
  StartToDraft = 'StartToDraft',
  StartToPublished = 'StartToPublished',
  SunsettingToPublished = 'SunsettingToPublished',
  SunsettingToRemoved = 'SunsettingToRemoved',
  SunsettingToSunsetting = 'SunsettingToSunsetting',
}

export enum ServiceOfferingAdvancedVerificationCommandType {
  StartToActive = 'StartToActive',
  ActiveToActive = 'ActiveToActive',
  ActiveToRemoved = 'ActiveToRemoved',
}

export enum ServiceOfferingFundingSourceAndScheduleCommandType {
  StartToActive = 'StartToActive',
  ActiveToActive = 'ActiveToActive',
  ActiveToRemoved = 'ActiveToRemoved',
}

export enum ServiceOfferingMerchantCategoryCommandType {
  StartToActive = 'StartToActive',
  ActiveToActive = 'ActiveToActive',
  ActiveToRemoved = 'ActiveToRemoved',
}

export enum ServiceOfferingRequestTypeCommandType {
  StartToActive = 'StartToActive',
  ActiveToActive = 'ActiveToActive',
  ActiveToRemoved = 'ActiveToRemoved',
}

export enum MerchantCategoryCodeCommandType {
  StartToActive = 'StartToActive',
}

export enum MileageRateCommandType {
  StartToActive = 'StartToActive',
  ActiveToActive = 'ActiveToActive',
  ActiveToInactive = 'ActiveToInactive',
}

export enum PricingModelCommandType {
  ActiveToActive = 'ActiveToActive',
  ActiveToExpired = 'ActiveToExpired',
  AnyToRemoved = 'AnyToRemoved',
  AnyToError = 'AnyToError',
  AnyToInactive = 'AnyToInactive',
  DraftToDraft = 'DraftToDraft',
  DraftToApproved = 'DraftToApproved',
  ApprovedToPropagatingPriceChanges = 'ApprovedToPropagatingPriceChanges',
  ApprovedToActive = 'ApprovedToActive',
  PropagatingPriceChangesToActive = 'PropagatingPriceChangesToActive',
  StartToDraft = 'StartToDraft',
}

export enum PricingModelOptionCommandType {
  ActiveToActive = 'ActiveToActive',
  AnyToRemoved = 'AnyToRemoved',
  DraftToDraft = 'DraftToDraft',
  DraftToActive = 'DraftToActive',
  StartToDraft = 'StartToDraft',
}

export enum PricingModelOptionCriteriaCommandType {
  ActiveToActive = 'ActiveToActive',
  AnyToRemoved = 'AnyToRemoved',
  DraftToDraft = 'DraftToDraft',
  DraftToActive = 'DraftToActive',
  StartToDraft = 'StartToDraft',
}

export enum PricingModelPriceCommandType {
  AnyToRemoved = 'AnyToRemoved',
  ActiveToActive = 'ActiveToActive',
  StartToActive = 'StartToActive',
}

export enum TradeCustodyPartnerCommandType {
  StartToActive = 'StartToActive',
  ActiveToBlackout = 'ActiveToBlackout',
  BlackoutToActive = 'BlackoutToActive',
}

export enum ServiceOfferingInactivationRulesCommandType {
  StartToPending = 'StartToPending',
  PendingToApplied = 'PendingToApplied',
  PendingToNotapplied = 'PendingToNotapplied',
}

export enum ServiceOfferingEligibilityEventCommandType {
  StartToPending = 'StartToPending',
  PendingToApplied = 'PendingToApplied',
  PendingToNotapplied = 'PendingToNotapplied',
}

export enum ServiceOfferingEligibilityModificationEventCommandType {
  StartToPending = 'StartToPending',
  PendingToApplied = 'PendingToApplied',
  PendingToNotapplied = 'PendingToNotapplied',
}

export enum ServiceOfferingFeesModificationEventCommandType {
  StartToActive = 'StartToActive',
}

export enum PlanPayeeCommandType {
  StartToActive = 'StartToActive',
  ActiveToActive = 'ActiveToActive',
  ActiveToInactive = 'ActiveToInactive',
  ActiveToRemoved = 'ActiveToRemoved',
}

export enum VendorContactCommandType {
  StartToActive = 'StartToActive',
  ActiveToActive = 'ActiveToActive',
  ActiveToRemoved = 'ActiveToRemoved',
}

export enum PaymentPlanState {
  Active = 'Active',
  Concluded = 'Concluded',
  Draft = 'Draft',
  Deleted = 'Deleted',
  Error = 'Error',
  Initiated = 'Initiated',
  PropagatingEdits = 'PropagatingEdits',
  Removed = 'Removed',
  Setup = 'Setup',
}

export enum BenefitPlanState {
  Active = 'Active',
  CardsOrdered = 'CardsOrdered',
  Conversion = 'Conversion',
  Draft = 'Draft',
  Error = 'Error',
  ErrorXFAuthFailure = 'ErrorXFAuthFailure',
  ErrorXFDuplicate = 'ErrorXFDuplicate',
  ErrorXFRetry = 'ErrorXFRetry',
  FinalizationPreCheck = 'FinalizationPreCheck',
  Finalized = 'Finalized',
  FinalizingAccounts = 'FinalizingAccounts',
  FinalizingPlan = 'FinalizingPlan',
  FinalizingError = 'FinalizingError',
  GracePeriod = 'GracePeriod',
  Initiated = 'Initiated',
  PendingXF = 'PendingXF',
  PropagatingDateEdits = 'PropagatingDateEdits',
  FinanciallyUnsafeDoNotUsePropagatingDateEdits = 'FinanciallyUnsafeDoNotUsePropagatingDateEdits',
  Reconciliation = 'Reconciliation',
  Removed = 'Removed',
  RolloverComplete = 'RolloverComplete',
  RolloverProcessingPreCheck = 'RolloverProcessingPreCheck',
  RolloverProcessing = 'RolloverProcessing',
  RunOut = 'RunOut',
  Setup = 'Setup',
  Start = 'Start',
  Upgrade = 'Upgrade',
}

export enum BenefitPlanAdvancedVerificationState {
  Start = 'Start',
  Active = 'Active',
  Inactive = 'Inactive',
  Removed = 'Removed',
}

export enum BenefitPlanFundingSourceAndScheduleState {
  Start = 'Start',
  Active = 'Active',
  Inactive = 'Inactive',
  Removed = 'Removed',
  Upgrade = 'Upgrade',
  Error = 'Error',
}

export enum PaymentPlanFundingSourceAndScheduleState {
  Start = 'Start',
  Active = 'Active',
  Removed = 'Removed',
}

export enum BenefitPlanMerchantCategoryState {
  Start = 'Start',
  Active = 'Active',
  PendingXF = 'PendingXF',
  ErrorXFAuthFailure = 'ErrorXFAuthFailure',
  ErrorXFDuplicate = 'ErrorXFDuplicate',
  ErrorXFRetry = 'ErrorXFRetry',
  Inactive = 'Inactive',
  Removed = 'Removed',
}

export enum BenefitPlanPaymentTierState {
  Active = 'Active',
  Inactive = 'Inactive',
  Removed = 'Removed',
}

export enum BenefitPlanRequestTypeState {
  Start = 'Start',
  Active = 'Active',
  Inactive = 'Inactive',
  Removed = 'Removed',
}

export enum CarryoverLimitState {
  Active = 'Active',
  Error = 'Error',
  Start = 'Start',
  Pending = 'Pending',
  Inactive = 'Inactive',
}

export enum TaxYearLimitState {
  Active = 'Active',
  Start = 'Start',
}

export enum ElectionLimitState {
  Active = 'Active',
  Inactive = 'Inactive',
  OnHold = 'OnHold',
  Removed = 'Removed',
  Start = 'Start',
}

export enum ServiceOfferingState {
  Draft = 'Draft',
  Inactive = 'Inactive',
  Published = 'Published',
  Removed = 'Removed',
  Start = 'Start',
  Sunsetting = 'Sunsetting',
}

export enum ServiceOfferingAdvancedVerificationState {
  Active = 'Active',
  Inactive = 'Inactive',
  Removed = 'Removed',
}

export enum ServiceOfferingFundingSourceAndScheduleState {
  Active = 'Active',
  Inactive = 'Inactive',
  Removed = 'Removed',
}

export enum ServiceOfferingMerchantCategoryState {
  Active = 'Active',
  Inactive = 'Inactive',
  Removed = 'Removed',
}

export enum ServiceOfferingRequestTypeState {
  Active = 'Active',
  Inactive = 'Inactive',
  Removed = 'Removed',
}

export enum MerchantCategoryCodeState {
  Active = 'Active',
}

export enum MileageRateState {
  Active = 'Active',
  Inactive = 'Inactive',
}

export enum PricingModelState {
  Draft = 'Draft',
  Active = 'Active',
  Approved = 'Approved',
  PropagatingPriceChanges = 'PropagatingPriceChanges',
  Inactive = 'Inactive',
  Removed = 'Removed',
  Error = 'Error',
}

export enum PricingModelOptionState {
  Active = 'Active',
  Draft = 'Draft',
  Inactive = 'Inactive',
  Removed = 'Removed',
}

export enum PricingModelOptionCriteriaState {
  Active = 'Active',
  Draft = 'Draft',
  Inactive = 'Inactive',
  Removed = 'Removed',
}

export enum PricingModelPriceState {
  Active = 'Active',
  Inactive = 'Inactive',
  Removed = 'Removed',
}

export enum TradeCustodyPartnerState {
  Active = 'Active',
  Blackout = 'Blackout',
}

export enum ServiceOfferingInactivationRulesState {
  Start = 'Start',
  Pending = 'Pending',
  Applied = 'Applied',
  Notapplied = 'Notapplied',
  Error = 'Error',
}

export enum ServiceOfferingEligibilityEventState {
  Start = 'Start',
  Pending = 'Pending',
  Applied = 'Applied',
  Notapplied = 'Notapplied',
  Error = 'Error',
}

export enum ServiceOfferingEligibilityModificationEventState {
  Start = 'Start',
  Pending = 'Pending',
  Applied = 'Applied',
  Notapplied = 'Notapplied',
  Error = 'Error',
}

export enum ServiceOfferingFeesModificationEventState {
  Start = 'Start',
  Active = 'Active',
}

export enum PlanPayeeState {
  Start = 'Start',
  Active = 'Active',
  Inactive = 'Inactive',
  Removed = 'Removed',
}

export enum VendorContactState {
  Start = 'Start',
  Active = 'Active',
  Removed = 'Removed',
}

export enum PayrollPostingScheduleType {
  Payroll = 'Payroll',
}

export enum FirstPayrollPostingDateType {
  FirstPayDate = 'FirstPayDate',
}

export enum AutoPostType {
  Yes = 'Yes',
  No = 'No',
}

export enum FirstDeductionPostingDateType {
  FirstPayDate = 'FirstPayDate',
}

export enum CashFinancialInstitution {
  CashFinancialInstitution1 = 'CashFinancialInstitution1',
  CashFinancialInstitution2 = 'CashFinancialInstitution2',
  StateBankofCrossPlains = 'StateBankofCrossPlains',
  USBank = 'USBank',
}

export enum AccountType {
  BAB = 'BAB',
  BCA = 'BCA',
  BCTA = 'BCTA',
  BTCA = 'BTCA',
  BEA = 'BEA',
  BFA = 'BFA',
  BINPA = 'BINPA',
  BINT = 'BINT',
  BXFBO = 'BXFBO',
  BXPTD = 'BXPTD',
  CAB = 'CAB',
  CARD_FORCED = 'CARD_FORCED',
  CCTA = 'CCTA',
  CFPHA = 'CFPHA',
  CPFA = 'CPFA',
  COCTA = 'COCTA',
  CRA = 'CRA',
  IAB = 'IAB',
  IOA = 'IOA',
  IOAB = 'IOA-B',
  PCTA = 'PCTA',
  PFPHA = 'PFPHA',
  PPTAB = 'PPTAB',
  PPTAB_ADVANCE = 'PPTAB_ADVANCE',
  PPSEA = 'PPSEA',
  PSEA = 'PSEA',
  PSIA = 'PSIA',
  PPSIA = 'PPSIA',
  PYAB = 'PYAB',
  TOA = 'TOA',
  IOADEDUCTIBLE = 'IOA-DEDUCTIBLE',
  IOAPAYOUT = 'IOA-PAYOUT',
  TPOOA = 'TPOOA',
  PBIN = 'PBIN',
}

export enum AutoVerificationType {
  SigisService90Percent = 'SigisService90Percent',
  SigisServiceIias = 'SigisServiceIias',
  RecurringRequests = 'RecurringRequests',
  EdenredParking = 'EdenredParking',
  EdenredTransit = 'EdenredTransit',
}

export enum PlanCloneType {
  Copy = 'Copy',
  Renewal = 'Renewal',
}

export enum BenefitPlanType {
  Simple = 'Simple',
  TieredPayouts = 'TieredPayouts',
  DefinedElection = 'DefinedElection',
}

export enum ParentType {
  CLIENT = 'CLIENT',
  INDIVIDUAL = 'INDIVIDUAL',
  SERVICE_OFFERING = 'SERVICE_OFFERING',
  BENEFIT_PLAN = 'BENEFIT_PLAN',
  INSTANCE = 'INSTANCE',
  PAYMENT_PLAN = 'PAYMENT_PLAN',
  PRICING_MODEL = 'PRICING_MODEL',
  PRICING_MODEL_OPTION = 'PRICING_MODEL_OPTION',
}

export enum ElectionProrationFrequency {
  None = 'None',
  Monthly = 'Monthly',
}

export enum EligibilityEffectiveDateType {
  FirstDay = 'FirstDay',
  FirstOfMonth = 'FirstOfMonth',
  SameDay = 'SameDay',
}

export enum EnrollmentType {
  Group = 'Group',
  Consumer = 'Consumer',
  Micro = 'Micro',
}

export enum EnrollmentInactivationRulesType {
  NoInactivation = 'NoInactivation',
  InactivationWithGracePeriod = 'InactivationWithGracePeriod',
  InactivationWithoutGracePeriod = 'InactivationWithoutGracePeriod',
}

export enum CarryoverCalculationMethodType {
  UseFullAvailableBalance = 'UseFullAvailableBalance',
  UseMaxAmount = 'UseMaxAmount',
}

export enum CardAuthorizationType {
  SigisService90Percent = 'SigisService90Percent',
  SigisServiceIiasHealthcare = 'SigisServiceIiasHealthcare',
  SigisServiceIiasRx = 'SigisServiceIiasRx',
  EdenredParking = 'EdenredParking',
  EdenredTransit = 'EdenredTransit',
  Unknown = 'Unknown',
}

export enum CarryOverLabel {
  Carryover = 'Carryover',
  Rollover = 'Rollover',
}

export enum ContributionMethodType {
  Check = 'Check',
  ACH = 'ACH',
  CreditOrDebitCard = 'CreditOrDebitCard',
  Wire = 'Wire',
  MyCash = 'MyCash',
}

export enum ContributionPostingType {
  AnnualSchedule = 'AnnualSchedule',
  PayrollSchedule = 'PayrollSchedule',
  CustomSchedule = 'CustomSchedule',
  UserInitiated = 'UserInitiated',
}

export enum BenefitPlanCoverageType {
  Single = 'Single',
  SinglePlusOne = 'SinglePlusOne',
  SinglePlusTwo = 'SinglePlusTwo',
  Family = 'Family',
  ByMemberUnlimited = 'ByMemberUnlimited',
}

export enum PaymentTierCoverageType {
  Single = 'Single',
  SinglePlusOne = 'SinglePlusOne',
  SinglePlusTwo = 'SinglePlusTwo',
  Family = 'Family',
  EachPerson = 'EachPerson',
}

export enum PricingModelTierType {
  Single = 'Single',
  EEOnly = 'EEOnly',
  EEPlusOne = 'EEPlusOne',
  Family = 'Family',
  SinglePlusSpouse = 'SinglePlusSpouse',
  SinglePlusOneChild = 'SinglePlusOneChild',
  SinglePlusChildren = 'SinglePlusChildren',
  SpouseOnly = 'SpouseOnly',
  FirstChild = 'FirstChild',
  SecondChild = 'SecondChild',
  ThirdChildPlus = 'ThirdChildPlus',
  Custom = 'Custom',
}

export enum PricingModelTierPersonRequiredType {
  Allowed = 'Allowed',
  Required = 'Required',
  NotAllowed = 'NotAllowed',
}

export enum AgeCalculationBasedOnType {
  CurrentDate = 'CurrentDate',
  EventDate = 'EventDate',
  EligibilityStartDate = 'EligibilityStartDate',
  FirstOfBirthMonth = 'FirstOfBirthMonth',
  FirstOfMonthFollowingDateOfBirth = 'FirstOfMonthFollowingDateOfBirth',
  PlanYearStartDate = 'PlanYearStartDate',
  EndOfPlanYear = 'EndOfPlanYear',
  FirstOfCalendarYear = 'FirstOfCalendarYear',
  EndOfCalendarYear = 'EndOfCalendarYear',
}

export enum SmokingStatusType {
  Smoker = 'Smoker',
  NonSmoker = 'NonSmoker',
}

export enum DisbursementType {
  Card = 'Card',
  Online = 'Online',
  Paper = 'Paper',
  ElectronicFile = 'ElectronicFile',
}

export enum PricingOptionType {
  Tier = 'Tier',
  Age = 'Age',
  Fee = 'Fee',
  Gender = 'Gender',
  SmokingStatus = 'SmokingStatus',
  FSA = 'FSA',
  IndividuallyRated = 'IndividuallyRated',
}

export enum SpecialDisbursementType {
  Takeover = 'Takeover',
  ExcessContribution = 'ExcessContribution',
  ProhibitedDisbursement = 'ProhibitedDisbursement',
  Disability = 'Disability',
  Death = 'Death',
  TransferToCustodian = 'TransferToCustodian',
  TransferToParticipant = 'TransferToParticipant',
  Fee = 'Fee',
}

export enum DisbursementMethodType {
  Check = 'Check',
  ACH = 'ACH',
  Wire = 'Wire',
  MyCash = 'MyCash',
}

export enum ElectionScheduleType {
  Annually = 'Annually',
  Monthly = 'Monthly',
}

export enum EligibilityEndDateType {
  DateOfTermination = 'DateOfTermination',
  EndOfMonthOfTerminationDate = 'EndOfMonthOfTerminationDate',
  EndOfMonthOfFullCoveragePaidThroughDate = 'EndOfMonthOfFullCoveragePaidThroughDate',
  EndOfPlanYear = 'EndOfPlanYear',
  LastScheduleDate = 'LastScheduleDate',
}

export enum EnrollmentMethodType {
  EDI = 'EDI',
  OnlineAndMobileEnrollment = 'OnlineAndMobileEnrollment',
  PaperOrPhone = 'PaperOrPhone',
}

export enum ExcessContributionType {
  Age = 'Age',
}

export enum ExternalPayeeNetwork {
  GuideStar = 'GuideStar',
}

export enum FinalizationType {
  Standard = 'Standard',
}

export enum FundingPostingType {
  PayrollSchedule = 'PayrollSchedule',
  PointOfDisbursement = 'PointOfDisbursement',
  CustomSchedule = 'CustomSchedule',
  UserInitiated = 'UserInitiated',
  ContributionSchedule = 'ContributionSchedule',
  CustomOther = 'CustomOther',
  Budgeted = 'Budgeted',
  DeductionSchedule = 'DeductionSchedule',
  CustomMonthly = 'CustomMonthly',
  CustomQuarterly = 'CustomQuarterly',
}

export enum FundingSourceType {
  ParticipantToClient = 'ParticipantToClient',
  ClientDirect = 'ClientDirect',
  ParticipantDirect = 'ParticipantDirect',
  ClientToPlan = 'ClientToPlan',
}

export enum GroupingTagType {
  MedicalFSA = 'MedicalFSA',
  LimitedPurposeFSA = 'LimitedPurposeFSA',
  DependentCareFSA = 'DependentCareFSA',
  PremiumAccount = 'PremiumAccount',
  TransitAccount = 'TransitAccount',
  ParkingAccount = 'ParkingAccount',
  HRA = 'HRA',
  HSA = 'HSA',
  PhilanthropyAccount = 'PhilanthropyAccount',
  COBRAAccount = 'COBRAAccount',
  RetireeBillingAccount = 'RetireeBillingAccount',
  LeaveBillingAccount = 'LeaveBillingAccount',
  EducationAccount = 'EducationAccount',
  FundedHRA = 'FundedHRA',
  NotApplicable = 'NotApplicable',
  Tracking = 'Tracking',
  AgBizAccount = 'AgBizAccount',
  DirectBillingAccount = 'DirectBillingAccount',
}

export enum InvestmentFinancialInstitution {
  InvestmentFinancialInstitution1 = 'InvestmentFinancialInstitution1',
  InvestmentFinancialInstitution2 = 'InvestmentFinancialInstitution2',
  Voya = 'Voya',
  Principal = 'Principal',
  Matrix = 'Matrix',
  Schwab = 'Schwab',
  BMO = 'BMO',
  VoyaAnnuity = 'VoyaAnnuity',
  BankOfOklahoma = 'BankOfOklahoma',
}

export enum ParticipantFundingMethodType {
  BankAccount = 'BankAccount',
  CreditCard = 'CreditCard',
  Check = 'Check',
  MyCash = 'MyCash',
  OtherBenefitAccount = 'OtherBenefitAccount',
}

export enum PlanPayeeType {
  Client = 'Client',
  TPABureau = 'TPABureau',
  Carrier = 'Carrier',
  Custom = 'Custom',
  BrokerDistributor = 'BrokerDistributor',
  Charity = 'Charity',
}

export enum PercentType {
  Base = 'Base',
  Fee = 'Fee',
  Surcharge = 'Surcharge',
}

export enum RemittanceFrequencyType {
  Weekly = 'Weekly',
  Monthly = 'Monthly',
  Quarterly = 'Quarterly',
}

export enum PlanLossOfCoverageType {
  DateOfEvent = 'DateOfEvent',
  EndOfMonth = 'EndOfMonth',
  EndOfFollowingMonth = 'EndOfFollowingMonth',
  EndOfPayPeriod = 'EndOfPayPeriod',
  RoundedQualifyingEventDate = 'RoundedQualifyingEventDate',
  FourteenthOfMonth = 'FourteenthOfMonth',
  ManuallyEntered = 'ManuallyEntered',
  NoLossOfCoverage = 'NoLossOfCoverage',
}

export enum PaymentSourceType {
  ACH = 'ACH',
  Check = 'Check',
  Wire = 'Wire',
  Card = 'Card',
}

export enum PayrollPostingType {
  PayrollSchedule = 'PayrollSchedule',
}

export enum PostingMethodType {
  AutoPost = 'AutoPost',
  ManualPost = 'ManualPost',
  ManualWithAutoPostBackup = 'ManualWithAutoPostBackup',
}

export enum FundingPostingFrequencyType {
  Annual = 'Annual',
  BiWeekly = 'BiWeekly',
  Custom = 'Custom',
  Daily = 'Daily',
  Monthly = 'Monthly',
  Quarterly = 'Quarterly',
  Weekly = 'Weekly',
}

export enum ContributionPostingFrequencyType {
  Quarterly = 'Quarterly',
  Monthly = 'Monthly',
  Custom = 'Custom',
}

export enum DeductionPostingFrequencyType {
  Annual = 'Annual',
  BiWeekly = 'BiWeekly',
  Custom = 'Custom',
  Daily = 'Daily',
  Monthly = 'Monthly',
  Quarterly = 'Quarterly',
  Weekly = 'Weekly',
}

export enum PostingStatusType {
  Scheduled = 'Scheduled',
  Posted = 'Posted',
  Error = 'Error',
  Reversed = 'Reversed',
  Rejected = 'Rejected',
}

export enum RecalculatePostingsOptionType {
  Recalculate = 'Recalculate',
  DoNotRecalculate = 'DoNotRecalculate',
}

export enum RunOutType {
  EndOfBenefitPlan = 'EndOfBenefitPlan',
  EndOfEligibility = 'EndOfEligibility',
  ServiceDate = 'ServiceDate',
}

export enum BillingTerminationRunOutType {
  EndOfBenefitPlan = 'EndOfBenefitPlan',
  EndOfEligibility = 'EndOfEligibility',
  EndOfPlanYear = 'EndOfPlanYear',
}

export enum BillableOptionalBenefitAccountState {
  GracePeriod = 'GracePeriod',
  RunOut = 'RunOut',
}

export enum RoundingType {
  Up = 'Up',
  Down = 'Down',
  Standard = 'Standard',
}

export enum TakeoverFundingType {
  BenefitPlan = 'BenefitPlan',
  Participant = 'Participant',
}

export enum InsuranceLevelType {
  SelfFunded = 'SelfFunded',
  FullyInsured = 'FullyInsured',
  NotApplicable = 'NotApplicable',
}

export enum ServiceOfferingType {
  ValueCurrencyBenefitPlan = 'ValueCurrencyBenefitPlan',
  ValueCurrencyPaymentPlan = 'ValueCurrencyPaymentPlan',
  ValuePoints = 'ValuePoints',
  TrackingOnly = 'TrackingOnly',
}

export enum RequireSSNType {
  Individual = 'Individual',
  Dependent = 'Dependent',
  IndividualAndDependent = 'IndividualAndDependent',
  None = 'None',
}

export enum ServiceOfferingStatusType {
  Draft = 'Draft',
  Published = 'Published',
}

export enum SpecialContributionType {
  TransferFromCustodian = 'TransferFromCustodian',
  TransferFromParticipant = 'TransferFromParticipant',
  Wellness = 'Wellness',
  Interest = 'Interest',
  Adjustment = 'Adjustment',
  UnfundedRollover = 'UnfundedRollover',
  FundedRollover = 'FundedRollover',
  ParticipantRepayment = 'ParticipantRepayment',
  ParticipantRefund = 'ParticipantRefund',
  COBRA = 'COBRA',
  ParticipantCardRefund = 'ParticipantCardRefund',
  ManualRequestRepayment = 'ManualRequestRepayment',
}

export enum ProrationType {
  FirstMonthOnly = 'FirstMonthOnly',
  EndMonthOnly = 'EndMonthOnly',
  BothFirstAndEndMonth = 'BothFirstAndEndMonth',
  None = 'None',
}

export enum TerminationRulesType {
  FullCoverageUntilEligibilityEndDate = 'FullCoverageUntilEligibilityEndDate',
  LimitedCoverageUntilEligibilityEndDate = 'LimitedCoverageUntilEligibilityEndDate',
  FullCoverageAfterTerminationWithNoClientLevelPostingsAfterLastScheduleDate = 'FullCoverageAfterTerminationWithNoClientLevelPostingsAfterLastScheduleDate',
}

export enum ThirdPartyAuthorizationType {
  SigisService90Percent = 'SigisService90Percent',
  SigisServiceIias = 'SigisServiceIias',
  EdenredParking = 'EdenredParking',
  EdenredTransit = 'EdenredTransit',
}

export enum MileageRateType {
  MedicalMileage = 'MedicalMileage',
}

export enum RegularlyScheduledHoursPerWeekType {
  FullTime = 'FullTime',
  PartTime = 'PartTime',
}

export enum UnionEmployeeStatusType {
  Excluded = 'Excluded',
  Eligible = 'Eligible',
  NotApplicable = 'NotApplicable',
}

export enum InvestmentAdvisorType {
  UBS = 'UBS',
  Advisor2 = 'Advisor 2',
  Advisor3 = 'Advisor 3',
}

export enum FullCoveragePaidThroughDateType {
  LastScheduleDate = 'LastScheduleDate',
  DateOfTermination = 'DateOfTermination',
  EndOfMonthOfTerminationDate = 'EndOfMonthOfTerminationDate',
  EndOfPlanYear = 'EndOfPlanYear',
}

export enum RequestSourceType {
  PICTURE_TO_PAY = 'PICTURE_TO_PAY',
  SAVE_FOR_LATER = 'SAVE_FOR_LATER',
  ADMIN = 'ADMIN',
  PAY_THE_PROVIDER = 'PAY_THE_PROVIDER',
  PUX = 'PUX',
  XF_CARD = 'XF_CARD',
  CLUX = 'CLUX',
  CARRIER_FILE = 'CARRIER_FILE',
  TELEFORM = 'TELEFORM',
  TRACKING_PROOF = 'TRACKING_PROOF',
}

export enum CashAccountMinimumBalanceOverride {
  Yes = 'Yes',
  No = 'No',
}

export enum CashAccountMinimumBalanceOverrideUpon {
  Retirement = 'Retirement',
  Termination = 'Termination',
}

export enum TaxDeductionMethod {
  PreTax = 'PreTax',
  PostTax = 'PostTax',
}

export enum TradeSystemType {
  SRTv1 = 'SRTv1',
  SRTv2 = 'SRTv2',
  Manual = 'Manual',
}

export enum ServiceOfferingEligibilityEventReferToType {
  EligibilityEvent = 'EligibilityEvent',
  QualifyingEvent = 'QualifyingEvent',
  LifeEvent = 'LifeEvent',
}

export enum EligibilityModificationEventDirectionType {
  TerminatesEligibility = 'TerminatesEligibility',
  AllowsNewCoverage = 'AllowsNewCoverage',
}

export enum ElectingParty {
  Client = 'Client',
  Participant = 'Participant',
  ParticipantAndClient = 'ParticipantAndClient',
}

export enum PricingPlanFeeCalculationType {
  Flat = 'Flat',
  Percentage = 'Percentage',
}

export enum PricingPlanFeesEditApplyToPlanType {
  CurrentPlans = 'CurrentPlans',
  NewOrRenewedPlans = 'NewOrRenewedPlans',
}

export enum ChainType {
  AND = 'AND',
  OR = 'OR',
}

export enum MatchType {
  CONTAINS = 'CONTAINS',
  EXACT = 'EXACT',
  NOT = 'NOT',
  IN = 'IN',
  NOT_IN = 'NOT_IN',
  NOT_CONTAINS = 'NOT_CONTAINS',
  STARTS_WITH = 'STARTS_WITH',
  NOT_STARTS_WITH = 'NOT_STARTS_WITH',
  LESS_THAN = 'LESS_THAN',
  LESS_THAN_EQUAL = 'LESS_THAN_EQUAL',
  GREATER_THAN = 'GREATER_THAN',
  GREATER_THAN_EQUAL = 'GREATER_THAN_EQUAL',
  IS_NULL = 'IS_NULL',
  BETWEEN = 'BETWEEN',
}
