import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig } from '@datorama/akita';
import { Offerings } from '@models/support/model';

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'Lookup', idKey: 'offering' })
export class LookupStore extends EntityStore<Offerings[]> {
  public constructor() {
    super();
  }
}
