import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaymentAccountGroupService } from '@app/enrollment/services/paymentAccountGroup.service';
import { withTransaction } from '@datorama/akita';
import { PaymentAccountGroup } from '@models/paymentAccount/model';
import { merge, Observable, of } from 'rxjs';
import { catchError, map, reduce, switchMap } from 'rxjs/operators';
import { BenefitPlansService } from 'src/app/benefit-accounts/services/benefit-plans.service';
import { RaygunService } from 'src/app/raygun/raygun.service';
import {
  AccountType,
  BenefitAccount,
  ChainType,
  EntrySOAData,
  EntryState,
  EntryType,
  FeatureAccount,
  FundingSourceType,
  PaymentSourceAccount,
  SoaEntryType,
  TransactionType,
} from 'src/app/shared/models/uba/account/model';
import {
  MatchType,
  ParentType,
  PaymentStatusType,
  RequestCardPaymentState,
  RequestManualPaymentState,
  RequestMethodType,
  RequestState,
  SearchCriteria,
} from 'src/app/shared/models/uba/request/model';
import { IndividualQuery, TransactionActivityStore } from 'src/app/state';
import { HiddenServiceOfferingQuery } from 'src/app/state/hidden-service-offering';
import {
  BrandId,
  CoreService,
  TransactionActivityEndpointSource,
  TransactionActivityType,
  TransactionCategory,
  TransactionStateCategory,
  UniqueIdType,
} from '../../models/pux/enum';
import {
  PaymentInfo,
  TransactionActivity,
  TransactionActivityModel,
  TransactionActivitySearchQuery,
} from '../../models/pux/model';
import { BenefitPlan, CarryOverLabel, ServiceOffering } from '../../models/uba/configuration/model';
import { Charity } from '../../models/uba/profile/model';
import { DestinationType, FundsTransferFrequencyType, FundsTransferType, SourceType } from '../../models/uba/profileConfiguration/model';
import { Dates } from '../../utils/dates';
import { BrandService } from '../brand.service';
import { ErrorHandlingService } from '../error-handling.service';
import { StatusService } from '../status.service';
import { Uri } from '../uri';
import { CharityService } from './charity.service';
import { EntryService } from './entry.service';

@Injectable({
  providedIn: 'root',
})
export class TransactionService {
  private static retrieveUUIDFromDescriptorId(descriptorId: string): string | undefined {
    return descriptorId?.substring(0, descriptorId.lastIndexOf('-'));
  }

  /**
   * Determine whether the transaction fits into the Pending or Posted category.
   * @param tx The transaction entity
   * @param dataSource Indicates the UBA endpoint where the data came from
   */
  private static getTransactionStateCategory(tx: TransactionActivity, dataSource: TransactionActivityEndpointSource): TransactionStateCategory {
    switch (tx.entryType) {
      case EntryType.DisbursementPreSettlement:
        return TransactionStateCategory.Pending;
      case EntryType.DisbursementSettlement:
      case EntryType.IndividualFeeAssessment:
      case EntryType.ParticipantFeeAssessment:
        return TransactionStateCategory.Posted;
    }

    switch (tx.requestCurrentState) {
      case RequestState.Draft:
        return TransactionStateCategory.Draft;

      case RequestState.UnpaidFinalized:
        return TransactionStateCategory.Applied;

      case RequestState.Accepted:
      case RequestState.AreTherePRsRemaining:
      case RequestState.AwaitingCustomerInput:
      case RequestState.IsClassified:
      case RequestState.IsPaid:
      case RequestState.IsValid:
      case RequestState.Pending:
      case RequestState.PendingAdmin:
      case RequestState.PendingSettlement:
      case RequestState.PotentialDuplicate:
      case RequestState.Submitted:
        return TransactionStateCategory.Pending;

      case RequestState.Denied:
        return TransactionStateCategory.Posted;
    }

    switch (tx.transactionState) {
      case RequestManualPaymentState.CanAutoPay:
      case RequestManualPaymentState.CustomerInputReq:
      case RequestManualPaymentState.ManualProcessingNeeded:
      case RequestManualPaymentState.PendingHold:
      case RequestManualPaymentState.PendingVerified:
      case RequestManualPaymentState.VerificationReq:
      case RequestCardPaymentState.Authorized:
      case RequestCardPaymentState.AreReceiptsAttached:
      case RequestCardPaymentState.CanAutoVerify:
      case RequestCardPaymentState.IsVerificationReq:
      case RequestCardPaymentState.IsVerified:
      case RequestCardPaymentState.Pending:
      case RequestCardPaymentState.PendingCustomerInputReq:
      case RequestCardPaymentState.PendingVerificationReq:
      case RequestCardPaymentState.PendingVerified:
        return TransactionStateCategory.Pending;
    }

    switch (dataSource) {
      case TransactionActivityEndpointSource.PendingEntry:
      case TransactionActivityEndpointSource.PendingRequest:
      case TransactionActivityEndpointSource.PendingTransfer:
        return TransactionStateCategory.Pending;
    }

    return TransactionStateCategory.Posted;
  }

  private static mutateAccountTransactionRecord(initialTransaction: TransactionActivity): TransactionActivityModel {
    const uniqueId = initialTransaction.postingId || initialTransaction.requestPaymentId;
    const uniqueIdType = initialTransaction.postingId ? UniqueIdType.Posting : UniqueIdType.RequestPayment;
    const dataSource = TransactionActivityEndpointSource.Account;
    const transactionType = TransactionService.getAccountTransactionType(initialTransaction);
    const transactionStateCategory = TransactionService.getTransactionStateCategory(initialTransaction, dataSource);
    const verificationRequired = false;

    return {
      ...initialTransaction,
      uniqueId,
      uniqueIdType,
      dataSource,
      transactionType,
      transactionStateCategory,
      verificationRequired,
    } as TransactionActivityModel;
  }

  private static getAccountTransactionType(tx: TransactionActivity): TransactionCategory {
    if (tx.transactionType) {
      return tx.transactionType;
    }

    // Overriding because the only feature account we are searching for is the PSIA, which we are debiting
    // Overriding chargebacks to be negative contributions per business requirements
    if ([
      EntryType.UndesignatedParticipantPaymentGroupFunding,
      EntryType.ParticipantPaymentGroupFunding,
      EntryType.DesignatedHoldingAccountChargeback,
      EntryType.UndesignatedHoldingAccountChargeback,
    ].includes(tx.entryType)) {
      return TransactionCategory.Contribution;
    }

    return tx.postingType === TransactionType.CREDIT ? TransactionCategory.Contribution : TransactionCategory.Expenditure;
  }

  private static shouldIncludePaymentAccountTransaction(transaction: TransactionActivity): boolean {
    const excludeDebitEntryTypes = [
      EntryType.ParticipantPaymentAccountContributionReversal,
      EntryType.ParticipantPaymentGroupFunding,
      EntryType.PaymentAccountClientUnremittance,
      EntryType.PaymentAccountPayeeUnremittance,
      EntryType.UndesignatedParticipantPaymentGroupFunding,
    ];

    const excludeCreditEntryTypes = [
      EntryType.ParticipantPaymentAccountContribution,
    ];

    const isPostingTypeExcluded = (tx: TransactionActivity) => {
      return (excludeDebitEntryTypes.includes(tx.entryType) && tx.postingType === TransactionType.DEBIT)
        || (excludeCreditEntryTypes.includes(tx.entryType) && tx.postingType === TransactionType.CREDIT);
    };

    return (![EntryState.ProcessingError, EntryState.Reversed, EntryState.ReversalPublished].includes(transaction.entryCurrentState)) && !isPostingTypeExcluded(transaction);
  }

  private readonly bankTransferLabel = 'Bank Transfer';
  private readonly entryTypesToLinkById = [ EntryType.ParticipantHoldingAccountTransfer ];
  private benefitAccounts: BenefitAccount[];
  private benefitPlans: BenefitPlan[];
  private featureAccounts: FeatureAccount[];
  private paymentSourceAccounts: PaymentSourceAccount[];
  private readonly brand: BrandId = this.brandService.getBrand();

  public constructor(
    private benefitPlansService: BenefitPlansService,
    private errorHandlingService: ErrorHandlingService,
    private http: HttpClient,
    private individualQuery: IndividualQuery,
    private charityService: CharityService,
    private raygunService: RaygunService,
    private statusService: StatusService,
    private transactionActivityStore: TransactionActivityStore,
    private entryService: EntryService,
    private brandService: BrandService,
    private hiddenServiceOfferingQuery: HiddenServiceOfferingQuery,
    private paymentAccountGroupService: PaymentAccountGroupService,
  ) { }

  public getAllTransactions(
    featureAccounts: FeatureAccount[],
    benefitAccounts: BenefitAccount[],
    benefitPlans: BenefitPlan[],
    paymentSourceAccounts: PaymentSourceAccount[],
  ): Observable<TransactionActivityModel[]> {
    this.benefitAccounts = benefitAccounts;
    this.benefitPlans = benefitPlans;
    this.featureAccounts = featureAccounts;
    this.paymentSourceAccounts = paymentSourceAccounts;
    return this.aggregateTransactions([
      TransactionActivityEndpointSource.Account,
      TransactionActivityEndpointSource.Contribution,
      TransactionActivityEndpointSource.PaymentAccountContribution,
      TransactionActivityEndpointSource.Expenditure,
      TransactionActivityEndpointSource.PendingEntry,
      TransactionActivityEndpointSource.PendingRequest,
      TransactionActivityEndpointSource.PendingTransfer,
    ]).pipe(
      switchMap((transactions) => this.mapLinkedEntryTypes(transactions)),
      withTransaction((transactions) => this.transactionActivityStore.set(transactions)),
    );
  }

  public refreshTransactions(...transactionSources: TransactionActivityEndpointSource[]): Observable<TransactionActivityModel[]> {
    if (!transactionSources?.length) {
      return of([]);
    }

    return this.aggregateTransactions(transactionSources)
      .pipe(
        withTransaction((transactions) => {
          for (const transactionSource of transactionSources) {
            this.transactionActivityStore.remove((transaction) => transaction.dataSource === transactionSource);
          }
          this.transactionActivityStore.add(transactions);
        }),
      );
  }

  private aggregateTransactions(transactionSources: TransactionActivityEndpointSource[]): Observable<TransactionActivityModel[]> {
    return this.paymentAccountGroupService.getPaymentAccountGroupsByIndividualId(this.individualQuery.getActiveId())
      .pipe(
        switchMap((pag: PaymentAccountGroup[]) => {
          return this.getTransactionsWithPag(transactionSources, pag);
        }),
      );

  }

  private getTransactionsWithPag(transactionSources: TransactionActivityEndpointSource[], pags: PaymentAccountGroup[]): Observable<TransactionActivityModel[]> {
    const requests = transactionSources.map((transactionSource) => {
      switch (transactionSource) {
        case TransactionActivityEndpointSource.Account:
          return this.getAccountTransactions();
        case TransactionActivityEndpointSource.Contribution:
          return this.getContributionOrExpenditureTransactions(TransactionCategory.Contribution);
        case TransactionActivityEndpointSource.PaymentAccountContribution:
          return this.getPaymentAccountContributionTransactions();
        case TransactionActivityEndpointSource.Expenditure:
          return this.getContributionOrExpenditureTransactions(TransactionCategory.Expenditure);
        case TransactionActivityEndpointSource.PendingEntry:
          return this.getPendingEntryTransactions(pags);
        case TransactionActivityEndpointSource.PendingRequest:
          return this.getPendingRequestTransactions();
        case TransactionActivityEndpointSource.PendingTransfer:
          return this.getPendingTransferTransactions();
        default:
          throw new Error(`Unsupported transaction source: ${transactionSource}`);
      }
    });

    return merge(...requests)
    .pipe(
      reduce((allTransactions, subsetTransactions) => allTransactions.concat(subsetTransactions)),
      map((allTransactions) => {
        this.removeDuplicatePendingCardSwipes(allTransactions);
        this.checkForInvalidTransactions(allTransactions);
        return allTransactions;
      }),
      switchMap((transactions) => {
        const payeeIds = this.getUniquePayeeIds(transactions);
        return this.charityService.getCharitiesById(payeeIds).pipe(
          map((charities) => {
            return transactions.map((tx) => {
              const benefitPlan = this.benefitPlans.find((bp) => bp.id === tx.planId);
              const sourcePayment = this.getSourcePaymentInfo(tx, pags);
              const destinationPayment = this.getDestinationPaymentInfo(tx, charities, pags);
              const carryoverLabel = this.getCarryoverLabel(tx);
              const isGivingAccount = benefitPlan && this.benefitPlansService.isGivingAccount(benefitPlan);
              const planName = this.getPlanName(tx, isGivingAccount, benefitPlan);
              return {
                ...tx,
                planName,
                sourcePaymentName: sourcePayment.name,
                sourcePaymentSourceType: sourcePayment.paymentType,
                destinationPaymentName: destinationPayment.name,
                destinationPaymentSourceIsGivingAccount: destinationPayment.isGivingAccount,
                destinationPaymentSourceType: destinationPayment.paymentType,
                carryoverLabel,
                isGivingAccount,
              } as TransactionActivityModel;
            });
          }),
        );
      }),
      catchError(this.errorHandlingService.rxjsErrorHandler()),
    );
  }

  private getUniquePayeeIds(transactions: TransactionActivityModel[]): string[] {
    const payeeIds = transactions.filter((tx) => tx.entryPayeeId).map((tx) => tx.entryPayeeId)
      .concat(transactions.filter((tx) => tx.fundsTransferDestinationType === DestinationType.Charity).map((tx) => tx.fundsTransferDestinationId));

    return Array.from(new Set(payeeIds));
  }

  private getContributionOrExpenditureTransactions(transactionType: TransactionCategory): Observable<TransactionActivityModel[]> {
    if (!(transactionType === TransactionCategory.Contribution || transactionType === TransactionCategory.Expenditure)) {
      throw new Error('Function getContributionOrExpenditureTransactions() requires TransactionCategory.Contribution or TransactionCategory.Expenditure as a parameter.');
    }

    const individual = this.individualQuery.getActive();

    const entryTypes = this.getIncludedEntryTypes(transactionType);
    const criteria: SearchCriteria = [
      {
        key: 'individualId',
        value: individual.id,
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      },
      {
        key: 'entryType',
        value: entryTypes.join('|'),
        matchType: MatchType.IN,
      },
      {
        key: 'postingType',
        value: transactionType === TransactionCategory.Contribution ? TransactionType.CREDIT : TransactionType.DEBIT,
        matchType: MatchType.EXACT,
      },
    ];

    const query: TransactionActivitySearchQuery = {
      skip: 0,
      take: Number.MAX_SAFE_INTEGER,
      transactionType: transactionType === TransactionCategory.Contribution ? TransactionActivityEndpointSource.Contribution : TransactionActivityEndpointSource.Expenditure,
      transactionActivityType: TransactionActivityType.Individual,
    };

    return this.getTransactions(criteria, query).pipe(
      map((transactions) => this.filterBABAUnfundedEntriesByTransactionType(transactions, transactionType)),
      map((transactions) => this.filterDisbursementSettlementEntries(transactions, transactionType)),
      map((transactions) => transactions.map((tx) => {
          const uniqueId = tx.postingId || tx.requestPaymentId;
          const uniqueIdType = tx.postingId ? UniqueIdType.Posting : UniqueIdType.RequestPayment;
          const dataSource = query.transactionType;
          const transactionStateCategory = TransactionService.getTransactionStateCategory(tx, dataSource);
          const verificationRequired = this.isVerificationRequiredForTransaction(tx, transactions);

          return {
            ...tx,
            uniqueId,
            uniqueIdType,
            dataSource,
            transactionStateCategory,
            verificationRequired,
          } as TransactionActivityModel;
        }),
      ),
    );
  }

  private filterBABAUnfundedEntriesByTransactionType(transactions: TransactionActivity[], type: TransactionCategory): TransactionActivity[] {
    const targetSoaEntryType = type === TransactionCategory.Contribution ? SoaEntryType.Credit : SoaEntryType.Debit;
    return transactions.filter((transaction) => transaction.entryType !== EntryType.BenefitAccountBalanceAdjustmentUnfunded ||
      JSON.parse(transaction.entrySoaData).soaEntryType === targetSoaEntryType,
    );
  }

  private filterDisbursementSettlementEntries(transactions: TransactionActivity[], type: TransactionCategory): TransactionActivity[] {
    if (type === TransactionCategory.Contribution) {
      return transactions;
    }
    const disbursementSettlementEntryTypes = [EntryType.DisbursementPreSettlement, EntryType.DisbursementSettlement];
    const filteredTransactions: TransactionActivity[] = [];

    for (const tx of transactions) {
      if (tx.entryType === EntryType.InvestmentSweep) {
        /**
         * For each InvestmentSweep entry, find any DisbursementPreSettlement (or Settlement) entries that contains it in its time span.
         * If there is just a PreSettlement entry, retain it in the list of transactions.
         * If there is both a PreSettlement and a corresponding Settlement entry, retain only the Settlement entry in the results
         * The Investment Sweep entry will now always be removed from the results.
         */
        const disbursementSettlementEntries = transactions.filter( (trans) => disbursementSettlementEntryTypes.includes(trans.entryType));
        if (disbursementSettlementEntries.length) {
          const filteredDisbursementSettlementEntries = disbursementSettlementEntries.filter( (dse) => {
            return (
              dse.entryParentId === tx.entryParentId
              && dse.entryPaymentSourceId === tx.entryPaymentSourceId
              && dse.entryStartDateTime <= tx.entryTransactionDateTime
              && dse.entryEndDateTime >= tx.entryTransactionDateTime
            );
          });

          if (filteredDisbursementSettlementEntries.length > 2) {
            this.raygunService.logError('Found more DisbursementSettlement entries than expected:',
              { investmentSweepEntry: tx, settlementEntries: filteredDisbursementSettlementEntries });
            return transactions;
          }

          const preSettlementEntry = filteredDisbursementSettlementEntries.find((entry) => entry.entryType === EntryType.DisbursementPreSettlement);
          const settlementEntry = filteredDisbursementSettlementEntries.find((entry) => entry.entryType === EntryType.DisbursementSettlement);
          if (preSettlementEntry || settlementEntry) {
            filteredTransactions.push(settlementEntry ? settlementEntry : preSettlementEntry);
          }
        }
      } else if (!disbursementSettlementEntryTypes.includes(tx.entryType)) {
        filteredTransactions.push(tx);
      }
    }
    return filteredTransactions;
  }

  private isVerificationRequiredForTransaction(transaction: TransactionActivity, allTransactions: TransactionActivity[]): boolean {
    // Verification is required if either the following is true:
    // (1) request.currentState = AwaitingCustomerInput
    // (2) the request payment has one of these states: 'PaidCustomerInputReq','PendingCustomerInputReq','CustomerInputReq'
    if (!transaction.requestId) {
      return false;
    }

    if (transaction.requestCurrentState === RequestState.AwaitingCustomerInput) {
      return true;
    }

    const states = this.statusService.getRequestPaymentStatesRequiringVerification();

    return allTransactions.some((tx) =>
      tx.requestId === transaction.requestId
      && tx.requestPaymentId === transaction.requestPaymentId
      && states.includes(tx.transactionState));
  }

  private getAccountTransactions(): Observable<TransactionActivityModel[]> {
    if (!this.featureAccounts || !this.featureAccounts.length) {
      return of([]);
    }
    const excludedEntryTypes = [
      ...this.statusService.getBCSEntryTypes(),
      EntryType.PreAuthorizationHold,
      EntryType.Reversal,
    ];
    const criteria: SearchCriteria = [
      {
        key: 'postingAccountId',
        value: this.featureAccounts.map((fa) => fa.id).join('|'),
        matchType: MatchType.IN,
        chainType: ChainType.AND,
      },
      {
        key: 'entryType',
        value: excludedEntryTypes.join('|'),
        matchType: MatchType.NOT_IN,
      },
    ];

    const query: TransactionActivitySearchQuery = {
      skip: 0,
      take: Number.MAX_SAFE_INTEGER,
      transactionType: TransactionActivityEndpointSource.Account,
      transactionActivityType: TransactionActivityType.Individual,
    };

    return this.getTransactions(criteria, query)
      .pipe(
        map((transactions) => {
          return transactions.map((tx) => {
            return TransactionService.mutateAccountTransactionRecord(tx);
          });
        }),
      );
  }

  private getCarryoverLabel(transaction: TransactionActivityModel): CarryOverLabel {
    let benefitPlan: BenefitPlan;
    switch (transaction.entryType) {
      case EntryType.ParticipantRollover:
      case EntryType.PayrollFundingPlanRollover:
      case EntryType.PodFundingPlanRollover:
        const benefitAccountId = transaction.entryParentId;
        const benefitAccount = this.benefitAccounts.find((ba) => ba.id === benefitAccountId);
        if (benefitAccount) {
          benefitPlan = this.benefitPlans.find((bp) => bp.id === benefitAccount.planId);
        }
        break;
      default:
        benefitPlan = this.benefitPlans.find((bp) => bp.id === transaction.planId);
        break;
    }

    if (benefitPlan && benefitPlan.carryoverLabel) {
      return benefitPlan.carryoverLabel;
    }
    return CarryOverLabel.Rollover;
  }

  private getSourcePaymentInfo(tx: TransactionActivityModel, pags?: PaymentAccountGroup[]): PaymentInfo {
    const response = {
      name: null,
      paymentType: null,
    };

    if (tx.entryOriginalPaymentSourceType) {
      response.paymentType = tx.entryOriginalPaymentSourceType;
    }

    const entryTypesWithSourceInHoldingDesc = [
      EntryType.ParticipantHoldingAccountTransfer,
      EntryType.DesignatedHoldingAccountPositiveAdjustment,
      EntryType.DesignatedHoldingAccountNegativeAdjustment,
      EntryType.ParticipantPaymentAccountContribution,
    ];
    if (entryTypesWithSourceInHoldingDesc.includes(tx.entryType)) {
      const entrySOAData = this.parseEntrySOAData(tx.entrySoaData);
      if (entrySOAData?.holdingAccountDescriptorId) {
        const accountUuid = TransactionService.retrieveUUIDFromDescriptorId(entrySOAData.holdingAccountDescriptorId);
        if (accountUuid) {
          const pag = pags?.find((group: PaymentAccountGroup) => group.id === accountUuid);
          response.name = pag?.displayName;
        }
      }
    } else {
      switch (tx.fundsTransferSourceType) {
        case SourceType.BenefitAccountForDisburseWithoutRequest:
          const benefitAccount = this.benefitAccounts.find((ba) => ba.planId === tx.fundsTransferSourceId);
          response.name = benefitAccount ? benefitAccount.planName : null;
          break;
        case SourceType.IAB:
          response.name = 'MyCash';
          break;
        case SourceType.PaymentSource:
          const paymentSource = this.paymentSourceAccounts.find((ps) => ps.id === tx.entryPaymentSourceId || ps.id === tx.fundsTransferSourceId);
          response.name = paymentSource ? paymentSource.bankAccountName : this.bankTransferLabel;
          response.paymentType = paymentSource ? paymentSource.paymentSourceType : null;
          break;
      }
    }

    return response;
  }

  private parseEntrySOAData(transactionSOAData: string): EntrySOAData | null {
    if (transactionSOAData !== undefined && transactionSOAData !== 'undefined' && transactionSOAData.trim().length > 0) {
      try {
        const soaData: EntrySOAData = JSON.parse(transactionSOAData);
        return soaData;
      } catch (error) {
        this.raygunService.logError('Expected entrySoaData to be a parsable JSON string', { entrySoaData: transactionSOAData, errorDetails: error });
      }
    }

    return null;
  }

  private getDestinationPaymentInfo(tx: TransactionActivityModel, charities: Charity[], pags?: PaymentAccountGroup[]): PaymentInfo {
    const oneTimeBcsFtcTypes = [
      FundsTransferType.PptACHToPptHoldingAccount,
      FundsTransferType.PptCardToPptHoldingAccount,
      FundsTransferType.PptCheckToPptHoldingAccount,
    ];
    if (this.statusService.getBCSEntryTypes().includes(tx.entryType) || oneTimeBcsFtcTypes.includes(tx.fundsTransferType as FundsTransferType)) {
      const paymentInfo = {
        name: ``,
        isGivingAccount: false,
        paymentType: tx.destinationPaymentSourceType,
      };
      const entrySOAData = this.parseEntrySOAData(tx.entrySoaData);
      const applicableEntryTypes = [
        EntryType.DesignatedHoldingAccountChargeback,
        EntryType.ParticipantPaymentGroupCardFunding,
        EntryType.ParticipantPaymentGroupFunding,
        EntryType.ParticipantPaymentGroupRefund,
        EntryType.PaymentRejectionFee,
      ];

      const nameSourceAccountDescriptor =
        applicableEntryTypes.includes(tx.entryType) ?
          entrySOAData?.holdingAccountDescriptorId :
          entrySOAData?.destinationHoldingAccountDescriptorId
        ??
          tx.fundsTransferDestinationId;

      if (nameSourceAccountDescriptor) {
        const accountUuid = TransactionService.retrieveUUIDFromDescriptorId(nameSourceAccountDescriptor);
        if (accountUuid) {
          const pag = pags?.find((group: PaymentAccountGroup) => group.id === accountUuid);
          paymentInfo.name = pag?.displayName;
        }
      }

      return paymentInfo;
    }

    if (tx.entryPaymentSourceId) {
      const paymentSource = this.paymentSourceAccounts.find((ps) => ps.id === tx.entryPaymentSourceId);
      return {
        name: paymentSource ? paymentSource.bankAccountName || paymentSource.paymentSourceType.toString() : this.bankTransferLabel,
        isGivingAccount: false,
        paymentType: paymentSource ? paymentSource.paymentSourceType : null,
      };
    }

    if (tx.entryPayeeId) {
      const charity = charities.find((p) => p.id === tx.entryPayeeId);
      return {
        name: charity ? charity.name : 'Your Donation',
        isGivingAccount: false,
        paymentType: null,
      };
    }

    switch (tx.fundsTransferDestinationType) {
      case DestinationType.Charity:
        const charity = charities.find((p) => p.id === tx.fundsTransferDestinationId);
        return {
          name: charity ? charity.name : 'Your Donation',
          isGivingAccount: false,
          paymentType: null,
        };

      case DestinationType.PaymentSource:
        const paymentSource = this.paymentSourceAccounts.find((ps) => ps.id === tx.fundsTransferDestinationId);
        return {
          name: paymentSource ? paymentSource.bankAccountName || paymentSource.paymentSourceType.toString() : this.bankTransferLabel,
          isGivingAccount: false,
          paymentType: paymentSource ? paymentSource.paymentSourceType : this.bankTransferLabel,
        };

      case DestinationType.BenefitAccount:
      case DestinationType.UnfundedBenefitAccount:
        const benefitAccount = this.benefitAccounts.find((ba) => ba.planId === tx.fundsTransferDestinationId);
        const benefitPlan = this.benefitPlans.find((bp) => bp.id === tx.fundsTransferDestinationId);
        return {
          name: benefitAccount ? benefitAccount.planName : null,
          isGivingAccount: benefitPlan ? this.benefitPlansService.isGivingAccount(benefitPlan) : null,
          paymentType: null,
        };
    }

    return {
      name: null,
      isGivingAccount: null,
      paymentType: null,
    };
  }

  private getTransactions(criteria: SearchCriteria, query: TransactionActivitySearchQuery): Observable<TransactionActivity[]> {
    const individual = this.individualQuery.getActive();
    const serviceOfferings = this.hiddenServiceOfferingQuery.getAll();

    const URL = new Uri(`/profile/${individual.id}/transactionActivity/search`, CoreService.Dashboard, query);
    return this.http.post<TransactionActivity[]>(URL.toString(), criteria)
      .pipe(
        catchError((error) => {
          this.raygunService.logError('TransactionActivity endpoint failed', { criteria, error, query });
          return of([]);
        }),
        map((transactions) => transactions.filter((transaction) => this.shouldIncludeTransaction(transaction, query.transactionType, serviceOfferings))),
        map((transactions) => transactions.map((tx) => {
          // workaround to prevent internal name and description from being displayed
          const benefitPlan = this.benefitPlans.find((bp) => bp.id === tx.planId);
          if (benefitPlan) {
            tx.planName = benefitPlan.externalName;
            tx.planDescription = benefitPlan.externalDescription;
          }
          return tx;
        })),
      );
  }

  /**
   * Determines whether the specified transaction should be visible in the UI. Put logic here that you cannot do in the
   * search criteria for the API call.
   * @param transaction The transaction
   */
  private shouldIncludeTransaction(transaction: TransactionActivity, transactionType: TransactionActivityEndpointSource, serviceOfferings: ServiceOffering[]): boolean {
    const hasActiveRequestState = !transaction.requestCurrentState || this.statusService.getActiveRequestStates().includes(transaction.requestCurrentState);
    if (!hasActiveRequestState) {
      return false;
    }
    const benefitPlan = this.benefitPlans.find((plan) => plan.id === transaction.planId);
    if (benefitPlan && (serviceOfferings.some((serviceOffering) => serviceOffering.id === benefitPlan.offeringId))) {
      return false;
    }

    switch (transactionType) {
      case TransactionActivityEndpointSource.Contribution:
        const invalidContributionType = (
          (
            transaction.entryType === EntryType.CardRequestPayment
            && transaction.postingType !== TransactionType.CREDIT
          )
          ||
          (
            transaction.entryType === EntryType.ClientContribution
            && (
              transaction.entryFundingSource !== FundingSourceType.ClientDirect
              && transaction.entryFundingSource !== FundingSourceType.ClientToPlan
              || !this.statusService.getContributionEntryStates().includes(transaction.entryCurrentState)
            )
          )
          ||
          (
            transaction.entryType === EntryType.ParticipantContribution
            && (
              transaction.entryFundingSource !== FundingSourceType.ParticipantDirect
              || !this.statusService.getContributionEntryStates().includes(transaction.entryCurrentState)
            )
          )
          ||
          (
            transaction.entryType === EntryType.PayrollPosting
            && (
              transaction.entryFundingSource !== FundingSourceType.ParticipantToClient
              || !this.statusService.getContributionEntryStates().includes(transaction.entryCurrentState)
            )
          )
        );
        return !invalidContributionType;
      case TransactionActivityEndpointSource.Expenditure:
        const invalidExpenditureType = (
          (
            transaction.entryType === EntryType.InvestmentSweep
            && transaction.accountType !== AccountType.PPTAB
          )
        );
        return !invalidExpenditureType;
    }

    return true;
  }

  private getPaymentAccountContributionTransactions(): Observable<TransactionActivityModel[]> {
    const individual = this.individualQuery.getActive();

    const criteria: SearchCriteria = [
      {
        key: 'entryType',
        value: this.statusService.getBCSEntryTypes().join('|'),
        matchType: MatchType.IN,
        chainType: ChainType.AND,
      },
      {
        key: 'individualId',
        value: individual.id,
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      },
    ];

    const query: TransactionActivitySearchQuery = {
      skip: 0,
      take: Number.MAX_SAFE_INTEGER,
      transactionType: TransactionActivityEndpointSource.PaymentAccountContribution,
      transactionActivityType: TransactionActivityType.Individual,
    };

    return this.getTransactions(criteria, query)
    .pipe(
      map((transactions) => transactions.filter((transaction: TransactionActivity) => TransactionService.shouldIncludePaymentAccountTransaction(transaction))),
      map((transactions) => {
        return transactions.map((tx) => {
          return TransactionService.mutateAccountTransactionRecord(tx);
        });
      }),
    );
  }

  private getPendingEntryTransactions(pags: PaymentAccountGroup[]): Observable<TransactionActivityModel[]> {
    const individual = this.individualQuery.getActive();
    const entryTypes = this.statusService.getPendingEntryTypes(this.brand);

    const criteria: SearchCriteria = [
      {
        key: 'entryType',
        value: entryTypes.join('|'),
        matchType: MatchType.IN,
        chainType: ChainType.AND,
      },
      {
        key: 'scheduledDate',
        value: Dates.today(),
        matchType: MatchType.LESS_THAN_EQUAL,
        chainType: ChainType.AND,
      },
      {
        key: 'benefitAccountId',
        value: this.benefitAccounts.map((ba) => ba.id).join('|'),
        matchType: MatchType.IN,
        chainType: ChainType.OR,
        groupCriteria: [{
            key: 'individualId',
            value: individual.id,
            matchType: MatchType.EXACT,
            chainType: ChainType.OR,
          },
          {
            key: 'paymentAccountGroupId',
            value: pags.map((pag) => pag.id).join('|'),
            matchType: MatchType.IN,
            chainType: ChainType.OR,
          },
        ],
      },
    ];

    const query: TransactionActivitySearchQuery = {
      skip: 0,
      take: Number.MAX_SAFE_INTEGER,
      transactionType: TransactionActivityEndpointSource.PendingEntry,
      transactionActivityType: TransactionActivityType.Individual,
    };

    return this.getTransactions(criteria, query)
      .pipe(
        map((transactions) => {
          return transactions.map((tx) => {
            const uniqueId = tx.entryId;
            const uniqueIdType = UniqueIdType.Entry;
            const dataSource = TransactionActivityEndpointSource.PendingEntry;
            const transactionType = this.getPendingEntryTransactionType(tx);
            const transactionStateCategory = TransactionStateCategory.Pending;
            const verificationRequired = false;

            return {
              ...tx,
              uniqueId,
              uniqueIdType,
              dataSource,
              transactionType,
              transactionStateCategory,
              verificationRequired,
            } as TransactionActivityModel;
          });
        }),
      );
  }

  private getPendingEntryTransactionType(tx: TransactionActivity): TransactionCategory {
    if (this.statusService.getPendingEntryExpenditureTypes().includes(tx.entryType)) {
      return TransactionCategory.Expenditure;
    }

    if (tx.entrySoaData !== undefined && tx.entrySoaData !== 'undefined' && tx.entrySoaData.trim().length > 0) {
      try {
        const soaData: EntrySOAData = JSON.parse(tx.entrySoaData);
        if (tx.entryType === EntryType.BenefitAccountBalanceAdjustment) {
          return soaData.soaEntryType === SoaEntryType.Debit ? TransactionCategory.Contribution : TransactionCategory.Expenditure;
        }
        if (soaData.soaEntryType === SoaEntryType.Debit &&
            ![ EntryType.ManualRefund, EntryType.ParticipantPaymentGroupCardFunding, EntryType.UndesignatedParticipantPaymentGroupCardFunding ].includes(tx.entryType)) {
          return TransactionCategory.Expenditure;
        }
        return TransactionCategory.Contribution;
      } catch (error) {
        this.raygunService.logError('Expected entrySoaData to be a parsable JSON string', { entrySoaData: tx.entrySoaData, errorDetails: error });
      }
    }
    return TransactionCategory.Contribution;
  }

  private getPendingRequestTransactions(): Observable<TransactionActivityModel[]> {
    const individual = this.individualQuery.getActive();

    const criteria: SearchCriteria = [
      {
        key: 'individualId',
        value: individual.id,
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      },
      {
        key: 'parentType',
        value: ParentType.INDIVIDUAL,
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      },
    ];

    const query: TransactionActivitySearchQuery = {
      skip: 0,
      take: Number.MAX_SAFE_INTEGER,
      transactionType: TransactionActivityEndpointSource.PendingRequest,
      transactionActivityType: TransactionActivityType.Individual,
    };

    return this.getTransactions(criteria, query)
      .pipe(
        map((transactions) => {
          return transactions.map((tx) => {
            const uniqueId = tx.requestPaymentId || tx.requestId;
            const uniqueIdType = tx.requestPaymentId ? UniqueIdType.RequestPayment : UniqueIdType.Request;
            const dataSource = query.transactionType;
            const transactionStateCategory = TransactionService.getTransactionStateCategory(tx, dataSource);
            const verificationRequired = this.isVerificationRequiredForTransaction(tx, transactions);

            return {
              ...tx,
              uniqueId,
              uniqueIdType,
              dataSource,
              transactionStateCategory,
              verificationRequired,
            } as TransactionActivityModel;
          });
        }),
      );
  }

  private getPendingTransferTransactions(): Observable<TransactionActivityModel[]> {
    const individual = this.individualQuery.getActive();

    const criteria: SearchCriteria = [
      {
        key: 'individualId',
        value: individual.id,
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      },
      {
        key: 'frequency',
        value: FundsTransferFrequencyType.OneTime,
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      },
    ];

    const query: TransactionActivitySearchQuery = {
      skip: 0,
      take: Number.MAX_SAFE_INTEGER,
      transactionType: TransactionActivityEndpointSource.PendingTransfer,
      transactionActivityType: TransactionActivityType.Individual,
    };

    return this.getTransactions(criteria, query)
      .pipe(
        map((transactions) => {
          return transactions.map((tx) => {
            const benefitAccount = this.benefitAccounts.find((ba) => ba.planId === tx.fundsTransferDestinationId);

            const uniqueId = tx.fundsTransferId;
            const uniqueIdType = UniqueIdType.FundsTransfer;
            const dataSource = query.transactionType;
            const transactionType = tx.fundsTransferAmount > 0 ? TransactionCategory.Contribution : TransactionCategory.Expenditure;
            const planId = benefitAccount ? benefitAccount.id : null;
            const planName = this.getPlanNameFromPendingTransfer(tx);
            const transactionStateCategory = TransactionStateCategory.Pending;
            const verificationRequired = false;

            return {
              ...tx,
              uniqueId,
              uniqueIdType,
              planId,
              planName,
              dataSource,
              transactionType,
              transactionStateCategory,
              verificationRequired,
            } as TransactionActivityModel;
          });
        }),
      );
  }

  private getPlanNameFromPendingTransfer(tx: TransactionActivity): string {
    switch (tx.fundsTransferSourceType) {
      case SourceType.IAB: return 'MyCash';
      case SourceType.GiveBackAccount: return 'Individual giving account';
      case SourceType.PPTAB:
        const plan = this.benefitAccounts.find((ba) => ba.planId === tx.fundsTransferSourceId);
        return plan ? plan.planName : null;
      default: return null;
    }
  }

  private getPlanName(transaction: TransactionActivity, isGivingAccount: boolean, benefitPlan: BenefitPlan): string {
    if (isGivingAccount) {
      return 'Individual giving account';
    }
    if (!transaction.planName && transaction.soaAccountId) {
      return this.getPlanNameFromSoaAccountId(transaction.soaAccountId);
    }
    if (!transaction.planName && benefitPlan) {
      return benefitPlan.externalName;
    }
    return transaction.planName;
  }

  private getPlanNameFromSoaAccountId(soaAccountId: string): string {
    const benefitAccount = this.benefitAccounts.find((ba) => ba.soaAccountId === soaAccountId);

    if (benefitAccount) {
      return benefitAccount.planName;
    }

    const featureAccount = this.featureAccounts.find((fa) => fa.id === soaAccountId);
    if (featureAccount) {
      switch (featureAccount.name) {
        case AccountType.IAB:
          return 'MyCash';
        case AccountType.PPTAB:
          return 'Card Decline Protection';
      }
    }

    return null;
  }

  /**
   * Identify any card swipes that exist in both the pendingRequest and expenditure data. If any are found, remove the
   * expenditure duplicate.
   * NOTE: This scenario can happen because card swipes exist as both pending and completed in the database for a brief
   * period of time during settlement (usually < 5 minutes). See PWP-3492 for details.
   * @param transactions A collection of TransactionActivityModel entities
   */
  private removeDuplicatePendingCardSwipes(transactions: TransactionActivityModel[]): void {
    const pendingCardSwipesInPendingRequests = transactions.filter((tx) =>
      tx.dataSource === TransactionActivityEndpointSource.PendingRequest
      && tx.requestMethod === RequestMethodType.Card
      && tx.requestPaymentPaymentStatus === PaymentStatusType.Pending,
    );

    pendingCardSwipesInPendingRequests.forEach((pendingCardSwipe) => {
      // Find the matching tx in the expenditure data and remove it
      const index = transactions.findIndex((tx) =>
        tx.dataSource === TransactionActivityEndpointSource.Expenditure
        && tx.entryType === EntryType.CardRequestPayment
        && !tx.requestId, // There won't be a request ID during this brief dual-state period (completed card swipes *will* have it)
      );

      if (index > -1) {
        transactions.splice(index, 1);
      }
    });
  }

  /**
   * Log an error if any of the transactions are missing a unique ID or repeat the same uniqueId. We want to make sure that
   * we are properly assigning the ID and that we don't retrieve the same transaction from multiple calls to the endpoint.
   * @param transactions A collection of TransactionActivityModel entities
   */
  private checkForInvalidTransactions(transactions: TransactionActivityModel[]): void {
    const set = new Map();
    transactions.forEach((tx) => {
      if (!tx.uniqueId) {
        const msg = 'Missing uniqueId on TransactionActivity entity.';
        this.raygunService.logError(msg, { transaction: tx });
      } else if (set.has(tx.uniqueId)) {
        const msg = `Found duplicate TransactionActivity ID.`;
        this.raygunService.logError(msg, { transaction1: set.get(tx.uniqueId), transaction2: tx });
      } else {
        set.set(tx.uniqueId, tx);
      }
    });
  }

  private getIncludedEntryTypes(transactionType: TransactionCategory): EntryType[] {
    switch (transactionType) {
      case TransactionCategory.Contribution:
        return this.statusService.getContributionEntryTypes();
      case TransactionCategory.Expenditure:
        return this.statusService.getExpenditureEntryTypes();
    }

    return [];
  }

  private mapLinkedEntryTypes(transactions: TransactionActivityModel[]): Observable<TransactionActivityModel[]> {
    const transactionsLinkedByLinkId = transactions.filter((tx: TransactionActivityModel) => !this.entryTypesToLinkById.includes(tx.entryType)); // We don't want to search by link Id if it is linked by id directly
    const linkIds = Array.from(new Set(transactionsLinkedByLinkId.filter(({entryLinkId}) => entryLinkId).map(({entryLinkId}) => entryLinkId)));
    if (!linkIds.length) {
      return of(transactions);
    }

    return this.entryService.getEntriesByLinkIds(linkIds).pipe(
      map((linkedEntries) => {
        transactions.forEach((tx) => {
          if (!this.entryTypesToLinkById.includes(tx.entryType)) {
            tx.linkedEntryTypes = !tx.entryLinkId
              ? undefined
              : Array.from(new Set(linkedEntries.filter(({linkId}) => tx.entryLinkId === linkId).map(({entryType}) => entryType)));
          }
        });
        return transactions;
      }),
    );
  }
}
