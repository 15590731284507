import { Injectable } from '@angular/core';
import { Order, QueryConfig, QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { Message, MessageStatusType } from 'src/app/shared/models/uba/communication/model';

import { MessageState, MessageStore } from './message.store';

@Injectable({
  providedIn: 'root',
})
@QueryConfig({
  sortBy: 'created',
  sortByOrder: Order.DESC,
})
export class MessageQuery extends QueryEntity<MessageState> {
  public constructor(protected store: MessageStore) {
    super(store);
  }

  public selectUnreadCount(): Observable<number> {
    return this.selectCount((message) => message.currentState !== MessageStatusType.Read);
  }
}
