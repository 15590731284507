import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { BenefitAccountPostingSummaryViewModel } from 'src/app/shared/models/pux/model';

import {
  BenefitAccountPostingSummaryState,
  BenefitAccountPostingSummaryStore,
} from './benefit-account-posting-summary.store';

@Injectable({
  providedIn: 'root',
})
export class BenefitAccountPostingSummaryQuery extends QueryEntity<BenefitAccountPostingSummaryState> {
  public constructor(protected store: BenefitAccountPostingSummaryStore) {
    super(store);
  }

  public selectAllWhenLoaded(): Observable<BenefitAccountPostingSummaryViewModel[]> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectAll()),
      );
  }
}
