import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Request } from 'src/app/shared/models/uba/request/model';

export interface RequestState extends EntityState<Request, string> { }

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'Request' })
export class RequestStore extends EntityStore<RequestState> {
  public constructor() {
    super();
  }
}
