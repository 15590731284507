import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthTermsGuard } from '../shared/guards/auth-terms.guard';
import { TermsOfUseComponent } from './container/terms-of-use/terms-of-use.component';
import { TermsOfUseSignState } from './models/terms-of-use.enum';

const routes: Routes = [
  {
    path: '',
    component: TermsOfUseComponent,
    canActivate: [AuthTermsGuard],
    data: {
      signState: TermsOfUseSignState.SignIn,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TermsOfUseRoutingModule {}
