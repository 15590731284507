import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { CardPackageViewModel } from '../../tasc-wallet/models/card-package-view-model';

export interface CardPackageViewModelState extends EntityState<CardPackageViewModel, string>, ActiveState { }

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'CardPackageViewModel', idKey: 'cardPackageId' })
export class CardPackageViewModelStore extends EntityStore<CardPackageViewModelState> {
  public constructor() {
    super();
  }
}
