import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Entry } from 'src/app/shared/models/uba/account/model';

export interface EntryState extends EntityState<Entry, string> {}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'Entry' })
export class EntryStore extends EntityStore<EntryState> {
  public constructor() {
    super();
  }
}
