import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { FeatureAccount } from 'src/app/shared/models/uba/account/model';

export interface FeatureAccountState extends EntityState<FeatureAccount, string> {}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'FeatureAccount' })
export class FeatureAccountStore extends EntityStore<FeatureAccountState> {
  public constructor() {
    super();
  }
}
