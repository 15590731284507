import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { CoreService } from '../../models/pux/enum';
import { SearchQuery, SearchResults } from '../../models/pux/model';
import { ChainType, Charity, CharityState, MatchType, SearchCriteria } from '../../models/uba/profile/model';
import { Uri } from '../uri';

@Injectable({
  providedIn: 'root',
})
export class CharityService {

  public constructor(
    private http: HttpClient,
  ) {}

  /**
   * Finds all charitys matching any of the specified search criteria. If no criteria is specified, the first page
   * of all charitys is returned. Returns 10 matches at a time.
   * @param name The text to search by. Optional. Matches on any name that contains this text.
   * @param state The 2-character state code abbreviation to search by. Optional. Performs exact match.
   * @param zipCode The 5-character zip code to search by. Optional. Performs exact match.
   * @param page Indicates which page of search results to return. Used in paging scenarios. Zero-based.
   * Defaults to zero when not specified.
   */
  public searchCharities(name?: string, state?: string, zipCode?: string, page: number = 0): Observable<SearchResults<Charity[]>> {
    const criteria: SearchCriteria = [
      {
        key: 'currentState',
        value: CharityState.Active,
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      }];

    if (name && name.length > 0) {
      criteria.push({
        key: 'name',
        value: name,
        matchType: MatchType.CONTAINS,
        chainType: ChainType.AND,
      });
    }

    if (state && state.length > 0) {
      criteria.push({
        key: 'state',
        value: state,
        matchType: MatchType.EXACT,
        chainType: ChainType.OR,
      });
    }

    if (zipCode && zipCode.length > 0) {
      criteria.push({
        key: 'zip',
        value: zipCode,
        matchType: MatchType.EXACT,
        chainType: ChainType.OR,
      });
    }

    const pageSize = 10;

    const query: SearchQuery = {
      take: pageSize + 1,
      skip: page * 10,
      orderBy: 'name',
      orderDirection: 'ASC',
    };
    const relativeURL = '/profile/profileType/charity/search';
    const uri = new Uri(relativeURL, CoreService.Profile, query);

    const headers: { observe: 'response' } = {
      observe: 'response',
    };

    return this.http
      .post<Charity[]>(uri.toString(), criteria, headers).pipe(
        map((response) => {
          const charities = response.body;
          return { data: charities.slice(0, pageSize), hasMore: charities.length > pageSize } as SearchResults<Charity[]>;
        }),
    );
  }

  public getCharitiesById(charityIds: string[]): Observable<Charity[]> {
    if (!charityIds.length) {
      return of([]);
    }

    const criteria: SearchCriteria = [{ key: 'id', value: charityIds.join('|'), matchType: MatchType.IN }];
    const query: SearchQuery = { skip: 0, take: Number.MAX_SAFE_INTEGER, orderBy: 'name', orderDirection: 'ASC' };

    const relativeURL = `/profile/profileType/charity/search`;
    const uri = new Uri(relativeURL, CoreService.Profile, query);

    return this.http.post<Charity[]>(uri.toString(), criteria);
  }
}
