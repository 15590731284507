import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClaimedFundsDisbursement, Entry, EntryCommand, EntryCommandType, MatchType } from '@models/account/model';
import { Observable } from 'rxjs';
import { mapTo } from 'rxjs/operators';

import { CoreService } from '../../models/pux';
import { CommandFactory } from '../../utils/command.factory';
import { Uri } from '../uri';

@Injectable({ providedIn: 'root' })
export class EntryService {
  public constructor(
    private http: HttpClient,
    private commandFactory: CommandFactory,
  ) {}

  public getEntriesByLinkIds(linkIds: string[]): Observable<Entry[]> {
    const criteria = [{
      key: 'linkId',
      value: linkIds.join('|'),
      matchType: MatchType.IN,
    }];

    const uri = new Uri(`/profile/*/entry/search`, CoreService.Account);
    return this.http.post<Entry[]>(uri.toString(), criteria);
  }

  public sendEntryCommand(entry: Entry, commandType: EntryCommandType, claimedFundsDisbursement?: ClaimedFundsDisbursement): Observable<Entry> {
    const command: EntryCommand = this.commandFactory.createCommand<Entry>(entry, commandType);
    command.claimedFundsDisbursement = claimedFundsDisbursement;
    const uri = new Uri(`/profile/*/entry/${entry.id}/command/${commandType}`, CoreService.Account);
    return this.http.put<void>(uri.toString(), command).pipe(
      mapTo(command.data),
    );
  }
}
