import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { RequestAttachment } from '@models/request/model';

export interface RequestAttachmentState extends EntityState<RequestAttachment, string> { }

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'RequestAttachment' })
export class RequestAttachmentStore extends EntityStore<RequestAttachmentState> {
  public constructor() {
    super();
  }
}
