import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { BenefitBalance } from 'src/app/shared/models/pux/model';

export interface BenefitBalanceState extends EntityState<BenefitBalance, string> { }

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'BenefitBalance' })
export class BenefitBalanceStore extends EntityStore<BenefitBalanceState> {
  public constructor() {
    super();
  }
}
