import { NgModule } from '@angular/core';

import { CardExpirationPipe } from './card-expiration.pipe';
import { CardStatePipe } from './card-state.pipe';
import { CarryoverPastTensePipe } from './carryover-past-tense.pipe';
import { CarryoverPolicyPipe } from './carryover-policy.pipe';
import { DateAddPipe } from './date-add.pipe';
import { ElectionSchedulePipe } from './election-schedule.pipe';
import { FundingSourceBalancePipe } from './funding-source-balance.pipe';
import { FundsTransferFrequencyPipe } from './funds-transfer-frequency.pipe';
import { FundsTransferSchedulePipe } from './funds-transfer-schedule.pipe';
import { JoinPipe } from './join.pipe';
import { OddNumberPipe } from './odd-number.pipe';
import { OrdinalPipe } from './ordinal.pipe';
import { PayrollFrequencyPipe } from './payroll-frequency.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { SafeImageUrlPipe } from './safe-image-url.pipe';
import { SafeUrlPipe } from './safe-url.pipe';
import { SentenceCasePipe } from './sentence-case.pipe';
import { ServiceTypeIconPipe } from './service-type-icon.pipe';
import { SupportRequestStatePipe } from './support-request-state.pipe';
import { ToCasePipe } from './toCase.pipe';
import { TradeAmountClassPipe } from './trade-amount-class.pipe';
import { TransactionAmountClassPipe } from './transaction-amount-class.pipe';
import { TransactionAmountPipe } from './transaction-amount.pipe';
import { TransactionFrequencyIndicatorPipe } from './transaction-frequency-indicator.pipe';
import { TransactionTypePipe } from './transaction-type.pipe';
import { UniqueDestinationPipe } from './unique-destination.pipe';
import { UniqueSourcePipe } from './unique-source.pipe';

@NgModule({
  declarations: [
    CardExpirationPipe,
    CardStatePipe,
    CarryoverPastTensePipe,
    CarryoverPolicyPipe,
    DateAddPipe,
    ElectionSchedulePipe,
    FundingSourceBalancePipe,
    FundsTransferFrequencyPipe,
    FundsTransferSchedulePipe,
    JoinPipe,
    OddNumberPipe,
    OrdinalPipe,
    PayrollFrequencyPipe,
    SafeHtmlPipe,
    SafeImageUrlPipe,
    SafeUrlPipe,
    SentenceCasePipe,
    ServiceTypeIconPipe,
    SupportRequestStatePipe,
    ToCasePipe,
    TradeAmountClassPipe,
    TransactionAmountPipe,
    TransactionAmountClassPipe,
    TransactionFrequencyIndicatorPipe,
    TransactionTypePipe,
    UniqueDestinationPipe,
    UniqueSourcePipe,

  ],
  exports: [
    CardExpirationPipe,
    CardStatePipe,
    CarryoverPastTensePipe,
    CarryoverPolicyPipe,
    DateAddPipe,
    ElectionSchedulePipe,
    FundingSourceBalancePipe,
    FundsTransferFrequencyPipe,
    FundsTransferSchedulePipe,
    JoinPipe,
    OddNumberPipe,
    OrdinalPipe,
    PayrollFrequencyPipe,
    SafeHtmlPipe,
    SafeImageUrlPipe,
    SafeUrlPipe,
    SentenceCasePipe,
    ServiceTypeIconPipe,
    SupportRequestStatePipe,
    ToCasePipe,
    TradeAmountClassPipe,
    TransactionAmountPipe,
    TransactionAmountClassPipe,
    TransactionFrequencyIndicatorPipe,
    TransactionTypePipe,
    UniqueDestinationPipe,
    UniqueSourcePipe,
  ],
})
export class PipesModule { }
