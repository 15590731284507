import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { CcxSubstantiationLink } from '@models/request/model';

export interface CcxSubstantiationLinkState extends EntityState<CcxSubstantiationLink, string> { }

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'CcxSubstantiationLink'})
export class CcxSubstantiationLinkStore extends EntityStore<CcxSubstantiationLinkState> {
  public constructor() {
    super();
  }
}
