import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Document } from 'src/app/shared/models/uba/profileConfiguration/model';

export interface LetterState extends EntityState<Document, string> { }

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'Letter' })
export class LetterStore extends EntityStore<LetterState> {
  public constructor() {
    super();
  }
}
