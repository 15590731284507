import { SearchQuery } from 'src/app/shared/models/pux/model';

import { environment } from '../../../environments/environment';
import { CoreService } from '../models/pux/enum';

/**
 * Creates an HTTP safe, environment aware URL to a UBA endpoint.
 */
export class Uri {

  private query = {};

  /**
   * Initialize a new instance of the Uri class.
   * @param relativeUrl The relative URL to the UBA endpoint.
   * (e.g. '/profile/fc0b5ec6-8b88-4213-8e54-ac1191e56bbe/attachment/command/StartToActive')
   * @param coreService Indicates the UBA core service
   * @param criteria The search criteria. Optional. If speciied, will be appended to the querystring.
   */
  public constructor(private relativeUrl: string, private coreService: CoreService, criteria?: SearchQuery) {
    if (criteria) {
      Object.keys(criteria).forEach((key) => {
        if (criteria[key] || criteria[key] === 0) {
          if (key !== 'criteria') {
            this.addQueryParam(key, criteria[key]);
          }
        }
      });
    }
  }

  /**
   * Add the specified query name and value to the internal state.
   * @param name The query string name
   * @param value The query string value
   */
  public addQueryParam(name: string, value: string): void {
    this.query[name] = value;
  }

  /**
   * Generates a fully qualified URL including the domain, relative URL, and query string parameters (if specified).
   * (e.g. https://d2-api.uba-dev.com/profile/v1/profile/profileType/individual/search)
   */
  public toString(): string {
    let url = this.getHostName() + this.relativeUrl;

    const kvp = Object.keys(this.query).map((key) => {
      if (Array.isArray(this.query[key])) {
        return `${key}=["${this.query[key].join('","')}"]`;
      } else {
        return `${key}=${this.query[key]}`;
      }
    });
    if (kvp.length > 0) {
      url += '?' + kvp.join('&');
    }
    return encodeURI(url);
  }

  /**
   * Returns the hostname and, if present, the port number.
   * Example: If the URL is '//localhost:3000/api/v1/serviceofferings', returns 'localhost:3000'
   * Example: If the URL is 'https://qmsbf5c548.execute-api.us-east-1.amazonaws.com/dev/api/v1/serviceofferings',
   * returns 'qmsbf5c548.execute-api.us-east-1.amazonaws.com'
   */
  public getHostAndPort(): string {
    let href = this.toString();

    if (!href.startsWith('https:') && !href.startsWith('http:')) {
      href = `https:${href}`;
    }

    const url = new URL(href);

    if (url.port) {
      return `${url.hostname}:${url.port}`;
    } else {
      return url.hostname;
    }
  }

  private getHostName(): string {
    switch (this.coreService) {
      case CoreService.Core:
        return environment.services.core.endpoint;
      case CoreService.Account:
        return environment.services.account.endpoint;
      case CoreService.Communication:
        return environment.services.communication.endpoint;
      case CoreService.Configuration:
        return environment.services.configuration.endpoint;
      case CoreService.PaymentAccount:
        return environment.services.paymentAccount.endpoint;
      case CoreService.Profile:
        return environment.services.profile.endpoint;
      case CoreService.ProfileConfiguration:
        return environment.services.profileConfiguration.endpoint;
      case CoreService.Request:
        return environment.services.request.endpoint;
      case CoreService.Lookup:
        return environment.services.lookup.endpoint;
      case CoreService.Card:
        return environment.services.card.endpoint;
      case CoreService.File:
        return environment.services.file.endpoint;
      case CoreService.Security:
        return environment.services.security.endpoint;
      case CoreService.Support:
        return environment.services.support.endpoint;
      case CoreService.Dashboard:
        return environment.services.dashboard.endpoint;
      case CoreService.Cognito:
        return environment.aws.cognitoURL;
      default:
        throw new Error('Encountered unexpected enum ' + this.coreService);
    }
  }
}
