import { Injectable } from '@angular/core';
import { Order, QueryConfig, QueryEntity } from '@datorama/akita';
import { Dependent } from '@models/profile/model';
import { Observable, zip } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { DisplayLetter, Document } from 'src/app/shared/models/uba/profileConfiguration/model';

import { DependentQuery } from '../dependents/dependent.query';
import { IndividualQuery } from '../individual/individual.query';
import { LetterState, LetterStore } from './letter.store';

@Injectable({
  providedIn: 'root',
})
@QueryConfig({
  sortBy: 'updated',
  sortByOrder: Order.DESC,
})
export class LetterQuery extends QueryEntity<LetterState> {
  public constructor(
    protected store: LetterStore,
    private individualQuery: IndividualQuery,
    private dependentQuery: DependentQuery,
  ) {
    super(store);
  }

  public selectAllWhenLoaded(): Observable<Document[]> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectAll()),
      );
  }

  public getLetters(): Observable<DisplayLetter[]> {
    return zip(
      this.selectAllWhenLoaded(),
      this.individualQuery.selectActiveWhenLoaded(),
      this.dependentQuery.selectAllWhenLoaded(),
    ).pipe(
      map(([letters, individual, dependents]) => {
        const displayLetters: DisplayLetter[] = letters.map((letter: DisplayLetter) => {
          let receiver;
          // DependentId will only be on the document when sent to them
          if (letter.dependentId) {
            const current: Dependent = dependents.find((dependent) => dependent.id === letter.dependentId);
            receiver = current && current.fullName.length ? current.fullName : 'NA';
          } else {
            receiver = individual.fullName;
          }
          return { ...letter, sentTo: receiver };
        });
        return displayLetters;
      }),
    );
  }
}
