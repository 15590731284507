<div class="custom-control pl-0" [ngClass]="{ 'custom-control-inline': displayType === DisplayType.Inline }">
  <label class="btn-radio pb-3" tabindex="0" [attr.aria-checked]="radioButtonElement?.nativeElement.checked" role="radio" (keyup.enter)="$event.target.querySelector('input').click()">
    <ng-content select="input[type='radio'].custom-control-input"></ng-content>
    <svg width="20px" height="20px" viewBox="0 0 20 20">
      <circle cx="10" cy="10" r="9"></circle>
      <path [ngClass]="{'read-only': readonly}" d="M10,7 C8.34314575,7 7,8.34314575 7,10 C7,11.6568542 8.34314575,13 10,13 C11.6568542,13 13,11.6568542 13,10 C13,8.34314575 11.6568542,7 10,7 Z"
            class="inner"></path>
      <path [ngClass]="{'read-only': readonly}" d="M10,1 L10,1 L10,1 C14.9705627,1 19,5.02943725 19,10 L19,10 L19,10 C19,14.9705627 14.9705627,19 10,19 L10,19 L10,19 C5.02943725,19 1,14.9705627 1,10 L1,10 L1,10 C1,5.02943725 5.02943725,1 10,1 L10,1 Z"
            class="outer"></path>
    </svg>
    <span *ngIf="label" [ngClass]="{active: radioButtonElement?.nativeElement.checked}">{{label}}</span>
  </label>
</div>
