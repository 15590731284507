import { Injectable } from '@angular/core';
import { Order, QueryConfig, QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { Document } from 'src/app/shared/models/uba/profileConfiguration/model';

import { DocumentState, DocumentStore } from './document.store';

@Injectable({
  providedIn: 'root',
})
@QueryConfig({
  sortBy: 'updated',
  sortByOrder: Order.DESC,
})
export class DocumentQuery extends QueryEntity<DocumentState> {
  public constructor(protected store: DocumentStore) {
    super(store);
  }

  public selectAllWhenLoaded(): Observable<Document[]> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectAll()),
      );
  }

  public selectSupportDocumentsWhenLoaded(benefitPlanIds: string[]): Observable<Document[]> {
    return this.selectAllWhenLoaded()
      .pipe(
        map((documents) => documents.filter((document) => document.displayToEmployees
          && (!document.linkedBenefitPlanIds || !document.linkedBenefitPlanIds.length || document.linkedBenefitPlanIds.some((benefitPlanId) => benefitPlanIds.includes(benefitPlanId))),
        )),
      );
  }
}
