import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, NavigationError, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { RaygunService } from './raygun/raygun.service';
import { BrandService } from './shared/services/brand.service';
import { InjectionTokens } from './shared/utils';
import { DataScopeService } from './state/data-scope.service';
import { PrefetchService } from './state/prefetch.service';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild(ToastContainerDirective, { static: true }) public toastContainer: ToastContainerDirective;
  public readonly brand = this.brandService.getBrandResources();
  private subscriptions = new Subscription();

  public constructor(
    private brandService: BrandService,
    private dataScopeService: DataScopeService,
    private prefetchService: PrefetchService,
    private raygunService: RaygunService,
    private renderer: Renderer2,
    private router: Router,
    private titleService: Title,
    private toastrService: ToastrService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(InjectionTokens.WINDOW) private window: Window,
  ) { }

  public ngOnInit(): void {
    this.toastrService.overlayContainer = this.toastContainer;
    const routerEventsSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationError) {
        this.raygunService.logError('Navigation error', { event });
      }
      if (event instanceof NavigationEnd) {
        this.renderer.setAttribute(this.document.getElementById('favicon'), 'href', `assets/images/${this.brand.faviconFileName}`);
        this.titleService.setTitle(this.brand.companyName);
        this.window.scrollTo(0, 0);
      }
    });
    this.subscriptions.add(routerEventsSubscription);

    const prefetchSubscription = this.prefetchService.prefetchData().subscribe();
    this.subscriptions.add(prefetchSubscription);

    const persistentSubscription = this.dataScopeService.applicationScopedData().subscribe();
    this.subscriptions.add(persistentSubscription);
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
