import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Attachment } from 'src/app/shared/models/uba/file/model';

export interface AttachmentState extends EntityState<Attachment, string> { }

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'Attachment' })
export class AttachmentStore extends EntityStore<AttachmentState> {
  public constructor() {
    super();
  }
}
