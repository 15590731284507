import { AuthenticationResult } from './authentication-result.model';

export interface SignUpResult {
  additionalInfo?: string;
  authenticationResult?: AuthenticationResult;
  error?: Error;
  type: SignUpResultType;
}

export enum SignUpResultType {
  AlreadyConfirmed = 'AlreadyConfirmed',
  ConfirmationCodeExpired = 'ConfirmationCodeExpired',
  ConfirmationCodeMismatch = 'ConfirmationCodeMismatch',
  EmailConfirmationRequired = 'EmailConfirmationRequired',
  ExternalIdRequired = 'ExternalIdRequired',
  Failed = 'Failed',
  IdNotFound = 'IdNotFound',
  MissingCredentials = 'MissingCredentials',
  NotAllowed = 'NotAllowed',
  Success = 'Success',
  UnhandledErrorCode = 'UnhandledErrorCode',
  Unsupported = 'Unsupported',
  UserAlreadyExists = 'UserAlreadyExists',
  ValidationFailed = 'ValidationFailed',
}
