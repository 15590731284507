import { BenefitPlan } from '@models/configuration/model';
import { OpenEndedPlanEnrollmentRanges } from '../models/pux';
import { Dates } from './dates';

export class EnrollmentUtil {

 /**
  * This function returns the enrollment period(s) we need to check for open ended plans ONLY.
  * EXAMPLE 1: If today's date is in 2021 and the enrollment start date is earlier than the end date and within
  * the same year (e.g. 12/01 to 12/31), this function returns:
  * first: 2021-12-01 to 2021-12-31
  * second: 2021-12-01 to 2021-12-31 (Note this is a duplicate of the first variable and is essentially ignored by the calling function)
  * The 'second' property is needed when the start and end dates span across years.
  * EXAMPLE 2: If today is in 2021 and enrollment spans between 09/01 thru 02/01, this function returns these two ranges:
  * first: 2021-09-01 to 2022-02-01
  * second: 2020-09-01 to 2021-02-01
  * @param plan The benefit plan we are getting enrollment ranges for.
  */
  public static getOpenEndedEnrollmentRanges(plan: BenefitPlan): OpenEndedPlanEnrollmentRanges {
    const first = { startDate: Dates.fromString(plan.enrollmentStartDate, 'MM/DD'), endDate: Dates.fromString(plan.enrollmentEndDate, 'MM/DD') };
    const second = { ...first };
    if (Dates.isAfter(first.startDate, first.endDate)) {
      first.endDate = first.endDate.add(1, 'year');
      second.startDate = second.startDate.subtract(1, 'year');
    }
    return { first, second };
 }
}
