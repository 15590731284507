export interface AuthenticationResult {
  additionalInfo?: string;
  error?: Error;
  type: AuthenticationResultType;
}

export enum AuthenticationResultType {
  Failed = 'Failed',
  IncorrectMFACode = 'IncorrectMFACode',
  IncorrectPassword = 'IncorrectPassword',
  MFACodeRequired = 'MFACodeRequired',
  PasswordResetRequired = 'PasswordResetRequired',
  PasswordUpdateRequired = 'PasswordUpdateRequired',
  Success = 'Success',
  Timeout = 'Timeout',
  UnhandledChallenge = 'UnhandledChallenge',
  UnhandledErrorCode = 'UnhandledErrorCode',
  UnsupportedChallenge = 'UnsupportedChallenge',
  UserConfirmationRequired = 'UserConfirmationRequired',
  UserNotFound = 'UserNotFound',
  CustomAuthChallenge = 'CustomAuthChallenge',
}
