import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { TradeActivityModel, TradeDescription, TradeStatus, TradeViewModelSearchCriteria } from 'src/app/shared/models/pux';
import { BenefitAccountBalanceQuery } from '../benefit-account-balance';
import { TradeActivityState, TradeActivityStore } from './trade-activity.store';

@Injectable({
  providedIn: 'root',
})
export class TradeActivityQuery extends QueryEntity<TradeActivityState> {

  public constructor(
    protected store: TradeActivityStore,
    private benefitAccountQuery: BenefitAccountBalanceQuery,
    ) {
    super(store);
  }

  public canViewInvestments(): Observable<boolean> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.benefitAccountQuery.selectVisibleBenefitAccounts()),
        switchMap((benefitAccounts) => this.selectAll({
          filterBy: (trade) => benefitAccounts.map(({id}) => id).includes(trade.benefitAccountId),
        })),
        map((trades) => trades.length > 0),
      );
  }

  public getForCriteria(criteria: TradeViewModelSearchCriteria): Observable<TradeActivityModel[]> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectAll({
          filterBy: (trade) => (
            criteria.pending
              ? [TradeStatus.Pending, TradeStatus.InvestmentBalanceUpdated]
              : [TradeStatus.Posted]
            ).includes(trade.status as TradeStatus) && (
              !criteria.planNames ||
              criteria.planNames.length === 0 ||
              criteria.planNames.includes(trade.planName)
            ) && (
              !criteria.startDate || criteria.startDate <= trade.createdDate
            ) && (
              !criteria.endDate || criteria.endDate >= trade.createdDate
            ),
        })),
      );
  }
}
