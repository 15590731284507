import { Injectable } from '@angular/core';
import { basicURLs } from 'src/brand/basic/basic.urls';
import { brandResources } from 'src/brand/brandResources';
import { crossroadsURLs } from 'src/brand/crossroads/crossroads.urls';
import { tascURLs } from 'src/brand/tasc/tasc.urls';

import { BrandId, BrandResource } from '../models/pux';
import { BrowserService } from './browser.service';

@Injectable({
  providedIn: 'root',
})
export class BrandService {
  private readonly brandId = this.determineBrand();

  public constructor(
    private browserService: BrowserService,
  ) { }

  public getBrand(): BrandId {
    return this.brandId;
  }

  public getBrandResources(): BrandResource {
    return brandResources[this.brandId];
  }

  public getBrandAddress(): { lineOne: string, lineTwo: string } {
    return {
      lineOne: 'PO Box 88137',
      lineTwo: 'Milwaukee, WI 53288-0001',
    };
  }

  private determineBrand(): BrandId {
    const hostname = this.browserService.getLocationHostname();
    if (tascURLs.includes(hostname)) {
      return BrandId.TASC;
    }
    if (basicURLs.includes(hostname)) {
      return BrandId.BASIC;
    }
    if (crossroadsURLs.includes(hostname)) {
      return BrandId.Crossroads;
    }
    return BrandId.Unknown;
  }
}
