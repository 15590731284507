import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Attachment, FileAttachmentMetadata } from 'src/app/shared/models/uba/file/model';
import { IndividualQuery } from 'src/app/state';
import { v4 as uuid } from 'uuid';
import { environment } from '../../../environments/environment';
import { ErrorHandlingService } from '../../shared/services/error-handling.service';
import { CoreService } from '../models/pux/enum';
import { Uri } from '../services/uri';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  private readonly supportRequestAttachmentBucket = `${environment.name}-external-attachment`;

  public constructor(
    private errorHandlingService: ErrorHandlingService,
    private http: HttpClient,
    private individualQuery: IndividualQuery,
  ) { }

  /**
   * Request signed url, then upload to request attachment to S3
   * @param file The file to be uploaded
   * @param attachment The attachment object
   * @returns An Observable containing s3 filepath
   */
  public uploadFile(file: File, attachment: Attachment): Observable<Attachment> {
    if (!attachment.id) {
      attachment.id = uuid();
    }

    return this.getS3PutUrl(attachment)
      .pipe(
        switchMap((url) => {
          return this.uploadFileAttachmentToS3(attachment, file, url).pipe(
            map(() => {
              attachment.filePath = `${this.supportRequestAttachmentBucket}/${attachment.id}`;
              return attachment;
            }),
          );
          }),
        catchError(this.errorHandlingService.rxjsErrorHandler()),
      );
  }

  private getS3PutUrl(attachment: Attachment): Observable<string> {
    const individualId = this.individualQuery.getActiveId();
    const uri = new Uri(`/profile/${individualId}/fileAttachment/${attachment.id}/puturl`, CoreService.File);
    const metaData = this.getFileAttachmentMetaData(attachment);

    return this.http.post<string>(uri.toString(), metaData);
  }

  private uploadFileAttachmentToS3(attachment: Attachment, file: File, url: string): Observable<void> {
    const httpHeaders = this.getHeadersForS3Request(attachment);
    return this.http.put<void>(url, file, { headers: httpHeaders });
  }

  private getFileAttachmentMetaData(attachment: Attachment): FileAttachmentMetadata {
    return {
      fileAttachmentId: attachment.id,
      fileAttachmentType: attachment.attachmentType,
      name: attachment.friendlyFileName,
      parentId: attachment.parentId,
      parentType: attachment.parentType,
    };
  }

  private getHeadersForS3Request(attachment: Attachment): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/octet-stream',
      'x-amz-meta-name': attachment.friendlyFileName,
      'x-amz-meta-parentId': attachment.parentId || '',
      'x-amz-meta-parentType': attachment.parentType || '',
    });
  }
}
