import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { FederatedSignInError } from 'src/app/shared/models/pux/enum';

@Injectable({
  providedIn: 'root',
})
export class LoginFederatedGuard implements CanActivate {
  public constructor(
    private router: Router,
    private toastrService: ToastrService,
  ) { }

  public canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (route.queryParams['identity_provider'] ||  route.queryParams['code']) {
      return true;
    }
    if (route.queryParams['error_description']) {
      if (route.queryParams['error_description'] === FederatedSignInError.NotAuthorizedException) {
        this.toastrService.error('User does not exist.');
      }
    }
    return this.router.parseUrl('/logout');
  }
}
