import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoginFederatedIdPiGuard implements CanActivate {
  public constructor(
    private router: Router,
  ) { }

  public canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (route.queryParams['code'] && route.queryParams['state']) {
      return true;
    }

    return this.router.parseUrl('/logout');
  }
}
