import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { AttachmentContentQuery } from 'src/app/state';
import { CustomizationQuery } from 'src/app/state/customization';

import { AttachmentContent } from '../models/pux/attachment-content';

@Injectable({
  providedIn: 'root',
})
export class ClientCustomizationService {
  public constructor(
    private attachmentContentQuery: AttachmentContentQuery,
    private customizationQuery: CustomizationQuery,
  ) { }

  public getClientLogo(): Observable<AttachmentContent> {
    return this.customizationQuery.selectActiveWhenLoaded()
      .pipe(
        filter((customization) => !!customization),
        switchMap(
          (customization) => {
            return this.attachmentContentQuery.selectEntityWhenLoaded(customization.brandingLogoId);
          },
        ),
      );
  }
}
