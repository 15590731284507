import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { withTransaction } from '@datorama/akita';
import { from, Observable, of, zip } from 'rxjs';
import { catchError, map, mergeMap, switchMap, toArray } from 'rxjs/operators';
import { CoreService } from 'src/app/shared/models/pux/enum';
import {
  BenefitAccountLimitedBalanceQuery,
  BenefitAccountPostingSummaryViewModel,
  ScheduleQuery,
  SearchQuery,
} from 'src/app/shared/models/pux/model';
import {
  BenefitAccountBalance,
  BenefitAccountLimitedBalance,
  BenefitAccountPostingSummary,
  Criteria,
  Entry,
  FeatureAccount,
  HideFromType,
  InvestmentBalance,
} from 'src/app/shared/models/uba/account/model';
import {
  BenefitPlan,
  BenefitPlanFundingSourceAndSchedule,
  GroupingTagType,
  ParentType as ConfigurationParentType,
  SearchCriteria,
  ServiceOffering,
} from 'src/app/shared/models/uba/configuration/model';
import { PayrollSchedule } from 'src/app/shared/models/uba/profileConfiguration/model';
import { ChainType, GroupCriteria, MatchType } from 'src/app/shared/models/uba/request/model';
import { EntityBase, ParentType as SecurityParentType, Token } from 'src/app/shared/models/uba/security/model';
import { Uri } from 'src/app/shared/services/uri';
import { CommandFactory } from 'src/app/shared/utils/command.factory';
import { Dates } from 'src/app/shared/utils/dates';
import { Transitions } from 'src/app/shared/utils/transitions';
import {
  BenefitAccountPostingSummaryStore,
  BenefitPlanFundingSourceAndScheduleQuery,
  BenefitPlanFundingSourceAndScheduleStore,
  BenefitPlanQuery,
  BenefitPlanStore,
  ClientQuery,
  EntryStore,
  FeatureAccountStore,
  IndividualQuery,
} from 'src/app/state';
import { BenefitAccountBalanceStore } from 'src/app/state/benefit-account-balance/benefit-account-balance.store';
import { InvestmentBalanceStore } from 'src/app/state/investment-balance';
import { PayrollScheduleStore } from 'src/app/state/payroll-schedule/payroll-schedule.store';

import { HiddenServiceOfferingStore } from 'src/app/state/hidden-service-offering';
import { ErrorHandlingService } from '../../shared/services/error-handling.service';

@Injectable({
  providedIn: 'root',
})
export class BenefitPlansService {
  public totalCashBalance = 0;
  public paymentSourceId: string;
  public userDataArr: GroupCriteria[] = [];
  private readonly givingAccountName = 'Individual giving account';

  public constructor(
    private benefitAccountBalanceStore: BenefitAccountBalanceStore,
    private benefitAccountPostingSummaryStore: BenefitAccountPostingSummaryStore,
    private benefitPlanFundingSourceAndScheduleQuery: BenefitPlanFundingSourceAndScheduleQuery,
    private benefitPlanFundingSourceAndScheduleStore: BenefitPlanFundingSourceAndScheduleStore,
    private benefitPlanQuery: BenefitPlanQuery,
    private benefitPlanStore: BenefitPlanStore,
    private clientQuery: ClientQuery,
    private commandFactory: CommandFactory,
    private entryStore: EntryStore,
    private investmentBalanceStore: InvestmentBalanceStore,
    private errorHandlingService: ErrorHandlingService,
    private featureAccountStore: FeatureAccountStore,
    private http: HttpClient,
    private individualQuery: IndividualQuery,
    private payrollScheduleStore: PayrollScheduleStore,
    private hiddenServiceOfferingStore: HiddenServiceOfferingStore,
  ) { }

  public getAllBenefitPlans(clientId: string): Observable<BenefitPlan[]> {
    const queryData: SearchCriteria = [
      {
        key: 'parentId',
        value: clientId,
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      },
      {
        key: 'parentType',
        value: 'CLIENT',
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      },
    ];
    const query: SearchQuery = {
      orderBy: 'name',
      orderDirection: 'asc',
    };

    const uri = new Uri(`/profile/${clientId}/configuration/benefitPlan/search`, CoreService.Configuration, query);

    return this.http.post<BenefitPlan[]>(uri.toString(), queryData)
      .pipe(
        map((benefitPlans) => benefitPlans.map((benefitPlan) => {
          if (this.isGivingAccount(benefitPlan)) {
            benefitPlan.name = this.givingAccountName;
          }
          return benefitPlan;
        })),
        withTransaction((benefitPlans) => {
          this.benefitPlanStore.set(benefitPlans);
        }),
        catchError(this.errorHandlingService.rxjsErrorHandler()),
      );
  }

  public getPlansEligibleForEnrollment(individualId: string): Observable<BenefitPlan[]> {
    const query = {
        enroller: 'participant',
    };
    const url = new Uri(`/profile/${individualId}/configuration/eligibleBenefitPlan/search`, CoreService.Configuration, query);
    return this.http.post<BenefitPlan[]>(url.toString(), null);
  }

  /**
   * Fetches all hidden service offerings (where hideBenefitAccountsFromParticipantsViews = true)
   * and stores them in Akita.
   */
  public getHiddenServiceOfferings(): Observable<ServiceOffering[]> {
    const searchData: SearchCriteria = [
      {
        key: 'hideBenefitAccountsFromParticipantsViews',
        value: 'true',
        matchType: MatchType.EXACT,
      },
    ];

    const individualId = this.individualQuery.getActiveId();
    const uri = new Uri(`/profile/*/configuration/serviceOffering/search`, CoreService.Configuration);
    return this.http.post<ServiceOffering[]>(uri.toString(), searchData)
      .pipe(
        withTransaction((serviceOfferings) => {
          if (serviceOfferings.length) {
            this.hiddenServiceOfferingStore.set(serviceOfferings);
          } else {
            this.hiddenServiceOfferingStore.set([]);
          }
        }),
        catchError(this.errorHandlingService.rxjsErrorHandler()),
      );
  }

  public isNotHiddenAccount(serviceOfferings: ServiceOffering[], benefitAccount: BenefitAccountBalance, benefitPlans: BenefitPlan[]): boolean {
    const benefitPlan = benefitPlans.find((plan) => plan.id === benefitAccount.planId);
    return !serviceOfferings.some((serviceOff) => serviceOff.id === benefitPlan.offeringId);
}

  public getAllBenefitAccounts(): Observable<BenefitAccountBalance[]> {
    const client = this.clientQuery.getActive();
    const individual = this.individualQuery.getActive();
    const benefitPlans = this.benefitPlanQuery.getAll();
    return this.getHiddenServiceOfferings().pipe(
      switchMap((serviceOfferings) => {
        const query: SearchQuery = {
          orderBy: 'planStartDate',
        };

        const uri = new Uri(
          `/profile/${individual.id}/client/${client.id}/benefitAccounts/balances`,
          CoreService.Account,
          query,
        );

        return this.http.get<BenefitAccountBalance[]>(uri.toString())
          .pipe(
            map((benefitAccounts) => {
              return benefitAccounts.filter((benefitAccount) => this.isNotHiddenAccount(serviceOfferings, benefitAccount, benefitPlans));
            }),
            map((benefitAccounts) => {
              return benefitAccounts.filter((benefitAccount) => benefitAccount.hideFrom === HideFromType.None || benefitAccount.hideFrom === HideFromType.Client);
            }),
            map((benefitAccountBalances) => {
              return benefitAccountBalances.map((benefitAccountBalance) => {
                const benefitPlan = benefitPlans.find((plan) => plan.id === benefitAccountBalance.planId);
                if (benefitPlan) {
                  if (this.isGivingAccount(benefitPlan)) {
                    benefitAccountBalance.planName = this.givingAccountName;
                  } else {
                    // workaround to prevent internal name from being displayed
                    benefitAccountBalance.planName = benefitPlan.externalName;
                  }
                  // workaround to prevent internal description from being displayed
                  benefitAccountBalance.planDescription = benefitPlan.externalDescription;
                }
                return benefitAccountBalance;
              });
            }),
            withTransaction((benefitAccountBalances) => this.benefitAccountBalanceStore.set(benefitAccountBalances)),
            catchError(this.errorHandlingService.rxjsErrorHandler()),
          );
    }));
  }

  /**
   * Gets the feature accounts belonging to the currently logged in participant. MyCash will be the one where name='IAB'
   * and Advance (CDP) will be name='PPTAB'.
   */
  public getFeatureAccounts(clientId: string): Observable<FeatureAccount[]> {
    const individual = this.individualQuery.getActive();

    const uri = new Uri(`/profile/${individual.id}/client/${clientId}/accounts/accountType/feature`, CoreService.Account);

    return this.http.get<FeatureAccount[]>(uri.toString())
      .pipe(
        map((featureAccounts) => Array.isArray(featureAccounts) ? featureAccounts : []),
        withTransaction((featureAccounts) => this.featureAccountStore.set(featureAccounts)),
        catchError(this.errorHandlingService.rxjsErrorHandler()),
      );
  }

  public isGivingAccount(benefitPlan: BenefitPlan): boolean {
    return benefitPlan.groupingTag === GroupingTagType.PhilanthropyAccount;
  }

  public getBenefitAccountPostingSummaries(benefitAccountSOAIds: string[]): Observable<BenefitAccountPostingSummaryViewModel[]> {
    if (!benefitAccountSOAIds?.length) {
      const postingSummaries = [];
      this.benefitAccountPostingSummaryStore.set(postingSummaries);
      return of(postingSummaries);
    }
    const individual = this.individualQuery.getActive();
    return from(benefitAccountSOAIds)
      .pipe(
        mergeMap((benefitAccountSOAId) => {
          const uri = new Uri(`/profile/${individual.id}/benefitAccount/${benefitAccountSOAId}/posting/summary`, CoreService.Account);
          return this.http.get<BenefitAccountPostingSummary>(uri.toString())
            .pipe(
              map((benefitAccountPostingSummary) => {
                const benefitAccountPostingSummaryViewModel: BenefitAccountPostingSummaryViewModel = {
                  id: benefitAccountSOAId,
                  ...benefitAccountPostingSummary,
                };
                return benefitAccountPostingSummaryViewModel;
              }),
            );
        }),
        toArray(),
        withTransaction((benefitAccountPostingSummaries) => {
          this.benefitAccountPostingSummaryStore.set(benefitAccountPostingSummaries);
        }),
      );
  }

  public getBenefitPlanFundingSources(benefitPlanIds: string[], effectiveDate: string = 'ALL'): Observable<BenefitPlanFundingSourceAndSchedule[]> {
    const userData: SearchCriteria = [
      {
        key: 'parentId',
        value: benefitPlanIds.join('|'),
        matchType: MatchType.IN,
        chainType: ChainType.AND,
      },
      {
        key: 'parentType',
        value: ConfigurationParentType.BENEFIT_PLAN,
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      },
    ];
    const individual = this.individualQuery.getActive();
    const query: ScheduleQuery = { effectiveDate };
    const uri = new Uri(`/profile/${individual.id}/configuration/benefitPlan/*/fundingSourceAndSchedule/search`, CoreService.Configuration, query);

    return this.http.post<BenefitPlanFundingSourceAndSchedule[]>(uri.toString(), userData)
      .pipe(
        withTransaction((bpfss) => this.benefitPlanFundingSourceAndScheduleStore.set(bpfss)),
      );
  }

  public getPayrollScheduleByPlan(benefitPlanIds: string[], effectiveDate: string = 'ALL'): Observable<PayrollSchedule[]> {
    const criteria: SearchCriteria = [
      {
        key: 'parentId',
        value: benefitPlanIds.join('|'),
        matchType: MatchType.IN,
      },
    ];
    const query: ScheduleQuery = {
      skip: 0,
      take: Number.MAX_SAFE_INTEGER,
      orderBy: 'payrollId',
      orderDirection: 'asc',
      effectiveDate,
    };

    const individual = this.individualQuery.getActive();
    const uri = new Uri(`/profile/${individual.id}/configuration/payrollSchedule/search`, CoreService.ProfileConfiguration, query);

    return this.http.post<PayrollSchedule[]>(uri.toString(), criteria)
      .pipe(
        withTransaction((payrollSchedules) => this.payrollScheduleStore.set(payrollSchedules)),
      );
  }

  /**
   * Get the rollover balances of a list of benefit accounts.
   * @param sourceIds list of Benefit Account Ids.
   */
  public getBenefitAccountRolloverBalances(sourceIds: string[]): Observable<BenefitAccountLimitedBalance[]> {
    if (!sourceIds || !sourceIds.length) {
      return of([]);
    }
    const profileId = this.individualQuery.getActiveId();
    const query: BenefitAccountLimitedBalanceQuery = {
      dateTime: Dates.now().format(Dates.DATE_FORMAT),
      skip: 0,
      take: 1,
    };
    const requests = sourceIds.map((id) => {
      const uri = new Uri(`/profile/${profileId}/benefitAccount/${id}/balances/rollover`, CoreService.Account, query);
      return this.http.get<BenefitAccountLimitedBalance>(uri.toString());
    });

    return zip(...requests)
      .pipe(
        catchError(this.errorHandlingService.rxjsErrorHandler()),
      );
  }

  public getBenefitAccountInvestmentBalances(benefitAccountIds: string[]): Observable<InvestmentBalance[]> {
    const requests = benefitAccountIds.map((benefitAccountId) => this.getBenefitAccountInvestmentBalance(benefitAccountId));
    return zip(...requests)
      .pipe(
        mergeMap((investmentBalances) => investmentBalances),
        withTransaction((investmentBalances) => this.investmentBalanceStore.upsertMany(investmentBalances)),
      );
  }

  public getBenefitAccountInvestmentBalanceEntries(benefitAccountIds: string[]): Observable<Entry[]> {
    const requests = benefitAccountIds.map((benefitAccountId) => this.getBenefitAccountInvestmentBalanceEntriesForAccount(benefitAccountId));
    return zip(...requests)
      .pipe(
        mergeMap((entries) => entries),
        withTransaction((entries) => this.entryStore.upsertMany(entries)),
      );
  }

  public changeInvestmentThreshold(benefitAccount: BenefitAccountBalance, thresholdAmount: number): Observable<void> {
    const command = Transitions.getTransition(benefitAccount.currentState, benefitAccount.currentState);
    const individualId = this.individualQuery.getActiveId();
    const cashThresholdRequest = this.commandFactory.createCommand(benefitAccount, command);
    cashThresholdRequest.data.cashAccountMinimumBalance = thresholdAmount;
    const uri = new Uri(`/profile/${individualId}/benefitAccount/${benefitAccount.id}/command/${command}`, CoreService.Account);
    return this.http.put<void>(uri.toString(), cashThresholdRequest)
      .pipe(
        withTransaction(() => {
          const account = cashThresholdRequest.data;
          this.benefitAccountBalanceStore.update(account.id, account);
        }),
        catchError(this.errorHandlingService.rxjsErrorHandler()),
      );
  }

  public getSrtLinkToken(srtLinkData: BenefitPlan): Observable<{ token: string, useSaml: boolean }> {
    const individual = this.individualQuery.getActive();
    const client = this.clientQuery.getActive();

    const parentProfileId = client ? client.parentId : '';

    const data: Token = {
      id: individual.id,
      parentId: parentProfileId,
      parentType: SecurityParentType.INSTANCE,
      currentState: 'Active',
      updated: srtLinkData.updated,
      updatedBy: srtLinkData.updatedBy,
      updatedById: srtLinkData.updatedById,
      created: srtLinkData.created,
      createdBy: srtLinkData.createdBy,
      createdById: srtLinkData.createdById,
      srtPlanId: srtLinkData.investmentRecordKeeperPlanId,
    };
    const srtLinkRequestData: EntityBase = this.commandFactory.createCommand(data, 'StartToActive');

    const uri = new Uri(`/token/command/createSRT`, CoreService.Security);

    return this.http.put<{ token: string, useSaml: boolean }>(uri.toString(), srtLinkRequestData)
      .pipe(
        catchError(this.errorHandlingService.rxjsErrorHandler(() => 'There was a problem generating the external link to your investments.')),
      );
  }

  public getSrtSamlResponse(): Observable<{ samlResponse: string, relayState: string, postUrl: string }> {
    const uri = new Uri(`/token/command/createSRTSAMLResponse`, CoreService.Security);

    return this.http.get<{ samlResponse: string, relayState: string, postUrl: string }>(uri.toString())
      .pipe(
        catchError(this.errorHandlingService.rxjsErrorHandler(() => 'There was a problem generating the external link to your investments.')),
      );
  }

  private getBenefitAccountInvestmentBalance(benefitAccountId: string): Observable<InvestmentBalance[]> {
    const individual = this.individualQuery.getActive();

    const qs = {
      take: 1,
      skip: 0,
      orderBy: 'created',
      orderDirection: 'DESC',
    };

    const searchCriteria: Criteria[] = [{
      key: 'parentId',
      value: benefitAccountId,
      matchType: MatchType.EXACT,
    }];

    const url = new Uri(`/profile/${individual.id}/investmentBalance/search`, CoreService.Account, qs).toString();
    return this.http.post<InvestmentBalance[]>(url, searchCriteria);
  }

  private getBenefitAccountInvestmentBalanceEntriesForAccount(benefitAccountId: string): Observable<Entry[]> {
    const individual = this.individualQuery.getActive();
    const queryParams: SearchQuery = {
      skip: 0,
      take: 1,
      orderBy: 'scheduledDate',
      orderDirection: 'desc',
    };
    const searchCriteria: SearchCriteria = [
      {
        key: 'parentId',
        value: benefitAccountId,
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      },
      {
        key: 'parentType',
        value: 'BENEFIT_ACCOUNT',
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      },
      {
        key: 'entryType',
        value: 'InvestmentSweep',
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      },
    ];
    const relativeURL = `/profile/${individual.id}/entry/search`;
    const uri = new Uri(relativeURL, CoreService.Account, queryParams);
    return this.http.post<Entry[]>(uri.toString(), searchCriteria)
      .pipe(
        catchError(this.errorHandlingService.rxjsErrorHandler()),
      );
  }
}
