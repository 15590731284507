import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ConfirmDismissedEvent } from './confirmDismissedEvent';
import { ConfirmSubmittedEvent } from './confirmSubmittedEvent';

/**
 * The display options for a confirmation dialog
 */
export interface ConfirmOptions {
    cancelButtonText?: string;
    message: string;
    title: string;
    submitButtonText?: string;
    doNotCloseAfterSubmission?: boolean;
    hideCancel?: boolean;
}

@Injectable()
export class ConfirmService {

    public confirmBeginSource = new Subject<ConfirmOptions>();
    public confirmEventSource = new Subject<ConfirmDismissedEvent | ConfirmSubmittedEvent>();
    public confirmCompleteSource = new Subject<void>();

    public confirmBegin$ = this.confirmBeginSource.asObservable();
    public confirmEvent$ = this.confirmEventSource.asObservable();
    public confirmComplete$ = this.confirmCompleteSource.asObservable();

    /**
     * Opens a confirm dialog for a user
     * @param   options The confirm options for display
     */
    public confirm(options: ConfirmOptions): any  {
      this.confirmBeginSource.next(options);
      return this.confirmEvent$;
    }

    /**
     * Dismiss the current confirm
     */
    public dismiss(): void {
      this.confirmEventSource.next(new ConfirmDismissedEvent());
    }

    /**
     * Submit the current confirm dialog
     * @param data Any related data to pass to the subscribers
     */
    public submit(data?: any): void {
      this.confirmEventSource.next(new ConfirmSubmittedEvent(data));
    }

    /**
     * Emits a complete event.
     */
    public markAsComplete(): void {
      this.confirmCompleteSource.next();
    }

}
