import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Election } from 'src/app/shared/models/uba/account/model';

export interface ElectionState extends EntityState<Election, string>, ActiveState {}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'Election' })
export class ElectionStore extends EntityStore<ElectionState> {
  public constructor() {
    super();
  }

  public removeBenefitAccountElections(benefitAccountId: string): void {
    return this.remove((election) => election.parentId === benefitAccountId);
  }
}
