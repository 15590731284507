import rg4js from 'raygun4js';

import packageJson from '../../../package.json';
import { environment } from '../../environments/environment';

rg4js('apiKey', environment.logging.apiKey);
rg4js('setVersion', packageJson.version);
rg4js('enableCrashReporting', true);
rg4js('options', {
    excludedHostnames: ['localhost'],
});
rg4js('withTags', ['pux-web', environment.name]);
rg4js('logContentsOfXhrCalls', true);
