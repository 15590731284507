// AUTOGENERATED BY FUNCTION genubamodel.js
export interface CommandBase {
  id: string;
  eventCorrelationId: string;
  jobId?: string;
  producerId: string;
  sequenceId?: number;
  version: number;
  type: string;
  created: string;
  createdBy: string;
  createdById: string;
}

export interface EntityBase {
  id?: string;
  parentId?: string;
  parentType?: ParentType;
  version?: number;
  created?: string;
  createdBy?: string;
  createdById?: string;
  updated?: string;
  updatedBy?: string;
  updatedById?: string;
  currentState?: string;
  lastTransition?: string;
  externalWorkflowId?: string;
  eventNotes?: string;
  transactionLockId?: string;
}

export interface AccountCommand extends CommandBase {
  data?: Account;
}

export interface BankAccountCommand extends CommandBase {
  data?: BankAccount;
  clientId?: string;
}

export interface BenefitAccountCommand extends CommandBase {
  data?: BenefitAccount;
  clientElection?: Election;
  individualElection?: Election;
}

export interface PersonBenefitAccountFeatureRestrictionCommand extends CommandBase {
  data?: PersonBenefitAccountFeatureRestriction;
}

export interface DependentBenefitAccessCommand extends CommandBase {
  data?: DependentBenefitAccess;
}

export interface EntryCommand extends CommandBase {
  data?: Entry;
  claimedFundsDisbursement?: ClaimedFundsDisbursement;
}

export interface EntriesCommand extends CommandBase {
  data?: CRUDEntriesCommandData;
}

export interface PaymentSourceCommand extends CommandBase {
  data?: PaymentSource;
  clientId?: string;
}

export interface PostingCommand extends CommandBase {
  data?: Posting;
}

export interface ScheduledPostingsActionCommand extends CommandBase {
  data?: ScheduledPostingsAction;
}

export interface InterestPostingCommand extends CommandBase {
  data?: InterestPosting;
}

export interface InvestmentContributionPostingCommand extends CommandBase {
  data?: InvestmentContributionPosting;
}

export interface ProcessSettlementCommand extends CommandBase {
  data?: ProcessSettlement;
}

export interface ProcessCABFundingCommand extends CommandBase {
  data?: ProcessCABFunding;
}

export interface RecalculateBenefitAccountCommand extends CommandBase {
  data?: RecalculateBenefitAccount;
}

export interface ScheduleAdjustmentsCommand extends CommandBase {
  data?: ScheduleAdjustments;
}

export interface ElectionCommand extends CommandBase {
  data?: Election;
}

export interface TradeCommand extends CommandBase {
  data?: Trade;
}

export interface InvestmentBalanceCommand extends CommandBase {
  data?: InvestmentBalance;
}

export interface Address {
  id?: string;
  addressType?: AddressType;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  country?: string;
  zip?: string;
  zipplusfour?: string;
}

export interface Account extends EntityBase {
  profileId: string;
  profileType: ProfileType;
  bureau?: string;
  accountType: AccountType;
  paymentSourceId?: string;
  primaryPath?: string;
  secondaryPath?: string;
  startDateTime?: string;
  endDateTime?: string;
  externalAccountId?: string;
}

export interface AccountPosting extends Posting {
  externalAccountId?: string;
  transactionDateTime?: string;
  accountType?: string;
  balancingAccountType?: string;
  methodType?: PostingMethodType;
  description?: string;
  paymentStatus?: PaymentStatusType;
}

export interface BankAccount extends EntityBase {
  name?: string;
  accountType?: BankAccountType;
  bankName?: string;
  routingNumber?: string;
  accountNumber?: string;
  paymentAccountType: PaymentAccountType;
  cardType?: CardType;
  token?: string;
  lastFour?: string;
  expirationDate?: string;
  address?: Address;
  addressOwnerType?: AddressOwnerType;
  paymentOwnerName?: string;
  useForFees?: boolean;
  useForFunding?: boolean;
  investmentTradingPartnerId?: string;
  investmentIndividualId?: string;
  investmentPlanId?: string;
  paymentVendor?: PaymentVendor;
}

export interface BenefitAccount extends EntityBase {
  clientId: string;
  planId: string;
  planName: string;
  planDescription?: string;
  planStartDate: string;
  planEndDate?: string;
  hireDate: string;
  benefitEffectiveDate: string;
  eligibilityEndDate?: string;
  clientLastScheduleDate?: string;
  individualLastScheduleDate?: string;
  requestTypes?: RequestTypes;
  soaAccountId?: string;
  balance?: number;
  carryoverDeclined: boolean;
  approvedBy?: string;
  approvedDate?: string;
  payrollScheduleId?: string;
  excludeFromBilling: boolean;
  cashAccountMinimumBalance?: number;
  enrollmentSource?: EnrollmentSourceType;
  clientScheduleFirstDate?: string;
  individualScheduleFirstDate?: string;
  scheduleEndDate?: string;
  lastDayToSubmitExpenses?: string;
  coverageTier?: BenefitPlanCoverageType;
  reasonToHoldDisbursements?: string;
  finalCoverageDate?: string;
  fullCoveragePaidThroughDate?: string;
  scheduleChangeDate?: string;
  disbursableDate?: string;
  participantTerminationType?: ParticipantTerminationType;
  hideFrom?: HideFromType;
  optForCobraCoverage?: boolean;
  inactivationDate?: string;
  inactivationReason?: BenefitAccountInactivationReasonType;
  lossOfCoverageOverride?: boolean;
}

export interface DependentBenefitAccess extends EntityBase {
  benefitAccountId: string;
  eligibilityStartDate: string;
  eligibilityEndDate?: string;
  inactivationDate?: string;
}

export interface ClaimedFundsDisbursement {
  disbursementMethod: DisbursementMethodType;
  externalCardToken?: string;
  paymentSourceId?: string;
}

export interface InterestPosting extends EntityBase {
  bureauId?: string;
  startDate?: string;
  endDate?: string;
  amount?: string;
  intendedAmount?: string;
  totalEntriesPublished?: number;
  totalEntriesEntered?: number;
  totalEntriesErrored?: number;
}

export interface InvestmentContributionPosting extends EntityBase {
  bureauId?: string;
  scheduledDate?: string;
  currentContributionDrawId?: string;
}

export interface ProcessSettlement extends EntityBase {
  externalId?: string;
  paymentDetails?: EntryPaymentDetails;
}

export interface ProcessCABFunding extends EntityBase {
  clientId?: string;
  benefitPlanId?: string;
  scheduledDate?: string;
  clientCABFundingId?: string;
  fundingSourceType?: FundingSourceType;
}

export interface RecalculateBenefitAccount extends EntityBase {
  fundingSourceType: FundingSourceType;
  planStartDate: string;
  electionChange?: boolean;
  fundingSourceAndScheduleId: string;
  electionEffectiveDate?: string;
  individualId?: string;
}

export interface ScheduleAdjustments extends EntityBase {
  electionChange?: boolean;
  fundingSourceAndScheduleId: string;
  adjustments: ScheduleAdjustment[];
}

export interface ScheduleAdjustment {
  scheduleType?: ScheduleType;
  scheduledDate: string;
  amount: number;
}

export interface Election extends EntityBase {
  electionProfileType: ElectionProfileType;
  electionScheduleType: ElectionScheduleType;
  amount?: number;
  proratedAmount?: number;
  effectiveDate: string;
  contributionScheduleEffectiveDate?: string;
  deductionScheduleEffectiveDate?: string;
  fundingScheduleEffectiveDate?: string;
  qualifyingLifeEvent?: string;
  excessContributionAmount?: number;
  bypassEnabled?: boolean;
  bypassReason?: string;
  coverageTier?: BenefitPlanCoverageType;
  calculationMethod: ElectionCalculationMethod;
}

export interface BenefitAccountLimitedBalance {
  balance?: number;
  balanceType?: BalanceType;
  isCarryover?: boolean;
  fundingAmount?: number;
  disbursementAmount?: number;
}

export interface BenefitAccountBalance extends BenefitAccount {
  balance?: number;
}

export interface OperationalAccountBalance {
  profileId?: string;
  operationalAccountProfileType?: OperationalAccountProfileType;
  profileName?: string;
  balance?: number;
  accountId?: string;
  accountType?: AccountType;
}

export interface PaymentSourceAccount extends PaymentSource {
  paymentAccountType?: PaymentAccountType;
  accountType?: BankAccountType;
  cardType?: CardType;
  lastFour?: number;
  expirationDate?: string;
  bankAccountName?: string;
  paymentOwnerName?: string;
  investmentTradingPartnerId?: string;
  investmentIndividualId?: string;
  investmentPlanId?: string;
  address?: Address;
  addressOwnerType?: AddressOwnerType;
  bankAccountNumber?: string;
}

export interface BenefitAccountElection extends BenefitAccount, Election {
  electionId?: string;
  electionCurrentState?: ElectionState;
  electionTransactionLockId?: string;
}

export interface BenefitAccountEntryEx extends Entry {
  benefitAccount_id?: string;
  benefitAccount_parentId?: string;
  benefitAccount_parentType?: BenefitAccountParentType;
  benefitAccount_currentState?: string;
  clientId?: string;
  planId?: string;
  planName?: string;
  planCurrentState?: string;
  planStartDate?: string;
  planEndDate?: string;
  offeringName?: string;
  fullName?: string;
  primaryEmail?: string;
  individualExternalId?: string;
  individualCurrentState?: string;
  electionProfileType?: ElectionProfileType;
  electionScheduleType?: ElectionScheduleType;
  pendingElectionAmount?: number;
  activeElectionAmount?: number;
  fundingPosting?: FundingPostingType;
  postingMethod?: PostingMethodType;
  autopostDelayedScheduledDate?: string;
}

export interface BenefitAccountPostingSummary {
  availableBalance?: number;
  totalContributionsToDate?: number;
  totalDisbursementsToDate?: number;
  totalPriorYearContributions?: number;
  totalCurrentYearContributions?: number;
}

export interface BenefitElectionSchedule extends EntityBase {
  postingDates?: string[];
  amounts?: number[];
  remainingDates?: string[];
  postingScheduleType?: PostingScheduleType;
  entryStates?: string[];
}

export interface BenefitElectionScheduleCriteria {
  clientId?: string;
  planId: string;
  benefitAccountId?: string;
  payrollScheduleId?: string;
  individualScheduleFirstDate?: string;
  clientScheduleFirstDate?: string;
  individualElectionAmount?: number;
  clientElectionAmount?: number;
  fundingSourceType?: FundingSourceType;
  scheduleType?: ScheduleType;
  effectiveDate?: string;
}

export interface BenefitElectionSourceAndSchedules {
  fundingSourceType: FundingSourceType;
  fundingSchedule: BenefitElectionSchedule;
  contributionSchedule: BenefitElectionSchedule;
  deductionSchedule?: BenefitElectionSchedule;
}

export interface ClientAchSettlement {
  entryId: string;
  amount: number;
  transactionDateTime: string;
  bankAccountName?: string;
  bankAccountNumber?: string;
  paymentSourceId: string;
  currentState?: string;
  startDateTime: string;
  endDateTime: string;
  sourceParentId?: string;
  parentType: ParentType;
  entryType: EntryType;
  paymentSourceType: PaymentSourceType;
  paymentOwnerName?: string;
  paymentDetails?: EntryPaymentDetails;
  bankAccountParentId?: string;
}

export interface PersonBenefitAccountFeatureRestriction extends EntityBase {
  benefitAccountId?: string;
  benefitPlanOrFeatureId: string;
  benefitPlanOrFeatureType: BenefitPlanOrFeatureType;
  benefitEffectiveDate?: string;
  eligibilityEndDate?: string;
  restrictRequests: boolean;
  restrictCard: boolean;
  restrictCardEditableByUser: boolean;
}

export interface Entry extends EntityBase {
  externalId?: string;
  scheduledDate?: string;
  startDateTime?: string;
  endDateTime?: string;
  transactionDateTime?: string;
  memo?: string;
  isManualCorrection?: boolean;
  override?: boolean;
  allowedRecalculationType?: AllowedRecalculationType;
  entryType?: EntryType;
  type?: string;
  eventSource?: EventSourceType;
  correlationId?: string;
  amount?: number;
  soaData?: EntrySOAData;
  fundingSource?: FundingSourceType;
  postingScheduleType?: PostingScheduleType;
  parentReversalId?: string;
  linkId?: string;
  scheduleManually?: boolean;
  paymentSourceId?: string;
  taxYear?: string;
  postings?: Postings;
  bureauId?: string;
  externalDocumentId?: string;
  isSetToBeRetroactive?: boolean;
  payeeId?: string;
  paymentDetails?: EntryPaymentDetails;
  invoiceNumber?: string;
  deferScheduledDate?: string;
}

export interface EntryPaymentUpdateView extends Entry {
  entryId?: string;
  benefitAccountId?: string;
  benefitPlanId?: string;
  benefitPlanPaymentSourceId?: string;
}

export interface EntryPosting {
  id?: string;
  parentId?: string;
  parentType?: ParentType;
  entryType?: string;
  scheduledDate?: string;
  deferScheduledDate?: string;
  accountId?: string;
  profileId?: string;
  entryState?: string;
  amount?: number;
  postingType?: string;
  transactionDateTime?: string;
  paymentDetails?: EntryPaymentDetails;
  accountType?: AccountType;
}

export interface BenefitAccountEnrollment {
  id?: string;
  parentId?: string;
  created?: string;
  electionAmount?: number;
  clientContributionAmount?: number;
  currentState?: string;
  clientId?: string;
  planId?: string;
  planName?: string;
  planStartDate?: string;
  individualId?: string;
  individualFullName?: string;
  individualFirstName?: string;
  individualLastName?: string;
  individualExternalId?: string;
  benefitPlanState?: string;
  benefitPlanLimitMaximum?: number;
  benefitPlanLimitMinimum?: number;
  divisionId?: string;
  subdivisionId?: string;
  divisionName?: string;
  subdivisions?: Subdivisions;
  employerLimit?: number;
  overallLimit?: number;
  approvedBy?: string;
  approvedDate?: string;
  enrollmentSource?: EnrollmentSourceType;
  benefitPlanAllowClientApproval?: boolean;
  benefitPlanRequireEnrollmentApproval?: boolean;
  benefitPlanType?: BenefitPlanType;
}

export interface EntrySOAData {
  advanceFeatureId?: string;
  advanceLimit?: number;
  amountToDisburse?: number;
  benefitPlanId?: string;
  bureauBin?: string;
  cashFeatureId?: string;
  clientId?: string;
  endDate?: string;
  frequency?: FrequencyType;
  individualId?: string;
  newParticipantAdvanceFundingId?: string;
  startDate?: string;
  thresholdAmount?: number;
  soaEntryType?: SoaEntryType;
  sourceBenefitPlanId?: string;
  targetBenefitPlanId?: string;
  investedBalance?: number;
  minimumTradeAmount?: number;
  balanceThreshold?: number;
  dependentId?: string;
  holdingAccountDescriptorId?: string;
  destinationHoldingAccountDescriptorId?: string;
  payeeId?: string;
}

export interface EntryPaymentDetails {
  checkDate?: string;
  checkNumber?: string;
  transactionId?: string;
  paymentId?: string;
  payeeId?: string;
  planName?: string;
  payeeName?: string;
  dateReceived?: string;
  amountReceived?: number;
  externalMemo?: string;
  paymentSourceType?: PaymentSourceType;
  cabFundingPaymentSummary?: CabFundingPaymentSummary;
  paymentAccountGroupName?: string;
  disputeAmount?: number;
  chargebackEntryId?: string;
  obligationId?: string;
  obligationLineItemId?: string;
  paymentPlanId?: string;
  subType?: EntrySubType;
  rejectReason?: string;
  feeLinkId?: string;
  charityId?: string;
  charityName?: string;
  financialProcessor?: FinancialProcessor;
}

export interface CabFundingPaymentSummary {
  clientCABFundingId?: string;
  cabFundingPaymentDetails?: CabFundingPaymentDetail[];
}

export interface CabFundingPaymentDetail {
  planId?: string;
  planName?: string;
  scheduledDate?: string;
  amountSum?: number;
}

export interface EntrySummary {
  entryType?: EntryType;
  planId?: string;
  currentState?: string;
  scheduledDate?: string;
  amountSum?: number;
}

export interface EntryPlan {
  entryId?: string;
  clientId?: string;
  entryType?: EntryType;
  planId?: string;
  planName?: string;
  planState?: string;
  currentState?: string;
  scheduledDate?: string;
  amount?: number;
  takeoverFundingType?: string;
  scheduleManually?: boolean;
  transactionLockId?: string;
  paymentSourceType?: string;
  fundingSource?: FundingSourceType;
  postingScheduleType?: PostingScheduleType;
  parentType?: ParentType;
  override?: boolean;
  allowedRecalculationType?: AllowedRecalculationType;
  hasTakeoverPlan?: boolean;
  takeoverYearToDate?: string;
  paymentSourceId?: string;
}

export interface EntryPlanSummary {
  entryType?: EntryType;
  planId?: string;
  currentState?: string;
  scheduledDate?: string;
  amountSum?: number;
  planState?: string;
  takeoverFundingType?: string;
}

export interface FeatureAccount {
  id?: string;
  name?: string;
  balance?: number;
  balanceDue?: number;
  maxAdvanceAmount?: number;
  currentState?: string;
}

export interface PaymentSource extends EntityBase {
  divisionId?: string;
  paymentAccountId: string;
  paymentSourceType: PaymentSourceType;
  achSecCodeIndicator?: TransactionCodeSecIndicator;
  achFrequencyIndicator?: TransactionFrequencyIndicator;
}

export interface Posting extends EntityBase {
  profileId?: string;
  profileType?: ProfileType;
  planId?: string;
  accountId?: string;
  sourceAccountId?: string;
  sourceAccountType?: SourceAccountType;
  amount?: number;
  entryType?: EntryType;
  postingType?: TransactionType;
}

export interface Subdivision extends EntityBase {
  name?: string;
}

export interface ScheduledPostingsAction extends EntityBase {
  searchCriteria?: SearchCriteria;
  paymentDetails?: EntryPaymentDetails;
  paymentSourceType?: PaymentSourceType;
  isSubsequentProcess?: boolean;
}

export interface SettlementDetail {
  benefitPlanId?: string;
  name?: string;
  amount?: number;
  currentState?: string;
  transactionDateTime?: string;
  paymentSourceId?: string;
  paymentDetails?: EntryPaymentDetails;
}

export interface Trade extends EntityBase {
  cashAccountMinimumBalance?: number;
  minimumTradeAmount?: number;
  tradeType?: TradeType;
  settledAmount?: number;
  requestedAmount?: number;
  investedBalance?: number;
  pptabBalance?: number;
  requestFileDocumentId?: string;
  tradeResponseReceivedDateTime?: string;
  tradeResponsePostedDateTime?: string;
  investmentAccountId?: string;
  investmentRecordKeepingId?: string;
  tradeCustodyPartner?: string;
  settledDate?: string;
}

export interface InvestmentBalance extends EntityBase {
  investmentAccountId?: string;
  investmentRecordKeepingId?: string;
  vestedBalance?: number;
  nonVestedBalance?: number;
}

export interface Criteria {
  key?: string;
  value?: string;
  matchType?: MatchType;
  chainType?: ChainType;
  groupCriteria?: GroupCriteria[];
}

export interface Aggregate {
  key?: string;
  aggregateType?: AggregateType;
  groupBy?: boolean;
}

export interface Error {
  message?: string;
  fields?: object;
}

export interface GroupCriteria {
  key?: string;
  value?: string;
  matchType?: MatchType;
  chainType?: ChainType;
}

export interface PingResponse {
  serverTime: ServerTime;
  services?: Service[];
}

export type SearchCriteria = Criteria[];

export type AggregateCriteria = Aggregate[];

export interface Service {
  name?: string;
  serverTime?: string;
}

export type ServerTime = string;

export interface OperationalBalanceInfo {
  profileName: string;
  benefitPlanName: string;
  benefitAccountId: string;
  benefitPlanId: string;
  sumOfEntries: number;
  deductibleBalance?: DeductibleBalance;
  payoutBalance?: PayoutBalance;
  operationalAccountsCreated: boolean;
}

export interface DeductibleBalance {
  totalDeductibleForProfile: number;
  remainingTotalDeductibleForProfile?: number;
  planDeductibleForEachPerson?: number;
  totalDeductibleForGroup?: number;
  planDeductibleForGroup?: number;
  entries: Entry[];
}

export interface PayoutBalance {
  totalPayoutForProfile: number;
  remainingTotalPayoutForProfile?: number;
  totalPayoutForGroup: number;
  entries: Entry[];
}

export interface SoaBalance {
  accountId: string;
  available: number;
}

export interface AccountBalance {
  accountId?: string;
  accountType?: string;
  externalAccountId?: string;
  balance?: number;
  debit?: number;
  credit?: number;
}

export interface PayeeDistributionDetail {
  remittancePaymentMethodAccountId?: string;
  planId?: string;
  payeeId?: string;
  paymentSourceId?: string;
  payeeName?: string;
}

export interface PayeePaymentSourceInfo {
  id?: string;
  parentType?: ParentType;
  paymentSourceType?: PaymentSourceType;
}

export type OperationalBalanceInfoList = OperationalBalanceInfo[];

export type AccountPostings = AccountPosting[];

export type BankAccounts = BankAccount[];

export type BenefitAccountBalances = BenefitAccountBalance[];

export type InterestPostings = InterestPosting[];

export type OperationalAccountBalances = OperationalAccountBalance[];

export type PaymentSourceAccounts = PaymentSourceAccount[];

export type BenefitAccountElections = BenefitAccountElection[];

export interface BenefitAccountEntryExes {
  total?: number;
  items?: BenefitAccountEntryEx[];
}

export type BenefitAccounts = BenefitAccount[];

export type BenefitAccountEnrollments = BenefitAccountEnrollment[];

export type PersonBenefitAccountFeatureRestrictions = PersonBenefitAccountFeatureRestriction[];

export interface CRUDEntriesCommandData {
  insertCommandData?: Entries;
  removeCommandData?: Entries;
  updateCommandData?: Entries;
  useOptimisticLocking?: boolean;
}

export type DependentBenefitAccesses = DependentBenefitAccess[];

export type Entries = Entry[];

export type EntryPostings = EntryPosting[];

export type EntrySummaries = EntrySummary[];

export type EntryPlans = EntryPlan[];

export type EntryPlanSummaries = EntryPlanSummary[];

export type FeatureAccounts = FeatureAccount[];

export type PaymentSources = PaymentSource[];

export type Postings = Posting[];

export type Elections = Election[];

export type Trades = Trade[];

export type InvestmentBalances = InvestmentBalance[];

export interface RequestType {
  expenseType?: string;
  serviceType?: string;
  limit?: number;
}

export type RequestTypes = RequestType[];

export type Subdivisions = Subdivision[];

export enum AccountCommandType {
  ActiveToActive = 'ActiveToActive',
  ActiveToInactive = 'ActiveToInactive',
  InactiveToActive = 'InactiveToActive',
  InactiveToInactive = 'InactiveToInactive',
  NeedsProcessingToActive = 'NeedsProcessingToActive',
  NeedsProcessingToProcessingError = 'NeedsProcessingToProcessingError',
  PendingToActive = 'PendingToActive',
  PendingToNeedsProcessing = 'PendingToNeedsProcessing',
  StartToPending = 'StartToPending',
  StartToEnrich = 'StartToEnrich',
  EnrichToActive = 'EnrichToActive',
  EnrichToProcessingError = 'EnrichToProcessingError',
}

export enum BankAccountCommandType {
  ActiveToActive = 'ActiveToActive',
  ActiveToInactive = 'ActiveToInactive',
  InactiveToActive = 'InactiveToActive',
  ActiveToOnHold = 'ActiveToOnHold',
  ActiveToRemoved = 'ActiveToRemoved',
  OnHoldToActive = 'OnHoldToActive',
  PendingToActive = 'PendingToActive',
  PendingToError = 'PendingToError',
  StartToError = 'StartToError',
  StartToPending = 'StartToPending',
  StartToActive = 'StartToActive',
  StartToInactive = 'StartToInactive',
  OnHoldToInUse = 'OnHoldToInUse',
  OnHoldToRemoved = 'OnHoldToRemoved',
  InUseToActive = 'InUseToActive',
  InUseToInactive = 'InUseToInactive',
  InUseToOnHold = 'InUseToOnHold',
  InUseToRemoved = 'InUseToRemoved',
  UpgradeToActive = 'UpgradeToActive',
  ActiveToExpired = 'ActiveToExpired',
  StartToLocked = 'StartToLocked',
  ActiveToLocked = 'ActiveToLocked',
  InactiveToLocked = 'InactiveToLocked',
}

export enum BenefitAccountCommandType {
  ALOAWithBenefitsToActive = 'ALOAWithBenefitsToActive',
  ALOAWithBenefitsToActiveNonDisbursable = 'ALOAWithBenefitsToActiveNonDisbursable',
  ALOAWithBenefitsToALOAWithBenefits = 'ALOAWithBenefitsToALOAWithBenefits',
  ALOAWithBenefitsToGracePeriod = 'ALOAWithBenefitsToGracePeriod',
  ALOAWithBenefitsToReconciliation = 'ALOAWithBenefitsToReconciliation',
  ALOAWithBenefitsToRunOut = 'ALOAWithBenefitsToRunOut',
  ALOAWithoutBenefitsToActive = 'ALOAWithoutBenefitsToActive',
  ALOAWithoutBenefitsToActiveNonDisbursable = 'ALOAWithoutBenefitsToActiveNonDisbursable',
  ALOAWithoutBenefitsToALOAWithoutBenefits = 'ALOAWithoutBenefitsToALOAWithoutBenefits',
  ALOAWithoutBenefitsToGracePeriod = 'ALOAWithoutBenefitsToGracePeriod',
  ALOAWithoutBenefitsToReconciliation = 'ALOAWithoutBenefitsToReconciliation',
  ALOAWithoutBenefitsToRunOut = 'ALOAWithoutBenefitsToRunOut',
  ActiveNonDisbursableToActive = 'ActiveNonDisbursableToActive',
  ActiveNonDisbursableToActiveNonDisbursable = 'ActiveNonDisbursableToActiveNonDisbursable',
  ActiveNonDisbursableToFinalizing = 'ActiveNonDisbursableToFinalizing',
  ActiveNonDisbursableToGracePeriod = 'ActiveNonDisbursableToGracePeriod',
  ActiveNonDisbursableToInactive = 'ActiveNonDisbursableToInactive',
  ActiveNonDisbursableToReconciliation = 'ActiveNonDisbursableToReconciliation',
  ActiveNonDisbursableToRunOut = 'ActiveNonDisbursableToRunOut',
  ActiveNonDisbursableToALOAWithoutBenefits = 'ActiveNonDisbursableToALOAWithoutBenefits',
  ActiveToActive = 'ActiveToActive',
  ActiveToALOAWithoutBenefits = 'ActiveToALOAWithoutBenefits',
  ActiveToActiveNonDisbursable = 'ActiveToActiveNonDisbursable',
  ActiveToClosed = 'ActiveToClosed',
  ActiveToGracePeriod = 'ActiveToGracePeriod',
  ActiveToALOAWithBenefits = 'ActiveToALOAWithBenefits',
  ActiveToReconciliation = 'ActiveToReconciliation',
  ActiveToRunOut = 'ActiveToRunOut',
  AnyToPropagatingDateEdits = 'AnyToPropagatingDateEdits',
  AuditAdjustmentToAuditAdjustment = 'AuditAdjustmentToAuditAdjustment',
  AuditAdjustmentToFinalizing = 'AuditAdjustmentToFinalizing',
  ClosedToFinalizing = 'ClosedToFinalizing',
  CreatedToEnrolled = 'CreatedToEnrolled',
  CreatedToErrorXFAuthFailure = 'CreatedToErrorXFAuthFailure',
  CreatedToErrorXFDuplicate = 'CreatedToErrorXFDuplicate',
  CreatedToErrorXFRetry = 'CreatedToErrorXFRetry',
  EnrolledToEnrolled = 'EnrolledToEnrolled',
  EnrolledToInitiated = 'EnrolledToInitiated',
  EnrolledToRemoved = 'EnrolledToRemoved',
  FinalizingToFinalizingError = 'FinalizingToFinalizingError',
  EnrolledToUnenrolled = 'EnrolledToUnenrolled',
  InitiatedToUnenrolled = 'InitiatedToUnenrolled',
  FinalizedToAuditAdjustment = 'FinalizedToAuditAdjustment',
  FinalizingToError = 'FinalizingToError',
  FinalizingToFinalized = 'FinalizingToFinalized',
  GracePeriodToGracePeriod = 'GracePeriodToGracePeriod',
  GracePeriodToRunOut = 'GracePeriodToRunOut',
  GracePeriodToReconciliation = 'GracePeriodToReconciliation',
  GracePeriodToActiveNonDisbursable = 'GracePeriodToActiveNonDisbursable',
  IdVerificationPendingToActive = 'IdVerificationPendingToActive',
  IdVerificationPendingToActiveNonDisbursable = 'IdVerificationPendingToActiveNonDisbursable',
  IdVerificationPendingToClosed = 'IdVerificationPendingToClosed',
  IdVerificationPendingToIdVerificationPending = 'IdVerificationPendingToIdVerificationPending',
  TOUAgreementPendingToActive = 'TOUAgreementPendingToActive',
  TOUAgreementPendingToActiveNonDisbursable = 'TOUAgreementPendingToActiveNonDisbursable',
  TOUAgreementPendingToClosed = 'TOUAgreementPendingToClosed',
  TOUAgreementPendingToFinalizing = 'TOUAgreementPendingToFinalizing',
  TOUAgreementPendingToTOUAgreementPending = 'TOUAgreementPendingToTOUAgreementPending',
  IdVerificationPendingToTOUAgreementPending = 'IdVerificationPendingToTOUAgreementPending',
  IdVerificationPendingToFinalizing = 'IdVerificationPendingToFinalizing',
  InactiveToActive = 'InactiveToActive',
  InactiveToActiveNonDisbursable = 'InactiveToActiveNonDisbursable',
  InactiveToFinalizing = 'InactiveToFinalizing',
  InactiveToInactive = 'InactiveToInactive',
  InitiatedToActive = 'InitiatedToActive',
  InitiatedToActiveNonDisbursable = 'InitiatedToActiveNonDisbursable',
  InitiatedToClosed = 'InitiatedToClosed',
  InitiatedToIdVerificationPending = 'InitiatedToIdVerificationPending',
  InitiatedToInitiated = 'InitiatedToInitiated',
  InitiatedToRemoved = 'InitiatedToRemoved',
  InitiatedToTOUAgreementPending = 'InitiatedToTOUAgreementPending',
  PendingApprovalToCreated = 'PendingApprovalToCreated',
  PendingApprovalToEnrolled = 'PendingApprovalToEnrolled',
  PendingApprovalToPendingApproval = 'PendingApprovalToPendingApproval',
  PendingApprovalToRemoved = 'PendingApprovalToRemoved',
  PendingApprovalToUnenrolled = 'PendingApprovalToUnenrolled',
  PropagatingDateEditsToAny = 'PropagatingDateEditsToAny',
  ReconciliationToFinalizing = 'ReconciliationToFinalizing',
  ReconciliationToInactive = 'ReconciliationToInactive',
  ReconciliationToReconciliation = 'ReconciliationToReconciliation',
  ReconciliationToRolloverProcessing = 'ReconciliationToRolloverProcessing',
  RolloverCompleteToFinalizing = 'RolloverCompleteToFinalizing',
  RolloverProcessingToError = 'RolloverProcessingToError',
  RolloverProcessingToRolloverComplete = 'RolloverProcessingToRolloverComplete',
  RunOutToReconciliation = 'RunOutToReconciliation',
  RunOutToRunOut = 'RunOutToRunOut',
  RunOutToActiveNonDisbursable = 'RunOutToActiveNonDisbursable',
  StartToEnrolled = 'StartToEnrolled',
  StartToError = 'StartToError',
  StartToCreated = 'StartToCreated',
  StartToPendingApproval = 'StartToPendingApproval',
  UnenrolledToEnrolled = 'UnenrolledToEnrolled',
  UnenrolledToPendingApproval = 'UnenrolledToPendingApproval',
  UnenrolledToFinalizing = 'UnenrolledToFinalizing',
  ActiveToInactive = 'ActiveToInactive',
  ALOAWithoutBenefitsToInactive = 'ALOAWithoutBenefitsToInactive',
  ALOAWithBenefitsToInactive = 'ALOAWithBenefitsToInactive',
  GracePeriodToInactive = 'GracePeriodToInactive',
  RunOutToInactive = 'RunOutToInactive',
  UpgradeToConversion = 'UpgradeToConversion',
  ConversionToActive = 'ConversionToActive',
  ConversionToActiveNonDisbursable = 'ConversionToActiveNonDisbursable',
  GeneratePodEntries = 'GeneratePodEntries',
  ErrorToFinalizing = 'ErrorToFinalizing',
  PendingApprovalToFinalized = 'PendingApprovalToFinalized',
  ErrorXFAuthFailureToFinalized = 'ErrorXFAuthFailureToFinalized',
  ErrorXFDuplicateToFinalized = 'ErrorXFDuplicateToFinalized',
  ErrorXFRetryToFinalized = 'ErrorXFRetryToFinalized',
}

export enum PersonBenefitAccountFeatureRestrictionCommandType {
  StartToActive = 'StartToActive',
  ActiveToActive = 'ActiveToActive',
  ActiveToRemoved = 'ActiveToRemoved',
}

export enum DependentBenefitAccessCommandType {
  StartToActive = 'StartToActive',
  ActiveToActive = 'ActiveToActive',
  ActiveToInactive = 'ActiveToInactive',
  InactiveToActive = 'InactiveToActive',
  InactiveToInactive = 'InactiveToInactive',
  UpgradeToConversion = 'UpgradeToConversion',
  ConversionToActive = 'ConversionToActive',
  ConversionToErrorXFAuthFailure = 'ConversionToErrorXFAuthFailure',
  ConversionToErrorXFDuplicate = 'ConversionToErrorXFDuplicate',
  ConversionToErrorXFRetry = 'ConversionToErrorXFRetry',
  StartToInactive = 'StartToInactive',
  StartToPendingApproval = 'StartToPendingApproval',
  PendingApprovalToActive = 'PendingApprovalToActive',
  PendingApprovalToInactive = 'PendingApprovalToInactive',
  PendingApprovalToPendingApproval = 'PendingApprovalToPendingApproval',
  InactiveToRemoved = 'InactiveToRemoved',
  PendingApprovalToRemoved = 'PendingApprovalToRemoved',
}

export enum EntryCommandType {
  AnyToTransactionLockIdSet = 'AnyToTransactionLockIdSet',
  EnrichToNeedsProcessing = 'EnrichToNeedsProcessing',
  EnrichToRefunded = 'EnrichToRefunded',
  EnteredToEntered = 'EnteredToEntered',
  EnteredToRejected = 'EnteredToRejected',
  EnteredToSettled = 'EnteredToSettled',
  NeedsProcessingToEnrich = 'NeedsProcessingToEnrich',
  NeedsProcessingToProcessingError = 'NeedsProcessingToProcessingError',
  NeedsProcessingToNeedsProcessing = 'NeedsProcessingToNeedsProcessing',
  NotPublishedToNotPublished = 'NotPublishedToNotPublished',
  NotPublishedToPendingPublished = 'NotPublishedToPendingPublished',
  NotPublishedToScheduled = 'NotPublishedToScheduled',
  PendingPublishedToPublished = 'PendingPublishedToPublished',
  PublishedToEntered = 'PublishedToEntered',
  PublishedToNeedsProcessing = 'PublishedToNeedsProcessing',
  PendingPublishedToNoPostings = 'PendingPublishedToNoPostings',
  PublishedToReversalPublished = 'PublishedToReversalPublished',
  PendingPublishedToReversalPublished = 'PendingPublishedToReversalPublished',
  ScheduledToClaimed = 'ScheduledToClaimed',
  ScheduledToExpired = 'ScheduledToExpired',
  ScheduledToPendingPublished = 'ScheduledToPendingPublished',
  ScheduledToRemoved = 'ScheduledToRemoved',
  ScheduledToNotPublished = 'ScheduledToNotPublished',
  ScheduledToScheduled = 'ScheduledToScheduled',
  SettledToRejected = 'SettledToRejected',
  StartToEntered = 'StartToEntered',
  StartToPendingPublished = 'StartToPendingPublished',
  StartToScheduled = 'StartToScheduled',
  StartToEnrich = 'StartToEnrich',
  EnrichToEntered = 'EnrichToEntered',
  EnrichToPublished = 'EnrichToPublished',
  PendingPublishedToErrorXFAuthFailure = 'PendingPublishedToErrorXFAuthFailure',
  PendingPublishedToErrorXFDuplicate = 'PendingPublishedToErrorXFDuplicate',
  PendingPublishedToErrorXFRetry = 'PendingPublishedToErrorXFRetry',
  StartToError = 'StartToError',
  ScheduledToError = 'ScheduledToError',
  PendingPublishedToError = 'PendingPublishedToError',
  EnteredToReversalPublished = 'EnteredToReversalPublished',
  ReversedToReversed = 'ReversedToReversed',
  ReversalPublishedToReversed = 'ReversalPublishedToReversed',
  EnteredToDisputed = 'EnteredToDisputed',
  EnteredToDisputeSettled = 'EnteredToDisputeSettled',
  DisputedToDisputeSettled = 'DisputedToDisputeSettled',
  DisputedToDisputeRejected = 'DisputedToDisputeRejected',
  StartToCardPaymentAuthorized = 'StartToCardPaymentAuthorized',
  CardPaymentAuthorizedToCardPaymentSettled = 'CardPaymentAuthorizedToCardPaymentSettled',
  CardPaymentSettledToPendingPublished = 'CardPaymentSettledToPendingPublished',
  CardPaymentAuthorizedToCardAuthorizationFailed = 'CardPaymentAuthorizedToCardAuthorizationFailed',
  StartToCardAuthorizationFailed = 'StartToCardAuthorizationFailed',
}

export enum EntriesCommandType {
  StartToScheduled = 'StartToScheduled',
  StartToError = 'StartToError',
  ScheduledToScheduled = 'ScheduledToScheduled',
}

export enum PaymentSourceCommandType {
  ActiveToPending = 'ActiveToPending',
  ActiveToRemoved = 'ActiveToRemoved',
  ActiveToSuspended = 'ActiveToSuspended',
  ActiveToUsed = 'ActiveToUsed',
  ActiveToInactive = 'ActiveToInactive',
  InactiveToActive = 'InactiveToActive',
  PendingToActive = 'PendingToActive',
  StartToPending = 'StartToPending',
  SuspendedToActive = 'SuspendedToActive',
  StartToError = 'StartToError',
  PendingToError = 'PendingToError',
  StartToCreated = 'StartToCreated',
  CreatedToActive = 'CreatedToActive',
  CreatedToPendingXF = 'CreatedToPendingXF',
  PendingXFToActive = 'PendingXFToActive',
  PendingXFToErrorXFRetry = 'PendingXFToErrorXFRetry',
  PendingXFToErrorXFDuplicate = 'PendingXFToErrorXFDuplicate',
  PendingXFToErrorXFAuthFailure = 'PendingXFToErrorXFAuthFailure',
}

export enum PostingCommandType {
  StartToEntered = 'StartToEntered',
  StartToNeedsProcessing = 'StartToNeedsProcessing',
  NeedsProcessingToEntered = 'NeedsProcessingToEntered',
  NeedsProcessingToProcessingError = 'NeedsProcessingToProcessingError',
  StartToEnrich = 'StartToEnrich',
  EnrichToEntered = 'EnrichToEntered',
  EnrichToPublished = 'EnrichToPublished',
  AnyToRemoved = 'AnyToRemoved',
}

export enum InterestPostingCommandType {
  StartToSearching = 'StartToSearching',
  SearchingToProcessing = 'SearchingToProcessing',
  ProcessingToComplete = 'ProcessingToComplete',
  ErrorToSearching = 'ErrorToSearching',
  ErrorToProcessing = 'ErrorToProcessing',
}

export enum InvestmentContributionPostingCommandType {
  StartToProcessing = 'StartToProcessing',
}

export enum ProcessSettlementCommandType {
  StartToProcessing = 'StartToProcessing',
}

export enum ProcessCABFundingCommandType {
  StartToProcessing = 'StartToProcessing',
}

export enum RecalculateBenefitAccountCommandType {
  StartToProcessing = 'StartToProcessing',
}

export enum ScheduleAdjustmentsCommandType {
  StartToProcessing = 'StartToProcessing',
}

export enum ScheduledPostingsActionCommandType {
  ProcessScheduledPostings = 'ProcessScheduledPostings',
}

export enum ElectionCommandType {
  ActiveToInactive = 'ActiveToInactive',
  PendingToActive = 'PendingToActive',
  PendingToError = 'PendingToError',
  PendingToPending = 'PendingToPending',
  PendingToInactive = 'PendingToInactive',
  StartToError = 'StartToError',
  StartToPending = 'StartToPending',
  UpgradeToConversion = 'UpgradeToConversion',
  ConversionToActive = 'ConversionToActive',
  StartToPendingApproval = 'StartToPendingApproval',
  PendingApprovalToPending = 'PendingApprovalToPending',
  PendingApprovalToInactive = 'PendingApprovalToInactive',
  ActiveToActive = 'ActiveToActive',
  StartToPendingAmount = 'StartToPendingAmount',
  PendingAmountToPending = 'PendingAmountToPending',
  PendingAmountToInactive = 'PendingAmountToInactive',
  PendingAmountToPendingApproval = 'PendingAmountToPendingApproval',
}

export enum TradeCommandType {
  StartToEntryPublished = 'StartToEntryPublished',
  StartToPendingTradeRequestFile = 'StartToPendingTradeRequestFile',
  EntryPublishedToPendingTradeRequestFile = 'EntryPublishedToPendingTradeRequestFile',
  PendingTradeRequestFileToPendingTradeResponse = 'PendingTradeRequestFileToPendingTradeResponse',
  PendingTradeResponseToTradeResponseReceived = 'PendingTradeResponseToTradeResponseReceived',
  TradeResponseReceivedToSettlementEntriesPublished = 'TradeResponseReceivedToSettlementEntriesPublished',
  SettlementEntriesPublishedToSettled = 'SettlementEntriesPublishedToSettled',
  AnyToRemoved = 'AnyToRemoved',
}

export enum InvestmentBalanceCommandType {
  StartToActive = 'StartToActive',
  ActiveToActive = 'ActiveToActive',
}

export enum AccountState {
  Active = 'Active',
  Inactive = 'Inactive',
  NeedsProcessing = 'NeedsProcessing',
  ProcessingError = 'ProcessingError',
  Pending = 'Pending',
  Start = 'Start',
  Enrich = 'Enrich',
}

export enum BankAccountState {
  Active = 'Active',
  Error = 'Error',
  Expired = 'Expired',
  Inactive = 'Inactive',
  InUse = 'InUse',
  Locked = 'Locked',
  OnHold = 'OnHold',
  Pending = 'Pending',
  Removed = 'Removed',
  Start = 'Start',
  Upgrade = 'Upgrade',
}

export enum BenefitAccountState {
  Active = 'Active',
  ActiveNonDisbursable = 'ActiveNonDisbursable',
  ALOAWithBenefits = 'ALOAWithBenefits',
  ALOAWithoutBenefits = 'ALOAWithoutBenefits',
  Created = 'Created',
  Closed = 'Closed',
  Cobra = 'Cobra',
  Enrolled = 'Enrolled',
  Unenrolled = 'Unenrolled',
  Error = 'Error',
  Finalized = 'Finalized',
  Finalizing = 'Finalizing',
  FinalizingError = 'FinalizingError',
  GracePeriod = 'GracePeriod',
  IdVerificationPending = 'IdVerificationPending',
  TOUAgreementPending = 'TOUAgreementPending',
  Inactive = 'Inactive',
  Initiated = 'Initiated',
  PendingApproval = 'PendingApproval',
  PropagatingDateEdits = 'PropagatingDateEdits',
  Reconciliation = 'Reconciliation',
  Removed = 'Removed',
  RolloverComplete = 'RolloverComplete',
  RolloverProcessing = 'RolloverProcessing',
  RunOut = 'RunOut',
  Start = 'Start',
  ErrorXFAuthFailure = 'ErrorXFAuthFailure',
  ErrorXFDuplicate = 'ErrorXFDuplicate',
  ErrorXFRetry = 'ErrorXFRetry',
  Upgrade = 'Upgrade',
  Conversion = 'Conversion',
}

export enum DependentBenefitAccessState {
  Active = 'Active',
  Inactive = 'Inactive',
  Conversion = 'Conversion',
  Upgrade = 'Upgrade',
  ErrorXFAuthFailure = 'ErrorXFAuthFailure',
  ErrorXFDuplicate = 'ErrorXFDuplicate',
  ErrorXFRetry = 'ErrorXFRetry',
  PendingApproval = 'PendingApproval',
  Removed = 'Removed',
}

export enum ElectionState {
  Active = 'Active',
  Conversion = 'Conversion',
  Error = 'Error',
  Inactive = 'Inactive',
  Pending = 'Pending',
  Upgrade = 'Upgrade',
  PendingApproval = 'PendingApproval',
  PendingAmount = 'PendingAmount',
}

export enum InterestPostingState {
  Start = 'Start',
  Searching = 'Searching',
  Processing = 'Processing',
  Complete = 'Complete',
  Error = 'Error',
}

export enum PersonBenefitAccountFeatureRestrictionState {
  Start = 'Start',
  Active = 'Active',
  Removed = 'Removed',
}

export enum EntryState {
  Entered = 'Entered',
  Expired = 'Expired',
  NeedsProcessing = 'NeedsProcessing',
  ProcessingError = 'ProcessingError',
  Published = 'Published',
  Scheduled = 'Scheduled',
  Settled = 'Settled',
  Start = 'Start',
  Removed = 'Removed',
  NoPostings = 'NoPostings',
  NotPublished = 'NotPublished',
  PendingPublished = 'PendingPublished',
  Reversed = 'Reversed',
  Rejected = 'Rejected',
  Enrich = 'Enrich',
  Refunded = 'Refunded',
  Claimed = 'Claimed',
  ErrorXFAuthFailure = 'ErrorXFAuthFailure',
  ErrorXFDuplicate = 'ErrorXFDuplicate',
  ErrorXFRetry = 'ErrorXFRetry',
  Error = 'Error',
  EnrichError = 'EnrichError',
  ReversalPublished = 'ReversalPublished',
  Disputed = 'Disputed',
  DisputeSettled = 'DisputeSettled',
  DisputeRejected = 'DisputeRejected',
  CardPaymentAuthorized = 'CardPaymentAuthorized',
  CardPaymentSettled = 'CardPaymentSettled',
  CardAuthorizationFailed = 'CardAuthorizationFailed',
}

export enum PaymentSourceState {
  Active = 'Active',
  Inactive = 'Inactive',
  Pending = 'Pending',
  Start = 'Start',
  Suspended = 'Suspended',
  Removed = 'Removed',
  Used = 'Used',
  Error = 'Error',
  Created = 'Created',
  PendingXF = 'PendingXF',
  ErrorXFRetry = 'ErrorXFRetry',
  ErrorXFDuplicate = 'ErrorXFDuplicate',
  ErrorXFAuthFailure = 'ErrorXFAuthFailure',
}

export enum PostingState {
  Entered = 'Entered',
  NeedsProcessing = 'NeedsProcessing',
  ProcessingError = 'ProcessingError',
  Start = 'Start',
  Removed = 'Removed',
}

export enum TradeState {
  EntryPublished = 'EntryPublished',
  PendingTradeRequestFile = 'PendingTradeRequestFile',
  PendingTradeResponse = 'PendingTradeResponse',
  TradeResponseReceived = 'TradeResponseReceived',
  SettlementEntriesPublished = 'SettlementEntriesPublished',
  Settled = 'Settled',
  Error = 'Error',
}

export enum InvestmentBalanceState {
  Active = 'Active',
  Start = 'Start',
  Error = 'Error',
}

export enum AddressOwnerType {
  External = 'External',
  Primary = 'Primary',
}

export enum ParentType {
  CLIENT = 'CLIENT',
  REQUEST = 'REQUEST',
  REQUEST_PAYMENT = 'REQUEST_PAYMENT',
  INDIVIDUAL = 'INDIVIDUAL',
  DEPENDENT = 'DEPENDENT',
  ENTRY = 'ENTRY',
  BENEFIT_ACCOUNT = 'BENEFIT_ACCOUNT',
  BENEFIT_PLAN = 'BENEFIT_PLAN',
  BUREAU = 'BUREAU',
  ADVANCE = 'ADVANCE',
  CARD = 'CARD',
  INSTANCE = 'INSTANCE',
  PROVIDER = 'PROVIDER',
  PAYEE = 'PAYEE',
  ELIGIBILITY_EVENT = 'ELIGIBILITY_EVENT',
  PAYMENT_PLAN = 'PAYMENT_PLAN',
  PAYMENT_ACCOUNT = 'PAYMENT_ACCOUNT',
  PAYMENT_ACCOUNT_GROUP = 'PAYMENT_ACCOUNT_GROUP',
  DHA = 'DHA',
  UHA = 'UHA',
  PLAN_PAYEE = 'PLAN_PAYEE',
  CHARITY = 'CHARITY',
  PYAB = 'PYAB',
}

export enum BalanceType {
  Full = 'Full',
  Carryover = 'Carryover',
  Limited = 'Limited',
  Inactive = 'Inactive',
}

export enum BankAccountType {
  BusinessChecking = 'BusinessChecking',
  BusinessSavings = 'BusinessSavings',
  GeneralLedger = 'GeneralLedger',
  PersonalChecking = 'PersonalChecking',
  PersonalSavings = 'PersonalSavings',
}

export enum AccountType {
  BAB = 'BAB',
  BCA = 'BCA',
  BCTA = 'BCTA',
  BTCA = 'BTCA',
  BEA = 'BEA',
  BFA = 'BFA',
  BINPA = 'BINPA',
  BINT = 'BINT',
  BXFBO = 'BXFBO',
  BXPTD = 'BXPTD',
  CAB = 'CAB',
  CARD_EXCEPTION = 'CARD_EXCEPTION',
  CARD_CORRECTION = 'CARD_CORRECTION',
  CARD_FORCED = 'CARD_FORCED',
  CCTA = 'CCTA',
  CFPHA = 'CFPHA',
  CPFA = 'CPFA',
  COCTA = 'COCTA',
  CRA = 'CRA',
  IAB = 'IAB',
  IOA = 'IOA',
  IOAB = 'IOA-B',
  PCTA = 'PCTA',
  PFPHA = 'PFPHA',
  PPTAB = 'PPTAB',
  PPTAB_ADVANCE = 'PPTAB_ADVANCE',
  PPSEA = 'PPSEA',
  PSEA = 'PSEA',
  PSIA = 'PSIA',
  PPSIA = 'PPSIA',
  PYAB = 'PYAB',
  TOA = 'TOA',
  IOADEDUCTIBLE = 'IOA-DEDUCTIBLE',
  IOAPAYOUT = 'IOA-PAYOUT',
  TPOOA = 'TPOOA',
  PBIN = 'PBIN',
}

export enum SourceAccountType {
  Checking = 'Checking',
  Payroll = 'Payroll',
  Card = 'Card',
}

export enum AddressType {
  Primary = 'Primary',
  Billing = 'Billing',
  Shipping = 'Shipping',
  Other = 'Other',
}

export enum EventSourceType {
  Card = 'Card',
  UBA = 'UBA',
  SOA = 'SOA',
}

export enum FrequencyType {
  OneTime = 'OneTime',
  Annual = 'Annual',
  Recurring = 'Recurring',
}

export enum SoaEntryType {
  Credit = 'Credit',
  Debit = 'Debit',
}

export enum AggregateType {
  SUM = 'SUM',
  COUNT = 'COUNT',
}

export enum BenefitAccountParentType {
  CLIENT = 'CLIENT',
  INDIVIDUAL = 'INDIVIDUAL',
  ENTRY = 'ENTRY',
}

export enum PaymentAccountType {
  BankAccount = 'BankAccount',
  Check = 'Check',
  Wire = 'Wire',
  Card = 'Card',
  Trade = 'Trade',
  Paypal = 'Paypal',
}

export enum PaymentVendor {
  XFormative = 'XFormative',
  Braintree = 'Braintree',
}

export enum BenefitPlanOrFeatureType {
  BenefitPlan = 'BenefitPlan',
  MyCash = 'MyCash',
  Advance = 'Advance',
}

export enum BenefitPlanCoverageType {
  Single = 'Single',
  SinglePlusOne = 'SinglePlusOne',
  SinglePlusTwo = 'SinglePlusTwo',
  Family = 'Family',
  ByMemberUnlimited = 'ByMemberUnlimited',
}

export enum BenefitPlanType {
  Simple = 'Simple',
  TieredPayouts = 'TieredPayouts',
  DefinedElection = 'DefinedElection',
}

export enum CardType {
  AmericanExpress = 'American Express',
  Discover = 'Discover',
  MasterCard = 'MasterCard',
  Visa = 'Visa',
}

export enum DisbursementMethodType {
  ACH = 'ACH',
  TASCCard = 'TASCCard',
  MastercardSend = 'MastercardSend',
}

export enum ElectionScheduleType {
  Monthly = 'Monthly',
  Annually = 'Annually',
}

export enum ElectionProfileType {
  Client = 'Client',
  Individual = 'Individual',
}

export enum ElectionCalculationMethod {
  User = 'User',
  System = 'System',
  Custom = 'Custom',
}

export enum OperationalAccountProfileType {
  Dependent = 'Dependent',
  Individual = 'Individual',
}

export enum EntryType {
  ACHConvenienceFee = 'ACHConvenienceFee',
  ACHConvenienceFeeSweepAutomated = 'ACHConvenienceFeeSweepAutomated',
  ACHConvenienceFeeSweepManual = 'ACHConvenienceFeeSweepManual',
  ATMWithdraw = 'ATMWithdraw',
  BenefitAccountBalanceAdjustment = 'BenefitAccountBalanceAdjustment',
  BenefitAccountBalanceAdjustmentBureauContribution = 'BenefitAccountBalanceAdjustmentBureauContribution',
  BenefitAccountBalanceAdjustmentUnfunded = 'BenefitAccountBalanceAdjustmentUnfunded',
  BenefitAccountBalanceTransfer = 'BenefitAccountBalanceTransfer',
  BenefitPlanFinalizationToCab = 'BenefitPlanFinalizationToCab',
  BureauContributionDraw = 'BureauContributionDraw',
  BureauDisbursementDraw = 'BureauDisbursementDraw',
  BureauInterestPayment = 'BureauInterestPayment',
  BuyTradeSettlement = 'BuyTradeSettlement',
  CABBalanceAdjustment = 'CABBalanceAdjustment',
  CardConvenienceFee = 'CardConvenienceFee',
  CardConvenienceFeeSweepAutomated = 'CardConvenienceFeeSweepAutomated',
  CardConvenienceFeeSweepManual = 'CardConvenienceFeeSweepManual',
  CardDisbursement = 'CardDisbursement',
  CardForced = 'CardForced',
  CardRequestPayment = 'CardRequestPayment',
  CardRefund = 'CardRefund',
  CashInvestmentContribution = 'CashInvestmentContribution',
  ClientBenefitPlanFinalization = 'ClientBenefitPlanFinalization',
  ClientBureauContribution = 'ClientBureauContribution',
  ClientCABContribution = 'ClientCABContribution',
  ClientCABFunding = 'ClientCABFunding',
  ClientCommitmentFinalization = 'ClientCommitmentFinalization',
  ClientContribution = 'ClientContribution',
  ClientFeeAssessment = 'ClientFeeAssessment',
  ClientFeeAssessmentSweepAutomated = 'ClientFeeAssessmentSweepAutomated',
  ClientFeeAssessmentSweepManual = 'ClientFeeAssessmentSweepManual',
  ClientFunding = 'ClientFunding',
  ClientFundingConversion = 'ClientFundingConversion',
  ClientNegativeFunding = 'ClientNegativeFunding',
  ClientPodFunding = 'ClientPodFunding',
  ClientRemittanceAccountNegativeAdjustment = 'ClientRemittanceAccountNegativeAdjustment',
  ClientRemittanceAccountPositiveAdjustment = 'ClientRemittanceAccountPositiveAdjustment',
  ClientRemittanceDistribution = 'ClientRemittanceDistribution',
  ConvenienceFee = 'ConvenienceFee',
  DesignatedHoldingAccountChargeback = 'DesignatedHoldingAccountChargeback',
  DesignatedHoldingAccountNegativeAdjustment = 'DesignatedHoldingAccountNegativeAdjustment',
  DesignatedHoldingAccountPositiveAdjustment = 'DesignatedHoldingAccountPositiveAdjustment',
  DisburseFromCab = 'DisburseFromCab',
  DisburseFromPayee = 'DisburseFromPayee',
  DisbursementPreSettlement = 'DisbursementPreSettlement',
  DisbursementPreSettlementRejection = 'DisbursementPreSettlementRejection',
  DisbursementRejection = 'DisbursementRejection',
  DisbursementSettlement = 'DisbursementSettlement',
  DisbursementSettlementRejection = 'DisbursementSettlementRejection',
  DisburseToPayee = 'DisburseToPayee',
  DisburseToPaymentSource = 'DisburseToPaymentSource',
  FeeChargeback = 'FeeChargeback',
  FinancialFileRejection = 'FinancialFileRejection',
  FundedConversionRolloverContribution = 'FundedConversionRolloverContribution',
  FundFromCAB = 'FundFromCAB',
  FundingPreSettlement = 'FundingPreSettlement',
  FundingPreSettlementRejection = 'FundingPreSettlementRejection',
  FundingRejection = 'FundingRejection',
  FundingSettlement = 'FundingSettlement',
  FundingSettlementRejection = 'FundingSettlementRejection',
  HSAOrphanFeeAssessment = 'HSAOrphanFeeAssessment',
  HSAOrphanFeeSweepAutomated = 'HSAOrphanFeeSweepAutomated',
  HSAOrphanFeeSweepManual = 'HSAOrphanFeeSweepManual',
  HsaTransfer = 'HsaTransfer',
  IndividualFeeAssessment = 'IndividualFeeAssessment',
  InvestmentBuyBureau = 'InvestmentBuyBureau',
  InvestmentBuyParticipant = 'InvestmentBuyParticipant',
  InvestmentSellBureau = 'InvestmentSellBureau',
  InvestmentSellParticipant = 'InvestmentSellParticipant',
  InvestmentSweep = 'InvestmentSweep',
  InvestmentSweepBuyFix = 'InvestmentSweepBuyFix',
  InvestmentSweepSellFix = 'InvestmentSweepSellFix',
  ManualRefund = 'ManualRefund',
  ManualRequestPayment = 'ManualRequestPayment',
  ManualRequestRepayment = 'ManualRequestRepayment',
  ManualRequestReturn = 'ManualRequestReturn',
  MyCashBalanceAdjustment = 'MyCashBalanceAdjustment',
  MyCashContribution = 'MyCashContribution',
  MyCashFeeAssessment = 'MyCashFeeAssessment',
  MyCashFeeSweepAutomated = 'MyCashFeeSweepAutomated',
  MyCashFeeSweepManual = 'MyCashFeeSweepManual',
  MyCashManualRefund = 'MyCashManualRefund',
  MycashThresholdDisbursement = 'MycashThresholdDisbursement',
  OperationalContribution = 'OperationalContribution',
  OperationalDeductibleContribution = 'OperationalDeductibleContribution',
  OperationalDeductibleDisbursement = 'OperationalDeductibleDisbursement',
  OperationalDisbursement = 'OperationalDisbursement',
  OperationalPayoutContribution = 'OperationalPayoutContribution',
  OperationalPayoutDisbursement = 'OperationalPayoutDisbursement',
  ParticipantAccountTransfer = 'ParticipantAccountTransfer',
  ParticipantAdvanceContribution = 'ParticipantAdvanceContribution',
  ParticipantAdvanceFunding = 'ParticipantAdvanceFunding',
  ParticipantAdvanceReplenishment = 'ParticipantAdvanceReplenishment',
  ParticipantBalanceFinalization = 'ParticipantBalanceFinalization',
  ParticipantBenefitPlanFinalization = 'ParticipantBenefitPlanFinalization',
  ParticipantBureauContribution = 'ParticipantBureauContribution',
  ParticipantCommitmentFinalization = 'ParticipantCommitmentFinalization',
  ParticipantContribution = 'ParticipantContribution',
  ParticipantDirectCobraFunding = 'ParticipantDirectCobraFunding',
  ParticipantDirectFunding = 'ParticipantDirectFunding',
  ParticipantDirectFundingConversion = 'ParticipantDirectFundingConversion',
  ParticipantFeeAssessment = 'ParticipantFeeAssessment',
  ParticipantHoldingAccountTransfer = 'ParticipantHoldingAccountTransfer',
  ParticipantInterestCreditSecondaryBureau = 'ParticipantInterestCreditSecondaryBureau',
  ParticipantPaymentAccountContribution = 'ParticipantPaymentAccountContribution',
  ParticipantPaymentAccountContributionReversal = 'ParticipantPaymentAccountContributionReversal',
  ParticipantPaymentGroupCardFunding = 'ParticipantPaymentGroupCardFunding',
  ParticipantPaymentGroupFunding = 'ParticipantPaymentGroupFunding',
  ParticipantPaymentGroupRefund = 'ParticipantPaymentGroupRefund',
  ParticipantRollover = 'ParticipantRollover',
  ParticipantToClientFunding = 'ParticipantToClientFunding',
  PayeeRemittanceAccountNegativeAdjustment = 'PayeeRemittanceAccountNegativeAdjustment',
  PayeeRemittanceAccountPositiveAdjustment = 'PayeeRemittanceAccountPositiveAdjustment',
  PayeeRemittanceDistribution = 'PayeeRemittanceDistribution',
  PaymentAdjustment = 'PaymentAdjustment',
  PayrollFundingPlanRollover = 'PayrollFundingPlanRollover',
  PayrollPosting = 'PayrollPosting',
  PaymentAccountClientBaseRemittance = 'PaymentAccountClientBaseRemittance',
  PaymentAccountClientBaseRemittanceReversal = 'PaymentAccountClientBaseRemittanceReversal',
  PaymentAccountClientFeeRemittance = 'PaymentAccountClientFeeRemittance',
  PaymentAccountClientFeeRemittanceReversal = 'PaymentAccountClientFeeRemittanceReversal',
  PaymentAccountClientSurchargeRemittance = 'PaymentAccountClientSurchargeRemittance',
  PaymentAccountClientSurchargeRemittanceReversal = 'PaymentAccountClientSurchargeRemittanceReversal',
  PaymentAccountClientUnremittance = 'PaymentAccountClientUnremittance',
  PaymentAccountPayeeBaseRemittance = 'PaymentAccountPayeeBaseRemittance',
  PaymentAccountPayeeBaseRemittanceReversal = 'PaymentAccountPayeeBaseRemittanceReversal',
  PaymentAccountPayeeFeeRemittance = 'PaymentAccountPayeeFeeRemittance',
  PaymentAccountPayeeFeeRemittanceReversal = 'PaymentAccountPayeeFeeRemittanceReversal',
  PaymentAccountPayeeSurchargeRemittance = 'PaymentAccountPayeeSurchargeRemittance',
  PaymentAccountPayeeSurchargeRemittanceReversal = 'PaymentAccountPayeeSurchargeRemittanceReversal',
  PaymentAccountPayeeUnremittance = 'PaymentAccountPayeeUnremittance',
  PaymentPlanAdminFee = 'PaymentPlanAdminFee',
  PaymentPlanAdminFeeReversal = 'PaymentPlanAdminFeeReversal',
  PaymentPlanAdminFeeSweepAutomated = 'PaymentPlanAdminFeeSweepAutomated',
  PaymentPlanAdminFeeSweepManual = 'PaymentPlanAdminFeeSweepManual',
  PaymentPlanAdminSurcharge = 'PaymentPlanAdminSurcharge',
  PaymentPlanAdminSurchargeReversal = 'PaymentPlanAdminSurchargeReversal',
  PaymentPlanAdminUnremittance = 'PaymentPlanAdminUnremittance',
  PaymentPlanNSFFeeSweepAutomated = 'PaymentPlanNSFFeeSweepAutomated',
  PaymentPlanNSFFeeSweepManual = 'PaymentPlanNSFFeeSweepManual',
  PaymentRejection = 'PaymentRejection',
  PaymentRejectionFee = 'PaymentRejectionFee',
  PodFundingPlanRollover = 'PodFundingPlanRollover',
  PreAuthorizationHold = 'PreAuthorizationHold',
  PreprocessedDisbursementApproved = 'PreprocessedDisbursementApproved',
  PushToDebitCard = 'PushToDebitCard',
  RemittanceStagingAccountNegativeAdjustment = 'RemittanceStagingAccountNegativeAdjustment',
  RemittanceStagingAccountPositiveAdjustment = 'RemittanceStagingAccountPositiveAdjustment',
  Reversal = 'Reversal',
  SellOrderContribution = 'SellOrderContribution',
  SellOrderContributionDraw = 'SellOrderContributionDraw',
  SellOrderFunding = 'SellOrderFunding',
  SellOrderSettlement = 'SellOrderSettlement',
  SweepBinPayeeAccount = 'SweepBinPayeeAccount',
  SweepPaymentProcessorBinAccount = 'SweepPaymentProcessorBinAccount',
  TakeoverDisbursement = 'TakeoverDisbursement',
  TakeoverOperationalDeductibleContribution = 'TakeoverOperationalDeductibleContribution',
  TakeoverOperationalDeductibleDisbursement = 'TakeoverOperationalDeductibleDisbursement',
  TakeoverOperationalPayoutContribution = 'TakeoverOperationalPayoutContribution',
  TakeoverOperationalPayoutDisbursement = 'TakeoverOperationalPayoutDisbursement',
  UndesignatedHoldingAccountChargeback = 'UndesignatedHoldingAccountChargeback',
  UndesignatedHoldingAccountNegativeAdjustment = 'UndesignatedHoldingAccountNegativeAdjustment',
  UndesignatedHoldingAccountPositiveAdjustment = 'UndesignatedHoldingAccountPositiveAdjustment',
  UndesignatedParticipantPaymentGroupCardFunding = 'UndesignatedParticipantPaymentGroupCardFunding',
  UndesignatedParticipantPaymentGroupFunding = 'UndesignatedParticipantPaymentGroupFunding',
  UndesignatedParticipantPaymentGroupRefund = 'UndesignatedParticipantPaymentGroupRefund',
  UnfundedConversionRolloverContribution = 'UnfundedConversionRolloverContribution',
}

export enum EntrySubType {
  ParticipantPaymentAccountContribution = 'ParticipantPaymentAccountContribution',
  ParticipantPaymentAccountContributionCredit = 'ParticipantPaymentAccountContributionCredit',
  ParticipantPaymentAccountContributionReversal = 'ParticipantPaymentAccountContributionReversal',
  ParticipantPaymentAccountContributionCreditReversal = 'ParticipantPaymentAccountContributionCreditReversal',
  PaymentAccountClientBaseRemittanceCredit = 'PaymentAccountClientBaseRemittanceCredit',
  PaymentAccountClientFeeRemittanceCredit = 'PaymentAccountClientFeeRemittanceCredit',
  PaymentAccountClientSurchargeRemittanceCredit = 'PaymentAccountClientSurchargeRemittanceCredit',
  PaymentAccountPayeeBaseRemittanceCredit = 'PaymentAccountPayeeBaseRemittanceCredit',
  PaymentAccountPayeeFeeRemittanceCredit = 'PaymentAccountPayeeFeeRemittanceCredit',
  PaymentAccountPayeeSurchargeRemittanceCredit = 'PaymentAccountPayeeSurchargeRemittanceCredit',
  PaymentPlanAdminFeeCredit = 'PaymentPlanAdminFeeCredit',
  PaymentPlanAdminSurchargeCredit = 'PaymentPlanAdminSurchargeCredit',
  PaymentAccountPayeeUnremittance = 'PaymentAccountPayeeUnremittance',
  PaymentAccountPayeeUnremittanceOfCredit = 'PaymentAccountPayeeUnremittanceOfCredit',
  PaymentAccountClientUnremittance = 'PaymentAccountClientUnremittance',
  PaymentAccountClientUnremittanceOfCredit = 'PaymentAccountClientUnremittanceOfCredit',
  PaymentPlanAdminUnremittance = 'PaymentPlanAdminUnremittance',
  PaymentPlanAdminUnremittanceOfCredit = 'PaymentPlanAdminUnremittanceOfCredit',
}

export enum FeatureAccountType {
  IAB = 'IAB',
  Advance = 'Advance',
}

export enum HideFromType {
  None = 'None',
  Participant = 'Participant',
  Client = 'Client',
  ParticipantAndClient = 'ParticipantAndClient',
}

export enum FundingPostingType {
  PayrollSchedule = 'PayrollSchedule',
  PointOfDisbursement = 'PointOfDisbursement',
  CustomSchedule = 'CustomSchedule',
  UserInitiated = 'UserInitiated',
  ContributionSchedule = 'ContributionSchedule',
  CustomOther = 'CustomOther',
  Budgeted = 'Budgeted',
  DeductionSchedule = 'DeductionSchedule',
  CustomMonthly = 'CustomMonthly',
  CustomQuarterly = 'CustomQuarterly',
}

export enum ParticipantTerminationType {
  FullCoverageWithTerminatedEmployment = 'FullCoverageWithTerminatedEmployment',
  TerminatedAfterPlanEndDate = 'TerminatedAfterPlanEndDate',
}

export enum BenefitAccountInactivationReasonType {
  InactivatedByEmployeeEligibilityClassChange = 'InactivatedByEmployeeEligibilityClassChange',
}

export enum PaymentSourceType {
  ACH = 'ACH',
  Check = 'Check',
  Wire = 'Wire',
  Card = 'Card',
  Trade = 'Trade',
}

export enum EnrollmentSourceType {
  AdminWeb = 'AdminWeb',
  ClientWeb = 'ClientWeb',
  ParticipantWeb = 'ParticipantWeb',
  ParticipantMobile = 'ParticipantMobile',
  File = 'File',
  ApiConnex = 'ApiConnex',
}

export enum PaymentStatusType {
  Paid = 'Paid',
  PartiallyPaid = 'PartiallyPaid',
  Pending = 'Pending',
  Posted = 'Posted',
  Refunded = 'Refunded',
  Returned = 'Returned',
  Reversed = 'Reversed',
  Unpaid = 'Unpaid',
}

export enum PayrollFrequencyType {
  Weekly = 'Weekly',
  BiWeekly26 = 'BiWeekly26',
  BiWeekly24 = 'BiWeekly24',
  SemiMonthly = 'SemiMonthly',
  Monthly = 'Monthly',
  Other = 'Other',
}

export enum PostingMethodType {
  ACH = 'ACH',
  ATM = 'ATM',
  Card = 'Card',
  Contribution = 'Contribution',
  ContributionReversal = 'ContributionReversal',
  Manual = 'Manual',
  Online = 'Online',
  Reimbursement = 'Reimbursement',
}

export enum PostingScheduleType {
  AnnualSchedule = 'AnnualSchedule',
  PayrollSchedule = 'PayrollSchedule',
  PointOfDisbursement = 'PointOfDisbursement',
  CustomSchedule = 'CustomSchedule',
  UserInitiated = 'UserInitiated',
  ContributionSchedule = 'ContributionSchedule',
  CustomOther = 'CustomOther',
  Budgeted = 'Budgeted',
  DeductionSchedule = 'DeductionSchedule',
}

export enum PostingStatusType {
  Scheduled = 'Scheduled',
  Posted = 'Posted',
  Error = 'Error',
  Reversed = 'Reversed',
  Rejected = 'Rejected',
}

export enum ProfileType {
  Bureau = 'Bureau',
  Client = 'Client',
  Dependent = 'Dependent',
  Individual = 'Individual',
  Instance = 'Instance',
  Payee = 'Payee',
}

export enum QualifyingLifeEvent {
  AdministrativeUseOnly = 'AdministrativeUseOnly',
  ChangeInLegalMaritalStatus = 'ChangeInLegalMaritalStatus',
  ChangeInNumberOfDependents = 'ChangeInNumberOfDependents',
  ChangeInEmploymentStatus = 'ChangeInEmploymentStatus',
  DependentSatisfiesOrCeasesToSatisfyEligibilityRequirements = 'DependentSatisfiesOrCeasesToSatisfyEligibilityRequirements',
  ChangeInResidence = 'ChangeInResidence',
  COBRA = 'COBRA',
  FMLA = 'FMLA',
  ChangeInTheCostOfCoverage = 'ChangeInTheCostOfCoverage',
  HIPAASpecialEnrollmentRights = 'HIPAASpecialEnrollmentRights',
  JudgementDecreeOrOrder = 'JudgementDecreeOrOrder',
  SignificantCurtailmentOfCoverage = 'SignificantCurtailmentOfCoverage',
  AdditionOrEliminationOfBenefitPackage = 'AdditionOrEliminationOfBenefitPackage',
  EntitlementToMedicareOrMedicaid = 'EntitlementToMedicareOrMedicaid',
  ChangeInCoverageOfSpouseOrDependentUnderOtherEmployersPlan = 'ChangeInCoverageOfSpouseOrDependentUnderOtherEmployersPlan',
  LossOfGroupHealthCoverageSponsoredByGovernmentalOrEducationalInstitutions = 'LossOfGroupHealthCoverageSponsoredByGovernmentalOrEducationalInstitutions',
  ExchangeEventReductionInHoursLessThan30 = 'ExchangeEventReductionInHoursLessThan30',
  ExchangeEventExchangeEnrollmentDuringExchangeOpenOrSpecialEnrollmentPeriod = 'ExchangeEventExchangeEnrollmentDuringExchangeOpenOrSpecialEnrollmentPeriod',
  AdoptionAssistance = 'AdoptionAssistance',
  ChangeInDependentEligibility = 'ChangeInDependentEligibility',
}

export enum AllowedRecalculationType {
  Always = 'Always',
  OnElectionChange = 'OnElectionChange',
  Never = 'Never',
}

export enum TransactionCodeSecIndicator {
  CCD = 'CCD',
  PPD = 'PPD',
  WEB = 'WEB',
  TEL = 'TEL',
}

export enum TransactionFrequencyIndicator {
  SINGLE = 'SINGLE',
  RECURRING = 'RECURRING',
}

export enum TransactionType {
  CREDIT = 'CREDIT',
  DEBIT = 'DEBIT',
}

export enum ValidElectionActivationBenefitAccountStates {
  Active = 'Active',
  ActiveNonDisbursable = 'ActiveNonDisbursable',
  ALOAWithBenefits = 'ALOAWithBenefits',
  ALOAWithoutBenefits = 'ALOAWithoutBenefits',
  GracePeriod = 'GracePeriod',
  RunOut = 'RunOut',
}

export enum TradeType {
  Buy = 'Buy',
  Sell = 'Sell',
}

export enum FinancialProcessor {
  XPS1 = 'XPS1',
  XPS2 = 'XPS2',
}

export enum ChainType {
  AND = 'AND',
  OR = 'OR',
}

export enum MatchType {
  BETWEEN = 'BETWEEN',
  CONTAINS = 'CONTAINS',
  EXACT = 'EXACT',
  NOT = 'NOT',
  IN = 'IN',
  NOT_IN = 'NOT_IN',
  NOT_CONTAINS = 'NOT_CONTAINS',
  STARTS_WITH = 'STARTS_WITH',
  NOT_STARTS_WITH = 'NOT_STARTS_WITH',
  LESS_THAN = 'LESS_THAN',
  LESS_THAN_EQUAL = 'LESS_THAN_EQUAL',
  GREATER_THAN = 'GREATER_THAN',
  GREATER_THAN_EQUAL = 'GREATER_THAN_EQUAL',
  IS_NULL = 'IS_NULL',
}

export enum FundingSourceType {
  ParticipantToClient = 'ParticipantToClient',
  ClientDirect = 'ClientDirect',
  ParticipantDirect = 'ParticipantDirect',
  ClientToPlan = 'ClientToPlan',
}

export enum ScheduleType {
  Contribution = 'Contribution',
  Deduction = 'Deduction',
  Funding = 'Funding',
}

export enum LinkableEntryType {
  ClientBureauContribution = 'ClientBureauContribution',
  ClientContribution = 'ClientContribution',
  ClientFunding = 'ClientFunding',
  ClientFundingConversion = 'ClientFundingConversion',
  ClientNegativeFunding = 'ClientNegativeFunding',
  ParticipantBureauContribution = 'ParticipantBureauContribution',
  ParticipantContribution = 'ParticipantContribution',
  ParticipantToClientFunding = 'ParticipantToClientFunding',
  PayrollPosting = 'PayrollPosting',
  FundFromCAB = 'FundFromCAB',
  ParticipantDirectFundingConversion = 'ParticipantDirectFundingConversion',
}

export enum DisbursementEntryType {
  DisburseFromCab = 'DisburseFromCab',
  DisburseFromPayee = 'DisburseFromPayee',
  DisburseToPaymentSource = 'DisburseToPaymentSource',
  InvestmentSweep = 'InvestmentSweep',
  MycashThresholdDisbursement = 'MycashThresholdDisbursement',
  InvestmentBuyParticipant = 'InvestmentBuyParticipant',
  ClientRemittanceDistribution = 'ClientRemittanceDistribution',
  PayeeRemittanceDistribution = 'PayeeRemittanceDistribution',
}

export enum FundingEntryType {
  ClientFunding = 'ClientFunding',
  ClientPodFunding = 'ClientPodFunding',
  ClientCABFunding = 'ClientCABFunding',
  InvestmentSweep = 'InvestmentSweep',
  ParticipantAdvanceFunding = 'ParticipantAdvanceFunding',
  ParticipantDirectFunding = 'ParticipantDirectFunding',
  ParticipantToClientFunding = 'ParticipantToClientFunding',
  SellOrderFunding = 'SellOrderFunding',
  ParticipantPaymentGroupFunding = 'ParticipantPaymentGroupFunding',
  UndesignatedParticipantPaymentGroupFunding = 'UndesignatedParticipantPaymentGroupFunding',
  ACHConvenienceFee = 'ACHConvenienceFee',
}
