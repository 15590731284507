import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';

import { TermsOfUseSignState } from '../../models/terms-of-use.enum';

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss'],
})
export class TermsOfUseComponent implements OnInit {
  public signState: TermsOfUseSignState;

  public constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private spinnerService: NgxUiLoaderService,
  ) { }

  public ngOnInit(): void {
    this.spinnerService.start();
    this.signState = this.activatedRoute.snapshot.data.signState;
  }

  public async signedTermsOfUse(): Promise<boolean> {
    switch (this.signState) {
      case TermsOfUseSignState.SignIn:
        return this.router.navigate(['/']);
      case TermsOfUseSignState.SignUp:
        return this.router.navigate(['/signup/ssn']);
    }
  }

  public termsOfUseLoaded(): void {
    this.spinnerService.stop();
  }
}
