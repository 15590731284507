import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/auth/services/authentication.service';
import { RaygunService } from 'src/app/raygun/raygun.service';
import { BrandId } from 'src/app/shared/models/pux';
import { BrandService } from 'src/app/shared/services/brand.service';

@Component({
  templateUrl: './logout.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoutComponent implements OnInit {
  public logout$: Promise<boolean>;

  public constructor(
    private authenticationService: AuthenticationService,
    private raygunService: RaygunService,
    private router: Router,
    private brandService: BrandService,
  ) { }

  public ngOnInit(): void {
    this.logout$ = this.authenticationService.signOut()
      .then(async () => {
        if (this.brandService.getBrand() === BrandId.Crossroads) {
          return this.router.navigate(['/login/refund'], { queryParams: { logout: 'true' } });
        }
        return this.router.navigate(['/login']);
      })
      .catch(async (error) => {
        this.raygunService.logError(error);
        if (this.brandService.getBrand() === BrandId.Crossroads) {
          return this.router.navigate(['/login/refund'], { queryParams: { logout: 'true' } });
        }
        return this.router.navigate(['/login']);
      });
  }
}
