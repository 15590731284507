import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Payroll } from 'src/app/shared/models/uba/profileConfiguration/model';

export interface PayrollState extends EntityState<Payroll, string>, ActiveState {}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'Payroll' })
export class PayrollStore extends EntityStore<PayrollState> {
  public constructor() {
    super();
  }
}
