import { Inject, Injectable } from '@angular/core';

import { InjectionTokens } from './injection-tokens';

@Injectable({
  providedIn: 'root',
})
export class MutationObserverFactory {
  public constructor(
    @Inject(InjectionTokens.MUTATION_OBSERVER) private mutationObserverPrototype: {
      prototype: MutationObserver;
      new(callback: MutationCallback): MutationObserver;
    }) { }

  public create(callback: MutationCallback): MutationObserver {
    return new this.mutationObserverPrototype(callback);
  }
}
