import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { withTransaction } from '@datorama/akita';
import { merge } from 'lodash';
import { Observable, of, zip } from 'rxjs';
import { map, mapTo, switchMap, tap } from 'rxjs/operators';
import {
  Attachment,
  AttachmentCommand,
  AttachmentCommandType,
  ChainType,
  MatchType,
  ParentType,
  SearchCriteria,
} from 'src/app/shared/models/uba/file/model';
import {
  AttachmentContentStore,
  AttachmentQuery,
  AttachmentStore,
  BillReceiptQuery,
  BillReceiptStore,
  cacheableById,
  IndividualQuery,
} from 'src/app/state';
import { v4 as uuid } from 'uuid';

import { AttachmentContent } from '../models/pux/attachment-content';
import { ContentDisposition, ContentType, CoreService } from '../models/pux/enum';
import { SearchQuery } from '../models/pux/model';
import { Uri } from '../services/uri';
import { CommandFactory } from './command.factory';
import { FileUploadService } from './fileUpload.service';

@Injectable({
  providedIn: 'root',
})
export class AttachmentService {
  public constructor(
    private attachmentContentStore: AttachmentContentStore,
    private attachmentQuery: AttachmentQuery,
    private attachmentStore: AttachmentStore,
    private billReceiptQuery: BillReceiptQuery,
    private billReceiptStore: BillReceiptStore,
    private commandFactory: CommandFactory,
    private fileUploadService: FileUploadService,
    private http: HttpClient,
    private individualQuery: IndividualQuery,
  ) { }

  public getBillReceipts(): Observable<Attachment[]> {
    if (this.billReceiptQuery.getHasCache()) {
      return this.billReceiptQuery.selectAllWhenLoaded();
    }

    const queryParams: SearchQuery = {
      orderBy: 'created',
      orderDirection: 'desc',
    };

    const individualId = this.individualQuery.getActiveId();
    const uri = new Uri(`/profile/${individualId}/attachment/search`, CoreService.File, queryParams);
    const criteria: SearchCriteria = [
      {
        key: 'parentId',
        value: individualId,
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      },
      {
        key: 'parentType',
        value: ParentType.INDIVIDUAL,
        matchType: MatchType.EXACT,
      },
    ];

    return this.http.post<Attachment[]>(uri.toString(), criteria)
      .pipe(
        withTransaction((attachments) => this.billReceiptStore.set(attachments)),
        switchMap(() => this.billReceiptQuery.selectAllWhenLoaded()),
      );
  }

  public deleteBillReceiptsByIds(attachmentIds: string[]): Observable<void> {
    if (!attachmentIds || !attachmentIds.length) {
      return of(null);
    }

    const attachments$ = attachmentIds.map((attachmentId) => this.getBillReceipt(attachmentId));
    return zip(...attachments$)
      .pipe(
        map((attachments) => attachments.filter((attachment) => !!attachment)),
        switchMap((attachments: Attachment[]) => {
          return attachments.length ? zip(...attachments.map((attachment) => this.deleteAttachment(attachment))) : of(null);
        }),
        withTransaction(() => this.billReceiptStore.remove(attachmentIds)),
        mapTo(null),
      );
  }

  /**
   * Loads an attachment entity by id.
   * @param attachmentId The id of the attachment to load.
   */
  public loadAttachment(attachmentId: string): Observable<void> {
    const individualId = this.individualQuery.getActiveId();
    const url = new Uri(`/profile/${individualId}/attachment/${attachmentId}`, CoreService.File);
    const request$ = this.http.get<Attachment>(url.toString())
      .pipe(
        withTransaction((attachment) => {
          this.attachmentStore.upsert(attachment.id, attachment);
          this.attachmentStore.setHasCache(true);
        }),
      );
    return cacheableById(attachmentId, this.attachmentStore, request$, { emitNext: true })
      .pipe(
        mapTo(null),
      );
  }

  public getAttachmentContentType(attachment: Attachment): ContentType {
    switch (attachment.attachmentType?.toLowerCase()) {
      case 'jpeg':
      case 'jpg':
        return ContentType.Jpg;
      case 'pdf':
        return ContentType.Pdf;
      case 'png':
        return ContentType.Png;
    }
  }
  public getAttachmentContentUrl(attachment: Attachment, contentDisposition?: ContentDisposition): Observable<string>;
  public getAttachmentContentUrl(attachmentId: string, contentDisposition: ContentDisposition, contentType: ContentType): Observable<string>;
  public getAttachmentContentUrl(
    attachment: Attachment | string,
    contentDisposition: ContentDisposition = ContentDisposition.Inline,
    contentType?: ContentType,
  ): Observable<string> {
    const attachmentId = typeof attachment === 'string' ? attachment : attachment.id;
    const individualId = this.individualQuery.getActiveId();
    const url = new Uri(
      `/profile/${individualId}/fileAttachment/${attachmentId}/geturl`,
      CoreService.File,
    );
    contentType = typeof attachment === 'string' ? contentType : this.getAttachmentContentType(attachment);
    const httpOptions = {
      params: new HttpParams({
        fromObject: {
          contentDisposition: contentDisposition.toLowerCase(),
          contentType,
        },
      }),
    };
    return this.http.get<string>(url.toString(), httpOptions);
  }

  public getAttachmentFile(attachment: Attachment, contentDisposition: ContentDisposition = ContentDisposition.Inline): Observable<AttachmentContent> {
    return this.getAttachmentContentUrl(attachment, contentDisposition)
      .pipe(
        switchMap((contentUrl) => {
          return this.http.get(contentUrl, { responseType: 'blob' });
        }),
        map((content) => {
          return {
            id: attachment.id,
            content,
          };
        }),
        withTransaction((attachmentContent) => this.attachmentContentStore.upsert(attachmentContent.id, attachmentContent)),
      );
  }

  /**
   * Create an attachment entity corresponding to the specified file. It is not persisted to the data store.
   * @param file The file to be used for the attachment
   * @param parentId The parent ID of the attachment (typically REQUEST, INDIVIDUAL, or SUPPORT_REQUEST)
   * @param parentType The parent type
   */
  public createAttachmentFromFile(file: File, parentId: string, parentType: ParentType): Attachment {
    return {
      id: uuid(),
      attachmentType: file.name.substr(file.name.lastIndexOf('.') + 1),
      friendlyFileName: file.name,
      parentId,
      parentType,
      filePath: '',
    };
  }

  /**
   * Clone and save the given attachment to the database.
   */
  public cloneAttachment(attachment: Attachment): Observable<Attachment> {
    let sourceAttachment$: Observable<Attachment>;

    if (attachment.filePath) {
      sourceAttachment$ = of(attachment);
    } else {
      // Attachment does not have the filePath property, which is populated by core within a few seconds
      // of uploading a bill/receipt. This is required for the clone functionality, so grab a fresh one.
      sourceAttachment$ = this.loadAttachment(attachment.id)
        .pipe(
          switchMap(() => this.attachmentQuery.selectEntity(attachment.id)),
          tap((att) => this.billReceiptStore.upsert(att.id, att)),
        );
    }
    const commandName = AttachmentCommandType.StartToUploaded;
    return sourceAttachment$
      .pipe(
        switchMap((sourceAttachment) => this.createAndSendAttachmentCommand({...sourceAttachment, id: uuid()}, commandName, { sourceURI: sourceAttachment.filePath })),
        withTransaction((clonedAttachment) => this.attachmentStore.add(clonedAttachment)),
      );
  }

  /**
   * Save the given attachment to the database. Note that we intentionally don't add the attachment to Akita here.
   * We must wait for the puturl endpoint to execute, which updates the filePath property on the Attachment record
   * in the DB. We need the filePath property to be populated so that attachments cloned from bills and receipts
   * will persist even if the original attachment is deleted. Therefore we only add to Akita when retrieving
   * attachments (e.g. loadAttachment() function)
   */
  public saveAttachment(file: File, attachment: Attachment): Observable<Attachment> {
    const commandName = AttachmentCommandType.StartToUploaded;
    return this.createAndSendAttachmentCommand(attachment, commandName)
      .pipe(
        switchMap((uploadedAttachment) => this.fileUploadService.uploadFile(file, uploadedAttachment).pipe(
          mapTo(uploadedAttachment),
        )),
      );
  }

  /**
   * Delete the given attachment from the database.
   */
  public deleteAttachment(attachment: Attachment): Observable<void> {
    return this.createAndSendAttachmentCommand(attachment, AttachmentCommandType.StoredToRemoved)
      .pipe(
        withTransaction(() => this.attachmentStore.remove(attachment.id)),
        mapTo(null),
      );
  }

  private getBillReceipt(attachmentId: string): Observable<Attachment> {
    if (this.billReceiptQuery.hasEntity(attachmentId)) {
      return this.billReceiptQuery.selectEntity(attachmentId);
    }

    const individualId = this.individualQuery.getActiveId();
    const url = new Uri(`/profile/${individualId}/attachment/${attachmentId}`, CoreService.File);
    return this.http.get<Attachment>(url.toString())
      .pipe(
        withTransaction((attachment) => this.billReceiptStore.upsert(attachment.id, attachment)),
      );
  }

  private createAndSendAttachmentCommand(attachment: Attachment, commandName: AttachmentCommandType, additionalProperties?: Partial<AttachmentCommand>): Observable<Attachment> {
    const sourceCommand: AttachmentCommand = this.commandFactory.createCommand(attachment, commandName);
    const command = merge(sourceCommand, additionalProperties);
    const individualId = this.individualQuery.getActiveId();
    const url = new Uri(`/profile/${individualId}/attachment/command/${command.type}`, CoreService.File);

    return this.http.put<void>(url.toString(), command)
      .pipe(
        mapTo(command.data),
      );
  }
}
