import { cacheable, Store } from '@datorama/akita';
import { Observable, throwError } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';

import { genericRetryStrategy } from '../shared/utils/rxjs.util';

const cachedRequestKeys = new Set<string>();
/**
 *
 * Helper function for checking if we have data in cache
 *
 * export class ProductsService {
 *   constructor(private productsStore: ProductsStore) {}
 *   get(productId: string): Observable<void> {
 *     const request = this.http.get(productId).pipe(
 *       tap(this.productsStore.set(response))
 *     );
 *
 *     return cacheableWithId(productId, this.productsStore, request);
 *   }
 * }
 */
export function cacheableById<T>(uniqueId: string, store: Store, request$: Observable<T>, options: { emitNext: boolean } = { emitNext: false }): Observable<T | undefined | never> {
  if (!uniqueId) {
    throw new Error('Unique Id is required.');
  }
  const key = `${store.storeName}_${uniqueId}`;
  if (cachedRequestKeys.has(key)) {
    return cacheable(store, request$, options);
  }
  cachedRequestKeys.add(key);
  return request$.pipe(
    retryWhen(genericRetryStrategy()),
    catchError((error) => {
      cachedRequestKeys.delete(key);
      return throwError(error);
    }),
  );
}
