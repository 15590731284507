import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { AuthenticationChallengeType } from 'src/app/auth/models/authentication-challenge.model';
import { AuthenticationService } from 'src/app/auth/services/authentication.service';
import { ModalComponent } from 'src/app/shared/components/forms/modal/modal.component';
import { BrandService } from 'src/app/shared/services/brand.service';

@Component({
  selector: 'app-federated-redirect-modal',
  templateUrl: 'federated-redirect-modal.component.html',
  styleUrls: ['./federated-redirect-modal.component.scss'],
})
export class FederatedRedirectModalComponent {
  @ViewChild(ModalComponent, { static: true }) public modal: ModalComponent;
  @Output() public modalClose = new EventEmitter<AuthenticationChallengeType>();

  public readonly AuthenticationChallengeType = AuthenticationChallengeType;
  public readonly brand = this.brandService.getBrandResources();
  public buttonLabel: string;
  private federatedIDURL: string;
  private identityProvider: string;
  private emailAddress: string;
  public constructor(
    private authenticationService: AuthenticationService,
    private brandService: BrandService,
  ) { }

  public open(identityProvider: string, emailAddress: string, idpOverrides?: any): void {
    this.modal.open();
    this.identityProvider = identityProvider;
    this.emailAddress = emailAddress;
    this.buttonLabel = 'Continue to your employer portal';
    this.federatedIDURL = null;
    if (idpOverrides && idpOverrides.federatedRedirectModal) {
      this.buttonLabel = idpOverrides.federatedRedirectModal.buttonLabel || this.buttonLabel;
      this.federatedIDURL = idpOverrides.federatedRedirectModal.buttonURL || this.federatedIDURL;
    }
  }

  public redirectToFederatedSignInPage(): void {
    this.authenticationService.redirectToFederatedSignInPage(this.identityProvider, this.emailAddress, this.federatedIDURL);
  }

  public setUserAuthenticationChallenge(challengeType: AuthenticationChallengeType): void {
    this.modalClose.emit(challengeType);
    this.modal.close();
  }
}
