export interface Weekday {
  index: number;
  name: string;
}

export class Weekdays {
  public static getWorkweekDays(): Weekday[] {
    return Weekdays.weekdays.slice(1, 6);
  }

  private static readonly weekdays: Weekday[] = [
    { index: 0, name: 'Sunday' },
    { index: 1, name: 'Monday' },
    { index: 2, name: 'Tuesday' },
    { index: 3, name: 'Wednesday' },
    { index: 4, name: 'Thursday' },
    { index: 5, name: 'Friday' },
    { index: 6, name: 'Saturday' },
  ];
}
