import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { IndividualPaymentStatus } from '@models/paymentAccount/model';

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'IndividualPaymentStatus' })
export class IndividualPaymentStatusStore extends Store<IndividualPaymentStatus> {
  public constructor() {
    super({});
  }
}
