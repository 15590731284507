import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { Payout } from '../../models';

export interface PayoutState extends EntityState<Payout, string>, ActiveState {
  entryId: string;
}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'Payout', idKey: 'entryId' })
export class PayoutStore extends EntityStore<PayoutState> {
  public constructor() {
    super();
  }

  public setEntryId(entryId: string): void {
    this.update({ entryId });
  }
}
