// AUTOGENERATED BY FUNCTION genubamodel.js
export interface AdvanceSetupCommand extends CommandBase {
  data?: AdvanceSetup;
  clientId?: string;
}

export interface ApiKeyCommand extends CommandBase {
  data?: ApiKey;
}

export interface ApiKeyCommandResponse {
  apiKey?: ApiKey;
  plainSecret?: string;
}

export interface CcxEnrollmentCommand extends CommandBase {
  data?: CcxEnrollment;
}

export interface ClientServiceProfileCommand extends CommandBase {
  data?: ClientServiceProfile;
}

export interface ClientServiceProfilePriorityCommand extends CommandBase {
  data?: ClientServiceProfilePriority;
}

export interface ClientServiceProfileTenuousRecordCommand extends CommandBase {
  data?: ClientServiceProfileTenuousRecord;
}

export interface ClientSpecialHandlingNoteCommand extends CommandBase {
  data?: ClientSpecialHandlingNote;
}

export interface CustomizationCommand extends CommandBase {
  data?: Customization;
}

export interface DepletionOrderCommand extends CommandBase {
  data?: DepletionOrder;
}

export interface DeviceCommand extends CommandBase {
  data?: Device;
}

export interface DistributorClientCommand extends CommandBase {
  data?: DistributorClient;
}

export interface DivisionCommand extends CommandBase {
  data?: Division;
}

export interface DocumentCommand extends CommandBase {
  data?: Document;
  additionalProperties?: AdditionalLetterProperties;
}

export interface DocumentDistributionCommand extends CommandBase {
  data?: DocumentDistribution;
}

export interface EmployeeEligibilityClassCommand extends CommandBase {
  data?: EmployeeEligibilityClass;
}

export interface EmploymentInfoCommand extends CommandBase {
  data?: EmploymentInfo;
}

export interface FundsTransferCriterionCommand extends CommandBase {
  data?: FundsTransferCriterion;
}

export interface IndividualServiceProfileCommand extends CommandBase {
  data?: IndividualServiceProfile;
}

export interface PayrollCommand extends CommandBase {
  data?: Payroll;
}

export interface PayrollScheduleCommand extends CommandBase {
  data?: PayrollSchedule;
}

export interface DocumentActionCommand extends CommandBase {
  data?: DocumentAction;
}

export interface ClientAccountManagementCommand extends CommandBase {
  data?: ClientAccountManagement;
}

export interface MspReportingRequestCommand extends CommandBase {
  data?: MspReportingRequest;
}

export interface MspReportingFileCommand extends CommandBase {
  data?: MspReportingFile;
}

export interface MspResponseFileLineCommand extends CommandBase {
  data?: MspResponseFileLine;
}

export interface EligibilityEventCommand extends CommandBase {
  data?: EligibilityEvent;
  electionData?: EligibilityEventElectionData[];
  refId?: string;
}

export interface EligibilityEventOfferingCommand extends CommandBase {
  data?: EligibilityEventOffering;
  electedProfileIds?: string[];
  priceIds?: string[];
}

export interface OfferingPriceCommand extends CommandBase {
  data?: OfferingPrice;
}

export interface Xps2MigrationCommand extends CommandBase {
  data?: Xps2Migration;
}

export interface AdditionalLetterProperties {
  selectedPAGIds?: string[];
  workOrderNumber?: string;
  informationRequiredToProcess?: string;
  participantName?: string;
  showElectionPacketResend?: boolean;
  pastObligationPrices?: ObligationPricesPayload[];
  futureObligationPrices?: ObligationPricesPayload[];
  timelineEffectiveDate?: string;
  originalDocumentId?: string;
  futureObligationStartDate?: string;
  totalAmountDue?: string;
  remainingAmountDue?: string;
}

export interface ObligationPricesPayload {
  planName: string;
  coverageLevel: string;
  paidThroughDate: string;
  dueDate: string;
  premium: string;
  initialDueDate: string;
  initialPaymentAmount: string;
  eligibilityEndDate: string;
  inactivationDate: string;
  conversionContactName?: string;
  conversionContactInfo?: string;
  startDate?: string;
}

export interface Address {
  id?: string;
  addressType: AddressType;
  addressLine1: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  country: string;
  zip?: string;
  zipplusfour?: string;
}

export interface AdvanceSetup extends EntityBase {
  benefitPlans: string[];
  advanceFeatureId: string;
  maxAdvanceAmount: number;
  contributionPosted: boolean;
  contributionPostingId?: string;
  effectiveDate?: string;
  newMaxAdvanceAmount?: number;
  paymentSourceId: string;
}

export interface ApiKey extends EntityBase {
  accessKey?: string;
  secret?: string;
  inactivateDate?: string;
  notes?: string;
}

export interface CcxEnrollment extends EntityBase {
  selectCarrierId: string;
  groupId: string[];
  ccxMemberId: string;
  startDate: string;
  endDate?: string;
  clientId?: string;
}

export interface ClientDivision extends EntityBase {
  clientId: string;
  divisions?: Division[];
}

export interface ClientServiceProfile extends EntityBase {
  contractStartDate?: string;
  contractEndDate?: string;
  totalEmployees?: number;
  totalEmployeesParticipating?: number;
  totalEligibleEmployees?: number;
  clientAccountExecutive?: string;
  clientMinimumAvailableBalance?: number;
  clientRelationshipProfile?: string;
  isAcquisition?: boolean;
  acquisitionMethod?: AcquisitionMethodType;
  acquisitionName?: string;
  acquisitionNames?: string[];
  scheduledMigrationDate?: string;
  liveDate?: string;
  sentCommunications?: SentCommunication[];
  accountManagerId?: string;
}

export interface ClientServiceProfilePriority extends EntityBase {
  totalScore: number;
  clientType?: PriorityClientType;
  clientTypeScore?: number;
  isVIC?: boolean;
  vicScore?: number;
  isTenuous?: boolean;
  tenuousCreatedBy?: string;
  tenuousDuration?: number;
  tenuousStartDate?: string;
  tenuousEndDate?: string;
  tenuousScore?: number;
  isSLA?: boolean;
  slaScore?: number;
  isServicePackage?: boolean;
  purchasedDate?: string;
  servicePackageScore?: number;
}

export interface ClientServiceProfileTenuousRecord extends EntityBase {
  startDate: string;
  endDate: string;
  duration: number;
  score: number;
}

export interface ClientSpecialHandlingNote extends EntityBase {
  text: string;
  accountOfferingId?: string;
  accountOfferingName?: string;
  serviceFunction?: ServiceFunctionType;
}

export interface Customization extends EntityBase {
  fromEmail?: string;
  logoAttachmentId?: string;
  brandingLogoId?: string;
  brandingEnabled?: boolean;
}

export type DepletionOrder = string[];

export interface Device extends EntityBase {
  token: string;
  deviceOSType?: DeviceOSType;
}

export interface DistributorClient extends EntityBase {
  clientId: string;
}

export interface Division extends EntityBase {
  name: string;
  subdivisions?: Subdivisions;
  shippingAddressId?: string;
  billingAddressId?: string;
  billingContactId?: string;
  primaryContactId?: string;
}

export interface Document extends EntityBase {
  name: string;
  documentType?: string;
  category: DocumentCategoryType;
  effectiveDate?: string;
  documentVersion?: string;
  documentAttachmentId?: string;
  description?: string;
  notes?: string;
  accountOffering?: string;
  source?: DocumentSourceType;
  displayToEmployees?: boolean;
  linkedBenefitPlanIds?: string[];
  letterServiceDocumentId?: string;
  letterServiceRequestList?: string[];
  firstMailDate?: string;
  lastMailDate?: string;
  eligibilityEventId?: string;
  dependentId?: string;
  communicationRequestId?: string;
  communicationTemplateId?: string;
}

export interface DocumentDistribution extends EntityBase {
  distributionType: DistributionType;
  distributionId?: string;
}

export interface PublishedDocument {
  id?: string;
  parentId?: string;
  parentType?: ParentType;
  createdBy?: string;
  createdById?: string;
  created?: string;
  updatedBy?: string;
  updatedById?: string;
  updated?: string;
  docState?: string;
  docLastTransition?: string;
  name?: string;
  documentType?: string;
  documentVersion?: string;
  category?: string;
  effectiveDate?: string;
  documentAttachmentId?: string;
  description?: string;
  externalWorkflowId?: string;
  eventNotes?: string;
  transactionLockId?: string;
  notes?: string;
  accountOffering?: string;
  source?: string;
  docDistId?: string;
  distributionParentId?: string;
  distributionCreated?: string;
  distributionCreatedBy?: string;
  distributionCreatedById?: string;
  distributionCurrentState?: string;
  distributionId?: string;
  distributionType?: string;
}

export interface DocumentUploader {
  parentId?: string;
  uploader?: string;
  currentState?: string;
}

export type EmploymentHistory = EmploymentInfo[];

export interface EmployeeEligibilityClass extends EntityBase {
  clientClassId: number;
  effectiveDate: string;
  individualId: string;
  clientId: string;
}

export interface EmploymentInfo extends EntityBase {
  individualId: string;
  nonEmployee?: boolean;
  relatedEmployeeName?: string;
  relationToEmployee?: RelationshipType;
  relatedToId?: string;
  employeeId?: string;
  externalId?: string;
  primaryEmail?: string;
  hireDate: string;
  clientExternalId?: string;
  clientName?: string;
  individualName?: string;
  divisionId?: string;
  eligibilityClassId?: number;
  payrollScheduleName?: string;
  clientPayrollId?: string;
  subdivisionId?: string;
  terminationDate?: string;
  retired?: boolean;
  leavesOfAbsence?: LeaveOfAbsence[];
  hasLeavesOfAbsence?: number;
  claimConnexMemberId?: string;
  occupationTitle?: string;
  wage?: number;
  wageFrequency?: WageFrequencyType;
  averageHoursWorked?: number;
  hdhpCoverageStartDate?: string;
  hdhpCoverageEndDate?: string;
  healthPlanMemberId?: string;
  trackingDesignation?: string;
}

export interface FundsTransferCriterion extends EntityBase {
  sourceId?: string;
  sourceType?: SourceType;
  sourcePaymentDetails?: PaymentDetails;
  destinationId?: string;
  destinationType?: DestinationType;
  amount?: number;
  frequency: FundsTransferFrequencyType;
  balance?: number;
  dayOfMonth?: number;
  startDate: string;
  endDate?: string;
  lastEntryDate?: string;
  nextEntryDate?: string;
  transferNote?: string;
  specialDisbursementType?: SpecialDisbursementType;
  specialContributionType?: SpecialContributionType;
  benefitPlanId?: string;
  benefitAccountId?: string;
  taxYear?: string;
  fundsTransferType?: FundsTransferType;
  quarterlyStartMonth?: number;
  convenienceFee?: number;
}

export type FundsTransferCriteria = FundsTransferCriterion[];

export interface IndividualServiceProfile extends EntityBase {
  isVIP?: boolean;
  vipScore?: number;
}

export interface LeaveOfAbsence {
  startDate: string;
  returnDate?: string;
  leaveOfAbsenceType?: LeaveOfAbsenceType;
}

export interface PaymentDetails {
  checkDate?: string;
  checkNumber?: string;
  transactionId?: string;
  paymentId?: string;
  dateReceived?: string;
  amountReceived?: number;
  externalMemo?: string;
}

export interface Payroll extends EntityBase {
  name: string;
  frequency: PayrollFrequencyType;
  otherFrequency?: string;
}

export interface PayrollSchedule extends EntityBase {
  payrollId: string;
  payrollDates: string[];
  weeklyPayday?: number;
  monthlyPayday?: number;
  firstSemiMonthlyPayday?: number;
  secondSemiMonthlyPayday?: number;
  biWeeklyPaydate?: string;
  paydayOnLastDayOfMonth?: boolean;
  startDate?: string;
  endDate?: string;
  payPeriodStartOrEnd?: PayPeriodStartOrEndType;
}

export interface OfferingsEligibleData {
  offeringId: string;
  eventProfileSelections?: EventProfileSelection[];
  paymentPlanName: string;
  paymentPlanId: string;
  eligibleOfferingPriceData?: EligibleOfferingPriceData[];
  eligibilityStartDate: string;
  eligibilityEndDate?: string;
  lossOfCoverageDate?: string;
  fullAmountAdjusted?: boolean;
  planGracePeriodAfterElection?: number;
  offerSentDate?: string;
  offerResponseDate?: string;
  offerStartDate?: string;
  offerEndDate?: string;
  fullAdjustmentEndDate?: string;
  electionDueDate?: string;
  reviewErrors?: ReviewErrorType[];
  coveredPersons?: CoveredPerson[];
  conversionContact?: ConversionContact;
}

export interface SentCommunication {
  date?: string;
}

export interface Subdivision extends EntityBase {
  name: string;
}

export interface DocumentAction extends EntityBase {
  type: DocumentActionType;
  documentId: string;
  username: string;
  userType: DocumentActionUserType;
}

export interface ClientAccountManagement extends EntityBase {
  name: string;
  phoneExtension: number;
  tpaPartner: TPAPartnerType;
}

export interface MspReportingRequest extends EntityBase {
  quarterName?: string;
  reportingPeriodStart?: string;
  reportingPeriodEnd?: string;
  reportingContext?: MspReportingRequestContext;
}

export interface MspReportingRequestContext {
  reportingOldestDate?: string;
  reportingDate?: string;
  RRE_ID?: string;
  RRE_TIN?: string;
  reportingStartDate?: string;
  reportingEndDate?: string;
  dob45yo?: string;
  reportingQuarter?: string;
  jobId?: string;
  priorReportingContext?: object;
  mspFileIds?: string[];
}

export interface MspReportingFile extends EntityBase {
  fileType: MspReportingFileType;
  fileName: string;
  s3Location?: string;
  headerTrailerRreId?: string;
  headerTrailerFileType?: string;
  headerTrailerFileDate?: string;
  trailerRecordCount?: number;
}

export interface MspReportingFileUrl {
  url?: string;
}

export interface MspInputFileLine extends EntityBase {
  medicareId?: string;
  beneficiarySurname?: string;
  beneficiaryFirstInitial?: string;
  beneficiaryDateOfBirth?: string;
  beneficiarySexCode?: string;
  dcn?: string;
  transactionType?: string;
  coverageType?: string;
  beneficiarySocialSecurityNumber?: string;
  effectiveDate?: string;
  terminationDate?: string;
  relationshipCode?: string;
  policyHolderFirstName?: string;
  policyHolderLastName?: string;
  policyHolderSsn?: string;
  employerSize?: string;
  groupPolicyNumber?: string;
  individualPolicyNumber?: string;
  employeeCoverageElection?: string;
  employeeStatus?: string;
  employerTin?: string;
  insurerTpaTin?: string;
  nationalHealthPlan?: string;
  rxInsuredIdNumber?: string;
  rxGroupNumber?: string;
  rxPcn?: string;
  rxBinNumber?: string;
  rxTollFreeNumber?: string;
  personCode?: string;
  smallEmployerExceptionMedicareId?: string;
  overrideCode?: string;
  benefitPlanId?: string;
  benefitAccountId?: string;
  aciType?: string;
  aciId?: string;
  aciParentId?: string;
  clientId?: string;
  lockedByCMS?: boolean;
  lastAcceptedAddInputLineId?: string;
  lastAcceptedAddResponseLineId?: string;
  lastAcceptedUpdateInputLineId?: string;
  lastAcceptedUpdateResponseLineId?: string;
}

export interface MspResponseFileLine extends EntityBase {
  rreAssignedDcn?: string;
  transactionType?: string;
  coverageType?: string;
  originalCoverageEffectiveDate?: string;
  originalCoverageTerminationDate?: string;
  bcrcDcn?: string;
  medicareId?: string;
  entitlementReason?: string;
  mspEffectiveDate?: string;
  mspTerminationDate?: string;
  splitEntitlementIndicator?: string;
  lateSubmissionIndicator?: string;
  dispositionCode?: string;
  dispositionDate?: string;
  errorCode1?: string;
  errorCode2?: string;
  errorCode3?: string;
  errorCode4?: string;
  rxDispositionCode?: string;
  rxDispositionDate?: string;
  rxErrorCode1?: string;
  rxErrorCode2?: string;
  rxErrorCode3?: string;
  rxErrorCode4?: string;
  beneficiarySurname?: string;
  beneficiaryFirstInitial?: string;
  beneficiaryDateOfBirth?: string;
  beneficiarySexCode?: string;
  relationshipCode?: string;
  medicareBeneficiaryDateOfDeath?: string;
}

export interface EligibilityEvent extends EntityBase {
  eventType: string;
  eventDate: string;
  effectiveDate?: string;
  incurredByProfileId: string;
  incurredById?: string;
  clientId: string;
  paymentAccountGroupId?: string;
  electionDueDate?: string;
  offerSentDate?: string;
  firstResponseDate?: string;
  lastResponseDate?: string;
  hasTakeover?: boolean;
  takeoverPaidThroughDate?: string;
  hideFrom?: HideFromType;
  originalEligibilityEventId?: string;
  modificationType?: string;
  replacesEEId?: string;
  sendOfferLetter?: boolean;
}

export interface EnrichableEligibilityEvent extends EligibilityEvent {
  meta?: EligibilityEventMeta;
}

export type EnrichableEligibilityEvents = EnrichableEligibilityEvent[];

export interface EligibilityEventMeta {
  eligibilityStartDate: string;
  eligibilityEndDate?: string;
  eventSummaryState?: EventSummaryStateType;
  inactivationHold?: string;
  isModification: boolean;
}

export interface EligibleEligibilityEvent {
  eligibilityEventId: string;
  paymentAccountGroupId?: string;
  readOnly: boolean;
  eventType: string;
  eventDate: string;
  incurredByName?: string;
  offeringsEligibleData: OfferingsEligibleData[];
  eventProfiles?: EventProfileData[];
  clientId?: string;
  individualId?: string;
  electionDueDate: string;
  lastDayToPayInitialObligations?: string;
  eligibilityEventState: string;
  offerSentDate?: string;
  firstResponseDate?: string;
  lastResponseDate?: string;
  earliestElectionDueDate?: string;
  serviceOfferingId?: string;
  displayName?: string;
}

export interface EligibilityEventOffering extends EntityBase {
  paymentPlanId: string;
  individualId: string;
  includedParties?: string[];
  lossOfCoverageDate?: string;
  eligibilityStartDate: string;
  eligibilityEndDate?: string;
  externalNotes?: string;
  reviewErrors?: ReviewErrorType[];
  pricingModelId?: string;
  pricingOptionTypes?: PricingOptionType[];
  fsaBenefitAccountId?: string;
  fsaAnnualElectionAmount?: number;
  fsaFundingYtd?: number;
  fsaDisbursementsYtd?: number;
}

export interface EligibilityEventElectionData {
  planId: string;
  offerResponseDate: string;
  electedProfileIds: string[];
}

export interface ConversionContact {
  carrierName: string;
  contactInfo: string;
}

export interface CoveredPerson {
  includedEventOfferingParty?: object;
  fullName?: string;
  relationshipType?: RelationshipType;
  primaryAddress?: Address;
  addressSameAsIndividual?: boolean;
  dateOfBirth?: string;
  profileType?: ProfileType;
  profileId?: string;
}

export interface EventProfileData {
  eventProfileId?: string;
  fullName?: string;
  relationshipType?: RelationshipType;
  primaryAddress?: Address;
  addressSameAsIndividual?: boolean;
  dateOfBirth?: string;
  profileType?: ProfileType;
  profileId?: string;
}

export interface EventProfileSelection {
  eventProfileId?: string;
  elected?: boolean;
  available?: boolean;
  fullName?: string;
  isIndividual?: boolean;
}

export interface EligibleOfferingPriceData {
  fullAmount?: number;
  adjustmentAmount?: number;
  feeAmount?: number;
  basePrice?: number;
  tier?: string;
  firstAdjustedDate?: string;
  lastAdjustedDate?: string;
  adjustedForFullDuration?: boolean;
  populateFullAdjustmentEndDate?: boolean;
  firstFullPaymentDate?: string;
}

export interface OfferingPrice extends EntityBase {
  tier?: string;
  basePrice: number;
  priceType?: string;
  feeAmount?: number;
  feePercentage?: number;
  feeType?: FeeType;
  feeRoundingType?: RoundingType;
  amountDue?: number;
  startMonthProrated?: number;
  endMonthProrated?: number;
  priceKey: string;
  includeFeesInCalculation?: boolean;
  includeSurchargeInCalculation?: boolean;
  adjustmentAmount?: number;
  adjustmentPercentage?: number;
  adjustmentCalculation?: AdjustmentCalculation;
  adjustmentDurationMonths?: number;
  adjustmentEndDate?: string;
  adjustmentDurationType?: AdjustmentDurationType;
  pricingEffectiveDate: string;
  pricingModelId: string;
  previousOfferingPriceId?: string;
  surcharges?: Surcharge[];
}

export interface FilteredOfferingPriceResponse {
  offeringPrice?: OfferingPrice;
  excessCoverage?: boolean;
  profileIds?: string[];
}

export interface ConvenienceFee {
  feeAmount?: number;
}

export interface ConvenienceFeeCalculationDefinition {
  paymentMethodType: ConvenienceFeePaymentType;
  calculationType: ConvenienceFeeCalculationType;
  amount: number;
}

export interface EligibilityEventOfferingEnriched extends EligibilityEventOffering {
  fsaMeta?: EligibilityEventOfferingFsaMeta;
  planPayees?: PlanPayeeInfo[];
}

export interface EligibilityEventOfferingFsaMeta {
  managedByTpa?: boolean;
  benefitAccountId?: string;
  electionAmount?: number;
  fundingYtd?: number;
  disbursementsYtd?: number;
}

export interface FundsTransferOptions {
  Disbursements?: FundsTransferGroupOption[];
  FeePayments?: FundsTransferGroupOption[];
  Contributions?: FundsTransferGroupOption[];
  SpecialContributions?: FundsTransferGroupOption[];
  Adjustments?: FundsTransferGroupOption[];
  Payments?: FundsTransferGroupOption[];
  FeeSweeps?: FundsTransferGroupOption[];
  BinSweeps?: FundsTransferGroupOption[];
}

export interface FundsTransferGroupOption {
  fundsTransferType?: FundsTransferType;
  specialDisbursementTypes?: SpecialDisbursementType[];
  specialContributionType?: string;
  fundingSourceType?: FundingSourceType;
  sourceName?: string;
  sourceId?: string;
  sourceType?: SourceType;
  destinationType?: DestinationType;
  destinationName?: string;
  destinationId?: string;
  achSecCodeIndicator?: string;
  achFrequencyIndicator?: string;
  fundsTransferGroup?: FundsTransferGroup;
  paymentSourceType?: string;
  fundsTransferFrequencyTypes?: FundsTransferFrequencyType[];
  benefitPlanId?: string;
  parentType?: string;
}

export interface BenefitAccountCategory {
  benefitPlanId?: string;
  benefitAccountId?: string;
  name?: string;
  disburseWithoutRequest?: boolean;
  giveBackAccount?: boolean;
  benefitAccountType?: string;
  optForCobraCoverage?: boolean;
  fundingSource?: string;
  secondaryBureauId?: string;
  benefitPlanPaymentSourceId?: string;
  isOpenEnded?: boolean;
  hasPODorBudgetedFunding?: boolean;
  benefitAccountState?: string;
  specialDisbursementTypes?: string[];
  specialContributionTypes?: string[];
  participantFundingMethods?: string[];
  contributionPosting?: string;
  hasActiveOpenEndedSchedule?: boolean;
}

export interface PaymentPlanRemittanceInfo {
  individualId?: string;
  paymentAccountId?: string;
  clientID?: string;
  paymentAccountGroupId?: string;
  obligationId?: string;
  lineItemId?: string;
  tier?: string;
  paymentPlanId?: string;
  planName?: string;
  obligationState?: string;
  lineItemAmount?: number;
  basePrice?: number;
  feeAmount?: number;
  planPayeeInfos?: PlanPayeeInfo[];
  surcharges?: Surcharge[];
  amountType?: ObligationAmountType;
}

export interface PlanPayeeInfo {
  id?: string;
  payeeId?: string;
  percentType?: PercentType;
  percent?: number;
  percentSubtype?: string;
  payeeType?: string;
  payeeName?: string;
}

export interface Surcharge {
  amount: number;
  surchargeType: string;
  calculationMethod: FeeType;
  percentage?: number;
}

export interface PayeeDistributionDetail {
  remittancePaymentMethodAccountId?: string;
  planId?: string;
  payeeId?: string;
  paymentSourceId?: string;
  payeeName?: string;
}

export interface Xps2Migration extends EntityBase {
  groupId: number;
  cardPreorderDetails?: CardPreorderDetails;
  cardSyncDetails?: CardSyncDetails;
  xps1CardBlockDetails?: Xps1CardBlockDetails;
  xps2CardUnblockDetails?: Xps2CardUnblockDetails;
}

export interface CardPreorderDetails {
  startDate?: string;
  completedDate?: string;
  xps1ActiveCardCount?: number;
  xps2PreorderCardCount?: number;
  error?: string;
}

export interface CardSyncDetails {
  startDate?: string;
  completedDate?: string;
  xps2OrderCardCount?: number;
  xps1BlockedWithoutFraudToClosed?: number;
  xps1BlockedWithFraudToClosed?: number;
  xps2BlockedWithoutFraudToClosedCount?: number;
  error?: string;
}

export interface Xps1CardBlockDetails {
  startDate?: string;
  completedDate?: string;
  xps1ActiveToClosedCount?: number;
  xps1CardClosingCount?: number;
  error?: string;
}

export interface Xps2CardUnblockDetails {
  startDate?: string;
  completedDate?: string;
  xps2BlockedWithoutFraudToActiveCount?: number;
  xps2ActivatingCardCount?: number;
  error?: string;
}

export interface Criteria {
  key?: string;
  value?: string;
  matchType?: MatchType;
  chainType?: ChainType;
  groupCriteria?: GroupCriteria[];
}

export interface Error {
  message?: string;
  fields?: object;
}

export interface ErrorBody {
  errors?: object;
}

export interface GroupCriteria {
  key?: string;
  value?: string;
  matchType?: MatchType;
  chainType?: ChainType;
  groupCriteria?: GroupCriteria[];
}

export interface ConvenienceFeeCriteria {
  convenienceFeePaymentType: ConvenienceFeePaymentType;
  paymentAmount: number;
  clientId?: string;
  parentId?: string;
}

export interface PingResponse {
  serverTime: ServerTime;
  services?: Service[];
}

export type SearchCriteria = Criteria[];

export interface Service {
  name?: string;
  serverTime?: string;
}

export interface DisplayLetter extends Document {
  sentTo?: string;
}

export type ServerTime = string;

export type AdvanceSetups = AdvanceSetup[];

export type ApiKeys = ApiKey[];

export type CcxEnrollments = CcxEnrollment[];

export type ClientDivisions = ClientDivision[];

export type ClientServiceProfiles = ClientServiceProfile[];

export type ClientServiceProfilePriorities = ClientServiceProfilePriority[];

export type ClientServiceProfileTenuousRecords = ClientServiceProfileTenuousRecord[];

export type ClientSpecialHandlingNotes = ClientSpecialHandlingNote[];

export type Customizations = Customization[];

export type Devices = Device[];

export type DistributorClients = DistributorClient[];

export type Divisions = Division[];

export type Documents = Document[];

export type DocumentDistributions = DocumentDistribution[];

export type DocumentUploaders = DocumentUploader[];

export type EmployeeEligibilityClasses = EmployeeEligibilityClass[];

export type IndividualServiceProfiles = IndividualServiceProfile[];

export type MspReportingRequests = MspReportingRequest[];

export type MspReportingFiles = MspReportingFile[];

export type MspInputFileLines = MspInputFileLine[];

export type MspResponseFileLines = MspResponseFileLine[];

export type Payrolls = Payroll[];

export type PayrollSchedules = PayrollSchedule[];

export type PublishedDocuments = PublishedDocument[];

export type Subdivisions = Subdivision[];

export type DocumentActions = DocumentAction[];

export interface CommandBase {
  id?: string;
  eventCorrelationId?: string;
  jobId?: string;
  producerId?: string;
  sequenceId?: number;
  version?: number;
  type?: string;
  created?: string;
  createdBy?: string;
  createdById?: string;
}

export interface EntityBase {
  id?: string;
  parentId?: string;
  parentType?: ParentType;
  version?: number;
  created?: string;
  createdBy?: string;
  createdById?: string;
  updated?: string;
  updatedBy?: string;
  updatedById?: string;
  currentState?: string;
  lastTransition?: string;
  externalWorkflowId?: string;
  eventNotes?: string;
  transactionLockId?: string;
}

export enum AdvanceSetupCommandType {
  ActiveToActive = 'ActiveToActive',
  ActiveToInactive = 'ActiveToInactive',
  ActiveToOnHold = 'ActiveToOnHold',
  ActiveToRemoved = 'ActiveToRemoved',
  InactiveToActive = 'InactiveToActive',
  OnHoldToActive = 'OnHoldToActive',
  OnHoldToOnHold = 'OnHoldToOnHold',
  StartToActive = 'StartToActive',
}

export enum ApiKeyCommandType {
  StartToActive = 'StartToActive',
  ActiveToActive = 'ActiveToActive',
  ActiveToInactive = 'ActiveToInactive',
  ActiveToRemoved = 'ActiveToRemoved',
  InactiveToRemoved = 'InactiveToRemoved',
}

export enum CcxEnrollmentCommandType {
  ActiveToActive = 'ActiveToActive',
  ActiveToInactive = 'ActiveToInactive',
  ActiveToRemoved = 'ActiveToRemoved',
  InactiveToActive = 'InactiveToActive',
  InactiveToInactive = 'InactiveToInactive',
  InactiveToRemoved = 'InactiveToRemoved',
  StartToActive = 'StartToActive',
  StartToInactive = 'StartToInactive',
}

export enum ClientServiceProfileCommandType {
  StartToActive = 'StartToActive',
  ActiveToActive = 'ActiveToActive',
  ActiveToInactive = 'ActiveToInactive',
}

export enum ClientServiceProfilePriorityCommandType {
  StartToActive = 'StartToActive',
  ActiveToActive = 'ActiveToActive',
}

export enum ClientServiceProfileTenuousRecordCommandType {
  StartToActive = 'StartToActive',
  ActiveToActive = 'ActiveToActive',
  ActiveToInactive = 'ActiveToInactive',
}

export enum ClientSpecialHandlingNoteCommandType {
  StartToActive = 'StartToActive',
  ActiveToActive = 'ActiveToActive',
  ActiveToInactive = 'ActiveToInactive',
}

export enum CustomizationCommandType {
  ActiveToActive = 'ActiveToActive',
  StartToActive = 'StartToActive',
}

export enum DeviceCommandType {
  ActiveToActive = 'ActiveToActive',
  ActiveToRemoved = 'ActiveToRemoved',
  StartToActive = 'StartToActive',
}

export enum DistributorClientCommandType {
  StartToActive = 'StartToActive',
  ActiveToActive = 'ActiveToActive',
  ActiveToInactive = 'ActiveToInactive',
  ActiveToRemoved = 'ActiveToRemoved',
  InactiveToActive = 'InactiveToActive',
  InactiveToInactive = 'InactiveToInactive',
  InactiveToRemoved = 'InactiveToRemoved',
}

export enum DivisionCommandType {
  ActiveToActive = 'ActiveToActive',
  ActiveToRemoved = 'ActiveToRemoved',
  StartToActive = 'StartToActive',
}

export enum DocumentCommandType {
  StartToActive = 'StartToActive',
  ActiveToActive = 'ActiveToActive',
  ActiveToArchived = 'ActiveToArchived',
  ActiveToRemoved = 'ActiveToRemoved',
  ArchivedToActive = 'ArchivedToActive',
  ArchivedToRemoved = 'ArchivedToRemoved',
  StartToRequestedDocument = 'StartToRequestedDocument',
  RequestedDocumentToRequestedMail = 'RequestedDocumentToRequestedMail',
  RequestedDocumentToError = 'RequestedDocumentToError',
  StartToRequestedMail = 'StartToRequestedMail',
  RequestedMailToMailed = 'RequestedMailToMailed',
  RequestedMailToError = 'RequestedMailToError',
  MailedToRequestedMail = 'MailedToRequestedMail',
  RequestedDocumentToActive = 'RequestedDocumentToActive',
  RequestedMailToActive = 'RequestedMailToActive',
}

export enum DocumentDistributionCommandType {
  StartToPublished = 'StartToPublished',
  PublishedToPublished = 'PublishedToPublished',
  PublishedToUnpublished = 'PublishedToUnpublished',
  UnpublishedToPublished = 'UnpublishedToPublished',
}

export enum EmployeeEligibilityClassCommandType {
  PendingToPending = 'PendingToPending',
  PendingToProcessed = 'PendingToProcessed',
  PendingToDeleted = 'PendingToDeleted',
  PendingToRemoved = 'PendingToRemoved',
  ProcessedToDeleted = 'ProcessedToDeleted',
  ProcessedToRemoved = 'ProcessedToRemoved',
  ProcessedToPending = 'ProcessedToPending',
  StartToPending = 'StartToPending',
  PendingToPendingDelete = 'PendingToPendingDelete',
  ProcessedToPendingDelete = 'ProcessedToPendingDelete',
  PendingDeleteToDeleted = 'PendingDeleteToDeleted',
}

export enum EmploymentInfoCommandType {
  ActiveToActive = 'ActiveToActive',
  ActiveToInactive = 'ActiveToInactive',
  ActiveToLeaveOfAbsence = 'ActiveToLeaveOfAbsence',
  ActiveToRemoved = 'ActiveToRemoved',
  ActiveToRetired = 'ActiveToRetired',
  ActiveToTerminated = 'ActiveToTerminated',
  InactiveToActive = 'InactiveToActive',
  InactiveToInactive = 'InactiveToInactive',
  LeaveOfAbsenceToActive = 'LeaveOfAbsenceToActive',
  LeaveOfAbsenceToInactive = 'LeaveOfAbsenceToInactive',
  LeaveOfAbsenceToRetired = 'LeaveOfAbsenceToRetired',
  LeaveOfAbsenceToTerminated = 'LeaveOfAbsenceToTerminated',
  LeaveOfAbsenceToLeaveOfAbsence = 'LeaveOfAbsenceToLeaveOfAbsence',
  RemovedToActive = 'RemovedToActive',
  RetiredToActive = 'RetiredToActive',
  StartToActive = 'StartToActive',
  StartToLeaveOfAbsence = 'StartToLeaveOfAbsence',
  StartToRetired = 'StartToRetired',
  StartToTerminated = 'StartToTerminated',
  TerminatedToActive = 'TerminatedToActive',
  ProcessOnHoldToRemoveHold = 'ProcessOnHoldToRemoveHold',
}

export enum FundsTransferCriterionCommandType {
  ActiveToActive = 'ActiveToActive',
  ActiveToProcessed = 'ActiveToProcessed',
  StartToActive = 'StartToActive',
  ActiveToCancelled = 'ActiveToCancelled',
  StartToProcessed = 'StartToProcessed',
  StartToPending = 'StartToPending',
  PendingToActive = 'PendingToActive',
  PendingToCancelled = 'PendingToCancelled',
}

export enum IndividualServiceProfileCommandType {
  StartToActive = 'StartToActive',
  ActiveToActive = 'ActiveToActive',
  ActiveToInactive = 'ActiveToInactive',
}

export enum PayrollCommandType {
  CreatedToRemoved = 'CreatedToRemoved',
  StartToCreated = 'StartToCreated',
  CreatedToCreated = 'CreatedToCreated',
  CreatedToInUse = 'CreatedToInUse',
  InUseToCreated = 'InUseToCreated',
  InUseToInUse = 'InUseToInUse',
}

export enum PayrollScheduleCommandType {
  ActiveToActive = 'ActiveToActive',
  ActiveToInactive = 'ActiveToInactive',
  InactiveToActive = 'InactiveToActive',
  InactiveToInactive = 'InactiveToInactive',
  InactiveToRemoved = 'InactiveToRemoved',
  StartToActive = 'StartToActive',
  ActiveToRemoved = 'ActiveToRemoved',
}

export enum DocumentActionCommandType {
  StartToActive = 'StartToActive',
  ActiveToActive = 'ActiveToActive',
  ActiveToRemoved = 'ActiveToRemoved',
}

export enum ClientAccountManagementCommandType {
  StartToActive = 'StartToActive',
  ActiveToActive = 'ActiveToActive',
  ActiveToRemoved = 'ActiveToRemoved',
}

export enum MspReportingRequestCommandType {
  AnyToError = 'AnyToError',
  StartToRequested = 'StartToRequested',
  RequestedToPhase1 = 'RequestedToPhase1',
  Phase1ToPendingPhase2 = 'Phase1ToPendingPhase2',
  PendingPhase2ToPhase2 = 'PendingPhase2ToPhase2',
  Phase2ToPendingPhase3 = 'Phase2ToPendingPhase3',
  PendingPhase3ToPhase3 = 'PendingPhase3ToPhase3',
  Phase3ToReady = 'Phase3ToReady',
  ReadyToPendingCmsResponse = 'ReadyToPendingCmsResponse',
  PendingCmsResponseToProcessingCmsResponse = 'PendingCmsResponseToProcessingCmsResponse',
  PreProcessingErrorToProcessingCmsResponse = 'PreProcessingErrorToProcessingCmsResponse',
  ProcessingCmsResponseToQuarterComplete = 'ProcessingCmsResponseToQuarterComplete',
  ProcessingCmsResponseToPreProcessingError = 'ProcessingCmsResponseToPreProcessingError',
  ProcessingCmsResponseToProcessingError = 'ProcessingCmsResponseToProcessingError',
}

export enum MspReportingFileCommandType {
  AnyToError = 'AnyToError',
  AnyToRemoved = 'AnyToRemoved',
  StartToPendingUpload = 'StartToPendingUpload',
  PendingUploadToUploaded = 'PendingUploadToUploaded',
  UploadedToReadyForProcessing = 'UploadedToReadyForProcessing',
  ReadyForProcessingToProcessing = 'ReadyForProcessingToProcessing',
  ProcessingToProcessed = 'ProcessingToProcessed',
  StartToPreparing = 'StartToPreparing',
  PreparingToReady = 'PreparingToReady',
  ReadyToDownloaded = 'ReadyToDownloaded',
}

export enum MspResponseFileLineCommandType {
  AnyToError = 'AnyToError',
  StartToCreated = 'StartToCreated',
  CreatedToProcessed = 'CreatedToProcessed',
}

export enum EligibilityEventCommandType {
  StartToDraft = 'StartToDraft',
  DraftToDraft = 'DraftToDraft',
  DraftToOffered = 'DraftToOffered',
  DraftToIneligible = 'DraftToIneligible',
  IneligibleToRemoved = 'IneligibleToRemoved',
  OfferedToOffered = 'OfferedToOffered',
  OfferedToResponded = 'OfferedToResponded',
  OfferedToExpired = 'OfferedToExpired',
  AnyToProfileSynchronized = 'AnyToProfileSynchronized',
  StartToModified = 'StartToModified',
  DraftToModified = 'DraftToModified',
  ModifiedToModified = 'ModifiedToModified',
  DraftToRemoved = 'DraftToRemoved',
  OfferedToRemoved = 'OfferedToRemoved',
  ExpiredToRemoved = 'ExpiredToRemoved',
  RespondedToRemoved = 'RespondedToRemoved',
  ModifiedToRemoved = 'ModifiedToRemoved',
  ModifiedToReplaced = 'ModifiedToReplaced',
  ReplacedToRemoved = 'ReplacedToRemoved',
  AnyToNeedsReview = 'AnyToNeedsReview',
  NeedsReviewToOffered = 'NeedsReviewToOffered',
  NeedsReviewToModified = 'NeedsReviewToModified',
  NeedsReviewToRemoved = 'NeedsReviewToRemoved',
  NeedsReviewToNeedsReview = 'NeedsReviewToNeedsReview',
}

export enum EligibilityEventOfferingCommandType {
  StartToStaged = 'StartToStaged',
  StartToNeedsReview = 'StartToNeedsReview',
  StagedToStaged = 'StagedToStaged',
  StagedToRejected = 'StagedToRejected',
  StagedToIncluded = 'StagedToIncluded',
  IncludedToIncluded = 'IncludedToIncluded',
  IncludedToStaged = 'IncludedToStaged',
  IncludedToNeedsReview = 'IncludedToNeedsReview',
  OverspentToNeedsReview = 'OverspentToNeedsReview',
  NeedsReviewToStaged = 'NeedsReviewToStaged',
  NeedsReviewToIncluded = 'NeedsReviewToIncluded',
  NeedsReviewToNeedsReview = 'NeedsReviewToNeedsReview',
  StagedToOverspent = 'StagedToOverspent',
  RejectedToStaged = 'RejectedToStaged',
  StagedToRemoved = 'StagedToRemoved',
  IncludedToRemoved = 'IncludedToRemoved',
  RejectedToRemoved = 'RejectedToRemoved',
  NeedsReviewToRemoved = 'NeedsReviewToRemoved',
  OverspentToRemoved = 'OverspentToRemoved',
  NeedsReviewToRejected = 'NeedsReviewToRejected',
}

export enum OfferingPriceCommandType {
  StartToIncluded = 'StartToIncluded',
  IncludedToIncluded = 'IncludedToIncluded',
  IncludedToRejected = 'IncludedToRejected',
  RejectedToIncluded = 'RejectedToIncluded',
  RejectedToRejected = 'RejectedToRejected',
  IncludedToRemoved = 'IncludedToRemoved',
  RejectedToRemoved = 'RejectedToRemoved',
}

export enum AdvanceSetupState {
  Active = 'Active',
  Inactive = 'Inactive',
  OnHold = 'OnHold',
  Removed = 'Removed',
  Start = 'Start',
}

export enum ApiKeyState {
  Active = 'Active',
  Inactive = 'Inactive',
  Removed = 'Removed',
}

export enum CcxEnrollmentState {
  Active = 'Active',
  Inactive = 'Inactive',
  Removed = 'Removed',
  Start = 'Start',
}

export enum ClientServiceProfileState {
  Active = 'Active',
  Inactive = 'Inactive',
  Start = 'Start',
}

export enum ClientServiceProfilePriorityState {
  Active = 'Active',
  Start = 'Start',
}

export enum ClientServiceProfileTenuousRecordState {
  Active = 'Active',
  Inactive = 'Inactive',
  Start = 'Start',
}

export enum ClientSpecialHandlingNoteState {
  Active = 'Active',
  Inactive = 'Inactive',
  Start = 'Start',
}

export enum CustomizationState {
  Active = 'Active',
  Start = 'Start',
}

export enum DeviceState {
  Active = 'Active',
  Removed = 'Removed',
  Start = 'Start',
}

export enum DistributorClientState {
  Active = 'Active',
  Inactive = 'Inactive',
  Removed = 'Removed',
}

export enum DivisionState {
  Active = 'Active',
  Removed = 'Removed',
  Start = 'Start',
}

export enum DocumentState {
  Active = 'Active',
  Error = 'Error',
  RequestedMail = 'RequestedMail',
  RequestedDocument = 'RequestedDocument',
  Mailed = 'Mailed',
  Removed = 'Removed',
  Archived = 'Archived',
}

export enum DocumentDistributionState {
  Start = 'Start',
  Published = 'Published',
  Unpublished = 'Unpublished',
}

export enum EmployeeEligibilityClassState {
  Pending = 'Pending',
  Processed = 'Processed',
  Deleted = 'Deleted',
  Removed = 'Removed',
  PendingDelete = 'PendingDelete',
}

export enum EmploymentInfoState {
  Active = 'Active',
  Inactive = 'Inactive',
  LeaveOfAbsence = 'LeaveOfAbsence',
  Removed = 'Removed',
  Retired = 'Retired',
  Start = 'Start',
  Terminated = 'Terminated',
  ProcessOnHold = 'ProcessOnHold',
  RemoveHold = 'RemoveHold',
}

export enum FundsTransferCriterionState {
  Active = 'Active',
  Cancelled = 'Cancelled',
  Completed = 'Completed',
  Error = 'Error',
  Processed = 'Processed',
  ErrorPendingRetry = 'ErrorPendingRetry',
  Pending = 'Pending',
}

export enum IndividualServiceProfileState {
  Active = 'Active',
  Inactive = 'Inactive',
  Start = 'Start',
}

export enum PayrollState {
  Created = 'Created',
  InUse = 'InUse',
  Removed = 'Removed',
  Start = 'Start',
}

export enum PayrollScheduleState {
  Active = 'Active',
  Inactive = 'Inactive',
  Removed = 'Removed',
  Start = 'Start',
}

export enum DocumentActionState {
  Active = 'Active',
  Removed = 'Removed',
}

export enum ClientAccountManagementState {
  Active = 'Active',
  Start = 'Start',
  Removed = 'Removed',
}

export enum MspReportingRequestState {
  Removed = 'Removed',
  Error = 'Error',
  Requested = 'Requested',
  Phase1 = 'Phase1',
  PendingPhase2 = 'PendingPhase2',
  Phase2 = 'Phase2',
  PendingPhase3 = 'PendingPhase3',
  Phase3 = 'Phase3',
  Ready = 'Ready',
  PendingCmsResponse = 'PendingCmsResponse',
  ProcessingCmsResponse = 'ProcessingCmsResponse',
  QuarterComplete = 'QuarterComplete',
  PreProcessingError = 'PreProcessingError',
  ProcessingError = 'ProcessingError',
}

export enum MspReportingFileState {
  Error = 'Error',
  Removed = 'Removed',
  PendingUpload = 'PendingUpload',
  Uploaded = 'Uploaded',
  ReadyForProcessing = 'ReadyForProcessing',
  Processing = 'Processing',
  Processed = 'Processed',
  Preparing = 'Preparing',
  Ready = 'Ready',
  Downloaded = 'Downloaded',
}

export enum MspInputFileLineState {
  Active = 'Active',
  NotSent = 'NotSent',
  Removed = 'Removed',
}

export enum MspTinInputFileLineState {
  Active = 'Active',
  Removed = 'Removed',
}

export enum MspResponseFileLineState {
  Created = 'Created',
  Error = 'Error',
  Processed = 'Processed',
  Removed = 'Removed',
}

export enum EligibilityEventState {
  Draft = 'Draft',
  Offered = 'Offered',
  Expired = 'Expired',
  Responded = 'Responded',
  Modified = 'Modified',
  Removed = 'Removed',
  Replaced = 'Replaced',
  NeedsReview = 'NeedsReview',
  ProfileSynchronized = 'ProfileSynchronized',
  Ineligible = 'Ineligible',
}

export enum EligibilityEventOfferingState {
  Staged = 'Staged',
  Included = 'Included',
  Rejected = 'Rejected',
  NeedsReview = 'NeedsReview',
  Overspent = 'Overspent',
  Removed = 'Removed',
}

export enum OfferingPriceState {
  Included = 'Included',
  Rejected = 'Rejected',
  Removed = 'Removed',
}

export enum AcquisitionMethodType {
  New = 'New',
  Purchased = 'Purchased',
}

export enum DeviceOSType {
  Android = 'Android',
  iOS = 'iOS',
}

export enum DistributionType {
  Clients = 'Clients',
  Individuals = 'Individuals',
}

export enum EventSummaryStateType {
  Draft = 'Draft',
  Offered = 'Offered',
  Responded = 'Responded',
  Active = 'Active',
  Declined = 'Declined',
  Expired = 'Expired',
  Cancelled = 'Cancelled',
  Completed = 'Completed',
  Ineligible = 'Ineligible',
}

export enum ParentType {
  CLIENT = 'CLIENT',
  INDIVIDUAL = 'INDIVIDUAL',
  DEPENDENT = 'DEPENDENT',
  BENEFIT_PLAN = 'BENEFIT_PLAN',
  CLIENT_CONTACT = 'CLIENT_CONTACT',
  ELIGIBILITY_EVENT = 'ELIGIBILITY_EVENT',
  ELIGIBILITY_EVENT_OFFERING = 'ELIGIBILITY_EVENT_OFFERING',
  INSTANCE = 'INSTANCE',
  EMPLOYMENT_INFO = 'EMPLOYMENT_INFO',
  DOCUMENT = 'DOCUMENT',
  MSP_REPORTING_REQUEST = 'MSP_REPORTING_REQUEST',
  BUREAU = 'BUREAU',
  MSP_REPORTING_FILE = 'MSP_REPORTING_FILE',
  PAYMENT_PLAN = 'PAYMENT_PLAN',
  DISTRIBUTOR = 'DISTRIBUTOR',
  PAYEE = 'PAYEE',
}

export enum AddressType {
  Primary = 'Primary',
  Billing = 'Billing',
  Shipping = 'Shipping',
  Other = 'Other',
}

export enum WageFrequencyType {
  Annual = 'Annual',
  Hourly = 'Hourly',
  Weekly = 'Weekly',
  BiWeekly = 'Bi-Weekly',
  SemiMonthly = 'Semi-Monthly',
  Monthly = 'Monthly',
}

export enum DestinationType {
  PaymentSource = 'PaymentSource',
  IAB = 'IAB',
  ParticipantDirectFundingBenefitPlan = 'ParticipantDirectFundingBenefitPlan',
  ParticipantDirectFundingInvestmentBenefitAccount = 'ParticipantDirectFundingInvestmentBenefitAccount',
  Check = 'Check',
  BenefitAccount = 'BenefitAccount',
  UnfundedBenefitAccount = 'UnfundedBenefitAccount',
  InvestmentBenefitAccount = 'InvestmentBenefitAccount',
  ParticipantToClientInvestment = 'ParticipantToClientInvestment',
  ClientDirectInvestment = 'ClientDirectInvestment',
  ClientToPlanInvestment = 'ClientToPlanInvestment',
  ParticipantToClient = 'ParticipantToClient',
  ClientDirect = 'ClientDirect',
  ParticipantDirect = 'ParticipantDirect',
  ClientToPlan = 'ClientToPlan',
  Charity = 'Charity',
  BFA = 'BFA',
  CAB = 'CAB',
  PaymentPlan = 'PaymentPlan',
  DesignatedParticipantHoldingAccount = 'DesignatedParticipantHoldingAccount',
  UndesignatedParticipantHoldingAccount = 'UndesignatedParticipantHoldingAccount',
  BureauCashAccount = 'BureauCashAccount',
  Individual = 'Individual',
  RemittanceStagingAccount = 'RemittanceStagingAccount',
  CRA = 'CRA',
  PYAB = 'PYAB',
}

export enum ConvenienceFeePaymentType {
  Card = 'Card',
  ACH = 'ACH',
  Check = 'Check',
}

export enum ConvenienceFeeCalculationType {
  Percentage = 'Percentage',
  Flat = 'Flat',
}

export enum SourceType {
  PaymentSource = 'PaymentSource',
  IAB = 'IAB',
  GiveBackAccount = 'GiveBackAccount',
  CAB = 'CAB',
  CRA = 'CRA',
  PPTAB = 'PPTAB',
  BenefitAccountForDisburseWithoutRequest = 'BenefitAccountForDisburseWithoutRequest',
  DesignatedParticipantHoldingAccount = 'DesignatedParticipantHoldingAccount',
  UndesignatedParticipantHoldingAccount = 'UndesignatedParticipantHoldingAccount',
  BureauFeeAccount = 'BureauFeeAccount',
  ServiceOffering = 'ServiceOffering',
  BureauCashAccount = 'BureauCashAccount',
  BinAccount = 'BinAccount',
}

export enum ServiceFunctionType {
  Audit = 'Audit',
  BAMComplianceFunction = 'BAM Compliance Function',
  BenefitCard = 'Benefit Card',
  ClientAdmin = 'Client Admin',
  ClientInvoicing = 'Client Invoicing',
  EnrollmentandATC = 'Enrollment and ATC',
  FinalizationReconciliation = 'Finalization/Reconciliation',
  Funding = 'Funding',
  InvestmentAdministration = 'Investment Administration',
  Management = 'Management',
  ParticipantIndividualAccount = 'Participant Individual Account',
  ParticipantRequestProcessing = 'Participant Request Processing',
  Reporting = 'Reporting',
  UserAccess = 'User Access',
}

export enum DocumentCategoryType {
  TASC = 'TASC',
  Client = 'Client',
  InternalTemplate = 'InternalTemplate',
}

export enum DocumentSourceType {
  Internal = 'Internal',
  External = 'External',
}

export enum FundsTransferFrequencyType {
  WhenBalanceReached = 'WhenBalanceReached',
  Weekly = 'Weekly',
  BiWeekly26 = 'BiWeekly26',
  Monthly = 'Monthly',
  OneTime = 'OneTime',
  Quarterly = 'Quarterly',
}

export enum LeaveOfAbsenceType {
  FullBenefits = 'FullBenefits',
  NoBenefits = 'NoBenefits',
}

export enum PayrollFrequencyType {
  Weekly = 'Weekly',
  BiWeekly26 = 'BiWeekly26',
  BiWeekly24 = 'BiWeekly24',
  SemiMonthly = 'SemiMonthly',
  Monthly = 'Monthly',
  Other = 'Other',
}

export enum PayPeriodStartOrEndType {
  Start = 'Start',
  End = 'End',
}

export enum PriorityClientType {
  NewTraditional = 'NewTraditional',
  NewACQ = 'NewACQ',
  NotApplicable = 'NotApplicable',
}

export enum SpecialDisbursementType {
  Takeover = 'Takeover',
  ExcessContribution = 'ExcessContribution',
  ProhibitedDisbursement = 'ProhibitedDisbursement',
  Disability = 'Disability',
  Death = 'Death',
  TransferToCustodian = 'TransferToCustodian',
  TransferToParticipant = 'TransferToParticipant',
  Fee = 'Fee',
}

export enum SpecialContributionType {
  FundedRollover = 'FundedRollover',
  UnfundedRollover = 'UnfundedRollover',
  Wellness = 'Wellness',
  Interest = 'Interest',
  Adjustment = 'Adjustment',
  ParticipantRepayment = 'ParticipantRepayment',
  ParticipantRefund = 'ParticipantRefund',
  TransferFromCustodian = 'TransferFromCustodian',
  TransferFromParticipant = 'TransferFromParticipant',
  COBRA = 'COBRA',
  ParticipantCardRefund = 'ParticipantCardRefund',
  ManualRequestRepayment = 'ManualRequestRepayment',
}

export enum DocumentActionType {
  Accepted = 'Accepted',
}

export enum DocumentActionUserType {
  Admin = 'Admin',
  Client = 'Client',
  Participant = 'Participant',
}

export enum TPAPartnerType {
  BASIC = 'BASIC',
  TASC = 'TASC',
}

export enum FundsTransferType {
  ParticipantACHToBenefitAccount = 'ParticipantACHToBenefitAccount',
  ParticipantACHToInvestmentBenefitAccount = 'ParticipantACHToInvestmentBenefitAccount',
  ParticipantCheckToBenefitAccount = 'ParticipantCheckToBenefitAccount',
  ParticipantCheckToInvestmentBenefitAccount = 'ParticipantCheckToInvestmentBenefitAccount',
  RemittanceDistributionToClient = 'RemittanceDistributionToClient',
  MyCashToPaymentSource = 'MyCashToPaymentSource',
  BenefitAccountToMyCashWithoutRequest = 'BenefitAccountToMyCashWithoutRequest',
  TakeoverBenefitAccountToMyCashWithoutRequest = 'TakeoverBenefitAccountToMyCashWithoutRequest',
  MyCashToCharity = 'MyCashToCharity',
  GivebackAccountToCharity = 'GivebackAccountToCharity',
  ClientPaymentSourceToBenefitAccount = 'ClientPaymentSourceToBenefitAccount',
  ClientPaymentSourceToInvestmentBenefitAccount = 'ClientPaymentSourceToInvestmentBenefitAccount',
  MyCashToBenefitAccount = 'MyCashToBenefitAccount',
  FundedConversionRolloverToBenefitAccount = 'FundedConversionRolloverToBenefitAccount',
  ParticipantPaymentSourceToMyCash = 'ParticipantPaymentSourceToMyCash',
  MyCashToBenefitAccountCardRefund = 'MyCashToBenefitAccountCardRefund',
  UnfundedConversionRolloverToBenefitAccount = 'UnfundedConversionRolloverToBenefitAccount',
  ParticipantPaymentSourceToBenefitAccountRefund = 'ParticipantPaymentSourceToBenefitAccountRefund',
  CustodialInvestmentRolloverToBenefitAccount = 'CustodialInvestmentRolloverToBenefitAccount',
  ParticipantInvestmentRolloverToBenefitAccount = 'ParticipantInvestmentRolloverToBenefitAccount',
  MyCashAdjustment = 'MyCashAdjustment',
  InvestmentBenefitAccountAdjustment = 'InvestmentBenefitAccountAdjustment',
  BenefitAccountAdjustment = 'BenefitAccountAdjustment',
  UnfundedBenefitAccountAdjustment = 'UnfundedBenefitAccountAdjustment',
  PaymentPlanToPayee = 'PaymentPlanToPayee',
  PptCheckToPptHoldingAccount = 'PptCheckToPptHoldingAccount',
  PptACHToPptHoldingAccount = 'PptACHToPptHoldingAccount',
  PptCardToPptHoldingAccount = 'PptCardToPptHoldingAccount',
  PptUndesignatedHoldingAccountToPptHoldingAccount = 'PptUndesignatedHoldingAccountToPptHoldingAccount',
  PptUndesignatedHoldingAccountToPptHoldingAccountPending = 'PptUndesignatedHoldingAccountToPptHoldingAccountPending',
  PptDesignatedHoldingAccountToPptHoldingAccount = 'PptDesignatedHoldingAccountToPptHoldingAccount',
  PptDesignatedHoldingAccountInactivation = 'PptDesignatedHoldingAccountInactivation',
  PptUndesignatedHoldingAccountInactivation = 'PptUndesignatedHoldingAccountInactivation',
  MyCashFeeSweepAutomated = 'MyCashFeeSweepAutomated',
  MyCashFeeSweepManual = 'MyCashFeeSweepManual',
  HSAOrphanFeeSweepAutomated = 'HSAOrphanFeeSweepAutomated',
  HSAOrphanFeeSweepManual = 'HSAOrphanFeeSweepManual',
  ClientFeeAssessmentSweepAutomated = 'ClientFeeAssessmentSweepAutomated',
  ClientFeeAssessmentSweepManual = 'ClientFeeAssessmentSweepManual',
  ACHConvenienceFeeSweepAutomated = 'ACHConvenienceFeeSweepAutomated',
  ACHConvenienceFeeSweepManual = 'ACHConvenienceFeeSweepManual',
  CardConvenienceFeeSweepAutomated = 'CardConvenienceFeeSweepAutomated',
  CardConvenienceFeeSweepManual = 'CardConvenienceFeeSweepManual',
  PaymentPlanAdminFeeSweepAutomated = 'PaymentPlanAdminFeeSweepAutomated',
  PaymentPlanAdminFeeSweepManual = 'PaymentPlanAdminFeeSweepManual',
  PaymentPlanNSFFeeSweepAutomated = 'PaymentPlanNSFFeeSweepAutomated',
  PaymentPlanNSFFeeSweepManual = 'PaymentPlanNSFFeeSweepManual',
  RemittanceDistributionToPayee = 'RemittanceDistributionToPayee',
  UndesignatedHoldingAccountAdjustment = 'UndesignatedHoldingAccountAdjustment',
  DesignatedHoldingAccountAdjustment = 'DesignatedHoldingAccountAdjustment',
  RemittanceStagingAccountAdjustment = 'RemittanceStagingAccountAdjustment',
  ClientRemittanceAccountAdjustment = 'ClientRemittanceAccountAdjustment',
  PayeeRemittanceAccountAdjustment = 'PayeeRemittanceAccountAdjustment',
  MyCashUndesignatedHoldingAccountAdjustment = 'MyCashUndesignatedHoldingAccountAdjustment',
  MyCashDesignatedHoldingAccountAdjustment = 'MyCashDesignatedHoldingAccountAdjustment',
  SweepPaymentProcessorBinAccount = 'SweepPaymentProcessorBinAccount',
  SweepBinPayeeAccount = 'SweepBinPayeeAccount',
}

export enum FundsTransferGroup {
  Disbursement = 'Disbursement',
  FeePayment = 'FeePayment',
  Contribution = 'Contribution',
  SpecialContribution = 'SpecialContribution',
  Adjustment = 'Adjustment',
  System = 'System',
  Payment = 'Payment',
  FeeSweep = 'FeeSweep',
  BinSweep = 'BinSweep',
}

export enum ReviewErrorType {
  INDIVIDUAL_MODIFIED = 'INDIVIDUAL_MODIFIED',
  DEPENDENT_ADDED = 'DEPENDENT_ADDED',
  DEPENDENT_REMOVED = 'DEPENDENT_REMOVED',
  DEPENDENT_MODIFIED = 'DEPENDENT_MODIFIED',
  PLAN_ADDED = 'PLAN_ADDED',
  PRICING_MODIFIED = 'PRICING_MODIFIED',
  PRICING_REQUIRED = 'PRICING_REQUIRED',
  INCOMPATIBLE_ELIGIBILITY_EVENT_OFFERINGS = 'INCOMPATIBLE_ELIGIBILITY_EVENT_OFFERINGS',
  PRICING_CHANGE_DEPENDENT_COVERAGE_LOSS = 'PRICING_CHANGE_DEPENDENT_COVERAGE_LOSS',
  PRICING_CHANGE_NEGATIVE_FEE = 'PRICING_CHANGE_NEGATIVE_FEE',
  PRICING_CHANGE_NO_COVERAGE_AVAILABLE = 'PRICING_CHANGE_NO_COVERAGE_AVAILABLE',
  PLAN_NO_LONGER_OFFERABLE = 'PLAN_NO_LONGER_OFFERABLE',
}

export enum ObligationAmountType {
  Base = 'Base',
  Fee = 'Fee',
  Surcharge = 'Surcharge',
}

export enum AdjustmentDurationType {
  NumberOfMonths = 'NumberOfMonths',
  UntilCoverageEnds = 'UntilCoverageEnds',
  CustomEndDate = 'CustomEndDate',
}

export enum AdjustmentCalculation {
  Flat = 'Flat',
  Percent = 'Percent',
}

export enum FeeType {
  Flat = 'Flat',
  Percentage = 'Percentage',
}

export enum PricingOptionType {
  Tier = 'Tier',
  Age = 'Age',
  Fee = 'Fee',
  Gender = 'Gender',
  SmokingStatus = 'SmokingStatus',
  FSA = 'FSA',
  IndividuallyRated = 'IndividuallyRated',
}

export enum RelationshipType {
  Spouse = 'Spouse',
  Child = 'Child',
  DomesticPartner = 'DomesticPartner',
  ExSpouse = 'ExSpouse',
  SurvivingSpouse = 'SurvivingSpouse',
  Other = 'Other',
}

export enum RoundingType {
  Up = 'Up',
  Down = 'Down',
  Standard = 'Standard',
}

export enum TransferParentGroup {
  INDIVIDUAL = 'INDIVIDUAL',
  CLIENT = 'CLIENT',
  SYSTEM = 'SYSTEM',
  BUREAU = 'BUREAU',
  PAYMENT_PLAN = 'PAYMENT_PLAN',
  PAYEE = 'PAYEE',
}

export enum FundingSourceType {
  ParticipantToClient = 'ParticipantToClient',
  ClientDirect = 'ClientDirect',
  ParticipantDirect = 'ParticipantDirect',
  ClientToPlan = 'ClientToPlan',
}

export enum ProfileType {
  INDIVIDUAL = 'INDIVIDUAL',
  DEPENDENT = 'DEPENDENT',
}

export enum MspReportingFileType {
  MSPInput = 'MSPInput',
  TINReference = 'TINReference',
  MSPResponse = 'MSPResponse',
}

export enum HideFromType {
  None = 'None',
  All = 'All',
  ParticipantOnly = 'ParticipantOnly',
  ClientOnly = 'ClientOnly',
  ParticipantAndClientOnly = 'ParticipantAndClientOnly',
}

export enum SystemModificationType {
  System = 'System',
  PriceChange = 'PriceChange',
}

export enum PercentType {
  Base = 'Base',
  Fee = 'Fee',
  Surcharge = 'Surcharge',
}

export enum Xps2MigrationCurrentState {
  PendingCardPreorder = 'PendingCardPreorder',
  Xps2CardPreorderPublished = 'Xps2CardPreorderPublished',
  Xps2CardPreorderCompleted = 'Xps2CardPreorderCompleted',
  PendingMigration = 'PendingMigration',
  CardSyncCompleted = 'CardSyncCompleted',
  Xps1CardBlockPublished = 'Xps1CardBlockPublished',
  Xps1CardBlockCompleted = 'Xps1CardBlockCompleted',
  Xps2CardUnblockPublished = 'Xps2CardUnblockPublished',
  Completed = 'Completed',
  Error = 'Error',
}

export enum Xps2MigrationCommandType {
  StartToPendingCardPreorder = 'StartToPendingCardPreorder',
  PendingCardPreorderToXps2CardPreorderPublished = 'PendingCardPreorderToXps2CardPreorderPublished',
  PendingCardPreorderToXps2CardPreorderCompleted = 'PendingCardPreorderToXps2CardPreorderCompleted',
  Xps2CardPreorderPublishedToXps2CardPreorderCompleted = 'Xps2CardPreorderPublishedToXps2CardPreorderCompleted',
  Xps2CardPreorderCompletedToPendingMigration = 'Xps2CardPreorderCompletedToPendingMigration',
  PendingMigrationToCardSyncCompleted = 'PendingMigrationToCardSyncCompleted',
  PendingMigrationToCompleted = 'PendingMigrationToCompleted',
  CardSyncCompletedToXps1CardBlockPublished = 'CardSyncCompletedToXps1CardBlockPublished',
  Xps1CardBlockPublishedToXps1CardBlockCompleted = 'Xps1CardBlockPublishedToXps1CardBlockCompleted',
  Xps1CardBlockCompletedToXps2CardUnblockPublished = 'Xps1CardBlockCompletedToXps2CardUnblockPublished',
  Xps2CardUnblockPublishedToCompleted = 'Xps2CardUnblockPublishedToCompleted',
}

export enum ChainType {
  AND = 'AND',
  OR = 'OR',
}

export enum MatchType {
  BETWEEN = 'BETWEEN',
  CONTAINS = 'CONTAINS',
  EXACT = 'EXACT',
  NOT = 'NOT',
  IN = 'IN',
  NOT_IN = 'NOT_IN',
  NOT_CONTAINS = 'NOT_CONTAINS',
  STARTS_WITH = 'STARTS_WITH',
  NOT_STARTS_WITH = 'NOT_STARTS_WITH',
  LESS_THAN = 'LESS_THAN',
  LESS_THAN_EQUAL = 'LESS_THAN_EQUAL',
  GREATER_THAN = 'GREATER_THAN',
  GREATER_THAN_EQUAL = 'GREATER_THAN_EQUAL',
  IS_NULL = 'IS_NULL',
}
