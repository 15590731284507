import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { withTransaction } from '@datorama/akita';
import {
  Dependent,
  DependentCommandType,
  DependentState,
  MatchType,
  RelationshipType,
  SearchCriteria,
} from '@models/profile/model';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CoreService } from 'src/app/shared/models/pux/enum';
import { DependentViewModel } from 'src/app/shared/models/pux/model';
import { BenefitAccountService } from 'src/app/shared/services/api/benefit-account.service';
import { Uri } from 'src/app/shared/services/uri';
import { CommandFactory } from 'src/app/shared/utils/command.factory';
import { Transitions } from 'src/app/shared/utils/transitions';
import { CardQuery, DependentBenefitAccessQuery, DependentQuery, DependentStore, IndividualQuery } from 'src/app/state';

import { BenefitAccount } from '@models/account/model';
import { ErrorHandlingService } from '../../shared/services/error-handling.service';

@Injectable({
  providedIn: 'root',
})
export class DependentService {
  public constructor(
    private benefitAccountService: BenefitAccountService,
    private commandFactory: CommandFactory,
    private dependentBenefitAccessQuery: DependentBenefitAccessQuery,
    private dependentQuery: DependentQuery,
    private dependentStore: DependentStore,
    private errorHandlingService: ErrorHandlingService,
    private http: HttpClient,
    private individualQuery: IndividualQuery,
    private cardQuery: CardQuery,
  ) { }

  public addDependent(dependent: Dependent): Observable<Dependent> {
    return this.dispatchDependentCommand(dependent, DependentCommandType.StartToActive)
      .pipe(
        withTransaction((addedDepdendent) => this.dependentStore.add(addedDepdendent)),
        catchError(this.errorHandlingService.rxjsErrorHandler()),
      );
  }

  public deleteDependent(dependent: Dependent, benefitAccounts: BenefitAccount[], numberOfCards: number): Observable<Dependent> {
    const commandName = Transitions.getTransition(
      dependent.currentState,
      numberOfCards === 0 && benefitAccounts.length === 0 ? DependentState.Removed : DependentState.Inactive,
    ) as DependentCommandType;

    return this.dispatchDependentCommand(dependent, commandName)
      .pipe(
        withTransaction(() => this.dependentStore.remove(dependent.id)),
        catchError(this.errorHandlingService.rxjsErrorHandler()),
      );
  }

  public updateDependent(dependent: Dependent): Observable<Dependent> {
    const commandName = Transitions.getTransition(dependent.currentState, dependent.currentState) as DependentCommandType;
    return this.dispatchDependentCommand(dependent, commandName)
      .pipe(
        withTransaction((updatedDependent) => this.dependentStore.update(updatedDependent.id, updatedDependent)),
        catchError(this.errorHandlingService.rxjsErrorHandler()),
      );
  }

  /**
   * Retrieve all dependents associated with the logged-on individual and any benefit accounts that they
   * have been specifically associated with.
   * NOTE: Only benefit plans that are configured to require specifying dependent associations are returned here
   * (benefitPlan.allowAssociatingDependentsToBenefitAccount===true). When this value is false, it is assumed all
   * dependents have access to the plan and we choose not to include them in this view model because we do not
   * want to display those in the UI.
   */
  public getDependentViewModels(): Observable<DependentViewModel[]> {
    return this.benefitAccountService.loadDependentBenefitAccesses()
      .pipe(
        switchMap(() => this.dependentQuery.selectAllWhenLoaded()),
        switchMap((dependents) => {
          dependents = dependents.filter((dep) => !dep.isHiddenFromIndividual);
          if (!dependents.length) {
            return of([]);
          }
          const viewModelObservables$ = dependents.map((dependent) => {
            return combineLatest([
              this.benefitAccountService.getBenefitAccountsForDependent(dependent.id),
              this.cardQuery.getCardsByDependentId(dependent.id)])
              .pipe(
                map(([benefitAccounts, cards]) => {
                  return {
                    dependent,
                    benefitAccounts,
                    numberOfCards: cards.length,
                    fullName: `${dependent.firstName} ${dependent.lastName}`,
                    relationship: (dependent.relationship === RelationshipType.Other) && dependent.otherRelationship
                      ? dependent.otherRelationship
                      : ([RelationshipType.DomesticPartner, RelationshipType.Other].includes(dependent.relationship))
                        ? 'Dependent'
                        : dependent.relationship,
                  };
                }),
              );
          });
          return combineLatest(viewModelObservables$);
        }),
      );
  }

  public getDependentsForBenefitAccount(benefitAccountId: string): Observable<Dependent[]> {
    return this.benefitAccountService.loadDependentBenefitAccesses()
      .pipe(
        switchMap(() => {
          return this.dependentBenefitAccessQuery.selectActiveByBenefitAccountWhenLoaded(benefitAccountId);
        }),
        switchMap((dependentBenefitAccesses) => {
          const dependentIds = dependentBenefitAccesses.map((dba) => dba.parentId);
          return this.dependentQuery.selectMany(dependentIds);
        }),
      );
  }

  public getDependents(): Observable<Dependent[]> {
    const individualId = this.individualQuery.getActiveId();
    const dependentCriteria: SearchCriteria = [
      {
        key: 'parentId',
        matchType: MatchType.EXACT,
        value: individualId,
      },
    ];
    const uri = new Uri(`/profile/profileType/dependent/search`, CoreService.Profile);
    return this.http.post<Dependent[]>(uri.toString(), dependentCriteria)
      .pipe(
        withTransaction((dependents) => this.dependentStore.set(dependents)),
        catchError(this.errorHandlingService.rxjsErrorHandler()),
      );
  }

  private dispatchDependentCommand(dependent: Dependent, commandName: DependentCommandType): Observable<Dependent> {
    const uri = new Uri(`/profile/${dependent.id}/profileType/dependent/command/${commandName}`, CoreService.Profile);
    const command = this.commandFactory.createCommand(dependent, commandName);

    return this.http.put<void>(uri.toString(), command)
      .pipe(
        map(() => command.data),
      );
  }
}
