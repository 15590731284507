import { Injectable } from '@angular/core';
import { Order, QueryConfig, QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { BenefitAccountBalance, BenefitAccountState } from 'src/app/shared/models/uba/account/model';

import { BenefitAccountBalanceState, BenefitAccountBalanceStore } from './benefit-account-balance.store';

@Injectable({
  providedIn: 'root',
})
@QueryConfig({
  sortBy: 'planName',
  sortByOrder: Order.ASC,
})
export class BenefitAccountBalanceQuery extends QueryEntity<BenefitAccountBalanceState> {

  public static isAccountEnrolled(account: BenefitAccountBalance): boolean {
    if (!account) {
      return false;
    }

    const enrolledStates = [
      BenefitAccountState.ActiveNonDisbursable,
      BenefitAccountState.Enrolled,
      BenefitAccountState.IdVerificationPending,
      BenefitAccountState.Initiated,
      BenefitAccountState.PendingApproval,
      BenefitAccountState.TOUAgreementPending,
    ];
    return enrolledStates.includes(account.currentState as BenefitAccountState) || (account.currentState === BenefitAccountState.Active && !account.planEndDate);
  }

  public constructor(protected store: BenefitAccountBalanceStore) {
    super(store);
  }

  public selectActiveBenefitAccounts(): Observable<BenefitAccountBalance[]> {
    const activeStates = [
      BenefitAccountState.Active,
      BenefitAccountState.ActiveNonDisbursable,
      BenefitAccountState.ALOAWithBenefits,
      BenefitAccountState.ALOAWithoutBenefits,
      BenefitAccountState.Finalizing,
      BenefitAccountState.FinalizingError,
      BenefitAccountState.GracePeriod,
      BenefitAccountState.IdVerificationPending,
      BenefitAccountState.Reconciliation,
      BenefitAccountState.RolloverComplete,
      BenefitAccountState.RolloverProcessing,
      BenefitAccountState.RunOut,
      BenefitAccountState.TOUAgreementPending,
    ];
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectAll({
          filterBy: (account) => activeStates.includes(account.currentState as BenefitAccountState),
        })),
      );
  }

  public selectInactiveBenefitAccounts(): Observable<BenefitAccountBalance[]> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectAll({
          filterBy: (account) => account.currentState === BenefitAccountState.Inactive,
        })),
      );
  }

  public selectClosedBenefitAccounts(): Observable<BenefitAccountBalance[]> {
    const closedStates = [
      BenefitAccountState.Finalized,
      BenefitAccountState.Inactive,
    ];
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectAll({
          filterBy: (account) => closedStates.includes(account.currentState as BenefitAccountState),
        })),
      );
  }

  public selectEntityWhenLoaded(benefitAccountId: string): Observable<BenefitAccountBalance> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectEntity(benefitAccountId)),
      );
  }

  public selectEnrolledBenefitAccount(benefitPlanId: string): Observable<BenefitAccountBalance> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectAll({
          filterBy: (account) => account.planId === benefitPlanId && BenefitAccountBalanceQuery.isAccountEnrolled(account),
        })),
        map((accounts) => accounts.length ? accounts[0] : null),
      );
  }

  public selectByPlanIds(benefitPlanIds: string[]): Observable<BenefitAccountBalance[]> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectAll({
          filterBy: (account) => benefitPlanIds.includes(account.planId),
        })),
      );
  }

  public selectVisibleBenefitAccounts(): Observable<BenefitAccountBalance[]> {
    const nonVisibleStates = [
      BenefitAccountState.Error,
      BenefitAccountState.PendingApproval,
      BenefitAccountState.Removed,
    ];
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectAll({
          filterBy: (account) => !nonVisibleStates.includes(account.currentState as BenefitAccountState),

        })),
      );
  }
}
