import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import Bowser from 'bowser';
import { saveAs } from 'file-saver';
import { BrandId } from 'src/app/shared/models/pux/enum';
import { basicURLs } from 'src/brand/basic/basic.urls';
import { crossroadsURLs } from 'src/brand/crossroads/crossroads.urls';
import { tascURLs } from 'src/brand/tasc/tasc.urls';

import { InjectionTokens } from '../utils';

@Injectable({
  providedIn: 'root',
})
export class BrowserService {
  private readonly _isRenderingInBrowser: boolean;

  public constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(DOCUMENT) private document: Document,
    @Inject(InjectionTokens.LOCATION) private location: Location,
    @Inject(InjectionTokens.WINDOW) private window: Window,
  ) {
    this._isRenderingInBrowser = isPlatformBrowser(platformId);
  }

  public clearFocus(): void {
    if (!this._isRenderingInBrowser) {
      return;
    }

    if (this.document.activeElement && this.document.activeElement['blur'] && typeof this.document.activeElement['blur'] === 'function') {
      this.document.activeElement['blur']();
    }
  }

  public downloadFile(data: BlobPart[], fileName: string, mimeType: string = 'text/plain;charset=utf-8'): void {
    if (!this._isRenderingInBrowser || this.window.hasOwnProperty('Cypress')) {
      return;
    }

    const blob = new Blob(data, { type: mimeType });
    saveAs(blob, fileName);
  }

  public getLocationHostname(): string {
    if (!this._isRenderingInBrowser) {
      return null;
    }
    if (this.window.hasOwnProperty('Cypress')) {
      return this.getMockedLocationHostname();
    }

    return this.location.hostname;
  }

  public getLocationOrigin(): string {
    if (!this._isRenderingInBrowser) {
      return null;
    }

    return `${this.location.protocol}//${this.location.hostname}${this.location.port ? `:${this.location.port}` : ''}`;
  }

  public getLocationPathname(): string {
    if (!this._isRenderingInBrowser) {
      return null;
    }

    return this.location.pathname;
  }

  public isBrowserSupported(): boolean {
    if (!this._isRenderingInBrowser) {
      return true;
    }

    const browserEnvironment = Bowser.getParser(this.window.navigator.userAgent);
    return !browserEnvironment.satisfies({
      ie: '>0',
      safari: '<10',
    });
  }

  public redirectToPage(href: string): void {
    if (!this._isRenderingInBrowser) {
      return;
    }

    this.location.href = href;
  }

  /**
   * Required to create events in browsers that don't support the Event constructor (i.e. Internet Explorer)
   * https://caniuse.com/#feat=mdn-api_event_event
   */
  public createEvent(eventType: string, bubbles: boolean, cancelable: boolean): Event {
    let event: Event;
    if (typeof Event === 'function') {
      event = new Event(eventType, { bubbles, cancelable });
    } else {
      event = this.document.createEvent('Event');
      event.initEvent(eventType, bubbles, cancelable);
    }
    return event;
  }

  /**
   * Return a mocked value for location.hostname. When the query string contains brand=BASIC,
   * return the prod URL for CBA. When it's missing or any other value, return the prod URL for UBA.
   * NOTE: We use this function ONLY when running a Cypress test, and we do it this way because
   * there does not appear to be a way to mock location.hostname from Cypress.
   */
  private getMockedLocationHostname(): string {
    const urlParams = new URLSearchParams(this.location.search);
    const brand = urlParams.get('brand') as BrandId;
    switch (brand) {
      case BrandId.Crossroads:
        return crossroadsURLs[crossroadsURLs.length - 1];
      case BrandId.BASIC:
        return basicURLs[basicURLs.length - 1];
      case BrandId.TASC:
      default:
        return tascURLs[tascURLs.length - 1];
    }
  }
}
