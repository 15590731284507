import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { withTransaction } from '@datorama/akita';
import {
  ChainType,
  Document,
  DocumentCategoryType,
  DocumentState,
  MatchType,
  ParentType,
  SearchCriteria,
} from '@models/profileConfiguration/model';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { DocumentStore } from 'src/app/state';

import { CoreService } from '../../models/pux/enum';
import { Dates } from '../../utils/dates';
import { Uri } from '../uri';

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  public constructor(
    private documentStore: DocumentStore,
    private http: HttpClient,
    private toastrService: ToastrService,
  ) { }

  public getClientDocuments(clientId: string): Observable<Document[]> {
    const clientDocumentsSearchCriteria: SearchCriteria = [
      { key: 'parentId', value: clientId, matchType: MatchType.EXACT, chainType: ChainType.AND },
      { key: 'parentType', value: ParentType.CLIENT, matchType: MatchType.EXACT, chainType: ChainType.AND },
      { key: 'currentState', value: DocumentState.Active, matchType: MatchType.EXACT, chainType: ChainType.AND },
      { key: 'category', value: DocumentCategoryType.Client, matchType: MatchType.EXACT, chainType: ChainType.AND },
      { key: 'effectiveDate', value: Dates.today(), matchType: MatchType.LESS_THAN_EQUAL },
    ];
    const relativeURL = `/profile/*/configuration/document/search`;
    const uri = new Uri(relativeURL, CoreService.ProfileConfiguration);
    return this.http.post<Document[]>(uri.toString(), clientDocumentsSearchCriteria)
      .pipe(
        withTransaction((documents) => this.documentStore.set(documents)),
      );
  }

  public downloadDocumentWithCommunicationRequestId(commRequestId: string, individualId: string): Observable<string> {
    const documentSearchCriteria: SearchCriteria = [
      { key: 'communicationRequestId', value: commRequestId, matchType: MatchType.EXACT },
    ];
    const relativeURL = `/profile/*/configuration/document/search`;
    const uri = new Uri(relativeURL, CoreService.ProfileConfiguration);
    return this.http.post<Document[]>(uri.toString(), documentSearchCriteria)
      .pipe(
        switchMap((documents) => {
          if (documents.length) {
            return this.downloadUrlByDocumentId(documents[0].id, individualId);
          } else {
            return of('');
          }
        }),
        catchError(() => {
          this.toastrService.error('Something went wrong while generating your document');
          return of('');
        }),
      );
  }

  private downloadUrlByDocumentId(id: string, individualId: string): Observable<string> {
    const relativeURL = `/profile/${individualId}/configuration/document/downloadUrl/${id}`;
    const uri = new Uri(relativeURL, CoreService.ProfileConfiguration);
    return this.http.get<string>(uri.toString())
      .pipe(
        catchError((err) => {
          this.toastrService.error('Something went wrong while generating your document');
          return of('');
        }),
      );
  }
}
