import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { CcxSubstantiation } from '@models/request/model';

export interface CcxSubstantiationState extends EntityState<CcxSubstantiation, string> { }

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'CcxSubstantiation' })
export class CcxSubstantiationStore extends EntityStore<CcxSubstantiationState> {
  public constructor() {
    super();
  }
}
