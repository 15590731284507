import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { AttachmentContent } from 'src/app/shared/models/pux/attachment-content';

export interface AttachmentContentState extends EntityState<AttachmentContent, string> {}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'AttachmentContent' })
export class AttachmentContentStore extends EntityStore<AttachmentContentState> {
  public constructor() {
    super();
  }
}
