import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { RequestAttachment } from '@models/request/model';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { RequestAttachmentState, RequestAttachmentStore } from './request-attachment.store';

@Injectable({
  providedIn: 'root',
})
export class RequestAttachmentQuery extends QueryEntity<RequestAttachmentState> {
  public constructor(protected store: RequestAttachmentStore) {
    super(store);
  }

  public selectAllWhenLoaded(): Observable<RequestAttachment[]> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectAll()),
      );
  }

  public selectByRequest(requestId: string): Observable<RequestAttachment[]> {
    return this.selectAllWhenLoaded()
      .pipe(
        map((requestAttachments) => requestAttachments.filter((requestAttachment) => requestAttachment.parentId === requestId)),
      );
  }
}
