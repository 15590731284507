import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { OperationalBalanceInfoModel } from 'src/app/shared/models/pux';

export interface OperationalBalanceInfoEntityState extends EntityState<OperationalBalanceInfoModel, string> { }

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'OperationalBalanceInfoModel' })
export class OperationalBalanceInfoStore extends EntityStore<OperationalBalanceInfoEntityState> {
  public constructor() {
    super();
  }
}
