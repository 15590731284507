import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/auth/services/authentication.service';
import { TermsOfUseSection } from 'src/app/shared/models/pux/enum';
import { BrandService } from 'src/app/shared/services/brand.service';
import { TermsOfUseModalComponent } from 'src/app/terms-of-use/components/terms-of-use-modal/terms-of-use-modal.component';

import { version } from '../../../../../package.json';

@Component({
  templateUrl: './login-federated.component.html',
  styleUrls: ['./login-federated.component.scss'],
})
export class LoginFederatedPageComponent implements OnInit {
  @ViewChild(TermsOfUseModalComponent, { static: true }) public termsOfUseModal: TermsOfUseModalComponent;
  public currentYear: number;
  public version = version;
  public readonly brand = this.brandService.getBrandResources();

  public constructor(
    private authenticationService: AuthenticationService,
    private brandService: BrandService,
    private route: ActivatedRoute,
  ) { }

  public ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
    const identityProvider = this.route.snapshot.queryParams['identity_provider'];
    if (identityProvider) {
      this.authenticationService.redirectToFederatedSignInPage(identityProvider);
      return;
    }

    this.authenticationService.listenForFederatedAuthentication();
  }

  public openPrivacyPolicy(): void {
    this.termsOfUseModal.open(TermsOfUseSection.PrivacyPolicy);
  }

  public openTermsOfUse(): void {
    this.termsOfUseModal.open();
  }
}
