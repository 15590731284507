import { Injectable } from '@angular/core';
import { IndividualPaymentStatusQuery } from '@app/state';
import { withTransaction } from '@datorama/akita';
import { cloneDeep } from 'lodash';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BrandService } from 'src/app/shared/services/brand.service';
import { ErrorHandlingService } from 'src/app/shared/services/error-handling.service';

import { PaymentPageViewModel } from '../models/payment-page-view-model';
import { PaymentPageViewModelStore } from '../ui-state/payment-page-view-model.store';

@Injectable({
  providedIn: 'root',
})
export class PaymentPageViewModelService {
  private readonly brand = this.brandService.getBrandResources();

  public constructor(
    private individualPaymentStatusQuery: IndividualPaymentStatusQuery,
    private paymentPageViewModelStore: PaymentPageViewModelStore,
    private brandService: BrandService,
    private errorHandlingService: ErrorHandlingService,
  ) {}

  public getPaymentViewModels(): Observable<PaymentPageViewModel[]> {
    return this.individualPaymentStatusQuery.selectPaymentAccountGroups().pipe(
      map((pagsEnriched) => pagsEnriched.map((pag) => new PaymentPageViewModel(cloneDeep(pag)))),
      withTransaction((models) => this.paymentPageViewModelStore.set(models)),
      catchError(this.errorHandlingService.rxjsErrorHandler(() => `Something went wrong. Please contact ${this.brand.companyName} at ${this.brand.phoneNumber}.`)),
    );
  }
}
