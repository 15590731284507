// AUTOGENERATED BY FUNCTION genubamodel.js
export interface NoteCommand extends CommandBase {
  data?: Note;
}

export interface CreateSupportRequestCommand extends CommandBase {
  data?: SupportRequest;
}

export interface SupportRequestCommentsCommand extends CommandBase {
  data: SupportRequestComments;
}

export interface Note extends EntityBase {
  externalId: string;
  requestId?: string;
  description?: string;
  topic: string;
}

export type SupportRequestTopics = Offerings[];

export interface Offerings {
  offering?: string;
  topics?: Topics[];
}

export interface Topics {
  name?: string;
  subTopics?: SubTopics[];
}

export interface SubTopics {
  name?: string;
  projectId?: number;
}

export interface SupportRequestDetail extends SupportRequest {
  dateStarted?: string;
  comments?: Comments[];
  documents?: Documents[];
}

export interface SupportRequestComments {
  commentText: string;
  attachments?: Attachment[];
}

export interface SupportRequest {
  id?: number;
  projectId: number;
  projectName?: string;
  offeringType?: string;
  topic: string;
  subtopic: string;
  relatedProfiles: RelatedProfiles;
  tascId?: string;
  fileNotes: string;
  ticketNumber?: string;
  started?: string;
  additionalRecipients?: string[];
  created?: string;
  createdBy: string;
  status?: string;
  updated?: string;
  contactRequestChange?: ContactRequestChange;
  serviceOffering?: string;
  attachments?: Attachment[];
  escalate?: boolean;
  processImpacted?: string;
  cause?: string;
  contactEmail?: string;
  customerName?: string;
}

export interface RelatedProfiles {
  client?: ProfileInfo;
  individual?: ProfileInfo;
  contact?: ProfileInfo;
  dependent?: ProfileInfo;
}

export interface ProfileInfo {
  id?: string;
  externalId?: string;
  name?: string;
}

export interface Criteria {
  key?: string;
  value?: string;
  matchType?: MatchType;
  chainType?: ChainType;
  groupCriteria?: GroupCriteria[];
}

export interface Error {
  message?: string;
  fields?: object;
}

export interface GroupCriteria {
  key?: string;
  value?: string;
  matchType?: MatchType;
  chainType?: ChainType;
}

export type SearchCriteria = Criteria[];

export interface PingResponse {
  serverTime: ServerTime;
  services?: Service[];
}

export interface Service {
  name?: string;
  serverTime?: string;
}

export type ServerTime = string;

export interface SupportRequestResponse {
  data: SupportRequest[];
  isTruncated: boolean;
}

export interface SupportRequestSearchFilters {
  createdFrom?: string;
  createdTo?: string;
  projectIds?: number[];
  requestId?: string;
  serviceOffering?: string;
  status?: string;
  subtopic?: string;
  tascId?: string;
  topic?: string;
  updatedFrom?: string;
  updatedTo?: string;
}

export interface CreateSupportRequestParameters {
  priorityValue?: number;
  tpaPartner?: string;
  contactEmail?: string;
  clientName?: string;
  clientId?: string;
  individualId?: string;
  projectDetails?: ProjectDetails;
  authorizationPath?: string;
}

export interface ProjectDetails {
  Id: number;
  Name: string;
  type: RequestType;
}

export interface Attachment {
  filePath?: string;
  attachmentType?: string;
  friendlyFileName?: string;
  internalUseOnly?: boolean;
}

export interface Documents {
  id?: number;
  size?: string;
  date?: string;
  name?: string;
  internalUseOnly?: boolean;
}

export interface Comments {
  id?: number;
  userDisplayName?: string;
  date?: string;
  text?: string;
}

export type SupportRequests = SupportRequest[];

export interface CommandBase {
  id?: string;
  eventCorrelationId?: string;
  jobId?: string;
  producerId?: string;
  sequenceId?: number;
  version?: number;
  type?: string;
  created?: string;
  createdBy?: string;
  createdById?: string;
}

export interface EntityBase {
  id?: string;
  parentId?: string;
  parentType?: ParentType;
  version?: number;
  created?: string;
  createdBy?: string;
  createdById?: string;
  updated?: string;
  updatedBy?: string;
  updatedById?: string;
  currentState?: string;
  lastTransition?: string;
  externalWorkflowId?: string;
  eventNotes?: string;
  transactionLockId?: string;
}

export enum SupportRequestCommandType {
  StartToSent = 'StartToSent',
}

export enum CreateSupportRequestCommandType {
  StartToSent = 'StartToSent',
}

export enum NoteCommandType {
  StartToSubmitted = 'StartToSubmitted',
}

export enum SupportRequestCommentsCommandType {
  StartToSent = 'StartToSent',
}

export enum ChainType {
  AND = 'AND',
  OR = 'OR',
}

export enum MatchType {
  CONTAINS = 'CONTAINS',
  EXACT = 'EXACT',
  NOT = 'NOT',
  IN = 'IN',
  NOT_IN = 'NOT_IN',
  LESS_THAN = 'LESS_THAN',
  LESS_THAN_EQUAL = 'LESS_THAN_EQUAL',
  GREATER_THAN = 'GREATER_THAN',
  GREATER_THAN_EQUAL = 'GREATER_THAN_EQUAL',
}

export enum SupportRequestStateType {
  Error = 'Error',
  Submitted = 'Submitted',
}

export enum RequestType {
  WorkRequestForm = 'WorkRequestForm',
  EmailTriage = 'EmailTriage',
  SupportRequest = 'SupportRequest',
  SupportRequestActionItems = 'SupportRequestActionItems',
  OnboardingEmailInbox = 'OnboardingEmailInbox',
  OnboardingActionItems = 'OnboardingActionItems',
  NewClientOnboarding = 'NewClientOnboarding',
  ServiceRequest = 'ServiceRequest',
  Note = 'Note',
}

export enum ContactRequestChange {
  TASCForce = 'TASCForce',
  Other = 'Other',
  Participant = 'Participant',
  Client = 'Client',
  Provider = 'Provider',
}

export enum ParentType {
  CLIENT = 'CLIENT',
  INDIVIDUAL = 'INDIVIDUAL',
}
