import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { BenefitAccountPostingSummaryViewModel } from 'src/app/shared/models/pux/model';

export interface BenefitAccountPostingSummaryState extends EntityState<BenefitAccountPostingSummaryViewModel, string> {}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'BenefitAccountPostingSummary' })
export class BenefitAccountPostingSummaryStore extends EntityStore<BenefitAccountPostingSummaryState> {
  public constructor() {
    super();
  }
}
