import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { InvestmentBalance } from '@models/account/model';

export interface InvestmentBalanceState extends EntityState<InvestmentBalance, string> {}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'InvestmentBalance' })
export class InvestmentBalanceStore extends EntityStore<InvestmentBalanceState> {
  public constructor() {
    super();
  }
}
