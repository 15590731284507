import { Injectable } from '@angular/core';
import { Order, QueryConfig, QueryEntity } from '@datorama/akita';
import {Card, CardState} from '@models/card/model';
import { Observable} from 'rxjs/internal/Observable';
import { filter, switchMap } from 'rxjs/operators';
import { CardState as CardCacheState, CardStore } from './card.store';

@Injectable({ providedIn: 'root' })
@QueryConfig({ sortBy: 'expiration', sortByOrder: Order.ASC })
export class CardQuery extends QueryEntity<CardCacheState> {
  public readonly visibleCardStates: ReadonlyArray<CardState> = [
    CardState.Active,
    CardState.BlockedWithFraud,
    CardState.BlockedWithoutFraud,
    CardState.Ordered,
  ];

  public constructor(
    protected store: CardStore,
  ) {
    super(store);
  }

  public selectActiveWhenLoaded(): Observable<Card> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectActive()),
      );
  }

  public selectAllWhenLoaded(): Observable<Card[]> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectAll()),
      );
  }

  public selectActiveCardsWhenLoaded(): Observable<Card[]> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectAll({
          filterBy: (card) => CardState.Active === (card.currentState as CardState),
        })),
      );
  }

  public selectVisibleCardsWhenLoaded(): Observable<Card[]> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectAll({
          filterBy: (card) => this.visibleCardStates.includes(card.currentState as CardState),
        })),
      );
  }

  public getCardsByDependentId(id: string): Observable<Card[]> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectAll({
          filterBy: (card) => card.parentId === id,
        })),
      );
  }
}
