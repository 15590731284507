import {Injectable} from '@angular/core';
import {Order, QueryConfig, QueryEntity} from '@datorama/akita';
import { CcxSubstantiationLinks } from '@models/request/model';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { CcxSubstantiationLinkState, CcxSubstantiationLinkStore } from './ccx-substantiation-link.store';

@QueryConfig({
  sortBy: 'serviceDate',
  sortByOrder: Order.ASC,
})
@Injectable({
  providedIn: 'root',
})
export class CcxSubstantiationLinkQuery extends QueryEntity<CcxSubstantiationLinkState> {
  public constructor(protected store: CcxSubstantiationLinkStore) {
    super(store);
  }

  public selectAllWhenLoaded(): Observable<CcxSubstantiationLinks> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectAll()),
      );
  }

  public selectByRequest(requestId: string): Observable<CcxSubstantiationLinks> {
    return this.selectAllWhenLoaded()
      .pipe(
        map((substantiationLinks) => substantiationLinks.filter((link) => link.requestId === requestId)),
      );
  }

}
