import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { FundsTransferCriterion } from '@models/profileConfiguration/model';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { FundsTransferCriterionState, FundsTransferCriterionStore } from './funds-transfer-criterion.store';

@Injectable({
  providedIn: 'root',
})
export class FundsTransferCriterionQuery extends QueryEntity<FundsTransferCriterionState> {
  public constructor(protected store: FundsTransferCriterionStore) {
    super(store);
  }

  public selectAllWhenLoaded(): Observable<FundsTransferCriterion[]> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectAll()),
      );
  }
}
