import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {cacheable, withTransaction} from '@datorama/akita';
import {Observable} from 'rxjs';
import {catchError, mapTo, switchMap, tap} from 'rxjs/operators';
import {
  cacheableById,
  FundsTransferCriterionStore,
  FundsTransferOptionsStore,
  IndividualQuery,
  TransactionActivityStore,
} from 'src/app/state';

import {CoreService, TransactionActivityEndpointSource} from '../../models/pux/enum';
import {SearchQuery} from '../../models/pux/model';
import {
  ChainType,
  CommandBase,
  FundsTransferCriterion,
  FundsTransferCriterionCommandType,
  FundsTransferCriterionState,
  FundsTransferFrequencyType,
  FundsTransferOptions,
  MatchType,
  ParentType,
  SearchCriteria,
} from '../../models/uba/profileConfiguration/model';
import {CommandFactory} from '../../utils/command.factory';
import {ErrorHandlingService} from '../error-handling.service';
import {Uri} from '../uri';
import {TransactionService} from './transaction.service';

@Injectable({
  providedIn: 'root',
})
export class FundsTransferCriterionService {
  public constructor(
    private http: HttpClient,
    private commandFactory: CommandFactory,
    private errorHandlingService: ErrorHandlingService,
    private fundsTransferCriterionStore: FundsTransferCriterionStore,
    private fundsTransferOptionsStore: FundsTransferOptionsStore,
    private individualQuery: IndividualQuery,
    private transactionActivityStore: TransactionActivityStore,
    private transactionService: TransactionService,
  ) { }

  /**
   * Load the scheduled fund transfers associated with the logged on user.
   */
  public loadScheduledFundsTransfer(): Observable<void> {
    const individual = this.individualQuery.getActive();

    const criteria: SearchCriteria = [
      {
        key: 'parentId',
        value: individual.id,
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      },
      {
        key: 'parentType',
        value: ParentType.INDIVIDUAL,
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      },
      {
        key: 'currentState',
        value: FundsTransferCriterionState.Active,
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      },
      {
        key: 'frequency',
        value: FundsTransferFrequencyType.OneTime,
        matchType: MatchType.NOT,
      },
    ];

    const query: SearchQuery = {
      orderBy: 'startDate',
      orderDirection: 'ASC',
    };

    const relativeURL = `/profile/${individual.id}/configuration/fundsTransferCriterion/search`;
    const uri = new Uri(relativeURL, CoreService.ProfileConfiguration, query);
    const request$ = this.httpPost<FundsTransferCriterion[]>(uri, criteria)
      .pipe(
        withTransaction((fundsTransfers) => this.fundsTransferCriterionStore.set(fundsTransfers)),
      );
    return cacheable(this.fundsTransferCriterionStore, request$, { emitNext: true })
      .pipe(
        mapTo(null),
      );
  }

  public loadFundsTransferOptions(reloadCache: boolean = false): Observable<FundsTransferOptions> {
    const individualId = this.individualQuery.getActiveId();
    const relativeURL = `/profile/${individualId}/configuration/fundsTransferOptions`;
    const uri = new Uri(relativeURL, CoreService.ProfileConfiguration);
    const request$ = this.httpGet<FundsTransferOptions>(uri)
      .pipe(
        withTransaction((options) => {
          this.fundsTransferOptionsStore.upsert(individualId, options);
          this.fundsTransferCriterionStore.setActive(individualId);
          this.fundsTransferOptionsStore.setHasCache(true);
        }),
      );
    return reloadCache ? request$ : cacheableById(individualId, this.fundsTransferOptionsStore, request$, {emitNext: true});
  }
  /**
   * Persist a new funds transfer object to the data store.
   * @param fundsTransfer The new funds transfer object to persist.
   * @param commandType The command to run for the funds transfer type.
   */
  public addFundsTransfer(fundsTransfer: FundsTransferCriterion, commandType: FundsTransferCriterionCommandType = FundsTransferCriterionCommandType.StartToActive): Observable<FundsTransferCriterion> {
    return this.updateFundsTransfer(fundsTransfer, commandType)
      .pipe(
        tap((updateFundsTransfer) => this.fundsTransferCriterionStore.add(updateFundsTransfer)),
        switchMap((updateFundsTransfer) => this.transactionService.refreshTransactions(TransactionActivityEndpointSource.PendingTransfer).pipe(mapTo(updateFundsTransfer))),
      );
  }

  /**
   * Cancel the funds transfer object in the data store.
   * @param fundsTransfer The funds transfer object to cancel.
   */
  public cancelFundsTransfer(fundsTransfer: FundsTransferCriterion): Observable<void> {
    return this.updateFundsTransfer(fundsTransfer, FundsTransferCriterionCommandType.ActiveToCancelled)
      .pipe(
        tap(() => {
          this.fundsTransferCriterionStore.remove(fundsTransfer.id);
          this.transactionActivityStore.remove(fundsTransfer.id);
        }),
        mapTo(null),
      );
  }

  private updateFundsTransfer(fundsTransfer: FundsTransferCriterion, commandName: FundsTransferCriterionCommandType): Observable<FundsTransferCriterion> {
    const relativeURL = `/profile/${fundsTransfer.parentId}/configuration/fundsTransferCriterion/${fundsTransfer.id}/command/${commandName}`;
    const uri = new Uri(relativeURL, CoreService.ProfileConfiguration);
    const command = this.commandFactory.createCommand(fundsTransfer, commandName);

    return this.httpPut(uri, command)
      .pipe(
        mapTo(command.data),
      );
  }

  private httpGet<T>(uri: Uri): Observable<T> {
    return this.http.get<T>(uri.toString())
      .pipe(catchError(this.errorHandlingService.rxjsErrorHandler()));
  }

  private httpPost<T>(uri: Uri, requestBody: SearchCriteria): Observable<T> {
    return this.http.post<T>(uri.toString(), requestBody)
      .pipe(catchError(this.errorHandlingService.rxjsErrorHandler()));
  }

  private httpPut(uri: Uri, requestBody: CommandBase): Observable<void> {
    return this.http.put<void>(uri.toString(), requestBody)
      .pipe(catchError(this.errorHandlingService.rxjsErrorHandler()));
  }
}
