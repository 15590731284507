import { NgModule, Optional, SkipSelf } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { LoginRoutingModule } from './login/login.routing';
import { DevGuard } from './shared/guards/dev.guard';
import { PayoutGuard } from './shared/guards/payout.guard';

const appRoutes: Routes = [
  {
    path: 'accounts',
    loadChildren: () => import('./benefit-accounts/benefit-accounts.module').then((module) => module.BenefitAccountsModule),
  },
  {
    path: 'contact-us',
    loadChildren: () => import('./contact-us/contact-us.module').then((module) => module.ContactUsModule),
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./overview/overview.module').then((module) => module.OverviewModule),
  },
  {
    path: 'demo',
    loadChildren: () => import('./demo/demo.module').then((module) => module.DemoModule),
    canLoad: [DevGuard],
  },
  {
    path: 'enrollment',
    loadChildren: () => import('./enrollment/enrollment.module').then((module) => module.EnrollmentModule),
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./forgot-password/forgot-password.module').then((module) => module.ForgotPasswordModule),
  },
  {
    path: 'mycash',
    loadChildren: () => import('./mycash/mycash.module').then((module) => module.MyCashModule),
  },
  {
    path: 'payout',
    loadChildren: () => import('./payout/payout.module').then((module) => module.PayoutModule),
    canLoad: [PayoutGuard],
  },
  {
    path: 'payment',
    loadChildren: () => import('./payment/payment.module').then((module) => module.PaymentModule),
  },
  {
    path: 'payprovider',
    loadChildren: () => import('./reimbursement/reimbursement.module').then((module) => module.ReimbursementModule),
  },
  {
    path: 'reimbursement',
    loadChildren: () => import('./reimbursement/reimbursement.module').then((module) => module.ReimbursementModule),
  },
  {
    path: 'requests',
    loadChildren: () => import('./reimbursement-request/reimbursement-request.module').then((module) => module.ReimbursementRequestModule),
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then((module) => module.SettingsModule),
  },
  {
    path: 'signup',
    loadChildren: () => import('./sign-up/sign-up.module').then((module) => module.SignUpModule),
  },
  {
    path: 'support',
    loadChildren: () => import('./support/support.module').then((module) => module.SupportModule),
  },
  {
    path: 'wallet',
    loadChildren: () => import('./tasc-wallet/tascwallet.module').then((module) => module.TascWalletModule),
  },
  {
    path: 'terms',
    loadChildren: () => import('./terms-of-use/terms-of-use.module').then((module) => module.TermsOfUseModule),
  },
  {
    path: 'transactions',
    loadChildren: () => import('./transactions/transactions.module').then((module) => module.TransactionsModule),
  },
  {
    path: 'sitemap',
    loadChildren: () => import('./sitemap/sitemap.module').then((module) => module.SitemapModule),
  },
  {
    path: '**',
    redirectTo: '/login',
  },
];

@NgModule({
  imports: [
    LoginRoutingModule,
    RouterModule.forRoot(
      appRoutes,
      {
        enableTracing: false,
        preloadingStrategy: PreloadAllModules,
      },
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
  public constructor(@Optional() @SkipSelf() self: AppRoutingModule) {
    if (self) {
      throw new Error(
        'AppRoutingModule is already loaded. Import it in the AppModule only.');
    }
  }
}
