import {Injectable} from '@angular/core';
import {QueryEntity} from '@datorama/akita';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { CardPackageViewModel } from '../../tasc-wallet/models/card-package-view-model';
import {CardPackageViewModelState, CardPackageViewModelStore} from './card-package-view-model-store';

@Injectable({
  providedIn: 'root',
})
export class CardPackageViewModelQuery extends QueryEntity<CardPackageViewModelState> {
  public constructor(protected store: CardPackageViewModelStore) {
    super(store);
  }

  public selectActiveWhenLoaded(): Observable<CardPackageViewModel> {
    return this.selectLoading()
    .pipe(
      filter((isLoading) => !isLoading),
      switchMap(() => this.selectActive()),
    );
  }

}
