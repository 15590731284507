import { NgModule } from '@angular/core';

import { DisableAfterClickDirective } from './disable-after-click.directive';
import { DisableAfterSubmitDirective } from './disable-after-submit.directive';
import { FileUploadDirective } from './file-upload.directive';

@NgModule({
  declarations: [
    DisableAfterClickDirective,
    DisableAfterSubmitDirective,
    FileUploadDirective,
  ],
  exports: [
    DisableAfterClickDirective,
    DisableAfterSubmitDirective,
    FileUploadDirective,
  ],
})
export class DirectivesModule { }
