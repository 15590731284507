import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { BenefitAccountBalance } from 'src/app/shared/models/uba/account/model';

export interface BenefitAccountBalanceState extends EntityState<BenefitAccountBalance, string> {}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'BenefitAccountBalance' })
export class BenefitAccountBalanceStore extends EntityStore<BenefitAccountBalanceState> {
  public constructor() {
    super();
  }
}
