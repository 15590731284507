import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-password-rules',
  templateUrl: './password-rules.component.html',
  styleUrls: ['./password-rules.component.scss'],
})
export class PasswordRulesComponent {
  @Input() public validateForm;
}
