import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { withTransaction } from '@datorama/akita';
import {
  Card,
  CardCommandType,
  CardPin,
  CardPinCommandType,
  CardState,
  KeyData,
  MatchType,
  SearchCriteria,
} from '@models/card/model';
import { EMPTY, Observable, of, zip } from 'rxjs';
import { catchError, delay, expand, map, skipWhile, switchMap, switchMapTo } from 'rxjs/operators';
import { TascWalletRedirect } from 'src/app/auth/models/tasc-wallet-redirect.model';
import { RaygunService } from 'src/app/raygun/raygun.service';
import { CoreService } from 'src/app/shared/models/pux/enum';
import { DatedKeyData, SearchQuery } from 'src/app/shared/models/pux/model';
import { BrandService } from 'src/app/shared/services/brand.service';
import { ErrorHandlingService } from 'src/app/shared/services/error-handling.service';
import { PgpService } from 'src/app/shared/services/pgp.service';
import { Uri } from 'src/app/shared/services/uri';
import { CommandFactory } from 'src/app/shared/utils/command.factory';
import { Dates } from 'src/app/shared/utils/dates';
import { BenefitAccountBalanceQuery, BenefitPlanQuery, ClientQuery, IndividualQuery } from 'src/app/state';
import { v4 as uuid } from 'uuid';

import { CardQuery, CardStore } from '../../state/card';

@Injectable({
  providedIn: 'root',
})
export class CardService {
  private readonly brand = this.brandService.getBrandResources();

  public constructor(
    private benefitAccountBalanceQuery: BenefitAccountBalanceQuery,
    private benefitPlanQuery: BenefitPlanQuery,
    private brandService: BrandService,
    private cardQuery: CardQuery,
    private cardStore: CardStore,
    private clientQuery: ClientQuery,
    private commandFactory: CommandFactory,
    private errorHandlingService: ErrorHandlingService,
    private http: HttpClient,
    private individualQuery: IndividualQuery,
    private pgpService: PgpService,
    private raygunService: RaygunService,
  ) { }

  public getCards(dependentIds: string[]): Observable<Card[]> {
    const query: SearchQuery = {
      orderBy: 'parentType',
      orderDirection: 'DESC',
    };
    const individualId = this.individualQuery.getActiveId();
    const criteria: SearchCriteria = [{
      key: 'parentId',
      matchType: MatchType.IN,
      value: [individualId, ...dependentIds].join('|'),
    }];

    const uri = new Uri('/profile/*/card/search', CoreService.Card, query);
    return this.http.post<Card[]>(uri.toString(), criteria)
      .pipe(
        withTransaction((cards) => {
          this.cardStore.set(cards);
          if (cards.length) {
            // Grab the first visible card. Since the query does a reverse sort on parentType, we'll match on the PPT before the dependent
            const activeCard = cards.find((card) => this.cardQuery.visibleCardStates.includes(card.currentState as CardState));
            if (activeCard) {
              this.cardStore.setActive(activeCard.id);
            }
          }
        }),
        catchError(this.errorHandlingService.rxjsErrorHandler(null, (error) => this.raygunService.logError(error), () => of([]))),
      );
  }

  public getCardPinEncryptionKey(cardId: string): Observable<DatedKeyData> {
    const individualId = this.individualQuery.getActiveId();
    const uri = new Uri(`/profile/${individualId}/card/${cardId}/cardPin/keyData/${uuid()}`, CoreService.Card);
    return this.http.get<KeyData>(uri.toString())
      .pipe(
        map((response) => {
          const date = new Date(8640000000000000); // maximum date value
          return { ...response, date };
        }),
      );
  }

  public setCardPin(cardPin: CardPin): Observable<void> {
    const individualId = this.individualQuery.getActiveId();
    const command = this.commandFactory.createCommand(cardPin, CardPinCommandType.Set);
    const uri = new Uri(`/profile/${individualId}/card/${cardPin.parentId}/cardPin/command/${command.type}`, CoreService.Card);
    return this.http.put<void>(uri.toString(), command);
  }

  public orderCard(card: Card): Observable<void> {
    const individualId = this.individualQuery.getActiveId();
    const command = this.commandFactory.createCommand(card, CardCommandType.StartToOrdered);
    const uri = new Uri(`/profile/${individualId}/card/${card.id}/command/${command.type}`, CoreService.Card);
    return this.http.put<void>(uri.toString(), command);
  }

  public updateCard(card: Card, commandName: CardCommandType): Observable<Card> {
    const individualId = this.individualQuery.getActiveId();
    const command = this.commandFactory.createCommand(card, commandName);
    const uri = new Uri(`/profile/${individualId}/card/${card.id}/command/${command.type}`, CoreService.Card);
    return this.http.put<void>(uri.toString(), command)
      .pipe(
        map(() => command.data),
        withTransaction((updatedCard) => this.cardStore.upsert(updatedCard.id, updatedCard)),
        catchError(this.errorHandlingService.rxjsErrorHandler()),
      );
  }

  public canViewTascWallet(): Observable<TascWalletRedirect> {
    // Show when:
    // User has a card
    // OR
    // User is enrolled in at least one plan and one of the following is true:
    // * A BP allows card as a request submission method AND Disbursable date not mandatory for disbursements
    // * A BP disallows card as a request submission method AND BP allows card issuance AND Client allows card for disbursements
    // * A BP allows card as a request submission method AND Disbursable date mandatory for disbursements AND Disbursable date is in past
    return this.cardQuery.selectAllWhenLoaded()
      .pipe(
        switchMap((cards) => {
          const userHasCards = !!cards && !!cards.length;
          if (userHasCards) {
            return of({ canViewTascWallet: true });
          }
          if (this.brand.hideWalletWithoutCard) {
            return of({ canViewTascWallet: false, redirectRoute: '/dashboard' });
          }
          return this.benefitAccountBalanceQuery.selectActiveBenefitAccounts()
            .pipe(
              switchMap((benefitAccounts) => {
                const enrolledPlanIds = benefitAccounts.map((ba) => ba.planId);
                if (!enrolledPlanIds.length) {
                  return of({ canViewTascWallet: false, redirectRoute: '/dashboard' });
                }
                return zip(
                  this.benefitPlanQuery.selectManyWhenLoaded(enrolledPlanIds),
                  this.clientQuery.selectActiveWhenLoaded(),
                ).pipe(
                  map(([benefitPlans, client]) => {
                    const canViewTascWallet = benefitPlans.some((benefitPlan) => {
                      if ((benefitPlan.allowCardSubmission && !benefitPlan.disbursableDateMandatoryForDisbursements) ||
                        (!benefitPlan.allowCardSubmission && !benefitPlan.restrictCardIssuance && client.allowDisbursement)) {
                        return true;
                      }
                      if (benefitPlan.allowCardSubmission && benefitPlan.disbursableDateMandatoryForDisbursements) {
                        const benefitAccount = benefitAccounts.find((ba) => ba.planId === benefitPlan.id);
                        return !!benefitAccount.disbursableDate && Dates.isSameOrBefore(benefitAccount.disbursableDate, Dates.now());
                      }
                    });
                    return { canViewTascWallet, redirectRoute: '/dashboard' };
                  }),
                );
              }),
            );
        }),
      );
  }

  public getHistoryCardsWhenLoaded(): Observable<Card[]> {
    const historyCardStates: ReadonlyArray<CardState> = [
      CardState.Active,
      CardState.BlockedWithFraud,
      CardState.BlockedWithoutFraud,
      CardState.Closed,
      CardState.Expired,
      CardState.PendingClosed,
      CardState.Reissued,
    ];

    return this.cardQuery.selectAllWhenLoaded()
      .pipe(
        map((cards) => cards.filter((c) => historyCardStates.includes(c.currentState as CardState))),
      );
  }
}
