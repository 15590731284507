import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { AttachmentContent } from 'src/app/shared/models/pux/attachment-content';

import { AttachmentContentState, AttachmentContentStore } from './attachment-content.store';

@Injectable({
  providedIn: 'root',
})
export class AttachmentContentQuery extends QueryEntity<AttachmentContentState> {
  public constructor(protected store: AttachmentContentStore) {
    super(store);
  }

  public selectEntityWhenLoaded(id: string): Observable<AttachmentContent> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectEntity(id)),
      );
  }
}
