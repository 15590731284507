import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { RaygunService } from 'src/app/raygun/raygun.service';
import { CurrentUserQuery } from 'src/app/state';

@Injectable({
  providedIn: 'root',
})
export class PhoneGuard implements CanActivate {
  public constructor(
    private currentUserQuery: CurrentUserQuery,
    private raygunService: RaygunService,
    private router: Router,
  ) { }

  public canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    try {
      const phoneNumber = this.currentUserQuery.getCognitoPhoneNumber();
      if (!phoneNumber) {
        return this.router.parseUrl('/login');
      }
      return true;
    } catch (error) {
      this.raygunService.logError('Error in phone.guard.ts:canActivate()', { error });
      return this.router.parseUrl('/logout');
    }
  }
}
