import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { Entry, EntryType } from 'src/app/shared/models/uba/account/model';

import { EntryState, EntryStore } from './entry.store';

@Injectable({
  providedIn: 'root',
})
export class EntryQuery extends QueryEntity<EntryState> {
  public constructor(protected store: EntryStore) {
    super(store);
  }

  public selectInvestmentEntriesByBenefitAccount(benefitAccountId: string): Observable<Entry[]> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectAll({
          filterBy: (entry) => entry.parentId === benefitAccountId && entry.entryType === EntryType.InvestmentSweep,
        })),
      );
  }
}
