import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaymentAccountGroupService } from '@app/enrollment/services/paymentAccountGroup.service';
import { ActiveState, EntityState, EntityStore, StoreConfig, withTransaction } from '@datorama/akita';
import { EligibleEligibilityEvent } from '@models/profileConfiguration/model';
import { Observable } from 'rxjs';
import { catchError, mapTo, tap } from 'rxjs/operators';
import { CoreService } from 'src/app/shared/models/pux';
import { ErrorHandlingService } from 'src/app/shared/services/error-handling.service';
import { Uri } from 'src/app/shared/services/uri';

export interface PaymentEnrollmentViewModelState extends EntityState<EligibleEligibilityEvent, string>, ActiveState { }

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'PaymentEnrollmentViewModel', idKey: 'eligibilityEventId'})
export class PaymentEnrollmentViewModelStore extends EntityStore<PaymentEnrollmentViewModelState> {
  public constructor(
    private http: HttpClient,
    private errorHandlingService: ErrorHandlingService,
  ) {
    super();
  }

  public load(profileId: string): Observable<boolean> {
    const url = new Uri(`/profile/${profileId}/configuration/eligibleEligibilityEvent/search`, CoreService.ProfileConfiguration);
    return this.http.post<EligibleEligibilityEvent[]>(url.toString(), [])
      .pipe(
        withTransaction((res) => {
          this.set(res);
        }),
        mapTo(true),
        catchError(this.errorHandlingService.rxjsErrorHandler()),
      );
  }
}
