/**
 * Encapsulates Transition State Handling
 */
export class Transitions {
    /**
     * The word used to denote a transition;
     */
    public static DENOTATION_WORD = 'To';

    /**
     * Returns the current state from a command name.
     * @param {string} commandName The command
     * @return         A string for the current state
     */
    public static fromState(commandName: string): string {
        return commandName.split(Transitions.DENOTATION_WORD).shift();
    }

    /**
     * Returns the desired state from a command name.
     * @param {string} commandName The command
     * @return         A string for the desired state
     */
    public static toState(commandName: string): string {
        return commandName.split(Transitions.DENOTATION_WORD).pop();
    }

    /**
     * Returns the desired state from a command name.
     * @param {string} commandName The command
     * @return         A string for the desired state
     */
    public static getTransition(fromState: string, toState: string): string {
        return `${fromState}${this.DENOTATION_WORD}${toState}`;
    }
}
