import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { BenefitPlan, BenefitPlanType } from 'src/app/shared/models/uba/configuration/model';

import { BenefitPlanState, BenefitPlanStore } from './benefit-plan.store';

@Injectable({
  providedIn: 'root',
})
export class BenefitPlanQuery extends QueryEntity<BenefitPlanState> {
  public constructor(protected store: BenefitPlanStore) {
    super(store);
  }

  public selectAllWhenLoaded(): Observable<BenefitPlan[]> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectAll()),
      );
  }

  public selectAllIdsWhenLoaded(): Observable<string[]> {
    return this.selectAllWhenLoaded()
      .pipe(
        map((benefitPlans) => benefitPlans.map((bp) => bp.id)),
      );
  }

  public selectEntityWhenLoaded(benefitPlanId: string): Observable<BenefitPlan> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectEntity(benefitPlanId)),
      );
  }

  public selectManyWhenLoaded(benefitPlanIds: string[]): Observable<BenefitPlan[]> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectMany(benefitPlanIds)),
      );
  }

  public selectAllWithInvestmentAccounts(): Observable<BenefitPlan[]> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectAll({
          filterBy: (benefitPlan) => benefitPlan.hasInvestmentAccount,
        })),
      );
  }

  public selectAllWithDependentAssociations(): Observable<BenefitPlan[]> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectAll({
          filterBy: (benefitPlan) => benefitPlan.allowAssociatingDependentsToBenefitAccount,
        })),
      );
  }

  public selectAllTieredPlans(): Observable<BenefitPlan[]> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectAll({
          filterBy: (benefitPlan) => benefitPlan.planType === BenefitPlanType.TieredPayouts,
        })),
      );
  }

  public selectNonTieredPlansWhenLoaded(): Observable<BenefitPlan[]> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectAll({
          filterBy: (benefitPlan) => benefitPlan.planType !== BenefitPlanType.TieredPayouts,
        })),
      );
  }

  public selectSourcePlans(planId: string): Observable<BenefitPlan[]> {
    return this.selectEntityWhenLoaded(planId)
      .pipe(
        switchMap((destinationPlan) => this.selectAll({
          filterBy: (benefitPlan) => destinationPlan.carryoverSourcePlanIds && destinationPlan.carryoverSourcePlanIds.includes(benefitPlan.id),
        })),
      );
  }
}
