import { ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';
import { ModalComponent } from 'src/app/shared/components/forms/modal/modal.component';
import { BrandId } from 'src/app/shared/models/pux';
import { TermsOfUseSection } from 'src/app/shared/models/pux/enum';
import { BrandService } from 'src/app/shared/services/brand.service';

@Component({
  selector: 'app-terms-of-use-modal',
  templateUrl: './terms-of-use-modal.component.html',
  styleUrls: ['./terms-of-use-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TermsOfUseModalComponent {
  @ViewChild(ModalComponent, { static: true }) public modal: ModalComponent;
  @ViewChild('termsIframe', { static: true }) public termsOfUseIframe: ElementRef<HTMLIFrameElement>;
  public readonly brand = this.brandService.getBrandResources();
  public readonly brandId = this.brandService.getBrand();
  public readonly BrandId = BrandId;
  public iframeUrl: string;

  public constructor(
    private brandService: BrandService,
  ) { }

  public printTermsOfUse(): void {
    const iframeElement = this.getTermsOfUseIframeElement();
    iframeElement.contentWindow.print();
  }

  public open(termsOfUseSection?: TermsOfUseSection): void {
    this.iframeUrl = `assets/terms-of-use/${this.brand.termsOfUseFileName}`;
    if (termsOfUseSection === TermsOfUseSection.PrivacyPolicy) {
      this.iframeUrl += '#tasc-privacy-policy';
    }
    this.modal.open();
  }

  public close(): void {
    this.modal.close();
  }

  private getTermsOfUseIframeElement(): HTMLIFrameElement {
    return this.termsOfUseIframe.nativeElement;
  }
}
