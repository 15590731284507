import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { FundsTransferOptions } from '@models/profileConfiguration/model';

export interface FundsTransferOptionsState extends EntityState<FundsTransferOptions> {}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'FundsTransferOptions' })
export class FundsTransferOptionsStore extends EntityStore<FundsTransferOptionsState> {
  public constructor() {
    super();
  }
}
