import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { BenefitEnrollmentViewModel } from '../enrollment/models';
import { EnrollmentService } from '../enrollment/services/enrollment.service';
import { PaymentPageViewModel } from '../payment/models/payment-page-view-model';
import { PaymentPageViewModelService } from '../payment/services/payment-page-view-model.service';
import { BenefitBalance } from '../shared/models/pux/model';
import { BenefitBalanceService } from './benefit-balance/benefit-balance.service';
import { CurrentUserQuery } from './current-user';

@Injectable({
  providedIn: 'root',
})
export class DataScopeService {
  public constructor(
    private benefitBalanceService: BenefitBalanceService,
    private currentUserQuery: CurrentUserQuery,
    private enrollmentService: EnrollmentService,
    private paymentService: PaymentPageViewModelService,
  ) { }

  /**
   * method for getting users application scoped data
   * @returns an observable array of user data
   */
  public applicationScopedData(): Observable<[BenefitBalance[], BenefitEnrollmentViewModel[], PaymentPageViewModel[]]> {
    return this.currentUserQuery.isLoggedIn$
      .pipe(
        switchMap((isLoggedIn) => {
          if (isLoggedIn) {
            return combineLatest([
              this.benefitBalanceService.getBenefitBalances(),
              this.enrollmentService.getBenefitPlansEligibleForEnrollment(),
              this.paymentService.getPaymentViewModels(),
            ]);
          }
          return of(null);
        }),
      );
  }
}
