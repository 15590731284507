import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { cacheable, withTransaction } from '@datorama/akita';
import { BenefitPlan, BenefitPlanRequestType } from '@models/configuration/model';
import { ClassifiedBenefitAccount, RequestSourceType } from '@models/request/model';
import { combineLatest, Observable, of, zip } from 'rxjs';
import { catchError, first, map, mapTo, mergeMap, switchMap, switchMapTo } from 'rxjs/operators';
import { RaygunService } from 'src/app/raygun/raygun.service';
import {
  CoreService,
  DependentBenefitAccessViewModel,
  OperationalBalanceInfoSearchQuery,
  ProfileTieredPlanViewModel,
  SearchQuery,
} from 'src/app/shared/models/pux';
import {
  BenefitAccountBalance,
  BenefitAccountState,
  ChainType,
  DependentBenefitAccess,
  Election,
  ElectionState,
  MatchType,
  OperationalBalanceInfo,
  ParentType,
  SearchCriteria,
} from 'src/app/shared/models/uba/account/model';
import {
  BenefitAccountBalanceQuery,
  BenefitPlanQuery,
  BenefitPlanRequestTypeStore,
  DependentBenefitAccessQuery,
  DependentBenefitAccessStore,
  DependentQuery,
  IndividualQuery,
  OperationalBalanceInfoQuery,
  OperationalBalanceInfoStore,
} from 'src/app/state';
import { ElectionStore } from 'src/app/state/election';
import { v4 as uuid } from 'uuid';

import { Dates } from '../../utils';
import { ErrorHandlingService } from '../error-handling.service';
import { Uri } from '../uri';

const disbursableBenefitAccountStates = [
  BenefitAccountState.Active,
  BenefitAccountState.ALOAWithBenefits,
  BenefitAccountState.GracePeriod,
  BenefitAccountState.RolloverComplete,
  BenefitAccountState.RolloverProcessing,
  BenefitAccountState.RunOut,
];

@Injectable({
  providedIn: 'root',
})
export class BenefitAccountService {
  /**
   * Returns true when the participant is allowed to submit a request today for the specified benefit account.
   */
  public static isDisbursable(benefitAccount: BenefitAccountBalance, benefitPlan: BenefitPlan): boolean {
    const isActiveDisbursable = disbursableBenefitAccountStates.includes(benefitAccount.currentState as BenefitAccountState) &&
      (!benefitPlan.disbursableDateMandatoryForDisbursements ||
        (!!benefitAccount.disbursableDate && Dates.isSameOrBefore(benefitAccount.disbursableDate, Dates.now())));

    const isInactiveDisbursable = (benefitAccount.currentState === BenefitAccountState.Inactive &&
      (Dates.isSameOrAfter(benefitAccount.lastDayToSubmitExpenses, Dates.now())));

    return (isActiveDisbursable || isInactiveDisbursable);
  }

  /**
   * Returns true when a benefit account is not disbursable today but is expected to be disbursable in the future.
   */
  public static isFutureDisbursable(benefitAccount: BenefitAccountBalance, benefitPlan: BenefitPlan): boolean {
    const benefitAccountStates = disbursableBenefitAccountStates.concat(BenefitAccountState.ActiveNonDisbursable);

    return benefitAccountStates.includes(benefitAccount.currentState as BenefitAccountState) &&
      benefitPlan.disbursableDateMandatoryForDisbursements &&
      (!benefitAccount.disbursableDate || ((!!benefitAccount.disbursableDate) && Dates.isAfter(benefitAccount.disbursableDate, Dates.now())));
  }

  public constructor(
    private benefitAccountBalanceQuery: BenefitAccountBalanceQuery,
    private benefitPlanQuery: BenefitPlanQuery,
    private benefitPlanRequestTypeStore: BenefitPlanRequestTypeStore,
    private dependentBenefitAccessQuery: DependentBenefitAccessQuery,
    private dependentBenefitAccessStore: DependentBenefitAccessStore,
    private dependentQuery: DependentQuery,
    private electionStore: ElectionStore,
    private errorHandlingService: ErrorHandlingService,
    private http: HttpClient,
    private individualQuery: IndividualQuery,
    private operationalBalanceInfoQuery: OperationalBalanceInfoQuery,
    private operationalBalanceInfoStore: OperationalBalanceInfoStore,
    private raygunService: RaygunService,
  ) { }

  /**
   * Retrieve the active dependent/benefit account relationships for the specified benefit account.
   * Performs a lazy cache load that invokes the API when data is not present in the Akita store.
   * @param benefitAccountId The benefit account ID
   */
  public getDependentBenefitAccessViewModels(benefitAccountId: string, benefitAccountState?: string): Observable<DependentBenefitAccessViewModel[]> {
    return this.loadDependentBenefitAccesses()
      .pipe(
        switchMapTo(combineLatest([
          benefitAccountState === BenefitAccountState.Reconciliation ?
            this.dependentBenefitAccessQuery.selectAllActiveAndInactiveWhenLoaded() :
            this.dependentBenefitAccessQuery.selectAllActiveWhenLoaded(),
          this.dependentQuery.selectAllWhenLoaded(),
        ])),
        map(([dependentBenefitAccesses, dependents]) => {
          return dependentBenefitAccesses
            .filter((dba) => dba.benefitAccountId === benefitAccountId)
            .map((dependentBenefitAccess) => {
              const dependent = dependents.find((dep) => dep.id === dependentBenefitAccess.parentId);
              const fullName = dependent ? `${dependent.firstName} ${dependent.lastName}` : 'Unknown';
              return {
                dependentId: dependent ? dependent.id : '',
                fullName,
                eligibilityStartDate: dependentBenefitAccess.eligibilityStartDate,
                eligibilityEndDate: dependentBenefitAccess.eligibilityEndDate,
              };
            });
        },
      ),
    );
  }

  /**
   * Retrieve benefit accounts that are disbursable today or at some point in the future.
   *
   * NOTE: A disbursable benefit account is one where the plan allows online submissions AND:
   * BA is INACTIVE AND benefitAccount.lastDayToSubmitExpenses has a value AND it is >= TODAY
   *  OR
   * BA has a disbursable currentState AND
   *   benefitPlan.disbursableDateMandatoryForDisbursements = false OR
   *   benefitPlan.disbursableDateMandatoryForDisbursements = true AND benefitAccount.disbursableDate has a value and it is <= TODAY
   */
  public getPresentAndFutureDisbursableBenefitAccounts(): Observable<BenefitAccountBalance[]> {
    return zip(
      this.benefitAccountBalanceQuery.selectActiveBenefitAccounts(),
      this.benefitAccountBalanceQuery.selectInactiveBenefitAccounts(),
      this.benefitPlanQuery.selectAllWhenLoaded(),
    ).pipe(
      first(),
      map(([activeBenefitAccounts, inactiveBenefitAccounts, benefitPlans]) => {
        const benefitAccounts = activeBenefitAccounts.concat(inactiveBenefitAccounts);
        const enrolledPlanIds = benefitAccounts.map((ba) => ba.planId);
        const enrolledPlans = benefitPlans.filter((bp) => enrolledPlanIds.includes(bp.id));

        return benefitAccounts.filter((benefitAccount) => {
          const benefitPlan = enrolledPlans.find((plan) => benefitAccount.planId === plan.id);
          if (!benefitPlan) {
            this.raygunService.logError('Benefit Plan not found for Benefit Account', { benefitAccount, benefitPlans });
            return false;
          }

          return benefitPlan.allowOnlineSubmission &&
            (
              BenefitAccountService.isDisbursable(benefitAccount, benefitPlan) ||
              BenefitAccountService.isFutureDisbursable(benefitAccount, benefitPlan)
            );
        });
      }),
    );
  }

  /**
   * Retrieve any active benefit accounts that are specifically associated with the dependent.
   * @param dependentId The dependent ID
   */
  public getBenefitAccountsForDependent(dependentId: string): Observable<BenefitAccountBalance[]> {
    return this.loadDependentBenefitAccesses()
      .pipe(
        switchMapTo(combineLatest([
          this.dependentBenefitAccessQuery.selectActiveByDependentWhenLoaded(dependentId),
          this.benefitAccountBalanceQuery.selectActiveBenefitAccounts(),
        ])),
        map(([dependentBenefitAccesses, benefitAccounts]) => {
          return benefitAccounts.filter((ba) => dependentBenefitAccesses.some((dba) => dba.benefitAccountId === ba.id));
        }),
      );
  }

  /**
   * Retrieve elections for a specific benefit account.
   * @param benefitAccountIds An array of benefit account IDs.
   */
  public getElections(benefitAccountIds: string[], instanceId: string): Observable<Election[]> {
    if (!benefitAccountIds || !benefitAccountIds.length) {
      this.electionStore.set([]);
      return of([]);
    }

    const criteria: SearchCriteria = [
      {
        key: 'parentId',
        value: benefitAccountIds.join('|'),
        matchType: MatchType.IN,
        chainType: ChainType.AND,
      },
      {
        key: 'parentType',
        value: ParentType.BENEFIT_ACCOUNT,
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      },
      {
        key: 'currentState',
        value: ElectionState.Inactive,
        matchType: MatchType.NOT,
      },
    ];

    const query: SearchQuery = {
      orderBy: 'created',
      orderDirection: 'asc',
    };

    const uri = new Uri(`/profile/${instanceId}/election/search`, CoreService.Account, query);

    return this.http.post<Election[]>(uri.toString(), criteria)
      .pipe(
        withTransaction((elections) => this.electionStore.set(elections)),
        catchError(this.errorHandlingService.rxjsErrorHandler()),
      );
  }

  public getMatchingBenefitAccounts(serviceDate: string, submissionDate: string): Observable<ClassifiedBenefitAccount[]> {
    const searchData: SearchCriteria = [
      {
        key: 'serviceDate',
        value: serviceDate,
        matchType: MatchType.EXACT,
      },
      {
        key: 'submissionDate',
        value: submissionDate,
        matchType: MatchType.EXACT,
      },
      {
        key: 'sourceId',
        value: RequestSourceType.PUX,
      },
    ];

    const individualId = this.individualQuery.getActiveId();
    const uri = new Uri(`/profile/${individualId}/classifiedBenefitAccount/search`, CoreService.Request);
    return this.http.post<ClassifiedBenefitAccount[]>(uri.toString(), searchData);
  }

  /**
   * Retrieve the dependent/benefit account relationships from the API and store in Akita.
   * If they already exist in the cache or if no benefit plans are configured to allow dependent
   * associations, don't bother making an API call. Call this function before invoking any query
   * methods in DependentBenefitAccessQuery to ensure the data has been loaded.
   */
  public loadDependentBenefitAccesses(): Observable<void> {
    return combineLatest([
      this.benefitPlanQuery.selectAllWithDependentAssociations(),
      this.benefitAccountBalanceQuery.selectVisibleBenefitAccounts(),
    ]).pipe(
      map(([benefitPlans, benefitAccounts]) => {
        return benefitAccounts
          .filter((ba) => benefitPlans.some((bp) => bp.id === ba.planId))
          .map((ba) => ba.id);
      }),
      switchMap((benefitAccountIds) => {
        if (!benefitAccountIds || !benefitAccountIds.length) {
          this.dependentBenefitAccessStore.set([]);
          return of(null);
        }

        const criteria: SearchCriteria = [
          {
            key: 'benefitAccountId',
            value: benefitAccountIds.join('|'),
            matchType: MatchType.IN,
          },
        ];

        const uri = new Uri(`/profile/${this.individualQuery.getActiveId()}/dependentBenefitAccess/search`, CoreService.Account);

        const request$ = this.http.post<DependentBenefitAccess[]>(uri.toString(), criteria)
          .pipe(
            withTransaction((items) => this.dependentBenefitAccessStore.set(items)),
          );

        return cacheable(this.dependentBenefitAccessStore, request$, { emitNext: true });
      }),
      mapTo(null),
    );
  }

  public loadRequestTypes(benefitPlanIds: string[]): Observable<void> {
    const searchData: SearchCriteria = [
      {
        key: 'parentId',
        value: benefitPlanIds.join('|'),
        matchType: MatchType.IN,
        chainType: ChainType.AND,
      },
      {
        key: 'parentType',
        value: ParentType.BENEFIT_PLAN,
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      },
    ];
    const individualId = this.individualQuery.getActiveId();
    const uri = new Uri(`/profile/${individualId}/configuration/benefitPlan/*/requestType/search`, CoreService.Configuration);
    const request$ = this.http.post<BenefitPlanRequestType[]>(uri.toString(), searchData)
      .pipe(
        withTransaction((benefitPlanRequestTypes) => this.benefitPlanRequestTypeStore.set(benefitPlanRequestTypes)),
      );
    return cacheable(this.benefitPlanRequestTypeStore, request$, { emitNext: true })
      .pipe(
        mapTo(null),
      );
  }

  /**
   * Retrieve the tiered plan details for the specified benefit account.
   * Performs a lazy cache load that invokes the API when data is not present in the Akita store.
   * @param benefitAccountId The benefit account ID
   */
  public getProfileTieredPlanViewModels(benefitAccountId: string): Observable<ProfileTieredPlanViewModel[]> {
    return this.loadOperationalBalanceInfos()
      .pipe(
        switchMapTo(this.operationalBalanceInfoQuery.selectByBenefitAccount(benefitAccountId)),
        map((operationalBalanceInfos) => {
          return operationalBalanceInfos.map((operationalBalanceInfo) => {
            return {
              fullName: operationalBalanceInfo.profileName,
              deductibleForProfile: operationalBalanceInfo.deductibleBalance?.totalDeductibleForProfile || 0,
              deductibleForGroup: operationalBalanceInfo.deductibleBalance?.totalDeductibleForGroup || 0,
              hasPlanDeductible: operationalBalanceInfo.deductibleBalance?.planDeductibleForGroup > 0 || operationalBalanceInfo.deductibleBalance?.planDeductibleForEachPerson > 0,
              planDeductibleForGroup: operationalBalanceInfo.deductibleBalance?.planDeductibleForGroup || 0,
              planDeductibleForPerson: operationalBalanceInfo.deductibleBalance?.planDeductibleForEachPerson || 0,
              payoutForProfile: operationalBalanceInfo.payoutBalance?.totalPayoutForProfile || 0,
              payoutForGroup: operationalBalanceInfo.payoutBalance?.totalPayoutForGroup || 0,
            };
          });
        },
      ),
    );
  }

  /**
   * Retrieve the tiered payout information from the API and store in Akita.
   * If they already exist in the cache or if the participant is not enrolled in any tiered plans,
   * don't bother calling the API. Call this function before invoking any query
   * methods in OperationalBalanceInfoQuery to ensure the data has been loaded.
   */
  private loadOperationalBalanceInfos(): Observable<void> {
    return combineLatest([
      this.benefitPlanQuery.selectAllTieredPlans(),
      this.benefitAccountBalanceQuery.selectVisibleBenefitAccounts(),
    ]).pipe(
      map(([benefitPlans, benefitAccounts]) => {
        return benefitAccounts
          .filter((ba) => benefitPlans.some((bp) => bp.id === ba.planId))
          .map((ba) => ba.id);
      }),
      switchMap((benefitAccountIds) => {
        if (!benefitAccountIds || !benefitAccountIds.length) {
          this.operationalBalanceInfoStore.set([]);
          return of(null);
        }

        const requests$ = benefitAccountIds.map((benefitAccountId) => {
          const query: OperationalBalanceInfoSearchQuery = {
            benefitAccountId,
          };
          const uri = new Uri(`/profile/${this.individualQuery.getActiveId()}/operationalBalanceInfo/search`, CoreService.Account, query);
          return this.http.post<OperationalBalanceInfo[]>(uri.toString(), null);
        });

        const mergedRequests$ = zip(...requests$)
          .pipe(
            mergeMap((infos) => infos),
            map((infos) => {
              return infos.map((info) => {
                return {
                  ...info,
                  id: uuid(),
                };
              });
            }),
            withTransaction((infos) => this.operationalBalanceInfoStore.set(infos)),
          );

        return cacheable(this.operationalBalanceInfoStore, mergedRequests$, { emitNext: true });
      }),
      mapTo(null),
    );
  }
}
