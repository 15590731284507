import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { withTransaction } from '@datorama/akita';
import {
  BenefitAccount,
  BenefitAccountBalance,
  BenefitAccountCommand,
  BenefitAccountState,
  Election,
  ElectionCalculationMethod,
  ElectionCommandType,
  ElectionProfileType,
  ElectionState,
  EnrollmentSourceType,
  MatchType,
  ParentType,
} from '@models/account/model';
import {
  BenefitPlan,
  BenefitPlanType,
} from '@models/configuration/model';
import { Client, EligibilityClass, EligibilityEffectiveDateType } from '@models/profile/model';
import { EmploymentInfo, PayrollSchedule } from '@models/profileConfiguration/model';
import dayjs from 'dayjs';
import { of as observableOf } from 'rxjs';
import { combineLatest, Observable, of, timer, zip } from 'rxjs';
import { catchError, exhaustMap, first as getFirst, map, startWith, switchMap } from 'rxjs/operators';
import { BenefitPlansService } from 'src/app/benefit-accounts/services/benefit-plans.service';
import { DependentService } from 'src/app/reimbursement/service/dependent.service';
import { CoreService } from 'src/app/shared/models/pux/enum';
import { BrandService } from 'src/app/shared/services/brand.service';
import { ErrorHandlingService } from 'src/app/shared/services/error-handling.service';
import { Uri } from 'src/app/shared/services/uri';
import { Clone } from 'src/app/shared/utils/clone';
import { CommandFactory } from 'src/app/shared/utils/command.factory';
import { Dates } from 'src/app/shared/utils/dates';
import { Transitions } from 'src/app/shared/utils/transitions';
import {
  BenefitAccountBalanceQuery,
  BenefitAccountBalanceStore,
  BenefitPlanFundingSourceAndScheduleQuery,
  BenefitPlanQuery,
  ClientQuery,
  DependentQuery,
  EmploymentInfoQuery,
  IndividualQuery,
  PayrollQuery,
  PayrollScheduleQuery,
} from 'src/app/state';
import { ElectionQuery, ElectionStore } from 'src/app/state/election';
import { v4 as uuid } from 'uuid';

import { BenefitEnrollmentViewModel } from '../models';
import { BenefitEnrollmentViewModelQuery, BenefitEnrollmentViewModelStore, EnrollmentListStore } from '../ui-state';

@Injectable({
  providedIn: 'root',
})
export class EnrollmentService {
  private readonly brand = this.brandService.getBrandResources();

  public constructor(
    private benefitAccountBalanceQuery: BenefitAccountBalanceQuery,
    private benefitAccountBalanceStore: BenefitAccountBalanceStore,
    private benefitPlanFundingSourceAndScheduleQuery: BenefitPlanFundingSourceAndScheduleQuery,
    private benefitPlanQuery: BenefitPlanQuery,
    private brandService: BrandService,
    private clientQuery: ClientQuery,
    private commandFactory: CommandFactory,
    private dependentQuery: DependentQuery,
    private dependentService: DependentService,
    private electionQuery: ElectionQuery,
    private electionStore: ElectionStore,
    private employmentInfoQuery: EmploymentInfoQuery,
    private benefitEnrollmentViewModelQuery: BenefitEnrollmentViewModelQuery,
    private benefitEnrollmentViewModelStore: BenefitEnrollmentViewModelStore,
    private errorHandlingService: ErrorHandlingService,
    private http: HttpClient,
    private individualQuery: IndividualQuery,
    private payrollQuery: PayrollQuery,
    private payrollScheduleQuery: PayrollScheduleQuery,
    private benefitPlansService: BenefitPlansService,
  ) { }

  public getBenefitPlansEligibleForEnrollment(): Observable<BenefitEnrollmentViewModel[]> {
    return this.employmentInfoQuery.selectActiveWhenLoaded().pipe(
      switchMap((employmentInfo) => {
        return combineLatest([
          of(employmentInfo),
          this.getPlansEligibleForEnrollment(employmentInfo?.individualId),
        ]);
      }),
      switchMap(([employmentInfo, eligibleBenefitPlans]) => {
        if (!employmentInfo || !eligibleBenefitPlans || !eligibleBenefitPlans.length) {
          return of(new Array<BenefitEnrollmentViewModel>());
        }
        const viewModelObservables = eligibleBenefitPlans.map((benefitPlan) => {
          return this.getBenefitAccount(benefitPlan)
            .pipe(
              switchMap(({ benefitAccount, isNew }) => {
                return combineLatest([
                  combineLatest([
                    this.benefitPlanFundingSourceAndScheduleQuery.selectByBenefitPlan(benefitPlan, true),
                    this.getIndividualElection(benefitAccount, benefitPlan),
                    this.individualQuery.selectActiveWhenLoaded(),
                    this.payrollQuery.selectActiveWhenLoaded(),
                  ]),
                  combineLatest([
                    this.payrollScheduleQuery.selectByPayrollAndBenefitPlan(employmentInfo.clientPayrollId, benefitPlan),
                    this.getClientElection(benefitAccount),
                    this.dependentQuery.selectAllWhenLoaded(),
                    this.dependentService.getDependentsForBenefitAccount(benefitAccount.id),
                  ]),
                ])
                  .pipe(
                    map(([[benefitPlanFundingSourcesAndSchedules, individualElection, individual, payroll], [payrollSchedule, clientElection, dependents, associatedDependents]]) => {
                      return new BenefitEnrollmentViewModel({
                        benefitPlan,
                        benefitPlanFundingSourcesAndSchedules,
                        benefitAccount,
                        individualElection,
                        individual,
                        payroll,
                        payrollSchedule,
                        clientElection,
                        dependents,
                        associatedDependents,
                        isNewBenefitAccount: isNew,
                      });
                    }),
                  );
              }),
            );
        });
        return combineLatest(viewModelObservables);
      }),
      withTransaction((benefitEnrollmentViewModels) => this.benefitEnrollmentViewModelStore.set(benefitEnrollmentViewModels)),
      catchError(this.errorHandlingService.rxjsErrorHandler(() => `Something went wrong. Please contact ${this.brand.companyName} at ${this.brand.phoneNumber}.`)),
    );
  }

  public isMissingPayrollSchedule(): Observable<boolean> {
    return zip(
      this.employmentInfoQuery.selectActiveWhenLoaded(),
      this.benefitPlanQuery.selectAllWhenLoaded(),
      this.payrollScheduleQuery.selectAllWhenLoaded(),
    ).pipe(
      map(([employmentInfo, benefitPlans, payrollSchedules]) => {
        if (employmentInfo.clientPayrollId) {
          return false;
        }
        return benefitPlans.some((benefitPlan) => {
          const benefitPlanHasPayrollSchedule = payrollSchedules.some((payrollSchedule) => payrollSchedule.parentId === benefitPlan.id);
          return benefitPlanHasPayrollSchedule;
        });
      }),
    );
  }

  public setActiveEnrollment(benefitPlanId?: string): void {
    this.benefitEnrollmentViewModelStore.setActive(benefitPlanId || null);
  }

  public unenrollBenefitAccount(benefitPlan: BenefitPlan, benefitAccount: BenefitAccountBalance): Observable<BenefitEnrollmentViewModel> {
    const commandType = Transitions.getTransition(benefitAccount.currentState, BenefitAccountState.Unenrolled);
    const accountToRemove = Clone.deep(benefitAccount);

    const command: BenefitAccountCommand = this.commandFactory.createCommand(accountToRemove, commandType);

    const url = new Uri(
      `/profile/${benefitAccount.parentId}/benefitAccount/${benefitAccount.id}/command/${commandType}`,
      CoreService.Account,
    );

    return this.http.put<void>(url.toString(), command)
      .pipe(
        withTransaction(() => {
          this.benefitAccountBalanceStore.remove(benefitAccount.id);
          this.electionStore.removeBenefitAccountElections(benefitAccount.id);
        }),
        switchMap(() => this.benefitEnrollmentViewModelQuery.selectEntity(benefitPlan.id)),
        catchError(this.errorHandlingService.rxjsErrorHandler(() => 'There was a problem deleting the selected Benefit Account Enrollment.')),
      );

  }

  public enrollBenefitAccount(benefitPlan: BenefitPlan, benefitAccount: BenefitAccountBalance, individualElection?: Election, clientElection?: Election): Observable<BenefitEnrollmentViewModel> {
    const fromState = benefitAccount.currentState || BenefitAccountState.Start;
    const isIndElectionChange: boolean = (fromState !== BenefitAccountState.Start && !!individualElection) ? true : false;
    let toState: string;
    if (fromState === BenefitAccountState.Start) {
      toState = benefitPlan.enrollmentApprovalBeforeInitiation ? BenefitAccountState.PendingApproval : BenefitAccountState.Enrolled;
    } else {
      // self to self transition when the benefit account is already created
      toState = fromState;
    }

    const commandType = Transitions.getTransition(fromState, toState);
    const account = Clone.deep(benefitAccount);
    const command: BenefitAccountCommand = this.commandFactory.createCommand(account, commandType);

    if (individualElection) {
      // create a new election
      if (isIndElectionChange) {
        individualElection.id = uuid();
      }
      command.individualElection = {
        ...individualElection,
        created: command.data.created,
        createdBy: command.data.createdBy,
        createdById: command.data.createdById,
        updated: command.data.updated,
        updatedBy: command.data.updatedBy,
        updatedById: command.data.updatedById,
      };
    }
    if (clientElection) {
      command.clientElection = {
        ...clientElection,
        updated: command.data.updated,
        updatedBy: command.data.updatedBy,
        updatedById: command.data.updatedById,
      };
    }
    const url = new Uri(
      `/profile/${benefitAccount.parentId}/benefitAccount/${benefitAccount.id}/command/${commandType}`,
      CoreService.Account,
    );

    return this.http.put<void>(url.toString(), command)
      .pipe(
        withTransaction(() => {
          this.benefitAccountBalanceStore.upsert(command.data.id, command.data);
          if (command.clientElection) {
            this.electionStore.upsert(command.clientElection.id, command.clientElection);
          }
          if (command.individualElection) {
            this.electionStore.upsert(command.individualElection.id, command.individualElection);
          }
        }),
        switchMap(() => this.waitForBenefitAccountToBeCreated(5000, benefitAccount)),
        switchMap((ubaBenefitAccount) => {
          // StartToX benefit account commands create the elections. Any self to self commands after start do not.
          // If the user edits the enrollment, we need to update the election amount as well.
          if (isIndElectionChange) {
            const electionCommand = ubaBenefitAccount.currentState === BenefitAccountState.PendingApproval ? ElectionCommandType.StartToPendingApproval : ElectionCommandType.StartToPending;
            return this.saveElection(individualElection, electionCommand);
          }
          return of(null);
        }),
        switchMap(() => this.benefitEnrollmentViewModelQuery.selectEntity(benefitPlan.id)),
        catchError(this.errorHandlingService.rxjsErrorHandler(() => `Something went wrong. Please contact ${this.brand.companyName} at ${this.brand.phoneNumber}.`)),
      );
  }

  public dispatchNewElectionCommand(election: Election, benefitAccount: BenefitAccount, benefitPlan: BenefitPlan): Observable<void> {
    const electionCommandType = benefitPlan.enrollmentApprovalBeforeInitiation ? ElectionCommandType.StartToPendingApproval : ElectionCommandType.StartToPending;
    const command = this.commandFactory.createCommand(election, electionCommandType);
    const url = new Uri(
      `/profile/${benefitAccount.parentId}/election/${election.id}/command/${electionCommandType}`,
      CoreService.Account,
    );

    return this.http.put<void>(url.toString(), command).pipe(
      withTransaction(() => this.electionStore.upsert(election.id, election)),
      catchError(this.errorHandlingService.rxjsErrorHandler(() => `Something went wrong. Please contact ${this.brand.companyName} at ${this.brand.phoneNumber}.`)),
    );
  }

  public createBenefitAccount(benefitPlan: BenefitPlan): Observable<BenefitAccountBalance> {
    return zip(
      this.getBenefitEffectiveDate(benefitPlan),
      this.employmentInfoQuery.selectActiveWhenLoaded(),
    ).pipe(
      switchMap(([benefitEffectiveDate, employmentInfo]) => this.payrollScheduleQuery.selectByPayrollAndBenefitPlan(employmentInfo.clientPayrollId, benefitPlan)
        .pipe(
          map((payrollSchedule) => ({ benefitEffectiveDate, employmentInfo, payrollSchedule })),
        )),
      map(({ benefitEffectiveDate, employmentInfo, payrollSchedule }) => {
        const firstPayrollDate = this.getFirstPayrollDate(benefitEffectiveDate, payrollSchedule);
        const benefitAccount: BenefitAccountBalance = {
          id: uuid(),
          parentId: employmentInfo.individualId,
          parentType: ParentType.INDIVIDUAL,
          clientId: employmentInfo.parentId, // taken from Admin UI logic
          planId: benefitPlan.id,
          planName: benefitPlan.externalName,
          planDescription: benefitPlan.externalDescription,
          planStartDate: benefitPlan.planStartDate,
          planEndDate: benefitPlan.planEndDate,
          scheduleEndDate: benefitPlan.scheduleEndDate,
          hireDate: employmentInfo.hireDate,
          clientScheduleFirstDate: benefitPlan.planStartDate,
          eligibilityEndDate: benefitPlan.finalExpenseDate, // taken from Admin UI logic
          currentState: BenefitAccountState.Start,
          carryoverDeclined: false,
          excludeFromBilling: false,
          enrollmentSource: EnrollmentSourceType.ParticipantWeb,
          individualScheduleFirstDate: firstPayrollDate,
          payrollScheduleId: payrollSchedule && payrollSchedule.id,
          benefitEffectiveDate,
          balance: 0,
        };
        if (benefitPlan.hasInvestmentAccount) { // taken from Admin UI logic
          benefitAccount.cashAccountMinimumBalance = benefitPlan.cashAccountMinimumBalance;
        }
        return benefitAccount;
      }),
    );
  }

  /**
   * Save election for benefit accounts.
   * @param individualElection This is the election you are saving.
   * @param commandType The transition for the API we are using to save the election.
   */
  public saveElection(individualElection: Election, commandType: ElectionCommandType): Observable<void> {
    const command = this.commandFactory.createCommand(individualElection, commandType);
    const uri = new Uri(`/profile/*/election/${individualElection.id}/command/${commandType}`, CoreService.Account);
    return this.http.put<void>(uri.toString(), command);
  }

  public getDefaultElectionCalculationMethod(benefitPlan: BenefitPlan): ElectionCalculationMethod {
    return benefitPlan.planType === BenefitPlanType.TieredPayouts
      ? ElectionCalculationMethod.System
      : ElectionCalculationMethod.User;
  }

  private waitForBenefitAccountToBeCreated(intervalMS: number, benefitAccount: BenefitAccount): Observable<BenefitAccount> {
    const url = new Uri(
      `/profile/${benefitAccount.parentId}/benefitAccount/search`,
      CoreService.Account,
      {
        skip: 0,
        take: 1,
      },
    );
    const criteria = [{
      key: 'planId',
      value: benefitAccount.planId,
      matchType: MatchType.EXACT,
    }];

    return timer(0, intervalMS)
      .pipe(
        exhaustMap(() => this.http.post<BenefitAccount[]>(url.toString(), criteria)),
        getFirst((benefitAccounts) => benefitAccounts.length > 0),
        switchMap((benefitAccounts) => {
          return observableOf(benefitAccounts[0]);
        }),
      );
  }

  private getBenefitAccount(benefitPlan: BenefitPlan): Observable<{ benefitAccount: BenefitAccountBalance, isNew: boolean }> {
    return this.benefitAccountBalanceQuery.selectEnrolledBenefitAccount(benefitPlan.id)
      .pipe(
        switchMap((benefitAccount) => {
          if (benefitAccount) {
            return of({ benefitAccount, isNew: false });
          }
          return this.createBenefitAccount(benefitPlan).pipe(map((newBenefitAccount) => ({ benefitAccount: newBenefitAccount, isNew: true })));
        }),
      );
  }

  /**
   * Returns the client elections for a benefit account. At most, two at one time.
   *
   * Ex:
   * electionProfileType=Client, currentState=Pending
   * electionProfileType=Client, currentState=Active
   *
   * @param benefitAccount current account being enrolled
   */
  private getClientElection(benefitAccount: BenefitAccountBalance): Observable<Election> {
    return this.electionQuery.selectPendingByBenefitAccount(benefitAccount.id, ElectionProfileType.Client)
      .pipe(
        map((e) => this.getMostRecentElection(e)),
      );
  }

  /**
   * Returns the individual elections for a benefit account. At most, two at one time.
   *
   * Ex:
   * electionProfileType=Individual, currentState=Pending
   * electionProfileType=Individual, currentState=Active
   *
   * @param benefitAccount current account being enrolled
   */
  private getIndividualElection(benefitAccount: BenefitAccountBalance, benefitPlan: BenefitPlan): Observable<Election> {
    return this.electionQuery.selectPendingByBenefitAccount(benefitAccount.id, ElectionProfileType.Individual)
      .pipe(
        map((e) => this.getMostRecentElection(e)),
        switchMap((election) => {
          if (election) {
            return of(election);
          }

          const individualElection: Election = {
            id: uuid(),
            currentState: benefitAccount.currentState === BenefitAccountState.PendingApproval ? ElectionState.PendingApproval : ElectionState.Pending,
            calculationMethod: this.getDefaultElectionCalculationMethod(benefitPlan),
            lastTransition: `StartTo${benefitAccount.currentState === BenefitAccountState.PendingApproval ? ElectionState.PendingApproval : ElectionState.Pending}`,
            electionProfileType: ElectionProfileType.Individual,
            electionScheduleType: benefitPlan.electionScheduleType,
            effectiveDate: benefitAccount.benefitEffectiveDate,
            parentType: ParentType.BENEFIT_ACCOUNT,
            parentId: benefitAccount.id,
            amount: null,
          };
          return of(individualElection);
        }),
      );
  }

  private getMostRecentElection(elections: Election[]): Election | null {
    const pendingElection = elections.find((e) => [ElectionState.Pending, ElectionState.PendingApproval].includes(e.currentState as ElectionState));
    const [activeLatestElection] = elections.filter((e) => e.currentState === ElectionState.Active).sort((a, b) => Dates.isSameOrAfter(a.effectiveDate, b.effectiveDate) ? -1 : 1);

    return pendingElection || activeLatestElection || null;
  }

  private getBenefitEffectiveDate(benefitPlan: BenefitPlan): Observable<string> {
    const today = Dates.now();
    return this.getEligibilityEffectiveDate()
      .pipe(
        map((eligibilityEffectiveDate) => {
          if (Dates.isSameOrBefore(eligibilityEffectiveDate, today)) {
            return benefitPlan.planStartDate;
          }
          if (Dates.isSameOrBefore(eligibilityEffectiveDate, benefitPlan.planStartDate)) {
            return benefitPlan.planStartDate;
          }
          return Dates.format(eligibilityEffectiveDate);
        }),
      );
  }

  private getEligibilityEffectiveDate(): Observable<dayjs.Dayjs> {
    return zip(
      this.employmentInfoQuery.selectActiveWhenLoaded(),
      this.clientQuery.selectActiveWhenLoaded(),
    ).pipe(
      map(([employmentInfo, client]) => {
        const eligibilityEffectiveDate = Dates.fromString(employmentInfo.hireDate);
        const eligibilityClass = this.getEligibilityClass(employmentInfo, client);
        const waitingPeriod = eligibilityClass.waitingPeriod;
        switch (eligibilityClass.eligibilityEffectiveDate) {
          case EligibilityEffectiveDateType.SameDay:
            return eligibilityEffectiveDate.add(waitingPeriod, 'day');
          case EligibilityEffectiveDateType.FirstDay:
            return eligibilityEffectiveDate.add(waitingPeriod ? waitingPeriod + 1 : 1, 'day');
          case EligibilityEffectiveDateType.FirstOfMonth:
            return eligibilityEffectiveDate.add(waitingPeriod, 'day').add(1, 'month').startOf('month');
        }
      }),
    );
  }

  private getFirstPayrollDate(benefitEffectiveDate: string, payrollSchedule: PayrollSchedule): string {
    if (payrollSchedule) {
      for (const payDate of payrollSchedule.payrollDates) {
        if (payDate >= benefitEffectiveDate) {
          return payDate;
        }
      }
    }
    return null;
  }

  private getEligibilityClass(employmentInfo: EmploymentInfo, client: Client): EligibilityClass {
    const eligiblityClassId = employmentInfo.eligibilityClassId;
    const eligibilityClass = eligiblityClassId && client.allowEligibilityClasses && client.eligibilityClasses.find((eliClass) => eliClass.classId === eligiblityClassId);
    if (eligibilityClass) {
      return eligibilityClass;
    }
    return {
      classId: null,
      eligibilityEffectiveDate: client.eligibilityEffectiveDate,
      name: '',
      waitingPeriod: client.waitingPeriod,
    };
  }

  private getPlansEligibleForEnrollment(individualId?: string): Observable<BenefitPlan[] | null> {
    if (!individualId) {
      return of(null);
    }
    return this.benefitPlansService.getPlansEligibleForEnrollment(individualId);
  }
}
