import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { AuthenticationChallengeType } from 'src/app/auth/models/authentication-challenge.model';

import { CurrentUserState } from './current-user.model';
import { CurrentUserStore } from './current-user.store';

@Injectable({
  providedIn: 'root',
})
export class CurrentUserQuery extends Query<CurrentUserState> {
  public isLoggedIn$ = this.select((state) => !!state.profileId && !!state.idToken);

  public constructor(protected store: CurrentUserStore) {
    super(store);
  }

  public getProfileId(): string {
    return this.get((state) => state.profileId);
  }

  public getCognitoPhoneNumber(): string {
    return this.get((state) => state.cognitoPhoneNumber);
  }

  public getEmailAddress(): string {
    return this.get((state) => state.email);
  }

  public getAuthenticationType(): AuthenticationChallengeType {
    return this.get((state) => state.authenticationType);
  }

  public getJwtToken(): string {
    const idToken = this.get((state) => state.idToken);
    if (idToken && idToken.getJwtToken) {
      return idToken.getJwtToken();
    }

    return null;
  }

  public getRememberedEmailAddress(): string {
    return this.get((state) => state.rememberedEmail);
  }

  public isJwtTokenExpired(): boolean {
    const state = this.getValue();
    return !state || !state.email || !state.idToken || !state.idToken.getExpiration || state.idToken.getExpiration() <= Date.now() / 1000;
  }

  public isRememberUserEnabled(): boolean {
    return !!this.getRememberedEmailAddress();
  }

  private get<T>(project: (state: CurrentUserState) => T): T {
    const state = this.getValue();
    if (state) {
      return project(state);
    }

    return null;
  }
}
