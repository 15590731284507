import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { withTransaction } from '@datorama/akita';
import { BenefitAccountBalance, MatchType, ParentType, SearchCriteria } from '@models/account/model';
import { BenefitPlan } from '@models/configuration/model';
import { TradeActivity, TradeType } from '@models/dashboard/model';
import { merge, Observable, of } from 'rxjs';
import { catchError, map, reduce } from 'rxjs/operators';
import { RaygunService } from 'src/app/raygun/raygun.service';
import { IndividualQuery, TradeActivityStore } from 'src/app/state';
import { CoreService, TradeActivityModel, TransactionActivityEndpointSource, TransactionActivitySearchQuery, TransactionActivityType } from '../../models/pux';
import { Numbers } from '../../utils';
import { Uri } from '../uri';

@Injectable({
  providedIn: 'root',
})
export class TradeService {
  private benefitAccounts: BenefitAccountBalance[];
  private benefitPlans: BenefitPlan[];

  public constructor(
    private tradeActivityStore: TradeActivityStore,
    private individualQuery: IndividualQuery,
    private http: HttpClient,
    private raygunService: RaygunService,
  ) {}

  public getAllTrades(benefitAccounts: BenefitAccountBalance[], benefitPlans: BenefitPlan[]): Observable<TradeActivityModel[]> {
    this.benefitPlans = benefitPlans.filter(({hasInvestmentAccount}) => hasInvestmentAccount);
    this.benefitAccounts = benefitAccounts.filter(({planId}) => this.benefitPlans.some(({id}) => id === planId));
    if (!benefitAccounts.length) {
      return of([]).pipe(withTransaction((res) => this.tradeActivityStore.set(res)));
    }

    return this.aggregateTrades([
      TransactionActivityEndpointSource.PendingTrade,
      TransactionActivityEndpointSource.Trade,
    ]).pipe(
      withTransaction((trades) => this.tradeActivityStore.set(trades)),
    );
  }

  private aggregateTrades(tradeSources: TransactionActivityEndpointSource[]): Observable<TradeActivityModel[]> {
    const requests = tradeSources.map((source) => this.getTradeTransactions(source));
    return merge(...requests).pipe(
      reduce((all, subset) => all.concat(subset)),
    );
  }

  private getTradeTransactions(transactionType: TransactionActivityEndpointSource): Observable<TradeActivityModel[]> {
    if (![
      TransactionActivityEndpointSource.PendingTrade, TransactionActivityEndpointSource.Trade,
    ].includes(transactionType)) {
      throw new Error('Function getTradeTransactions() requires TransactionActivityEndpointSource.PendingTrade or TransactionActivityEndpointSource.Trade as a parameter');
    }

    const criteria: SearchCriteria = [{
      key: 'parentId',
      value: this.benefitAccounts.map(({id}) => id).join('|'),
      matchType: MatchType.IN,
    }, {
      key: 'ParentType',
      value: ParentType.BENEFIT_ACCOUNT,
      matchType: MatchType.EXACT,
    }];

    const query: TransactionActivitySearchQuery = {
      skip: 0,
      take: Number.MAX_SAFE_INTEGER,
      transactionType,
      transactionActivityType: TransactionActivityType.Individual,
    };

    return this.getTransactions(criteria, query);
  }

  private getTransactions(criteria: SearchCriteria, query: TransactionActivitySearchQuery): Observable<TradeActivityModel[]> {
    const individual = this.individualQuery.getActive();

    const getAmountDisplay = (type: TradeType, amount: number) => {
      if (!amount) {
        return null;
      }
      const sign = type === TradeType.Buy
        ? '- '
        : '+ ';
      const currency = Numbers.formatCurrency(amount);
      return sign.concat(currency);
    };

    const URL = new Uri(`/profile/${individual.id}/tradeActivity/search`, CoreService.Dashboard, query);
    return this.http.post<TradeActivity[]>(URL.toString(), criteria).pipe(
      map((trades) => trades.map((trade) => {
        return {
          ...trade,
          uniqueId: trade.tradeId,
          requestedAmountDisplay: getAmountDisplay(trade.type, trade.requestedAmount),
          settledAmountDisplay: getAmountDisplay(trade.type, trade.settledAmount),
        };
      })),
      catchError((error) => {
        this.raygunService.logError('TradeActivity endpoint failed', { criteria, error, query });
        return of([]);
      }),
    );
  }
}
