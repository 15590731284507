import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { EligibilityEventState, EligibleEligibilityEvent } from '@models/profileConfiguration/model';
import { Observable } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { PaymentEnrollmentViewModelState, PaymentEnrollmentViewModelStore } from './payment-enrollment-view-model.store';

@Injectable({
  providedIn: 'root',
})
export class PaymentEnrollmentViewModelQuery extends QueryEntity<PaymentEnrollmentViewModelState> {
  public constructor(
    protected store: PaymentEnrollmentViewModelStore,
    ) {
    super(store);
  }

  public hasOpenEnrollments(): Observable<boolean> {
    return this.selectAllWhenLoaded()
    .pipe(
      map((enrollments) => enrollments.some((enrollment) => !enrollment.paymentAccountGroupId)),
    );
  }

  public selectActiveWhenLoaded(): Observable<EligibleEligibilityEvent> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectActive()),
      );
  }

  public activeEnrollmentHasEnrollments(): Observable<boolean> {
    return this.selectLoading()
    .pipe(
      filter((isLoading) => !isLoading),
      switchMap(() => this.selectActive()),
      map((enrollment) => enrollment.offeringsEligibleData.some((data) => data.offerResponseDate)),
    );
  }

  public selectAllWhenLoaded(): Observable<EligibleEligibilityEvent[]> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectAll()),
      );
  }
}
