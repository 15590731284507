
import { UIOption } from '../models/pux';

export class UIOptionUtil {
  public static createUIOptionsFromEnum<T extends string>(input: Record<string, T>): Array<UIOption<T>> {
    return Object.keys(input)
      .map((key: T) => ({
        value: key,
        label: key.replace(/([a-z])([A-Z])/g, '$1 $2'),
      }) as UIOption<T>);
  }
}
