import { AfterContentInit, Component, ContentChild, ElementRef, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Component({
  selector: 'app-password-textbox',
  templateUrl: './password-textbox.component.html',
  styleUrls: ['./password-textbox.component.scss'],
})
export class PasswordTextboxComponent implements AfterContentInit {
  @Input() public allowShowPassword = true;
  @Input() public label: string;
  @Input() public size: 'sm' | 'lg' | undefined;
  @ContentChild(NgControl, { read: ElementRef, static: true }) public passwordInputRef: ElementRef<HTMLInputElement>;
  public isPasswordVisible: boolean;
  private _originalInputType: string;

  public ngAfterContentInit(): void {
    if (this.passwordInputRef && this.passwordInputRef.nativeElement) {
      if (!this.allowShowPassword || this.passwordInputRef.nativeElement.type === 'password') {
        this.isPasswordVisible = false;
        this.passwordInputRef.nativeElement.type = 'password';
        this._originalInputType = 'text';
      } else {
        this.isPasswordVisible = true;
        this._originalInputType = this.passwordInputRef.nativeElement.type;
      }
    }
  }

  public togglePasswordVisibility(): void {
    this.isPasswordVisible = !this.isPasswordVisible;

    if (this.passwordInputRef && this.passwordInputRef.nativeElement) {
      const inputType = this.isPasswordVisible ? (this._originalInputType || 'text') : 'password';
      this.passwordInputRef.nativeElement.type = inputType;
    }
  }
}
