import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { OperationalBalanceInfoModel } from 'src/app/shared/models/pux';

import { OperationalBalanceInfoEntityState, OperationalBalanceInfoStore } from './operational-balance-info.store';

@Injectable({
  providedIn: 'root',
})
export class OperationalBalanceInfoQuery extends QueryEntity<OperationalBalanceInfoEntityState> {
  public constructor(protected store: OperationalBalanceInfoStore) {
    super(store);
  }

  public selectAllWhenLoaded(): Observable<OperationalBalanceInfoModel[]> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectAll()),
      );
  }

  public selectByBenefitAccount(benefitAccountId: string): Observable<OperationalBalanceInfoModel[]> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectAll({
          filterBy: (operationalBalanceInfo) => operationalBalanceInfo.benefitAccountId === benefitAccountId,
        })),
      );
  }
}
