import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Attachment } from 'src/app/shared/models/uba/file/model';

export interface BillReceiptState extends EntityState<Attachment, string> { }

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'BillReceipt' })
export class BillReceiptStore extends EntityStore<BillReceiptState> {
  public constructor() {
    super();
  }
}
