import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { Request, RequestState as RequestEntityState } from 'src/app/shared/models/uba/request/model';

import { RequestState, RequestStore } from './request.store';

@Injectable({
  providedIn: 'root',
})
export class RequestQuery extends QueryEntity<RequestState> {
  public constructor(protected store: RequestStore) {
    super(store);
  }

  public selectDraftRequests(): Observable<Request[]> {
    return this.selectAll({
      filterBy: (request) => request.currentState === RequestEntityState.Draft,
    });
  }

  public selectEntityWhenLoaded(id: string): Observable<Request> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectEntity(id)),
      );
  }
}
