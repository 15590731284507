import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { ServiceOffering } from 'src/app/shared/models/uba/configuration/model';

export interface HiddenServiceOfferingState extends EntityState<ServiceOffering, string> {}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'HiddenServiceOffering' })
export class HiddenServiceOfferingStore extends EntityStore<HiddenServiceOfferingState> {
  public constructor() {
    super();
  }
}
