import { Injectable } from '@angular/core';
import { Order, Query, QueryConfig } from '@datorama/akita';
import { IndividualPaymentStatus, ObligationsSummary, PaymentAccountEnriched, PaymentAccountGroupEnriched } from '@models/paymentAccount/model';
import { flatten, orderBy } from 'lodash';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { IndividualPaymentStatusStore } from './individual-payment-status.store';

@Injectable({ providedIn: 'root' })
@QueryConfig({
  sortBy: 'created',
  sortByOrder: Order.DESC,
})
export class IndividualPaymentStatusQuery extends Query<IndividualPaymentStatus> {
  public constructor(protected store: IndividualPaymentStatusStore) {
    super(store);
  }

  public selectWhenLoaded(): Observable<IndividualPaymentStatus> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.select()),
        map((individualPaymentStatus) => {
          if (!(individualPaymentStatus && individualPaymentStatus.paymentAccountGroups)) {
            return {
              paymentAccountGroups: [],
            };
          }
          return individualPaymentStatus;
        }),
      );
  }

  public selectPaymentAccountGroupById(paymentAccountGroupId: string): Observable<PaymentAccountGroupEnriched> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.select()),
        map((individualPaymentStatus) => {
          if (!(individualPaymentStatus && individualPaymentStatus.paymentAccountGroups)) {
            return null;
          }
          return individualPaymentStatus.paymentAccountGroups.find((paymentAccountGroup) => paymentAccountGroup.id === paymentAccountGroupId);
        }),
      );
  }

  public selectSortedPaymentAccountGroup(sortBy: keyof PaymentAccountGroupEnriched, desc?: boolean): Observable<PaymentAccountGroupEnriched[]> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.select()),
        map((individualPaymentStatus) => {
          return orderBy(individualPaymentStatus.paymentAccountGroups, (p) => p[sortBy], desc ? 'desc' : 'asc');
        }),
      );
  }

  public selectPaymentAccountGroups(): Observable<PaymentAccountGroupEnriched[]> {
    return this.selectWhenLoaded().pipe(
      map((summary) => summary.paymentAccountGroups),
    );
  }

  public selectPaymentDueGroups(): Observable<PaymentAccountGroupEnriched[]> {
    return this.selectWhenLoaded().pipe(
      map((summary) => summary.paymentAccountGroups
        .filter(({ obligations }) => !!obligations.currentAmountDue) || [],
      ),
    );
  }

  public hasPaymentDue(): Observable<boolean> {
    return this.selectPaymentDueGroups().pipe(
      map((paymentAccountGroups) => paymentAccountGroups?.some((pag) => pag.obligations.unpaidObligations?.length)),
    );
  }

  public selectPaymentDueObligations(): Observable<ObligationsSummary[]> {
    return this.selectPaymentDueGroups().pipe(
      map((pagsEnriched) => flatten(
        pagsEnriched.map(({ obligations }) => obligations),
      )),
    );
  }
}
