import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Dependent } from 'src/app/shared/models/uba/profile/model';

export interface DependentState extends EntityState<Dependent, string> { }

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'Dependent' })
export class DependentStore extends EntityStore<DependentState> {
  public constructor() {
    super();
  }
}
