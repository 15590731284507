import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Card } from '@models/card/model';

export interface CardState extends EntityState<Card, string>, ActiveState {}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'Card' })
export class CardStore extends EntityStore<CardState> {
  public constructor() {
    super();
  }
}
