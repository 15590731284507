import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CoreService } from '@app/shared/models/pux';
import { Uri } from '@app/shared/services/uri';
import { CommandFactory } from '@app/shared/utils/command.factory';
import {
  ChainType,
  EnrollmentSelection,
  MatchType,
  ParentType,
  PaymentAccountGroup,
  PaymentAccountGroupCommandType,
  PaymentAccountGroupState,
  SearchCriteria,
} from '@models/paymentAccount/model';
import { EligibilityEvent, EligibleEligibilityEvent } from '@models/profileConfiguration/model';
import { interval, Observable, of } from 'rxjs';
import { catchError, exhaustMap, first, map, switchMap, takeWhile } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

export interface EnrollmentSelectionEnriched extends EnrollmentSelection {
  eligibilityEventOfferingId: string;
  paymentPlanId?: string;
}
@Injectable({
  providedIn: 'root',
})

export class PaymentAccountGroupService {

  public constructor(
    private http: HttpClient,
    private commandFactory: CommandFactory,
  ) { }

  /**
   * Retrieves all of the payment account groups associated with a given individual.
   * @param individualId The Id of the individual being retrieved.
   * @returns An observable emitting the array of payment account groups
   */
  public getPaymentAccountGroupsByIndividualId(individualId: string): Observable<PaymentAccountGroup[]> {
    return this.fetch$([
      {
        key: 'parentId',
        value: individualId,
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      },
      {
        key: 'parentType',
        value: ParentType.INDIVIDUAL,
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      }]);
  }

  /**
   * transitions a Payment Account Group
   * @param paymentAccountGroup The PaymentAccountGroup to create/update.
   * @param commandType The command being called to save this PaymentAccountGroup
   * @param enrollmentSelections List of enrollment selections
   */
  public updatePaymentAccountGroup(
    paymentAccountGroup: PaymentAccountGroup,
    commandType?: PaymentAccountGroupCommandType,
    enrollmentSelections?: EnrollmentSelection[],
  ): Observable<PaymentAccountGroup> {
    const commandName = commandType || `${paymentAccountGroup.currentState}To${PaymentAccountGroupState.Active}`;
    paymentAccountGroup.version = (paymentAccountGroup.version || 0) + 1;
    const paymentAccountGroupCommandData = this.commandFactory.createCommand(paymentAccountGroup, commandName);
    // @ts-ignore
    paymentAccountGroupCommandData.enrollmentSelections = enrollmentSelections;
    const url = new Uri(
      `/profile/${paymentAccountGroup.firstEligibilityEventId}/paymentAccountGroup/${paymentAccountGroup.id}/command/${commandName}`,
      CoreService.PaymentAccount,
    );
    return this.http.put(url.toString(), paymentAccountGroupCommandData, {
      headers: {
        'x-uba-route': 'queue',
      },
    }).pipe(
      switchMap(() => {
        const criteria: SearchCriteria = [
          { key: 'id', value: paymentAccountGroup.id, matchType: MatchType.EXACT },
          { key: 'version', value: paymentAccountGroup.version.toString(), matchType: MatchType.GREATER_THAN_EQUAL },
        ];
        const startTime = Date.now();
        const timeoutMs = 120000;

        return interval(2000).pipe(
          takeWhile(() => Date.now() - startTime < timeoutMs),
          exhaustMap(() => this.fetch$(criteria)),
          first((pags) => pags?.length && pags[0].currentState !== 'Processing'),
          map((pags) => {
            if (!pags.length) {
              throw Error(`Timed out trying to save elections, operation may still complete successfully.`);
            }

            const polledPag = pags[0];

            if (polledPag.currentState === 'Error') {
              throw Error(`Election has failed to save, please contact us.`);
            }

            return polledPag;
          }),
          catchError((err) => {
            if (err?.name && err?.name === 'EmptyError') {
              throw Error(`Timed out trying to save elections, operation may still complete successfully.`);
            }
            throw err;
          }),
        );
      }),
    );
  }

  public createPaymentAccountGroup(
    eligibilityEvent: EligibilityEvent,
    eligibleEligibilityEvent: EligibleEligibilityEvent,
  ): PaymentAccountGroup {
    return {
      id: uuid(),
      parentId: eligibilityEvent.parentId,
      parentType: ParentType.INDIVIDUAL,
      currentState: 'Start',
      displayName: eligibleEligibilityEvent.displayName,
      firstEligibilityEventId: eligibilityEvent.id,
      serviceOfferingId: eligibleEligibilityEvent.serviceOfferingId,
    };
  }

  public getPaymentAccountGroupByEEId(individualId: string, eligibilityEventId: string): Observable<PaymentAccountGroup> {
    return this.fetch$([
      {
        key: 'parentId',
        value: individualId,
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      },
      {
        key: 'parentType',
        value: ParentType.INDIVIDUAL,
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      },
      {
        key: 'firstEligibilityEventId',
        value: eligibilityEventId,
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      }]).pipe(
        map( (pag) => (pag[0])),
      );
  }

  public getPaymentAccountGroup(id: string): Observable<PaymentAccountGroup> {
    const url = new Uri(`/profile/*/paymentAccountGroup/${id}`, CoreService.PaymentAccount, null);
    return this.http.get<PaymentAccountGroup>(url.toString());
  }

  private fetch$(searchCriteria: SearchCriteria): Observable<PaymentAccountGroup[]> {
    const url = new Uri(`/profile/*/paymentAccountGroup/search/`, CoreService.PaymentAccount, null);
    return this.http.post<PaymentAccountGroup[]>(url.toString(), searchCriteria);
  }

}
