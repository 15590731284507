import { NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PhoneGuard } from '../auth/guards/phone.guard';
import { BrandGuard } from '../shared/guards/brand.guard';
import { NoAuthGuard } from '../shared/guards/no-auth.guard';
import { LoginCodeOnlyComponent } from './components/login-code-only/login-code-only.component';
import { LoginFederatedIdPiPageComponent } from './components/login-federated-IdPi/login-federated-idpi.component';
import { LoginFederatedPageComponent } from './components/login-federated/login-federated.component';
import { LoginFormComponent } from './components/login-form/login-form.component';
import { LoginMFAComponent } from './components/login-mfa/login-mfa.component';
import { LogoutFederatedComponent } from './components/logout-federated/logout-federated.component';
import { LogoutComponent } from './components/logout/logout.component';
import { NewPasswordRequiredComponent } from './components/new-password-required/new-password-required.component';
import { NoBrandComponent } from './components/no-brand/no-brand.component';
import { LoginPageComponent } from './containers/login-page/login-page.component';
import { LoginFederatedIdPiGuard } from './guards/login-federated-idpi.guard';
import { LoginFederatedGuard } from './guards/login-federated.guard';
import { LogoutFederatedGuard } from './guards/logout-federated.guard';

const routes: Routes = [
  {
    path: 'login',
    component: LoginPageComponent,
    canActivate: [NoAuthGuard, BrandGuard],
    children: [
      {
        path: '',
        component: LoginFormComponent,
      },
      {
        path: 'mfa',
        component: LoginMFAComponent,
        canActivate: [PhoneGuard],
      },
      {
        path: 'refund',
        component: LoginCodeOnlyComponent,
      },
    ],
  },
  {
    path: 'login/federated',
    component: LoginFederatedPageComponent,
    canActivate: [LoginFederatedGuard],
  },
  {
    path: 'login/federatedIdPi',
    component: LoginFederatedIdPiPageComponent,
    canActivate: [LoginFederatedIdPiGuard],
  },
  {
    path: 'login/new-password',
    component: NewPasswordRequiredComponent,
    canActivate: [NoAuthGuard],
  },
  {
    path: 'logout',
    component: LogoutComponent,
  },
  {
    path: 'logout/federated',
    component: LogoutFederatedComponent,
    canActivate: [LogoutFederatedGuard],
  },
  {
    path: 'no-brand',
    component: NoBrandComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [RouterModule],
})
export class LoginRoutingModule {
  public constructor(@Optional() @SkipSelf() self: LoginRoutingModule) {
    if (self) {
      throw new Error(
        'LoginRoutingModule is already loaded. Import it in the AppModule only.');
    }
  }
}
