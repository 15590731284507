import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Message } from 'src/app/shared/models/uba/communication/model';

export interface MessageState extends EntityState<Message, string> {}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'Message' })
export class MessageStore extends EntityStore<MessageState> {
  public constructor() {
    super();
  }
}
