import { BrandResource } from 'src/app/shared/models/pux/model';

export const basicResources: BrandResource = {
  generalAccountText: 'account',
  generalPlanText: 'plan',
  benefitAccountText: 'benefit account',
  benefitPlanText: 'benefit plan',
  benefitText: 'benefit',
  cardLegalText: 'The BASIC Card is issued by Pathward®, N.A., Member FDIC, pursuant to license by Mastercard® International Incorporated. Mastercard and the circles design are registered trademarks of Mastercard International Incorporated. This Mastercard is administered by BASIC, a registered agent of Pathward, N.A. Use of this card is authorized as set forth in your Cardholder Agreement.',
  cardLegalIssuerStatement: 'The BASIC Card is issued by Pathward®, N.A., Member FDIC, pursuant to license by Mastercard® International Incorporated. This Mastercard is administered by BASIC, a registered agent of Pathward, N.A. Use of this card is authorized as set forth in your Cardholder Agreement.',
  cardLink: 'Wallet',
  companyCard: 'BASIC card',
  companyFullName: 'Benefit Administrative Services International Corporation',
  companyName: 'BASIC',
  companyNameCopyright: 'BASIC Benefits, LLC',
  contactHours: '9:00 a.m. to 7:00 p.m. ET<br>Monday - Friday',
  brandCssClass: 'brand-basic',
  faviconFileName: 'favicon-basic.ico',
  hideWalletAvailableBalance: true,
  logoFileName: 'logo-basic.png',
  participantId: 'Individual ID',
  phoneNumber: '800-372-3539',
  productName: 'Consumer Driven Accounts',
  requestCompleteImage1FileName: 'rfr-complete-1-basic.png',
  requestCompleteImage3FileName: 'rfr-complete-3-basic.png',
  termsOfUseFileName: 'terms-basic.html',
};
