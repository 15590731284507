import {
  AfterContentInit,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2,
} from '@angular/core';
import { NgControl } from '@angular/forms';

@Component({
  selector: 'app-textbox',
  templateUrl: './textbox.component.html',
  styleUrls: ['./textbox.component.scss'],
})
export class TextboxComponent implements AfterContentInit {
  @Input() public size: 'sm' | 'lg' | undefined;
  @Input() public label?: string;
  @Input() public clearable = false;
  @Input() public showIconWhenValid = false;
  @Output() public clear = new EventEmitter<void>();
  @ContentChild(NgControl, { static: true }) public inputControl: NgControl;
  @ContentChild(NgControl, { read: ElementRef, static: true }) public textboxElement: ElementRef<HTMLInputElement | HTMLTextAreaElement>;

  public constructor(
    private renderer: Renderer2,
  ) { }

  public ngAfterContentInit(): void {
    const hasTextbox = this.textboxElement && this.textboxElement.nativeElement;
    if (hasTextbox) {
      const placeholder = this.textboxElement.nativeElement.getAttribute('placeholder');
      const hasLabel = !!this.label;
      const hasPlaceholder = !!placeholder;

      if (hasLabel && !hasPlaceholder) {
        this.renderer.setAttribute(this.textboxElement.nativeElement, 'placeholder', this.label);
      } else if (!hasLabel && hasPlaceholder) {
        this.label = placeholder;
      }
    }
  }

  public onClearInput(): void {
    // Clear the textarea or input value
    this.inputControl.control.setValue('');
    this.inputControl.control.markAsDirty();
    this.inputControl.control.markAsTouched();
    // callback for clear button
    this.clear.emit();
  }
}
