import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BrandId, TermsOfUseSection } from 'src/app/shared/models/pux/enum';
import { BrandService } from 'src/app/shared/services/brand.service';
import { TermsOfUseModalComponent } from 'src/app/terms-of-use/components/terms-of-use-modal/terms-of-use-modal.component';

import { version } from '../../../../../package.json';

@Component({
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnInit, OnDestroy {
  @ViewChild(TermsOfUseModalComponent, { static: true }) public termsOfUseModal: TermsOfUseModalComponent;
  public currentYear: number;
  public version = version;

  public readonly brand = this.brandService.getBrandResources();
  public readonly brandId = this.brandService.getBrand();

  public BrandId = BrandId;

  public constructor(
    private brandService: BrandService,
    private readonly toastr: ToastrService,
  ) {}

  public ngOnDestroy(): void {
    this.toastr.clear();
  }

  public ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
  }

  public openPrivacyPolicy(): void {
    this.termsOfUseModal.open(TermsOfUseSection.PrivacyPolicy);
  }

  public openTermsOfUse(): void {
    this.termsOfUseModal.open();
  }
}
