import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ServiceOffering } from 'src/app/shared/models/uba/configuration/model';

import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { HiddenServiceOfferingState, HiddenServiceOfferingStore } from './hidden-service-offering.store';

@Injectable({
  providedIn: 'root',
})
export class HiddenServiceOfferingQuery extends QueryEntity<HiddenServiceOfferingState> {
  public constructor(protected store: HiddenServiceOfferingStore) {
    super(store);
  }
  public selectAllWhenLoaded(): Observable<ServiceOffering[]> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectAll()),
      );
  }
}
