import { Injectable } from '@angular/core';
import { Order, QueryConfig, QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { EnrollmentListItem } from '../../models';
import { EnrollmentListState, EnrollmentListStore } from './enrollment-list.store';

@Injectable({
  providedIn: 'root',
})
export class EnrollmentListQuery extends QueryEntity<EnrollmentListState> {
  public constructor(protected store: EnrollmentListStore) {
    super(store);
  }

  public selectActiveWhenLoaded(): Observable<EnrollmentListItem> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectActive()),
      );
  }

  public selectAllWhenLoaded(): Observable<EnrollmentListItem[]> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectAll()),
      );
  }

}
