import { BrandResource, BrandResources } from '../app/shared/models/pux';
import { basicResources } from './basic/basic.resources';
import { crossroadsResources } from './crossroads/crossroads.resources';
import { tascResources } from './tasc/tasc.resources';

export const brandResources: BrandResources = {
  BASIC: basicResources,
  Crossroads: crossroadsResources,
  TASC: tascResources,
  Unknown: {} as BrandResource,
};
