import { Directive, EventEmitter, Host, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[appDisableAfterSubmit]',
})
export class DisableAfterSubmitDirective implements OnInit, OnDestroy {
  @Input('appDisableAfterSubmit') public enableForm: EventEmitter<void>;

  public subscription: Subscription;

  public constructor(@Host() private formGroup: FormGroupDirective) { }

  public ngOnInit(): void {
    if (this.enableForm) {
      this.subscription = this.enableForm.subscribe(() => this.formGroup.form.enable());
    }
  }

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  @HostListener('submit', ['$event'])
  public onSubmit(event: Event): void {
    this.formGroup.form.disable();
  }
}
