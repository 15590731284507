import { Injectable } from '@angular/core';
import { Order, QueryConfig, QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';

import { filter, switchMap } from 'rxjs/operators';

import { Dependent } from '@models/profile/model';
import { DependentState, DependentStore } from './dependent.store';

@Injectable({ providedIn: 'root' })
@QueryConfig({
  sortBy: 'firstName',
  sortByOrder: Order.ASC,
})
export class DependentQuery extends QueryEntity<DependentState> {
  public constructor(protected store: DependentStore) {
    super(store);
  }

  public selectAllWhenLoaded(): Observable<Dependent[]> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectAll()),
      );
  }
}
