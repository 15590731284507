import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import ABAValidator from 'abavalidator';

import { Dates } from './dates';

export const OTHER_RELATIONSHIP_PATTERN = /^[a-zA-Z -]+$/;
export class PuxValidators {
  public static formGroupHasAtLeastOneValidControl(validator: ValidatorFn): ValidatorFn {
    return (group: FormGroup): ValidationErrors | null => {
      if (!group) {
        return null;
      }

      const controls = PuxValidators.getAllControlsInFormGroup(group);
      const isValid = controls.some((control) => !validator(control));
      if (!isValid) {
        return { hasNoValidControls: true };
      }
      return null;
    };
  }

  public static hasLowercaseCharacter(control: AbstractControl): ValidationErrors | null {
    const isValid = !control || !control.value || /[a-z]/.test(control.value);
    if (!isValid) {
      return { requiresLowercaseCharacter: true };
    }
    return null;
  }

  public static hasNoSpaceCharacter(control: AbstractControl): ValidationErrors | null {
    const isValid = !control || !control.value || /^\S*$/.test(control.value);
    if (!isValid) {
      return { hasSpaceCharacter: true };
    }
    return null;
  }

  public static hasNonWhitespaceCharacter(control: AbstractControl): ValidationErrors | null {
    const isValid = !control || !control.value || typeof(control.value) !== 'string' || control.value.trim().length > 0;
    if (!isValid) {
      return { requiresNonWhitespaceCharacter: true };
    }
    return null;
  }

  public static hasNumericCharacter(control: AbstractControl): ValidationErrors | null {
    const isValid = !control || !control.value || /\d/.test(control.value);
    if (!isValid) {
      return { requiresNumericCharacter: true };
    }
    return null;
  }

  public static hasSpecialCharacter(control: AbstractControl): ValidationErrors | null {
    const isValid = !control || !control.value || /[!@#$%]/.test(control.value);
    if (!isValid) {
      return { requiresSpecialCharacter: true };
    }
    return null;
  }

  public static hasUppercaseCharacter(control: AbstractControl): ValidationErrors | null {
    const isValid = !control || !control.value || /[A-Z]/.test(control.value);
    if (!isValid) {
      return { requiresUppercaseCharacter: true };
    }
    return null;
  }
  public static isDate(control: AbstractControl): ValidationErrors | null {
    if (!control || !control.value) {
      return null;
    }
    const isValid = PuxValidators.isValidDate(control.value);
    if (!isValid) {
      return { invalidDateFormat: 'The date must use the MM/DD/YYYY format.' };
    }
    return null;
  }

  public static isDateOfBirth(control: AbstractControl): ValidationErrors | null {
    if (!control || !control.value) {
      return null;
    }
    const dateValidationErrors = PuxValidators.isDate(control);
    if (dateValidationErrors) {
      return dateValidationErrors;
    }
    const dateOfBirth = Dates.fromString(control.value, Dates.UI_DATE_FORMAT);
    const startOfRange = Dates.fromString('01/01/1900', Dates.UI_DATE_FORMAT);
    const endOfRange = Dates.now().endOf('day');
    const isValid = Dates.isBetween(dateOfBirth, startOfRange, endOfRange);
    if (!isValid) {
      return { invalidDateOfBirth: 'The date must be between 01/01/1900 and today.' };
    }
    return null;
  }

  public static isDateTodayOrInThePast(control: AbstractControl): ValidationErrors | null {
    if (!control || !control.value || !PuxValidators.isValidDate(control.value)) {
      return null;
    }
    const date = Dates.fromString(control.value, Dates.UI_DATE_FORMAT);
    const today = Dates.now();
    if (date.isValid() && today.isBefore(date)) {
      return { futureDate: 'The date must be today or a past date.' };
    }
    return null;
  }

  public static isEmailAddress(control: AbstractControl): ValidationErrors | null {
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isValid = !control || !control.value || emailRegex.test(control.value);
    if (!isValid) {
      return { invalidEmailFormat: true };
    }
    return null;
  }

  public static isMatchingEmail(control: AbstractControl): ValidationErrors | null {
    const email = control.get('email');
    const confirmEmail = control.get('confirmEmail');
    if ((email.value && confirmEmail.value) && (email?.value !== confirmEmail?.value)) {
      return { matchingEmail: true };
    }
    return null;
  }

  public static isValidBankRoutingNumber(control: AbstractControl): ValidationErrors | null {
    const isValid = !control || !control.value || ABAValidator.validate(control.value);
    if (!isValid) {
      return { invalidRoutingNumber: true };
    }
    return null;
  }

  public static minDate(minimumDate: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control || !control.value || !PuxValidators.isValidDate(control.value) || !PuxValidators.isValidDate(minimumDate)) {
        return null;
      }
      const minimumDateObj = Dates.fromString(minimumDate, Dates.UI_DATE_FORMAT);
      const enteredDateObj = Dates.fromString(control.value, Dates.UI_DATE_FORMAT);
      if (enteredDateObj.isValid() && minimumDateObj.isValid() && enteredDateObj.isBefore(minimumDateObj)) {
        return { minDate: `The date must be ${minimumDate} or later.` };
      }
      return null;
    };
  }

  private static getAllControlsInFormGroup(group: FormGroup): AbstractControl[] {
    const flattenedControls = [];
    for (const key of Object.keys(group.controls)) {
      const control = group.controls[key];
      if (control instanceof FormGroup) {
        flattenedControls.push(...PuxValidators.getAllControlsInFormGroup(control));
      } else {
        flattenedControls.push(control);
      }
    }
    return flattenedControls;
  }

  private static isValidDate(date: string): boolean {
    const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/;
    const isValid = dateRegex.test(date) && Dates.fromString(date, Dates.UI_DATE_FORMAT).isValid();
    return isValid;
  }
}
