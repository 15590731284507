import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Customization } from '@models/profileConfiguration/model';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { CustomizationState, CustomizationStore } from './customization.store';

@Injectable({
  providedIn: 'root',
})
export class CustomizationQuery extends QueryEntity<CustomizationState> {
  public constructor(protected store: CustomizationStore) {
    super(store);
  }

  public selectActiveWhenLoaded(): Observable<Customization> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectActive()),
      );
  }
}
