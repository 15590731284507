import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { cacheable, withTransaction } from '@datorama/akita';
import { CardPackage } from '@models/card/model';
import {EMPTY, Observable, of} from 'rxjs';
import { mapTo, switchMap } from 'rxjs/operators';
import { CoreService } from 'src/app/shared/models/pux/enum';
import { Uri } from 'src/app/shared/services/uri';
import { AttachmentService } from 'src/app/shared/utils/attachment.service';
import { AttachmentQuery, ClientQuery } from 'src/app/state';

import { CardPackageViewModelStore } from '../../state/card-package';
import { CardPackageViewModel } from '../models/card-package-view-model';

@Injectable({
  providedIn: 'root',
})
export class CardPackageService {
  public constructor(
    private attachmentQuery: AttachmentQuery,
    private attachmentService: AttachmentService,
    private cardPackageViewModelStore: CardPackageViewModelStore,
    private clientQuery: ClientQuery,
    private http: HttpClient,
  ) {}

  public loadCardPackageAndCacheImage(): Observable<void> {
    const client = this.clientQuery.getActive();

    if (!client || !client.cardPackageId) {
      this.cardPackageViewModelStore.set([]);
      return of(null);
    }

    const uri = new Uri(`/profile/*/cardPackage/${client.cardPackageId}`, CoreService.Card);
    const request$ = this.http.get<CardPackage>(uri.toString())
    .pipe(
      switchMap((cardPackage) => {
        if (!cardPackage.attachmentId) {
          return of(null).pipe(
            withTransaction(() => {
              const viewModel = {
                cardPackageId: cardPackage.id,
                cardPackage,
              } as CardPackageViewModel;

              this.cardPackageViewModelStore.set([viewModel]);
              this.cardPackageViewModelStore.setActive(viewModel.cardPackageId);
            }),
          );
        }

        return this.attachmentService.loadAttachment(cardPackage.attachmentId).pipe(
            switchMap(() => this.attachmentQuery.selectEntity(cardPackage.attachmentId)),
            switchMap((attachment) => this.attachmentService.getAttachmentContentUrl(attachment)),
            switchMap((cardImageUrl) => {
              const headers = new HttpHeaders().set('Accept', 'image/webp,*/*');
              return this.http.get(cardImageUrl, { headers, responseType: 'blob' });
            }),
            withTransaction((cardImage) => {
              const viewModel = {
                cardPackageId: cardPackage.id,
                cardPackage,
                cardImageUrl: URL.createObjectURL(cardImage),
              } as CardPackageViewModel;

              this.cardPackageViewModelStore.set([viewModel]);
              this.cardPackageViewModelStore.setActive(viewModel.cardPackageId);
            }),
          );
      }),
    );

    return cacheable(this.cardPackageViewModelStore, request$, { emitNext: true })
    .pipe(
      mapTo(null),
    );
    }

}
