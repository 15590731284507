import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {withTransaction} from '@datorama/akita';
import {
  BankAccount,
  ChainType,
  MatchType,
  ParentType,
  PaymentAccountType,
  PaymentSourceAccount,
  SearchCriteria,
  TransactionCodeSecIndicator,
} from '@models/account/model';
import {merge, Observable} from 'rxjs';
import {catchError, reduce} from 'rxjs/operators';
import {IndividualQuery, PaymentSourceAccountStore} from 'src/app/state';

import {CoreService} from '../../models/pux/enum';
import {PaymentSourceAccountSearchQuery} from '../../models/pux/model';
import {ErrorHandlingService} from '../error-handling.service';
import {Uri} from '../uri';

@Injectable({
  providedIn: 'root',
})
export class PaymentSourceAccountService {
  public constructor(
    private errorHandlingService: ErrorHandlingService,
    private http: HttpClient,
    private individualQuery: IndividualQuery,
    private paymentSourceAccountStore: PaymentSourceAccountStore,
  ) { }

  public getPaymentSourceAccounts(paymentAccountTypes: PaymentAccountType[] = [PaymentAccountType.BankAccount]): Observable<PaymentSourceAccount[]> {
    const individualId = this.individualQuery.getActiveId();
    const paymentAccountSearchCriteria: SearchCriteria = [
      {
        key: 'parentId',
        value: individualId,
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      },
      {
        key: 'parentType',
        value: ParentType.INDIVIDUAL,
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      },
      {
        key: 'paymentAccountType',
        value: paymentAccountTypes.join('|'),
        matchType: MatchType.IN,
        chainType: ChainType.AND,
      },
      {
        key: 'achSecCodeIndicator',
        value: [TransactionCodeSecIndicator.CCD, TransactionCodeSecIndicator.PPD, TransactionCodeSecIndicator.WEB].join('|'),
        matchType: MatchType.IN,
      },
    ];
    const paperCheckSearchCriteria: SearchCriteria = [
      {
        key: 'parentId',
        value: individualId,
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      },
      {
        key: 'parentType',
        value: ParentType.INDIVIDUAL,
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      },
      {
        key: 'paymentAccountType',
        value: PaymentAccountType.Check,
        matchType: MatchType.EXACT,
      },
    ];
    const cardSearchCriteria: SearchCriteria = [
      {
        key: 'parentId',
        value: individualId,
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      },
      {
        key: 'parentType',
        value: ParentType.INDIVIDUAL,
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      },
      {
        key: 'paymentAccountType',
        value: PaymentAccountType.Card,
        matchType: MatchType.EXACT,
      },
    ];

    const query: PaymentSourceAccountSearchQuery = { includeInactive: true };

    const paymentAccountUri = new Uri(`/profile/${individualId}/paymentSourceAccount/search`, CoreService.Account, query);
    paymentAccountUri.addQueryParam('intent', 'BankAccount');

    const paperCheckUri = new Uri(`/profile/${individualId}/paymentSourceAccount/search`, CoreService.Account);
    paperCheckUri.addQueryParam('intent', 'PaperCheck');

    const cardUri = new Uri(`/profile/${individualId}/paymentSourceAccount/search`, CoreService.Account, query);
    cardUri.addQueryParam('intent', 'Card');

    return merge(
      this.http.post<PaymentSourceAccount[]>(paymentAccountUri.toString(), paymentAccountSearchCriteria),
      this.http.post<PaymentSourceAccount[]>(paperCheckUri.toString(), paperCheckSearchCriteria),
      this.http.post<PaymentSourceAccount[]>(cardUri.toString(), cardSearchCriteria),
    ).pipe(
      reduce((accounts, results) => accounts.concat(results)),
      withTransaction((accounts) => this.paymentSourceAccountStore.set(accounts)),
      catchError(this.errorHandlingService.rxjsErrorHandler()),
    );
  }

  /**
   * Retrieve and add a single payment source account to the store.
   * @param bankAccount The bank account to get the payment source account for.
   */
  public getPaymentSourceAccount(bankAccount: BankAccount): Observable<PaymentSourceAccount[]> {
    const searchCriteria: SearchCriteria = [
      {
        key: 'parentId',
        value: bankAccount.parentId,
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      },
      {
        key: 'parentType',
        value: bankAccount.parentType,
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      },
      {
        key: 'PaymentAccountId',
        value: bankAccount.id,
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      },
    ];

    const uri = new Uri(`/profile/${bankAccount.parentId}/paymentSourceAccount/search`, CoreService.Account);
    return this.http.post<PaymentSourceAccount[]>(uri.toString(), searchCriteria)
      .pipe(
        withTransaction((account) => {
          this.paymentSourceAccountStore.add(account);
        }),
        catchError(this.errorHandlingService.rxjsErrorHandler()),
      );
  }
}
