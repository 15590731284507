import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { from, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/auth/services/authentication.service';
import { PayoutQuery } from 'src/app/payout/state';
import { RaygunService } from 'src/app/raygun/raygun.service';

import { BrandId } from '../models/pux';
import { BrandService } from '../services/brand.service';

@Injectable({
  providedIn: 'root',
})
export class NoAuthGuard implements CanActivate {
  private readonly brand = this.brandService.getBrandResources();

  public constructor(
    private authenticationService: AuthenticationService,
    private payoutQuery: PayoutQuery,
    private raygunService: RaygunService,
    private router: Router,
    private brandService: BrandService,
  ) { }

  public canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return from(this.authenticationService.refreshSession())
      .pipe(
        map(({ isValidSession }) => {
          const brandId = this.brandService.getBrand();
          if (isValidSession) {
            if (_state.url.replace(/\?.+/, '') === '/login/refund' && brandId === BrandId.Crossroads) {
              // user may have clicked a second link to claim another refund, so need to log them out so they can log in with new context
              this.authenticationService.signOut().catch(() => true);
              return true;
            }

            // Do not want to redirect to the dashboard if we are on the home page
            if (this.brand.homePageOverride && _state.url.replace(/\?.+/, '') === this.brand.homePageOverride) {
              return true;
            }

            if (brandId === BrandId.Crossroads) {
              const entryId = this.payoutQuery.getEntryId();
              return this.router.parseUrl(entryId ? `/payout/${entryId}` : this.brand.homePageOverride);
            }

            return this.router.parseUrl('/dashboard');
          }

          if (_state.url.replace(/\?.+/, '') === '/login' && brandId === BrandId.Crossroads) {
            return this.router.parseUrl(this.brand.homePageOverride || '/logout');
          }

          return true;
        }),
        catchError((error) => {
          this.raygunService.logError('Error in no-auth.guard.ts:canActivate()', { error });
          return of(this.router.parseUrl('/logout'));
        }),
      );
  }
}
