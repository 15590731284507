<div class="enter-authentication pb-5" *ngIf="state === 'selectMethod'">
  <div class="authentication-code mb-4">Select Authentication Method</div>
  <div class="authentication-code-sub mb-4">
    We will send a code to the chosen method.
  </div>
  <div class="mfa-fields">
    <form name="codeDeliverySelectionForm" [formGroup]="codeDeliverySelectionForm" (ngSubmit)="selectDeliveryMethod()">
      <div *ngFor="let deliveryMethod of deliveryMethods" class="form-group">
        <app-radio-button [label]="deliveryMethod.destination">
          <input type="radio"
            [id]="deliveryMethod.method"
            class="custom-control-input"
            [value]="deliveryMethod.method"
            formControlName="deliveryMethod"
            data-cy="delivery-method-checkbox"
            tabindex="-1"
          >
        </app-radio-button>
      </div>
      <div class="form-group d-flex pt-3 pb-1">
        <button tabindex="0" class="btn btn-primary flex-fill" type="submit" [ngClass]="{ 'btn-disabled btn-light': !codeDeliverySelectionForm.valid }" [disabled]="!codeDeliverySelectionForm.valid">
          Send Code
        </button>
      </div>
      <div *ngIf="showDataRateMessage" class="data-rate-msg">Standard call, messaging or data rates may apply</div>
    </form>
  </div>
</div>

<div class="enter-authentication pb-5" *ngIf="state === 'loginSuccess'">
  <div class="authentication-code mb-4">Loading your information</div>
  <div class="authentication-code-sub mb-4">
    Please wait...
  </div>
</div>

<div class="enter-authentication pb-5" *ngIf="state === 'showBookmarkMsg'">
  <div class="authentication-code mb-4">Returning Later?</div>
  <div class="authentication-code-sub mb-4">
    Bookmark this page to make it easier to return later.
  </div>
  <div class="mfa-fields">
    <button tabindex="0" class="btn btn-primary flex-fill" type="button" (click)="continueToMainPage()">Continue</button>
  </div>
</div>

<div class="enter-authentication pb-5" *ngIf="state === 'loading'">
  <div class="authentication-code mb-4">Validating URL</div>
  <div class="authentication-code-sub mb-4">
    Please wait...
  </div>
</div>

<div class="enter-authentication pb-5" *ngIf="state === 'sendingCode'">
  <div class="authentication-code mb-4">Sending Code</div>
  <div class="authentication-code-sub mb-4">
    Please wait...
  </div>
</div>

<div class="enter-authentication pb-5" *ngIf="state === 'invalidURL'">
  <div class="authentication-code mb-4">Invalid URL</div>
  <div class="authentication-code-sub mb-4">
    The URL you have arrived on is invalid.
  </div>
</div>

<div class="enter-authentication pb-5" *ngIf="state === 'loggedOut'">
  <div class="authentication-code mb-4">You are now logged out</div>
  <div class="authentication-code-sub mb-4">
    Thank you for visiting.
  </div>
</div>

<div class="enter-authentication pb-5" *ngIf="state === 'loginFailure'">
  <div class="authentication-code mb-4">Login Failure</div>
  <div class="authentication-code-sub mb-4">
  </div>
  <div class="mfa-fields">
    <button tabindex="0" class="btn btn-primary flex-fill" type="button" (click)="processIdentifyIndividualResults()">Retry</button>
  </div>
</div>

<div class="enter-authentication pb-5" *ngIf="state === 'codeSent'">
  <div class="authentication-code mb-4">Enter Authentication Code</div>
  <div class="authentication-code-sub mb-4">
    We just sent a code to {{codeSentDestination}}.
  </div>
  <div class="mfa-fields">
    <form name="codeVerificationForm" [formGroup]="codeVerificationForm" (ngSubmit)="submitVerificationCode()">
      <app-textbox label="Enter 6-digit Code" [showIconWhenValid]="true">
        <input class="form-control" type="tel" formControlName="code" placeholder="Enter Code" maxlength="6" minlength="6" />
        <app-error [errors]="codeVerificationForm.controls?.code?.errors" forError="pattern">
          Verification codes must be a 6 digit number.
        </app-error>
        <app-error [errors]="codeVerificationForm.controls?.code?.errors" forError="minlength">
          Verification codes must be a 6 digit number.
        </app-error>
        <app-error [errors]="codeVerificationForm.controls?.code?.errors" forError="required">
          Please enter your valid verification code.
        </app-error>
      </app-textbox>
      <div class="form-group">
        <button tabindex="0" type="button" class="no-border-btn btn-success-text" (click)="sendLoginCode(codeSentMethod)">Resend Code</button>
      </div>
      <div class="form-group">
        <button tabindex="0" type="button" class="no-border-btn btn-success-text" (click)="setState('selectMethod')" *ngIf="deliveryMethods.length > 1">Change Code Delivery Method</button>
      </div>
      <div class="form-group d-flex pt-3 pb-1">
        <button tabindex="0" class="btn btn-outline-secondary flex-fill" routerLink="/logout" type="button">Cancel</button>
        <button tabindex="0" class="btn btn-primary flex-fill" type="submit" [ngClass]="{ 'btn-disabled btn-light': !codeVerificationForm.valid }" [disabled]="!codeVerificationForm.valid">
          Verify
        </button>
      </div>
    </form>
  </div>
</div>
