import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { DependentBenefitAccess } from '@models/account/model';

export interface DependentBenefitAccessEntityState extends EntityState<DependentBenefitAccess, string> { }

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'DependentBenefitAccess' })
export class DependentBenefitAccessStore extends EntityStore<DependentBenefitAccessEntityState> {
  public constructor() {
    super();
  }
}
