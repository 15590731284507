import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CoreService} from '@app/shared/models/pux';
import {Uri} from '@app/shared/services/uri';
import {AdhocPaymentSourceAccountStore, IndividualQuery} from '@app/state';
import {withTransaction} from '@datorama/akita';
import {Observable} from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root',
})
export class AdhocPaymentSourceAccountService {
  public constructor(
    private readonly adhocPaymentSourceAccountStore: AdhocPaymentSourceAccountStore,
    private readonly individualQuery: IndividualQuery,
    private readonly http: HttpClient,
  ) {}

  public loadAdhocPaymentAccounts(): Observable<any> {
    const userId = this.individualQuery.getActiveId();
    const url = new Uri(`/profile/${userId}/adhocPaymentSourceAccount/search`, CoreService.Account);
    return this.http.post(url.toString(), {}).pipe(
      withTransaction((adhocPaymentSourceAccounts) => {
        this.adhocPaymentSourceAccountStore.set(adhocPaymentSourceAccounts);
      }));
  }
}
