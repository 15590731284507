import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig } from '@datorama/akita';
import { PaymentSourceAccount } from '@models/account/model';

import { PaymentSourceAccountState } from './payment-source-account.query';

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'PaymentSourceAccount' })
export class PaymentSourceAccountStore extends EntityStore<PaymentSourceAccountState> {
  public constructor() {
    super();
  }
}
