import { Component } from '@angular/core';
import { BrandService } from 'src/app/shared/services/brand.service';
import { version } from '../../../../../package.json';

@Component({
  selector: 'app-footer',
  templateUrl: './app-footer.component.html',
  styleUrls: ['./app-footer.component.scss'],
})
export class AppFooterComponent {
  public readonly brand = this.brandService.getBrandResources();
  public readonly currentYear: number = new Date().getFullYear();
  public version = version;

  public constructor(
    private brandService: BrandService,
  ) { }
}
