import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { InvestmentBalance } from '@models/account/model';
import { Observable } from 'rxjs';
import { filter, first, map, switchMap } from 'rxjs/operators';
import { InvestmentBalanceState, InvestmentBalanceStore } from './investment-balance.store';

@Injectable({
  providedIn: 'root',
})
export class InvestmentBalanceQuery extends QueryEntity<InvestmentBalanceState> {

  public constructor(
    protected store: InvestmentBalanceStore,
    ) {
    super(store);
  }

  public selectInvestmentBalanceByBenefitAccount(benefitAccountId: string): Observable<InvestmentBalance> {
    return this.selectLoading()
    .pipe(
      filter((isLoading) => !isLoading),
      switchMap(() => this.selectAll({
        filterBy: (balance) => balance.parentId === benefitAccountId,
      })),
      map((balances) => balances[0]),
    );
  }
}
