import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Customization } from '@models/profileConfiguration/model';

export interface CustomizationState extends EntityState<Customization, string>, ActiveState {}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'Customization' })
export class CustomizationStore extends EntityStore<CustomizationState> {
  public constructor() {
    super();
  }
}
