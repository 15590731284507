import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { FundsTransferGroupOption, FundsTransferOptions } from '@models/profileConfiguration/model';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { FundsTransferOptionsState, FundsTransferOptionsStore } from './funds-transfer-options.store';

@Injectable({
  providedIn: 'root',
})
export class FundsTransferOptionsQuery extends QueryEntity<FundsTransferOptionsState> {
  public constructor(protected store: FundsTransferOptionsStore) {
    super(store);
  }

  public selectAllWhenLoaded(): Observable<FundsTransferOptions> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectFirst()),
      );
  }

  public selectOptionsForTargetId(targetId: string): Observable<FundsTransferOptions> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectFirst()),
        map((options) => {
          return {
            Adjustments: options.Adjustments.filter((opt) => this.sourceOrDestinationIdMatches(opt, targetId)),
            Contributions: options.Contributions.filter((opt) => this.sourceOrDestinationIdMatches(opt, targetId)),
            Disbursements: options.Disbursements.filter((opt) => this.sourceOrDestinationIdMatches(opt, targetId)),
            SpecialContributions: options.SpecialContributions.filter((opt) => this.sourceOrDestinationIdMatches(opt, targetId)),
            FeePayments: options.FeePayments.filter((opt) => this.sourceOrDestinationIdMatches(opt, targetId)),
          } as FundsTransferOptions;
        }),
      );
  }

  private sourceOrDestinationIdMatches(option: FundsTransferGroupOption, targetId: string): boolean {
    return [option.sourceId, option.destinationId, option.benefitPlanId].includes(targetId);
  }
}
