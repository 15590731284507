import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Election, ElectionProfileType, ElectionState as AccountElectionState } from '@models/account/model';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { ElectionState, ElectionStore } from './election.store';

@Injectable({
  providedIn: 'root',
})
export class ElectionQuery extends QueryEntity<ElectionState> {
  public constructor(protected store: ElectionStore) {
    super(store);
  }

  // get election by benefit account ID
  public selectByBenefitAccount(benefitAccountId: string, electionType?: ElectionProfileType): Observable<Election[]> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectAll({
          filterBy: (election) => election.parentId === benefitAccountId && (!electionType || election.electionProfileType === electionType),
        })),
      );
  }

  // get pending election by benefit account ID
  public selectPendingByBenefitAccount(benefitAccountId: string, electionType?: ElectionProfileType): Observable<Election[]> {
    const electionStates: string[] = [AccountElectionState.Active, AccountElectionState.Pending, AccountElectionState.PendingApproval];
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectAll({
          filterBy: (election) => election.parentId === benefitAccountId && (!electionType || election.electionProfileType === electionType)
            && (electionStates.includes(election.currentState)),
        })),
      );
  }

}
