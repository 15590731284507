import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { BenefitBalance } from 'src/app/shared/models/pux/model';

import { BenefitBalanceState, BenefitBalanceStore } from './benefit-balance.store';

@Injectable({
  providedIn: 'root',
})
export class BenefitBalanceQuery extends QueryEntity<BenefitBalanceState> {
  public constructor(protected store: BenefitBalanceStore) {
    super(store);
  }

  public selectEntityWhenLoaded(id: string): Observable<BenefitBalance> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectEntity(id)),
      );
  }

  public selectAllWhenLoaded(): Observable<BenefitBalance[]> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectAll()),
      );
  }
}
