import { Injectable } from '@angular/core';
import { withTransaction } from '@datorama/akita';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BenefitBalance } from 'src/app/shared/models/pux/model';

import { BenefitAccountBalanceQuery, BenefitAccountPostingSummaryQuery } from '..';
import { BenefitBalanceStore } from './benefit-balance.store';

@Injectable({
  providedIn: 'root',
})
export class BenefitBalanceService {
  public constructor(
    private benefitAccountBalanceQuery: BenefitAccountBalanceQuery,
    private benefitAccountPostingSummaryQuery: BenefitAccountPostingSummaryQuery,
    private benefitBalanceStore: BenefitBalanceStore,
  ) { }

  public getBenefitBalances(): Observable<BenefitBalance[]> {
    return combineLatest([
      this.benefitAccountBalanceQuery.selectActiveBenefitAccounts(),
      this.benefitAccountPostingSummaryQuery.selectAllWhenLoaded(),
    ]).pipe(
      map(([benefitAccountBalances, benefitAccountPostingSummaries]) => {
        return benefitAccountBalances.map((benefitAccountBalance) => {
          const benefitAccountPostingSummary = benefitAccountPostingSummaries
            .find((postingSummary) => postingSummary.id === benefitAccountBalance.soaAccountId);
          return {
            id: benefitAccountBalance.id,
            account: benefitAccountBalance,
            balanceSummary: benefitAccountPostingSummary,
          };
        });
      }),
      withTransaction((benefitBalances) => this.benefitBalanceStore.set(benefitBalances)),
    );
  }
}
