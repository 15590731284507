import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { SessionStorage } from 'src/app/shared/models/pux/enum';
import { BrowserSessionStorageService } from 'src/app/shared/services/browser-session-storage.service';

@Injectable({
  providedIn: 'root',
})
export class LogoutFederatedGuard implements CanActivate {
  public constructor(
    private browserSessionStorageService: BrowserSessionStorageService,
    private router: Router,
    private toastrService: ToastrService,
  ) { }

  public canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const federatedError = this.browserSessionStorageService.get<string>(SessionStorage.FederatedError);
    if (federatedError) {
      this.toastrService.error(federatedError);
      this.browserSessionStorageService.remove(SessionStorage.FederatedError);
    }
    return this.router.parseUrl('/login');
  }
}
