import { Injectable } from '@angular/core';
import rg4js from 'raygun4js';
import { environment } from 'src/environments/environment';

import { BrowserService } from '../shared/services/browser.service';

@Injectable({
  providedIn: 'root',
})
export class RaygunService {
  public constructor(
    private browserService: BrowserService,
  ) { }

  /**
   * Send the specified message or error and optional custom data to Raygun.
   * @param error A string or Error object
   * @param customData An object containing custom data to store in Raygun.
   */
  public logError(error: string | Error | object, customData?: object | object[]): void {
    if (this.isSupportedErrorType(error)) {
      this.sendToRaygun(error, customData);
      return;
    }

    const customDataWithError = this.addErrorToCustomData(error, customData);
    if (error && error['code'] && this.isSupportedErrorType(error['code'])) {
      this.sendToRaygun(error['code'], customDataWithError);
    } else if (error && error['message'] && this.isSupportedErrorType(error['message'])) {
      this.sendToRaygun(error['message'], customDataWithError);
    } else {
      this.sendToRaygun('Unhandled exception', customDataWithError);
    }
  }

  public setUser(username: string, email: string): void {
    rg4js('setUser', {
      identifier: username,
      isAnonymous: false,
      email,
    });
  }

  public clearUser(): void {
    rg4js('setUser', {
      identifier: '',
      isAnonymous: true,
      email: '',
    });
  }

  private addErrorToCustomData(error: object, customData: object | object[]): object | object[] {
    if (!error) {
      return customData;
    }
    if (!customData) {
      return error;
    }
    if (this.isArray(customData)) {
      return [error, ...customData];
    }
    return [error, customData];
  }

  private enableErrorToJsonMapping(): void {
    if (!('toJSON' in Error.prototype)) {
      Object.defineProperty(Error.prototype, 'toJSON', {
        value(): object {
          const result = {};
          const keys = Object.getOwnPropertyNames(this);
          for (const key of keys) {
            result[key] = this[key];
          }
          return result;
        },
        configurable: true,
        writable: true,
      });
    }
  }

  private isArray(val: object | object[]): val is object[] {
    return Array.isArray(val);
  }

  private isSupportedErrorType(error: string | Error | object): error is string | Error {
    return !!error && (typeof error === 'string' || (error instanceof Error && !!error.message));
  }

  private sendToRaygun(error: string | Error, customData: object | object[]): void {
    this.enableErrorToJsonMapping();
    const errorWithStack = typeof error === 'string' ? new Error(error) : error;
    rg4js('send', { error: errorWithStack, customData });
    const hostname = this.browserService.getLocationHostname();
    if (hostname === 'localhost' || !environment.production) {
      // tslint:disable-next-line: no-console
      console.error(error);
      // tslint:disable-next-line: no-console
      console.error(customData);
    }
  }
}
