import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthenticationResultType } from 'src/app/auth/models';
import { AuthenticationService } from 'src/app/auth/services/authentication.service';
import { RememberUserAction } from 'src/app/shared/models/pux/enum';
import { BrandService } from 'src/app/shared/services/brand.service';
import { AuthenticationChallengeType } from '../../../auth/models/authentication-challenge.model';
import { PayoutStore } from '../../../payout/state';

type States = 'loading' | 'selectMethod' | 'sendingCode' | 'codeSent' | 'invalidURL' | 'loggedOut' | 'loginSuccess' | 'loginFailure' | 'showBookmarkMsg';

@Component({
  selector: 'app-login-code-only',
  templateUrl: './login-code-only.component.html',
  styleUrls: ['./login-code-only.component.scss'],
})
export class LoginCodeOnlyComponent implements OnInit {
  public state: States;

  public qsValues: { individualId: string, entryId: string, skipBookmark: boolean };

  public username: string;
  public cognitoUser: any;

  public deliveryMethods: Array<{ method: string, destination: string }>;
  public showDataRateMessage: boolean;

  public codeSentMethod: string;
  public codeSentDestination: string;

  public codeToTest: string;

  public codeDeliverySelectionForm: FormGroup;
  public codeDeliverySelectionFormControls: {
    deliveryMethod: FormControl,
  };

  public codeVerificationForm: FormGroup;
  public codeVerificationFormControls: {
    code: FormControl,
  };

  public constructor(
    private authenticationService: AuthenticationService,
    private formBuilder: FormBuilder,
    private spinnerService: NgxUiLoaderService,
    private toastrService: ToastrService,
    private brandService: BrandService,
    private route: ActivatedRoute,
    private router: Router,
    private payoutStore: PayoutStore,
  ) { }

  public ngOnInit(): void {
    this.setState('loading');

    if (!Object.getOwnPropertyNames(this.route.snapshot.queryParams).length) {
      this.router.navigate([this.brandService.getBrandResources().homePageOverride || '/logout']).catch(() => true);
      return;
    }

    if (Object.getOwnPropertyNames(this.route.snapshot.queryParams).includes('logout')) {
      this.setState('loggedOut');

      this.authenticationService.signOut().catch(() => true);

      return;
    }

    const { k1: individualId, k2: entryId, sb: skipBookmark } = this.route.snapshot.queryParams;

    if (!individualId || !entryId) {
      this.setState('invalidURL');
      return;
    }

    this.qsValues = { individualId, entryId, skipBookmark: skipBookmark === 'yes' };

    this.validateUser().catch(() => true);
  }

  public setState(state: States): void {
    this.state = state;
    if (this.state === 'selectMethod') {
      this.initializeCodeDeliveryMethodForm();
    } else if (this.state === 'codeSent') {
      this.initializeCodeVerificationForm();
    } else if (this.state === 'loginFailure') {
      this.cognitoUser = null;
    } else if (this.state === 'showBookmarkMsg') {
      const queryParams: Params = {
        k1: this.qsValues.individualId,
        k2: this.qsValues.entryId,
        sb: 'yes',
      };

      this.router.navigate([], { relativeTo: this.route, queryParams }).catch(() => true);
    }
  }

  public initializeCodeVerificationForm(): void {
    this.codeVerificationFormControls = {
      code: new FormControl(null, [Validators.required, Validators.pattern('[0-9]*')]),
    };

    this.codeVerificationForm = this.formBuilder.group(this.codeVerificationFormControls);
  }

  public initializeCodeDeliveryMethodForm(): void {
    this.showDataRateMessage = this.deliveryMethods.some((dm) => dm.method === 'phone');

    if (this.deliveryMethods.length === 1) {
      this.codeSentMethod = this.deliveryMethods[0].method;
    } else if (this.deliveryMethods.length > 1 && !this.codeSentMethod) {
      this.codeSentMethod = this.deliveryMethods.some((dm) => dm.method === 'email') ? 'email' : this.codeSentMethod;
    }

    this.codeDeliverySelectionFormControls = {
      deliveryMethod: this.formBuilder.control(this.codeSentMethod, [Validators.required]),
    };

    this.codeDeliverySelectionForm = this.formBuilder.group(this.codeDeliverySelectionFormControls);
  }

  public selectDeliveryMethod(): void {
    this.sendLoginCode(this.codeDeliverySelectionForm.value.deliveryMethod).catch(() => true);
  }

  public async validateUser(): Promise<void> {
    this.spinnerService.start();

    try {
      const response = await this.authenticationService.identifyIndividual(this.qsValues.individualId, this.qsValues.entryId);

      this.username = response?.username;
      this.deliveryMethods = response?.methods || [];

      this.processIdentifyIndividualResults();

    } catch (error) {
      this.setState('invalidURL');
    }

    this.spinnerService.stop();
  }

  public processIdentifyIndividualResults(): void {
    if (this.deliveryMethods.length > 0) {
      this.setState('selectMethod');
    } else {
      this.setState('invalidURL');
    }
  }

  public async sendLoginCode(method: string): Promise<void> {
    const savedState = this.state;

    this.setState('sendingCode');

    this.codeSentMethod = method;
    this.codeSentDestination = this.deliveryMethods.find((dm) => dm.method === method)?.destination;

    try {
      await this.authenticationService.requestLoginAuthCode(this.qsValues.individualId, this.qsValues.entryId, this.codeSentMethod);
      this.setState('codeSent');
    } catch (error) {
      if (error.status === 429) {
        this.toastrService.error(error.error.error);
      } else {
        this.toastrService.error('An error has occurred sending the authentication code.');
      }
      this.setState(savedState);
    }
  }

  public async submitVerificationCode(): Promise<void> {
    this.spinnerService.start();

    this.codeToTest = this.codeVerificationForm.value.code;

    if (this.cognitoUser) {
      await this.validateCode();
    } else {
      await this.signIn();
    }
  }

  public async validateCode(): Promise<void> {
    this.spinnerService.start();

    const response = await this.authenticationService.sendCustomChallengeAnswer(this.cognitoUser, this.codeToTest, undefined, this.customAuthHandler.bind(this));

    if (response.type === AuthenticationResultType.Success) {
      this.payoutStore.setEntryId(this.qsValues.entryId);
      this.authenticationService.setAuthenticationType(AuthenticationChallengeType.CODE_ONLY);
      if (this.qsValues.skipBookmark) {
        this.continueToMainPage();
      } else {
        this.setState('showBookmarkMsg');
        this.spinnerService.stop();
      }
    } else if (response.error?.['code'] === 'NotAuthorizedException' && response.error?.message === 'Invalid session for the user.') {
      // cognito session timed out..auto-retry
      this.cognitoUser = null;
      await this.signIn();
    } else {
      this.toastrService.error('Incorrect Code');
      this.spinnerService.stop();
      if (response.type === 'CustomAuthChallenge') {
        this.setState('codeSent');
      } else {
        this.setState('loginFailure');
      }
    }
  }

  public continueToMainPage(): void {
    this.setState('loginSuccess');
    setTimeout(() => this.router.navigate(['/payout', this.qsValues.entryId]).then(() => this.spinnerService.stop(), () => this.spinnerService.stop()));
  }

  public async customAuthHandler(data: any): Promise<boolean> {
    this.spinnerService.stop();

    if (data.challengeName !== 'CUSTOM_CHALLENGE') {
      return false;
    }

    const autoSendFirstAttempt = !this.cognitoUser;

    this.cognitoUser = data;

    switch (data.challengeParam?.challenge) {
      case 'ENTER_CODE':
        if (autoSendFirstAttempt) {
          await this.validateCode();
        }
        break;
      default:
        return false;
    }

    return true;
  }

  public async signIn(): Promise<void> {
    this.spinnerService.start();
    const authenticationResult = await this.authenticationService.signIn(this.username, undefined, RememberUserAction.Forget, undefined, this.customAuthHandler.bind(this));
    if (authenticationResult.type !== AuthenticationResultType.CustomAuthChallenge) {
      this.toastrService.error('Unable to connect to the server. Please try again.');
    }
  }
}
