import { BenefitAccountBalance, BenefitAccountState, Election } from 'src/app/shared/models/uba/account/model';
import {
  BenefitPlan,
  BenefitPlanFundingSourceAndSchedule,
  FundingSourceType,
} from 'src/app/shared/models/uba/configuration/model';
import { Dependent, Individual } from 'src/app/shared/models/uba/profile/model';
import { Payroll, PayrollSchedule } from 'src/app/shared/models/uba/profileConfiguration/model';
import { EnrollmentUtil } from 'src/app/shared/utils';
import { Dates } from 'src/app/shared/utils/dates';
import { Numbers, RoundingStrategy } from 'src/app/shared/utils/numbers';
import { BenefitAccountBalanceQuery } from 'src/app/state';

export class BenefitEnrollmentViewModel {
  public id: string;
  public benefitPlan: BenefitPlan;
  public benefitPlanFundingSourcesAndSchedules: BenefitPlanFundingSourceAndSchedule[];
  public benefitAccount: BenefitAccountBalance;
  public individualElection: Election;
  public individual: Individual;
  public payroll?: Payroll;
  public payrollSchedule?: PayrollSchedule;
  public clientElection?: Election;
  public dependents: Dependent[];
  public associatedDependents: Dependent[];

  public benefitPlanLimitMinimum: number;
  public benefitPlanLimitMaximum?: number;

  public readonly isNewBenefitAccount: boolean;

  public constructor(enrollment: Partial<BenefitEnrollmentViewModel>) {
    Object.assign(this, enrollment);
    this.id = enrollment.benefitPlan.id;

    this.setEffectiveDateOnIndividualElection();

    this.benefitPlanLimitMinimum = this.calculateMinimumAnnualContribution();
    this.benefitPlanLimitMaximum = this.benefitPlan.limitMaximum;
  }

  public get allowEmployerContribution(): boolean {
    return this.benefitPlanFundingSourcesAndSchedules.some((funding) => funding.fundingSource === FundingSourceType.ClientDirect || funding.fundingSource === FundingSourceType.ClientToPlan);
  }

  public get allowIndividualContribution(): boolean {
    return this.benefitPlanFundingSourcesAndSchedules.some((funding) => funding.fundingSource === FundingSourceType.ParticipantDirect || funding.fundingSource === FundingSourceType.ParticipantToClient);
  }

  public get allowParticipantToClientContribution(): boolean {
    return this.benefitPlanFundingSourcesAndSchedules.some((funding) => funding.fundingSource === FundingSourceType.ParticipantToClient);
  }

  public get hasEmployerContribution(): boolean {
    return !!this.clientElection && this.clientElection.amount > 0;
  }

  public get hasIndividualContribution(): boolean {
    return !!this.individualElection && this.individualElection.amount > 0;
  }

  public get isEnrolled(): boolean {
    return !!this.benefitAccount && BenefitAccountBalanceQuery.isAccountEnrolled(this.benefitAccount);
  }

  public get isPending(): boolean {
    return !!this.benefitAccount && this.benefitAccount.currentState === BenefitAccountState.PendingApproval;
  }

  public get showAssociatedDependentWarning(): boolean {
    return this.benefitPlan.allowAssociatingDependentsToBenefitAccount && this.dependents.length > 0 && this.associatedDependents.length === 0;
  }

  public get totalContribution(): number {
    let total = 0;
    if (this.clientElection) {
      total += this.clientElection.amount;
    }
    if (this.individualElection) {
      total += this.individualElection.amount;
    }

    return total;
  }

  public get enrollmentEndDate(): string {
    if (this.benefitPlan.planEndDate) {
      return this.benefitPlan.enrollmentEndDate;
    }
    const { first } = EnrollmentUtil.getOpenEndedEnrollmentRanges(this.benefitPlan);
    return Dates.format(first.endDate, Dates.UI_DATE_FORMAT);
  }

  public get isSubsequentYearEnrollment(): boolean {
    return !this.benefitPlan.planEndDate &&
      !!this.benefitPlanFundingSourcesAndSchedules[0] &&
      this.benefitPlanFundingSourcesAndSchedules[0]?.startDate !== this.benefitPlan.planStartDate;
  }

  public get electionEffectiveDate(): string {
    return Dates.format(Dates.fromString(this.individualElection.effectiveDate), Dates.DATE_FORMAT_MMDDYYYY);
  }

  public getRemainingPayrollDates(): string[] {
    if (!this.payrollSchedule) {
      return [];
    }
    const today = Dates.today();
    return this.payrollSchedule.payrollDates.filter((date) => date > today);
  }

  public calculatePerPaycheckContribution(annualContribution: number): number {
    if (!annualContribution) {
      return 0;
    }

    const remainingPaydates = this.getRemainingPayrollDates().length;
    const contributionPerPaycheck = annualContribution / (remainingPaydates || 1);

    return Numbers.roundToHundredth(contributionPerPaycheck, RoundingStrategy.RoundDown);
  }

  private calculateMinimumAnnualContribution(): number {
    if (this.benefitPlan.limitMinimum) {
      return this.benefitPlan.limitMinimum;
    }
    if (this.benefitPlan.allowZeroDollarElection) {
      return 0;
    }
    return 0.01;
  }

  private setEffectiveDateOnIndividualElection(): void {
    if (this.individualElection) {
      this.individualElection = this.benefitPlan.planEndDate ? this.individualElection : { ...this.individualElection, effectiveDate: this.benefitPlanFundingSourcesAndSchedules[0].startDate } as Election;
    }
  }
}
