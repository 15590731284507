import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { withTransaction } from '@datorama/akita';
import * as communicationModel from '@models/communication/model';
import { Individual } from '@models/profile/model';
import {
  ChainType,
  Document,
  DocumentState,
  MatchType,
  ParentType,
  SearchCriteria,
} from '@models/profileConfiguration/model';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { LetterStore } from 'src/app/state/letter';
import { v4 as uuid } from 'uuid';
import { CoreService } from '../../models/pux/enum';
import { Uri } from '../uri';

import { SearchQuery } from '@app/shared/models/pux';
import { catchError, map } from 'rxjs/operators';
import { CommandFactory } from 'src/app/shared/utils/command.factory';

@Injectable({
  providedIn: 'root',
})
export class LetterService {
  public constructor(
    private http: HttpClient,
    private letterStore: LetterStore,
    private commandFactory: CommandFactory,
    private toastrService: ToastrService,
  ) { }

  public getLetters(individualId: string): Observable<Document[]> {
    const letterSearchCriteria: SearchCriteria = [
      { key: 'parentType', value: ParentType.INDIVIDUAL, matchType: MatchType.EXACT, chainType: ChainType.AND },
      { key: 'parentId', value: individualId, matchType: MatchType.EXACT, chainType: ChainType.AND },
      {
        key: 'currentState',
        value: [DocumentState.Active, DocumentState.RequestedDocument, DocumentState.RequestedMail, DocumentState.Mailed].join('|'),
        matchType: MatchType.IN,
        chainType: ChainType.AND,
      },
    ];

    const query: SearchQuery = {
      orderBy: 'updated',
      orderDirection: 'DESC',
    };
    const relativeURL = `/profile/*/configuration/document/search`;
    const uri = new Uri(relativeURL, CoreService.ProfileConfiguration, query);
    return this.http.post<Document[]>(uri.toString(), letterSearchCriteria)
      .pipe(
        withTransaction((letters) => this.letterStore.set(letters)),
      );
  }

  public requestLetter(individual: Individual, requestType: communicationModel.CommunicationRequestType): Observable<string> {
    const communicationRequest: communicationModel.CommunicationRequest = {
      id: uuid(),
      parentType: communicationModel.ParentType.INDIVIDUAL,
      parentId: individual.id,
      communicationRequestType: requestType,
      recipientId: individual.id,
    };

    const commandName: string = 'StartToRequested';
    const command: communicationModel.CommunicationRequestCommand = this.commandFactory.createCommand(communicationRequest, commandName);
    const relativeUrl: string = `/profile/*/communicationRequest/${communicationRequest.id}/command/${commandName}`;
    const uri = new Uri(relativeUrl, CoreService.Communication);
    return this.http.put<any>(uri.toString(), command)
      .pipe(
        map(() => {
          return communicationRequest.id;
        }),
        catchError((err) => {
          this.toastrService.error('Something went wrong while requesting your document');
          return of('');
        }),
      );
  }
}
