import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { BenefitPlanFundingSourceAndSchedule } from 'src/app/shared/models/uba/configuration/model';

export interface BenefitPlanFundingSourceAndScheduleState extends EntityState<BenefitPlanFundingSourceAndSchedule, string> {}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'BenefitPlanFundingSourceAndSchedule' })
export class BenefitPlanFundingSourceAndScheduleStore extends EntityStore<BenefitPlanFundingSourceAndScheduleState> {
  public constructor() {
    super();
  }
}
