import { Injectable } from '@angular/core';
import { KeyPair } from 'openpgp';
import { from, Observable } from 'rxjs';
import { CurrentUserQuery } from 'src/app/state';

@Injectable({ providedIn: 'root' })
export class PgpService {
  public constructor(
    private readonly currentUserQuery: CurrentUserQuery,
  ) { }

  public decrypt(armoredText: string, keyPair: KeyPair): Observable<string> {
    return from(this.decryptUsingArmoredKey(armoredText, keyPair));
  }

  public encryptText(plainText: string, publicKey: string, date?: Date): Observable<string> {
    return from(this.encryptUsingArmoredKey(plainText, publicKey, date));
  }

  public generateKeyPair(): Observable<KeyPair> {
    return from(this.generateECCKeyPair());
  }

  private async decryptUsingArmoredKey(armoredText: string, keyPair: KeyPair): Promise<string> {
    const openpgp = await import('openpgp');
    const message = await openpgp.readMessage({ armoredMessage: armoredText });
    const privateKeys = await openpgp.readKey({ armoredKey: keyPair.privateKeyArmored });
    const publicKeys = await openpgp.readKey({ armoredKey: keyPair.publicKeyArmored });
    const decryptionResult = await openpgp.decrypt({
      message,
      privateKeys,
      publicKeys,
    });
    return decryptionResult.data.toString();
  }

  private async encryptUsingArmoredKey(plainText: string, publicKey: string, date?: Date): Promise<string> {
    const openpgp = await import('openpgp');
    const message = openpgp.Message.fromText(plainText);
    const publicKeys = await openpgp.readKey({ armoredKey: publicKey });
    return openpgp.encrypt({
      message,
      publicKeys,
      date,
    });
  }

  private async generateECCKeyPair(): Promise<KeyPair> {
    const profileId = this.currentUserQuery.getProfileId();
    const openpgp = await import('openpgp');
    return openpgp.generateKey({
      type: 'ecc', // Type of the key, defaults to ECC
      curve: 'curve25519', // ECC curve name, defaults to curve25519
      userIds: [{ name: profileId }], // you can pass multiple user IDs
    });
  }
}
