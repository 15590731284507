import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Observable, of } from 'rxjs';

import { EnrollmentListItem } from '../../models';

export interface EnrollmentListState extends EntityState<EnrollmentListItem, string>, ActiveState { }

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'EnrollmentListItem' })
export class EnrollmentListStore extends EntityStore<EnrollmentListState> {
  public constructor() {
    super();
  }

  public load(listModel: EnrollmentListItem[]): Observable<boolean> {
    this.set(listModel);
    return of(true);
  }
}
