import { Injectable } from '@angular/core';
import { Order, QueryConfig, QueryEntity } from '@datorama/akita';
import { Attachment } from '@models/file/model';
import { Observable, of } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { RequestAttachmentQuery } from '../request-attachment';
import { AttachmentState, AttachmentStore } from './attachment.store';

@Injectable({
  providedIn: 'root',
})
@QueryConfig({
  sortBy: 'friendlyFileName',
  sortByOrder: Order.ASC,
})
export class AttachmentQuery extends QueryEntity<AttachmentState> {
  public constructor(
    private requestAttachmentQuery: RequestAttachmentQuery,
    protected store: AttachmentStore,
  ) {
    super(store);
  }

  public selectAllWhenLoaded(): Observable<Attachment[]> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectAll()),
      );
  }

  public selectByRequest(requestId: string): Observable<Attachment[]> {
    return this.requestAttachmentQuery.selectByRequest(requestId)
      .pipe(
        switchMap((requestAttachments) => {
          const attachmentIds = requestAttachments.map((requestAttachment) => requestAttachment.attachmentId);
          return this.selectMany(attachmentIds);
        }),
      );
  }
}
