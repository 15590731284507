import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { BenefitEnrollmentViewModel } from '../../models';

export interface BenefitEnrollmentViewModelState extends EntityState<BenefitEnrollmentViewModel, string>, ActiveState { }

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'BenefitEnrollmentViewModel' })
export class BenefitEnrollmentViewModelStore extends EntityStore<BenefitEnrollmentViewModelState> {
  public constructor() {
    super();
  }
}
