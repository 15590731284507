import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { TradeActivityModel } from 'src/app/shared/models/pux/model';

export interface TradeActivityState extends EntityState<TradeActivityModel, string> {}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'TradeActivity', idKey: 'uniqueId' })
export class TradeActivityStore extends EntityStore<TradeActivityState> {
  public constructor() {
    super();
  }
}
