import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { BrandId } from '../models/pux';
import { BrandService } from '../services/brand.service';

@Injectable({
  providedIn: 'root',
})
export class BrandGuard implements CanActivate {
  public constructor(
    private brandService: BrandService,
    private router: Router,
  ) { }

  public canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.brandService.getBrand() === BrandId.Unknown) {
      return this.router.parseUrl('/no-brand');
    }

    return true;
  }
}
