import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

function mockResponseBody(parentId: string): unknown[] {
  return [{
    id: uuid(),
    parentId,
  }];
}

/**
 * !!! ONLY ENABLED IN D ENVIRONMENTS !!!
 *
 * This is useful for mocking out responses to endpoints.
 * You can achieve just about any flow with this. This class is in the main module and
 * thus will persist with the app, so you can set class variables to track things
 * i.e. how many calls / do different things on second call / store previous call body /
 * store another endpoint call to return data that depends on that endpoints response / etc.
 *
 * See the example endpoint on how you would intercept domain/exampleEndpoint/{parentId}
 * and return a response with parentId equal to the call.
 *
 *
 */
@Injectable()
export class HttpDEBUGInterceptor implements HttpInterceptor {

  public hasCalledPreview = false;

  // tslint:disable-next-line:no-any
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const url = req.url;
    if (window['Cypress']) {
        return next.handle(req);
    }

    if (url) {
      if (url.includes('exampleEndpoint')) {

        const urlParts = url.split('/');
        const parentId = urlParts[urlParts.length - 1];

        // Either return a custom HTTP Response or call next.handle to make the network call.
        return of(new HttpResponse({
          body: mockResponseBody(parentId),
        })).pipe(
          delay(1000), // Makes it realistic
        );
      }

    }
    return next.handle(req);
  }
}
