export enum CoreService {
  Core = 'core',
  Profile = 'profile',
  Account = 'account',
  Communication = 'communication',
  Configuration = 'configuration',
  Card = 'card',
  ProfileConfiguration = 'profileConfiguration',
  Request = 'request',
  Lookup = 'lookup',
  File = 'file',
  Security = 'security',
  Support = 'support',
  Dashboard = 'dashboard',
  Cognito = 'cognito',
  PaymentAccount = 'paymentAccount',
}

/**
 * Specifies error codes that may be returned by AWS when confirming the registration code.
 * These values map to AWSError.code.
 */
export enum ConfirmRegistrationError {
  ExpiredCodeException = 'ExpiredCodeException',
}

/**
 * Specifies error codes that may be returned by AWS during the sign in process
 * These values map to AWSError.code.
 */
export enum SignInError {
  NotAuthorizedException = 'NotAuthorizedException',
  UserNotConfirmedException = 'UserNotConfirmedException',
  CodeMismatchException = 'CodeMismatchException',
}

/**
 * Specifies error codes that may be returned by AWS during the forgot password process
 * These values map to AWSError.code.
 */
export enum SignUpError {
  UserNotFoundException = 'UserNotFoundException', // When user is not found on Cognito
  InvalidParameterException = 'InvalidParameterException', // User found but in unconfirmed state
}

/**
 * Specifies error codes that may be returned by AWS during the oAuth Federated login process
 * These values map to the error_description URL params returned during the SSO process
 */
export enum FederatedSignInError {
  NotAuthorizedException = 'Error in SAML response processing: PreSignUp failed with error UnknownUser. ', // When user is not found in UBA
}

/**
 * Specifies a reason why a verification code is being sent to the user.
 */
export enum SendVerificatonCodeReason {
  ExpiredCode = 'ExpiredCode',
  RequestedByUser = 'RequestedByUser',
  UserNotConfirmed = 'UserNotConfirmed',
}

/**
 * Specifies a section within the Terms of Use document.
 */
export enum TermsOfUseSection {
  PrivacyPolicy = 'PrivacyPolicy',
}

export enum TradeDescription {
  CashToInvestmentBuy = 'Cash to Investment Buy',
  CashFromInvestmentSell = 'Cash from Investment Sell',
}

export enum TradeStatus {
  Pending = 'Pending',
  InvestmentBalanceUpdated = 'Investment Balance Updated',
  Posted = 'Posted',
}

export enum TransactionCategory {
  Contribution = 'Contribution',
  Expenditure = 'Expenditure',
}

export enum TransactionActivityEndpointSource {
  Account = 'Account',
  Contribution = 'Contribution',
  PaymentAccountContribution = 'PaymentAccountContribution',
  Expenditure = 'Expenditure',
  PendingEntry = 'PendingEntry',
  PendingRequest = 'PendingRequest',
  PendingTransfer = 'PendingTransfer',
  PendingTrade = 'PendingTrade',
  Trade = 'Trade',
}

export enum TransactionTypeLabel {
  ACH = 'ACH',
  ATM = 'ATM',
  Card = 'Card',
  Carryover = 'Carryover',
  ClaimConneXRequest = 'ClaimConneXRequest',
  CompanyCard = 'CompanyCard',
  Contribution = 'Contribution',
  ContributionReversal = 'ContributionReversal',
  Donation = 'Donation',
  Expenditure = 'Expenditure',
  InvestmentBuy = 'InvestmentBuy',
  InvestmentSell = 'InvestmentSell',
  Manual = 'Manual',
  Online = 'Online',
  PayTheProvider = 'PayTheProvider',
  PictureToPay = 'PictureToPay',
  PlanFinalization = 'PlanFinalization',
  Refund = 'Refund',
  Reimbursement = 'Reimbursement',
  ReimbursementRequest = 'ReimbursementRequest',
  Repayment = 'Repayment',
  Rollover = 'Rollover',
  Transfer = 'Transfer',
}

export enum PageFilter {
  All,
  Contributions,
  Expenditures,
  MyCash,
  CardDeclineProtection,
  Buy,
  Sell,
}

export enum TransactionState {
  Applied = 'Applied',
  Completed = 'Completed',
  Declined = 'Declined',
  Disputed = 'Disputed',
  DisputeSettled = 'Dispute Settled',
  DisputeRejected = 'Dispute Rejected',
  Draft = 'Draft',
  Paid = 'Paid',
  PartiallyPaid = 'Partially Paid',
  Pending = 'Pending',
  Posted = 'Posted',
  Rejected = 'Rejected',
  Repaid = 'Repaid',
  Returned = 'Returned',
  Reversed = 'Reversed',
  Submitted = 'Submitted',
  Unknown = 'Unknown',
  VerificationRequired = 'Verification Required',
}

export enum TransactionActivityType {
  Individual = 'Individual',
  Client = 'Client',
}

export enum UniqueIdType {
  Entry,
  FundsTransfer,
  Posting,
  Request,
  RequestPayment,
}

export enum RequestPageType {
  Draft,
  Provider,
  Request,
}

export enum TransactionStateCategory {
  All,
  Applied,
  Draft,
  Pending,
  Posted,
}

export enum ContentType {
  Jpg = 'image/jpeg',
  Pdf = 'application/pdf',
  Png = 'image/png',
}

export enum ContentDisposition {
  Inline = 'Inline',
  Attachment = 'Attachment',
}

export enum ChangeMFASettingType {
  None,
  EnableMFA,
  DisableMFA,
  ChangeMFAPhone,
}

export enum RememberUserAction {
  Forget = 'Forget',
  NoAction = 'NoAction',
  Remember = 'Remember',
}

export enum ChangePhoneSettingType {
  Mobile,
  Home,
  Work,
}

export enum SessionStorage {
  Email = 'Email',
  FederatedError = 'FederatedError',
  Url = 'Url',
}

export enum DateRangeType {
  Custom = 'Custom',
  Quarter = 'Quarter',
}

export enum SelectionMode {
  Single,
  Multiple,
}

export enum ArrayViewMode {
  List,
  Tile,
}

export enum DisplayType {
  Block = 'Block',
  Inline = 'Inline',
}

export enum BrandId {
  BASIC = 'BASIC',
  Crossroads = 'Crossroads',
  TASC = 'TASC',
  Unknown = 'Unknown',
}

export enum DayJsInclusivity {
  ExclusiveStartExclusiveEnd = '()',
  ExclusiveStartInclusiveEnd = '(]',
  InclusiveStartExclusiveEnd = '[)',
  InclusiveStartInclusiveEnd = '[]',
}
