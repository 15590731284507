import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BrandService } from 'src/app/shared/services/brand.service';

@Component({
  selector: 'app-login-header',
  templateUrl: './login-header.component.html',
  styleUrls: ['./login-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginHeaderComponent {
  public readonly brand = this.brandService.getBrandResources();

  public constructor(
    private brandService: BrandService,
  ) {}
}
