export enum PageTitle {
    ALL_BENEFITS = 'Accounts Summary',
    BANK_ACCOUNT = 'Bank Accounts',
    DEPENDENTS = 'Dependents',
    LOGIN = 'Employee Sign In',
    MY_CASH = 'MyCash Transfer',
    OVERVIEW = 'Accounts Overview',
    PAY_A_PROVIDER = 'Pay A Provider',
    PAY_PROVIDER_DETAILS = 'Pay Provider Details',
    PROFILE = 'Profile',
    REIMBURSEMENT_DETAILS = 'Reimbursement Details',
    REIMBURSEMENT_HISTORY = 'Reimbursement History',
    REIMBURSEMENT_REQUEST = 'Reimbursement Request',
    REIMBURSEMENT_DRAFTS = 'View Draft Reimbursement Requests',
    SECURITY = 'Security',
    SIGN_UP = 'Account Sign Up',
    TRANSACTIONS = 'Account Transactions',
    INVESTMENTS = 'Investment Activity',
    ENROLL = 'Enroll into Accounts',
    ENROLL_PAYMENT = 'Enroll Payment Plans',
    ENROLL_BENEFIT = 'Enroll Benefit Plans',
    REVIEW_ENROLL = 'Review Enrollment',
    SIGNIN_SECURITY = 'Sign In and Security',
    SUPPORT_REQUESTS = 'Support Requests History',
    MY_DOCUMENTS = 'My Documents',
    LETTERS = 'Letters',
    CONTACT_US = 'New Support Request - Contact Us',
    FORGOT_PASSWORD = 'Forgot Password',
    MY_CASH_SCHEDULE = 'MyCash Schedule Transfer',
    MY_CASH_MANAGE = 'MyCash Manage Transfer Schedules',
}
