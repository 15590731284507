import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { Document } from 'src/app/shared/models/uba/profileConfiguration/model';

import { TermsOfUseState, TermsOfUseStore } from './terms-of-use.store';

@Injectable({
  providedIn: 'root',
})
export class TermsOfUseQuery extends QueryEntity<TermsOfUseState> {
  public constructor(protected store: TermsOfUseStore) {
    super(store);
  }

  public selectActiveWhenLoaded(): Observable<Document> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectActive()),
      );
  }
}
