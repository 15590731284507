import {
  EventSummary,
  HideFromType,
  HoldingBalance,
  ObligationEnriched,
  ObligationLineItemEnriched,
  ObligationType,
  ObligationsSummary,
  PaymentAccountEnriched,
  PaymentAccountGroupEnriched,
} from '@models/paymentAccount/model';
import { cloneDeep } from 'lodash';
import { Dates } from 'src/app/shared/utils';

interface AggregateLineItem extends ObligationLineItemEnriched {
  latestDueDate: string;
}

export enum PaymentPageTitle {
  SelectPaymentMethod = 'Select Payment Method',
  SelectPaymentAccounts = 'Select Payment Accounts',
  Submit = 'Submit',
  AutoPay = 'Setup Auto-Pay',
}

export enum EventStatus {
  InFlight,
  Success,
  Failure,
}

export class PaymentPageViewModel implements PaymentAccountGroupEnriched {
  public eligibilityEvent: EventSummary;
  public accounts: PaymentAccountEnriched[];
  public currentTotalElectionAmountNonProrated?: number;
  public obligations: ObligationsSummary;
  public firstEligibilityEventId: string;
  public hideFrom?: HideFromType;
  public parentId?: string;
  public id?: string;
  public balance: HoldingBalance;
  public projectedPaidThroughDate?: string;
  public remittedThroughDate?: string;
  public displayName: string;
  public subTotalOverride?: boolean;
  public subTotalByAmount?: boolean;
  public serviceOfferingId: string;
  public inactivationDate: string;

  // calculated fields
  public aggregateLineItems: AggregateLineItem[];
  public dueObligations: ObligationEnriched[];
  public nsfFees: ObligationEnriched[];
  public subtotalObligations: ObligationEnriched[];
  public aggregateAmountDue: number;
  public nsfAmountDue: number;

  public constructor(model: Partial<PaymentPageViewModel>) {
    Object.assign(this, model);

    this.setPaymentDueObligations();
    this.setAggregateLineItems();
  }

  public get hasPaymentDue(): boolean {
    return this.dueObligations.filter((o) => o.late).length > 0;
  }

  public get amountDue(): number {
    return this.aggregateAmountDue + (this.subTotalByAmount ? 0 : this.nsfAmountDue);
  }

  public calculateAggregateAmountDue(): void {
    const total = this.dueObligations.reduce((agg, curr) => agg + curr.amountDue, 0);
    this.aggregateAmountDue = Number(total.toFixed(2));
  }

  public calculateNsfFee(): void {
    const total = this.nsfFees.reduce((agg, curr) => agg + curr.amountDue, 0);
    this.nsfAmountDue = Number(total.toFixed(2));
  }

  public get latestDueDate(): string {
    return this.dueObligations[this.dueObligations.length - 1]?.dueDate;
  }

  public get destinationId(): string {
    return this.id;
  }

  private setPaymentDueObligations(): void {
    this.dueObligations = cloneDeep(this.obligations.unpaidObligations)
      .filter((o) => o.late)
      .filter((o) => o.obligationType !== ObligationType.PriorityFee)
      .sort((a, b) => Dates.isBefore(a.dueDate, b.dueDate) ? -1 : 1);

    this.nsfFees = cloneDeep(this.obligations.unpaidObligations)
      .filter((o) => o.obligationType === ObligationType.PriorityFee);
  }

  private setAggregateLineItems(): void {
    const aggregateLineItems = [];

    if (this.dueObligations.length) {
      this.dueObligations.forEach((obligation) => {
        obligation.lineItems.forEach((lineItem) => {
          const index = aggregateLineItems.findIndex(({ paymentElectionId }) => paymentElectionId === lineItem.paymentElectionId);
          if (index === -1) {
            aggregateLineItems.push({ ...lineItem, latestDueDate: obligation.dueDate });
          } else {
            aggregateLineItems[index].amount += lineItem.amount;
            aggregateLineItems[index].latestDueDate = obligation.dueDate;
          }
        });
      });
    } else {
      const paidObligations = cloneDeep(this.obligations.paidObligations)
        .sort((a, b) => Dates.isBefore(a.dueDate, b.dueDate) ? 1 : -1);

      if (paidObligations[0]) {
        const obligation = paidObligations[0];
        obligation.lineItems.forEach((lineItem) => {
          aggregateLineItems.push({ ...lineItem, amount: undefined });
        });
      }
    }
    this.aggregateLineItems = aggregateLineItems;
    this.calculateAggregateAmountDue();
    this.calculateNsfFee();
  }
}
