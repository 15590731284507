import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment } from '@angular/router';
import { Observable } from 'rxjs';

import { BrandService } from '../services/brand.service';

@Injectable({
  providedIn: 'root',
})
export class PayoutGuard implements CanLoad {
  private readonly brand = this.brandService.getBrandResources();

  public constructor(
    private brandService: BrandService,
  ) { }

  public canLoad(_route: Route, _segments: UrlSegment[]): boolean | Observable<boolean> | Promise<boolean> {
    return !!this.brand.showPayoutWorkflow;
  }
}
