import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { PaymentPageViewModel } from '../models/payment-page-view-model';

export interface PaymentPageViewModelState extends EntityState<PaymentPageViewModel, string> { }

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'PaymentPageViewModel' })
export class PaymentPageViewModelStore extends EntityStore<PaymentPageViewModelState> {
  public constructor() {
    super();
  }
}
