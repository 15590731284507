export interface AuthenticationChallenge {
  challengeType: AuthenticationChallengeType;
  identityProvider?: string;
  additionalInfo?: string;
  idpOverrides?: any;
  error?: Error;
}

export enum AuthenticationChallengeType {
  Failed = 'Failed',
  Password = 'Password',
  SAML = 'SAML',
  Unknown = 'Unknown',
  CODE_ONLY = 'CODE_ONLY',
}
