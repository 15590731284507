import { Injectable } from '@angular/core';
import { shouldPolyfill as shouldPolyfillIntlDateTimeFormat } from '@formatjs/intl-datetimeformat/should-polyfill';
import { shouldPolyfill as shouldPolyfillIntlGetCanonicalLocales } from '@formatjs/intl-getcanonicallocales/should-polyfill';
import { shouldPolyfill as shouldPolyfillIntlLocale } from '@formatjs/intl-locale/should-polyfill';

@Injectable({
  providedIn: 'root',
})
export class IntlPolyfillService {
  public async loadDynamicPolyfills(): Promise<void> {
    if (shouldPolyfillIntlGetCanonicalLocales()) {
      await import('@formatjs/intl-getcanonicallocales/polyfill');
    }
    if (shouldPolyfillIntlLocale()) {
      await import('@formatjs/intl-locale/polyfill');
    }
    if (shouldPolyfillIntlDateTimeFormat()) {
      await import('@formatjs/intl-datetimeformat/polyfill');
      await Promise.all([
        import('@formatjs/intl-datetimeformat/add-golden-tz'),
        import('@formatjs/intl-datetimeformat/locale-data/en'),
      ]);
    }
  }
}
