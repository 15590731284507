import {Injectable} from '@angular/core';
import {EntityState, Order, QueryConfig, QueryEntity} from '@datorama/akita';
import {AddressOwnerType, BankAccount, PaymentAccountType} from '@models/account/model';
import {Observable} from 'rxjs';
import {filter, map, switchMap} from 'rxjs/operators';

import {BankAccountStore} from './bank-account.store';

export interface BankAccountState extends EntityState<BankAccount, string> { }

@Injectable({ providedIn: 'root' })
@QueryConfig({
  sortBy: 'created',
  sortByOrder: Order.DESC,
})
export class BankAccountQuery extends QueryEntity<BankAccountState> {
  public constructor(protected store: BankAccountStore) {
    super(store);
  }

  public selectBankAccounts(): Observable<BankAccount[]> {
    return this.selectAllWhenLoaded()
      .pipe(
        map((accounts) => accounts.filter((account) => account.paymentAccountType === PaymentAccountType.BankAccount)),
      );
  }

  public selectCardAccounts(): Observable<BankAccount[]> {
    return this.selectAllWhenLoaded()
      .pipe(
        map((accounts) => accounts.filter(({paymentAccountType}) => paymentAccountType === PaymentAccountType.Card)),
      );
  }

  public selectEntityWhenLoaded(bankAccountId: string): Observable<BankAccount> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectEntity(bankAccountId)),
      );
  }

  public selectProviders(): Observable<BankAccount[]> {
    return this.selectAllWhenLoaded()
      .pipe(
        map((accounts) => accounts.filter((account) => account.paymentAccountType === PaymentAccountType.Check && account.addressOwnerType === AddressOwnerType.External)),
      );
  }

  private selectAllWhenLoaded(): Observable<BankAccount[]> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectAll()),
      );
  }
}
