import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { DependentBenefitAccess, DependentBenefitAccessState } from '@models/account/model';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { DependentBenefitAccessEntityState, DependentBenefitAccessStore } from './dependent-benefit-access.store';

@Injectable({
  providedIn: 'root',
})
export class DependentBenefitAccessQuery extends QueryEntity<DependentBenefitAccessEntityState> {
  public constructor(protected store: DependentBenefitAccessStore) {
    super(store);
  }

  public selectAllWhenLoaded(): Observable<DependentBenefitAccess[]> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectAll()),
      );
  }

  public selectAllActiveWhenLoaded(): Observable<DependentBenefitAccess[]> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectAll({
          filterBy: (dependentBenefitAccess) => dependentBenefitAccess.currentState === DependentBenefitAccessState.Active,
        })),
      );
  }

  public selectAllActiveAndInactiveWhenLoaded(): Observable<DependentBenefitAccess[]> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectAll({
          filterBy: (dependentBenefitAccess) => [
            DependentBenefitAccessState.Active,
            DependentBenefitAccessState.Inactive,
          ].includes(dependentBenefitAccess.currentState as DependentBenefitAccessState),
        })),
      );
  }

  public selectActiveByDependentWhenLoaded(dependentId: string): Observable<DependentBenefitAccess[]> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectAll({
          filterBy: (dependentBenefitAccess) => dependentBenefitAccess.parentId === dependentId
            && dependentBenefitAccess.currentState === DependentBenefitAccessState.Active,
        })),
      );
  }

  public selectActiveByBenefitAccountWhenLoaded(benefitAccountId: string): Observable<DependentBenefitAccess[]> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectAll({
          filterBy: (dependentBenefitAccess) => dependentBenefitAccess.benefitAccountId === benefitAccountId,
        })),
      );
  }
}
