import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { withTransaction } from '@datorama/akita';
import { Observable } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ChangeUserAttributeResult } from 'src/app/auth/models';
import { AuthenticationService } from 'src/app/auth/services/authentication.service';
import {
  DistributionMethodType,
  Message,
  MessageCommandType,
  MessageContent,
  MessageStatusType,
  RecipientType,
} from 'src/app/shared/models/uba/communication/model';
import { EmploymentInfo, Payroll } from 'src/app/shared/models/uba/profileConfiguration/model';
import { CommandFactory } from 'src/app/shared/utils/command.factory';
import { PhoneNumbers } from 'src/app/shared/utils/phone-numbers';
import {
  ClientStore,
  CurrentUserQuery,
  EmploymentInfoQuery,
  EmploymentInfoStore,
  IndividualQuery,
  IndividualStore,
  PayrollStore,
} from 'src/app/state';
import { MessageStore } from 'src/app/state/message/message.store';
import { v4 as uuid } from 'uuid';

import { environment } from '../../../environments/environment';
import {
  ChainType,
  Client,
  Individual,
  IndividualCommand,
  MatchType,
  Phone,
  PhoneType,
  SearchCriteria,
} from '../../shared/models/uba/profile/model';
import { ErrorHandlingService } from '../../shared/services/error-handling.service';

const API_PROFILE = `${environment.services['profile'].endpoint}/profile/`;
const API_PROFILE_CONFIG = `${environment.services['profileConfiguration'].endpoint}/profile/`;
const API_COMMUNICATION = `${environment.services['communication'].endpoint}/profile/`;

@Injectable({
  providedIn: 'root',
})
export class UserProfileService {
  public constructor(
    private authenticationService: AuthenticationService,
    private clientStore: ClientStore,
    private commandFactory: CommandFactory,
    private currentUserQuery: CurrentUserQuery,
    private employmentInfoQuery: EmploymentInfoQuery,
    private employmentInfoStore: EmploymentInfoStore,
    private errorHandlingService: ErrorHandlingService,
    private http: HttpClient,
    private indivivualQuery: IndividualQuery,
    private individualStore: IndividualStore,
    private messageStore: MessageStore,
    private payrollStore: PayrollStore,
  ) { }

  public getLoggedInIndividual(): Observable<Individual> {
    const profileId = this.currentUserQuery.getProfileId();
    const INDIVIDUAL_SEARCH = `${API_PROFILE}${profileId}/profileType/individual`;
    return this.http.get<Individual>(INDIVIDUAL_SEARCH)
      .pipe(
        withTransaction((individual) => {
          if (individual) {
            this.individualStore.set([individual]);
            this.individualStore.setActive(individual.id);
          } else {
            this.individualStore.set([]);
          }
        }),
        catchError(this.errorHandlingService.rxjsErrorHandler()),
      );
  }

  /**
   * Updates the Individual name and the name attribute stored in AWS Cognito.
   * @param individual The individual entity to update.
   * @param firstName The new first name. Required.
   * @param lastName The new last name. Required.
   * @param middleName The new middle name. Optional.
   */
  public changeName(individual: Individual, firstName: string, lastName: string, middleName?: string): Observable<ChangeUserAttributeResult> {
    const fullName = firstName + (middleName ? ' ' + middleName : '') + ' ' + lastName;
    const updatedIndividual = {
      ...individual,
      firstName,
      fullName,
      middleName,
      lastName,
    };
    return this.updateIndividualById(updatedIndividual)
      .pipe(
        switchMap(() => this.authenticationService.changeName(fullName)),
      );
  }

  /**
   * Updates the Individual phone number stored in UBA.
   * @param individual The individual entity to update.
   * @param phoneNumber The new phone number.
   * @param phoneType
   */
  public updatePhoneNumber(individual: Individual, phoneNumber: string, phoneType: PhoneType): Observable<void> {
    const formattedPhoneNumber = PhoneNumbers.formatPhoneNumberForUBA(phoneNumber);
    const phones = this.createOrUpdatePhone(individual, formattedPhoneNumber, phoneType);
    const updatedIndividual = {
      ...individual,
      phones,
    };
    return this.updateIndividualById(updatedIndividual);
  }

  /**
   * Updates the Individual's last four digits of their social security number. ("Personal security code")
   * @param individual The individual entity to update.
   * @param lastFourSSN The last four digits of the social security number.
   */
  public changelastFourSSN(individual: Individual, lastFourSSN: string): Observable<void> {
    const updatedIndividual: Individual = {
      ...individual,
      lastFourOfSSN: lastFourSSN,
    };
    return this.updateIndividualById(updatedIndividual);
  }

  public updateIndividualById(updateData: Individual): Observable<void> {
    const individual = this.indivivualQuery.getActive();
    const INDIVIDUAL_BY_ID_API = `${API_PROFILE + individual.id}/profileType/individual/command/ActiveToActive`;
    return this.http.put<void>(INDIVIDUAL_BY_ID_API, this.createUpdateIndividualCommand(updateData))
      .pipe(
        switchMap((response) => {
          return this.getLoggedInIndividual()
            .pipe(
              map(() => response),
            );
        }),
        catchError(this.errorHandlingService.rxjsErrorHandler()),
      );
  }

  public getEmploymentInfo(): Observable<EmploymentInfo> {
    const individual = this.indivivualQuery.getActive();

    const searchCriteria: SearchCriteria = [
      {
        key: 'individualId',
        value: individual.id,
        matchType: MatchType.EXACT,
      },
    ];
    const API = `${API_PROFILE_CONFIG + individual.id}/configuration/employmentInfo/search`;
    return this.http.post<EmploymentInfo[]>(API, searchCriteria)
      .pipe(
        map((employmentInfoRecords) => employmentInfoRecords && employmentInfoRecords[0]),
        withTransaction((employmentInfo) => {
          if (employmentInfo) {
            this.employmentInfoStore.set([employmentInfo]);
            this.employmentInfoStore.setActive(employmentInfo.id);
          } else {
            this.employmentInfoStore.set([]);
          }
        }),
        catchError(this.errorHandlingService.rxjsErrorHandler()),
      );
  }

  public getClient(): Observable<Client> {
    const employmentInfo = this.employmentInfoQuery.getActive();
    const INDIVIDUAL_BY_ID_API = `${API_PROFILE + employmentInfo.parentId}/profileType/client`;
    return this.http.get<Client>(INDIVIDUAL_BY_ID_API)
      .pipe(
        withTransaction((client) => {
          this.clientStore.set([client]);
          this.clientStore.setActive(client.id);
        }),
        catchError(this.errorHandlingService.rxjsErrorHandler()),
      );
  }

  public getPayroll(clientPayrollId?: string): Observable<Payroll[]> {
    const employmentInfo = this.employmentInfoQuery.getActive();
    const payrollUrl = `${API_PROFILE_CONFIG + employmentInfo.parentId}/configuration/payroll/search?orderBy=name&orderDirection=asc`;
    const searchData = [{ key: 'parentId', value: employmentInfo.parentId, matchType: 'EXACT' }];
    return this.http.post<Payroll[]>(payrollUrl, searchData)
      .pipe(
        withTransaction((payrolls) => {
          this.payrollStore.set(payrolls);
          const primaryPayroll = payrolls.find((payroll) => payroll.id === clientPayrollId);
          if (primaryPayroll) {
            this.payrollStore.setActive(primaryPayroll.id);
          }
        }),
        catchError(this.errorHandlingService.rxjsErrorHandler()),
      );
  }

  // to get the count of unread alerts ||
  // to get read and unread alert message list
  public getAlerts(): Observable<Message[]> {
    const individual = this.indivivualQuery.getActive();
    const searchCriteria: SearchCriteria = [
      {
        key: 'recipientId',
        value: individual.externalId,
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      },
      {
        key: 'recipientType',
        value: RecipientType.Individual,
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      },
      {
        key: 'distributionMethodType',
        value: DistributionMethodType.TASCMessageCenter,
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      },
      {
        key: 'currentState',
        value: [MessageStatusType.Delivered, MessageStatusType.Read].join('|'),
        matchType: MatchType.IN,
        chainType: ChainType.AND,
      },
    ];
    const alertUrl = `${API_COMMUNICATION + individual.id}/message/search?&orderBy=created&orderDirection=desc`;
    return this.http.post<Message[]>(alertUrl, searchCriteria)
      .pipe(
        withTransaction((messages) => {
          this.messageStore.set(messages);
        }),
        catchError(this.errorHandlingService.rxjsErrorHandler()),
      );
  }

  public getAlertDetail(messageId: string): Observable<MessageContent> {
    const individual = this.indivivualQuery.getActive();

    const alertDetailUrl = `${API_COMMUNICATION + individual.id}/message/${messageId}`;
    return this.http.get<MessageContent>(alertDetailUrl)
      .pipe(
        tap((messageContent) => messageContent.messageContent = atob(messageContent.messageContent)),
        catchError(this.errorHandlingService.rxjsErrorHandler()),
      );
  }

  public markAlertRead(messageId: string, data: Message): Observable<void> {
    const individual = this.indivivualQuery.getActive();
    const commandType = MessageCommandType.DeliveredToRead;
    const command = this.commandFactory.createCommand(data, commandType);
    const alertDetailUrl = `${API_COMMUNICATION + individual.id}/message/${messageId}/command/${commandType}`;
    return this.http.put<void>(alertDetailUrl, command)
      .pipe(
        withTransaction(() => {
          this.messageStore.update(messageId, { currentState: MessageStatusType.Read });
        }),
        catchError(this.errorHandlingService.rxjsErrorHandler()),
      );
  }

  private createUpdateIndividualCommand(updateData: Individual): IndividualCommand {
    const todayIso = (new Date()).toISOString();
    const individual = this.indivivualQuery.getActive();

    return {
      data: {
        ...updateData,
        created: updateData.created ? updateData.created : todayIso,
        createdBy: updateData.createdBy ? updateData.createdBy : individual.fullName,
        createdById: individual.createdById ? individual.createdById : individual.id,
        updated: updateData.updated ? updateData.updated : todayIso,
        updatedBy: updateData.updatedBy ? updateData.updatedBy : individual.fullName,
        updatedById: individual.updatedById ? individual.updatedById : individual.id,
      },
      created: updateData.created ? updateData.created : todayIso,
      createdBy: updateData.createdBy ? updateData.createdBy : individual.fullName, // this will be username of logged in user
      createdById: individual.createdById ? individual.createdById : individual.id, // this will be the id(UUID) of the logged in user
      eventCorrelationId: uuid(), // this can just be a new UUID as you will not have any correlating events
      id: uuid(), // new UUID to identify command on event stream
      jobId: uuid(), // new UUID as you will not be submitting jobs
      producerId: uuid(), // this will be a UUID that will uniquely identify the channel will be different for web, mobile, ios....
      sequenceId: 1, // will alway be 1 as you are not submitting jobs
      version: 1, // will always be 1 for you until command gets versioned
    };
  }

  private createOrUpdatePhone(individual: Individual, phoneNumber: string, phoneType: PhoneType): Phone[] {
    const formattedPhoneNumber = PhoneNumbers.formatPhoneNumberForUBA(phoneNumber);
    const phones = [...individual.phones];
    const phoneIndex = phones.findIndex((phone) => phone.phoneType === phoneType);
    if (phoneIndex > -1) {
      phones[phoneIndex] = {
        ...phones[phoneIndex],
        isSmsCapable: PhoneType.Mobile ? true : false,
        isVerified: false,
        phone: formattedPhoneNumber,
        phoneType,
      };
    } else {
      phones.unshift({
        isSmsCapable: PhoneType.Mobile ? true : false,
        isVerified: false,
        phone: formattedPhoneNumber,
        phoneType,
      });
    }
    return phones;
  }

}
