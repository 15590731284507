import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { from, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/auth/services/authentication.service';
import { RaygunService } from 'src/app/raygun/raygun.service';
import { SessionStorage } from 'src/app/shared/models/pux/enum';
import { BrowserSessionStorageService } from 'src/app/shared/services/browser-session-storage.service';
import { IndividualQuery } from 'src/app/state';

@Injectable({
  providedIn: 'root',
})
export class AuthTermsGuard implements CanActivate {
  public constructor(
    private authenticationService: AuthenticationService,
    private browserSessionStorageService: BrowserSessionStorageService,
    private individualQuery: IndividualQuery,
    private raygunService: RaygunService,
    private router: Router,
  ) { }

  public canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return from(this.authenticationService.refreshSession())
      .pipe(
        switchMap((refreshSessionResult) => {
          if (!refreshSessionResult || !refreshSessionResult.isValidSession) {
            this.browserSessionStorageService.set(SessionStorage.Url, _state.url);
            return of(this.router.parseUrl('/logout'));
          }

          return this.individualQuery.selectActiveWhenLoaded()
            .pipe(
              map((individual) => {
                if (!individual) {
                  return this.router.parseUrl('/logout');
                }
                return true;
              }),
          );
        }),
        catchError((error) => {
          this.raygunService.logError('Error in auth-terms.guard.ts:canActivate()', { error });
          return of(this.router.parseUrl('/logout'));
        }),
      );
  }
}
