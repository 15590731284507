import { AfterViewInit, ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { ModalComponent } from 'src/app/shared/components/forms/modal/modal.component';
import { BrowserService } from 'src/app/shared/services/browser.service';

@Component({
  selector: 'app-unsupported-browser-modal',
  templateUrl: './unsupported-browser-modal.component.html',
  styleUrls: ['./unsupported-browser-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnsupportedBrowserModalComponent implements AfterViewInit {
  @ViewChild(ModalComponent, { static: true }) public modal: ModalComponent;

  public constructor(
    private browserService: BrowserService,
  ) { }

  public ngAfterViewInit(): void {
    const isBrowserSupported = this.browserService.isBrowserSupported();
    if (!isBrowserSupported) {
      this.modal.open();
    }
  }
}
