import { Component, OnDestroy, OnInit } from '@angular/core';
import { Client } from '@models/profile/model';
import { Observable } from 'rxjs';
import { switchMapTo, tap } from 'rxjs/operators';
import { AttachmentContent } from 'src/app/shared/models/pux/attachment-content';
import { BrandService } from 'src/app/shared/services/brand.service';
import { ClientCustomizationService } from 'src/app/shared/services/client-customization.service';
import { ClientQuery } from 'src/app/state';

@Component({
  selector: 'app-header-workflow',
  templateUrl: './app-header-workflow.component.html',
  styleUrls: ['./app-header-workflow.component.scss'],
})
export class AppHeaderWorkflowComponent implements OnInit, OnDestroy {
  public client$: Observable<Client>;
  public clientLogoUrl: string;

  public readonly brand = this.brandService.getBrandResources();

  public constructor(
    private brandService: BrandService,
    private clientQuery: ClientQuery,
    private clientCustomizationService: ClientCustomizationService,
  ) { }

  public ngOnInit(): void {
    this.client$ = this.clientCustomizationService.getClientLogo()
      .pipe(
        tap((clientLogo: AttachmentContent) => {
          this.clientLogoUrl = clientLogo ? URL.createObjectURL(clientLogo.content) : null;
        }),
        switchMapTo(this.clientQuery.selectActiveWhenLoaded()),
      );
  }

  public ngOnDestroy(): void {
    if (this.clientLogoUrl) {
      URL.revokeObjectURL(this.clientLogoUrl);
    }
  }
}
