<div class="form-group input-group password-instruction my-0" [ngClass]="{'show-inst': validateForm.controls.password.value}">
  <ul class="d-flex flex-column text-left mx-0 px-0">
    <li [ngClass]="{ 'password-valid': !(validateForm?.controls?.password?.errors?.minlength) }" class="py-1" data-cy="password-validation-eight-characters">
      <svg class="icon" attr.aria-label="{{validateForm?.controls?.password?.errors?.minlength ? 'Warning unsatisfied requirement' : 'Satisfied requirement'}}" viewBox="8 0 32 32" height="20" width="30">
        <use attr.xlink:href="assets/fonts/icomoon.svg#{{validateForm?.controls?.password?.errors?.minlength ? 'icon-process-svg' : 'icon-check-svg'}}"></use>
      </svg>
      At least 8 characters
    </li>
    <li [ngClass]="{ 'password-valid': !(validateForm?.controls?.password?.errors?.requiresUppercaseCharacter) }" class="py-1" data-cy="password-validation-one-upper">
      <svg class="icon" attr.aria-label="{{validateForm?.controls?.password?.errors?.requiresUppercaseCharacter ? 'Warning unsatisfied requirement' : 'Satisfied requirement'}}" viewBox="8 0 32 32" height="20" width="30">
        <use attr.xlink:href="assets/fonts/icomoon.svg#{{validateForm?.controls?.password?.errors?.requiresUppercaseCharacter ? 'icon-process-svg' : 'icon-check-svg'}}"></use>
      </svg>
      At least one uppercase letter
    </li>
    <li [ngClass]="{ 'password-valid': !(validateForm?.controls?.password?.errors?.requiresLowercaseCharacter) }" class="py-1" data-cy="password-validation-one-lower">
      <svg class="icon" attr.aria-label="{{validateForm?.controls?.password?.errors?.requiresLowercaseCharacter ? 'Warning unsatisfied requirement' : 'Satisfied requirement'}}" viewBox="8 0 32 32" height="20" width="30">
        <use attr.xlink:href="assets/fonts/icomoon.svg#{{validateForm?.controls?.password?.errors?.requiresLowercaseCharacter ? 'icon-process-svg' : 'icon-check-svg'}}"></use>
      </svg>
      At least one lowercase letter
    </li>
    <li [ngClass]="{ 'password-valid': !(validateForm?.controls?.password?.errors?.requiresSpecialCharacter) }" class="py-1" data-cy="password-validation-one-special">
      <svg class="icon" attr.aria-label="{{validateForm?.controls?.password?.errors?.requiresSpecialCharacter ? 'Warning unsatisfied requirement' : 'Satisfied requirement'}}" viewBox="8 0 32 32" height="20" width="30">
        <use attr.xlink:href="assets/fonts/icomoon.svg#{{validateForm?.controls?.password?.errors?.requiresSpecialCharacter ? 'icon-process-svg' : 'icon-check-svg'}}"></use>
      </svg>
      At least one special character (!@#$%)
    </li>
    <li [ngClass]="{ 'password-valid': !(validateForm?.controls?.password?.errors?.requiresNumericCharacter) }" class="py-1" data-cy="password-validation-one-numeric">
      <svg class="icon" attr.aria-label="{{validateForm?.controls?.password?.errors?.requiresNumericCharacter ? 'Warning unsatisfied requirement' : 'Satisfied requirement'}}" viewBox="8 0 32 32" height="20" width="30">
        <use attr.xlink:href="assets/fonts/icomoon.svg#{{validateForm?.controls?.password?.errors?.requiresNumericCharacter ? 'icon-process-svg' : 'icon-check-svg'}}"></use>
      </svg>
      At least one numeric
    </li>
  </ul>
</div>
