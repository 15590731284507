import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import minMax from 'dayjs/plugin/minMax';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { DayJsInclusivity, Weekday, Weekdays } from '../models/pux';

dayjs.extend(customParseFormat);
dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(minMax);
dayjs.extend(quarterOfYear);
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(duration);

type DateFormat = 'YYYY-MM-DD' | 'MM-DD-YY' | 'MM/DD/YY' | 'MM/DD/YYYY' | 'MM/DD' | 'MM-DD-YYYY' | 'YYYY' | 'MMM D, YYYY, h:mm:ss A';

/**
 * Encapsulates date creation and handling
 */
export class Dates {
  /**
   * The date format for strings
   * This value can be overriden at runtime in consuming applications
   */
  public static DATE_FORMAT: 'YYYY-MM-DD' = 'YYYY-MM-DD';
  public static DATE_FORMAT_MMDDYY: 'MM-DD-YY' = 'MM-DD-YY';
  public static DATE_FORMAT_MMDDYY_SLASHES: 'MM/DD/YY' = 'MM/DD/YY';
  public static UI_DATE_FORMAT: 'MM/DD/YYYY' = 'MM/DD/YYYY';
  public static UI_DATE_FORMAT_SHORT: 'MM/DD/YY' = 'MM/DD/YY';
  public static DATE_FORMAT_MMDD: 'MM/DD' = 'MM/DD';
  public static DATE_FORMAT_MMDDYYYY: 'MM-DD-YYYY' = 'MM-DD-YYYY';
  public static DATE_FORMAT_MMDDYY_HHMMA_SLASHES: 'MMM D, YYYY, h:mm:ss A' = 'MMM D, YYYY, h:mm:ss A';

  /**
   * The default timezone for all dates
   * This value can be overriden at runtime in consuming applications
   */
  public static TIMEZONE = 'America/Chicago';

  /**
   * Returns an ISO 8601 date string in the configured timezone
   * @param date A date string or Dayjs object
   */
  public static format(date: dayjs.ConfigType, format: DateFormat = Dates.DATE_FORMAT): string {
    return Dates.toDayJS(date).format(format);
  }

  /**
   * Returns true if date is between the given start and end dates
   * See https://day.js.org/docs/en/plugin/is-between as reference
   * @param date The date being checked
   * @param startOfRange The start of the date range to check
   * @param endOfRange The end of the date range to check
   * @param inclusivity Indicates whether to include the start and end of the date range when checking
   */
  public static isBetween(date: dayjs.ConfigType, startOfRange: dayjs.ConfigType, endOfRange: dayjs.ConfigType, inclusivity: DayJsInclusivity = DayJsInclusivity.InclusiveStartInclusiveEnd): boolean {
    if (!date || !startOfRange || !endOfRange) {
      return false;
    }

    return Dates.toDayJS(date)
      .isBetween(Dates.toDayJS(startOfRange), Dates.toDayJS(endOfRange), 'day', inclusivity);
  }

  /**
   * Returns true if date is after other regardless of time
   * See https://day.js.org/docs/en/query/is-after as reference
   * @param date The first date to compare
   * @param other The second date to compare
   */
  public static isAfter(date: dayjs.ConfigType, other: dayjs.ConfigType): boolean {
    return Dates.toDayJS(date).isAfter(Dates.toDayJS(other), 'day');
  }

  /**
   * Returns true if date is before other regardless of time
   * See https://day.js.org/docs/en/query/is-before as reference
   * @param date The first date to compare
   * @param other The second date to compare
   */
  public static isBefore(date: dayjs.ConfigType, other: dayjs.ConfigType): boolean {
    return Dates.toDayJS(date).isBefore(Dates.toDayJS(other), 'day');
  }

  /**
   * Returns true if date is same or after other regardless of time
   * See https://day.js.org/docs/en/query/is-same-or-after as reference
   * @param date The first date to compare
   * @param other The second date to compare
   */
  public static isSameOrAfter(date: dayjs.ConfigType, other: dayjs.ConfigType): boolean {
    return Dates.toDayJS(date).isSameOrAfter(Dates.toDayJS(other), 'day');
  }

  /**
   * Returns true if date is same or before other regardless of time
   * See https://day.js.org/docs/en/query/is-same-or-before as reference
   * @param date The first date to compare
   * @param other The second date to compare
   */
  public static isSameOrBefore(date: dayjs.ConfigType, other: dayjs.ConfigType): boolean {
    return Dates.toDayJS(date).isSameOrBefore(Dates.toDayJS(other), 'day');
  }

  /**
   * Returns true if date matches other regardless of time
   * See https://day.js.org/docs/en/query/is-same as reference
   * @param date The first date to compare
   * @param other The second date to compare
   */
  public static isSame(date: dayjs.ConfigType, other: dayjs.ConfigType, unit: dayjs.QUnitType = 'day'): boolean {
    return Dates.toDayJS(date).isSame(Dates.toDayJS(other), unit);
  }

  /**
   * Converts from JavaScript Date to Dayjs with timezone
   * @param date JS Date
   */
  public static fromDate(date: Date): dayjs.Dayjs {
    return dayjs(date).tz(Dates.TIMEZONE, true);
  }

  public static minDate(dates: string[]): string {
    if (!Array.isArray(dates) || !dates.length) {
      return undefined;
    }

    return dates.reduce((date1, date2) => {
      const date1Valid = dayjs(date1).isValid();
      const date2Valid = dayjs(date2).isValid();
      if ((!date1 || !date1Valid) && (!date2 || !date2Valid)) {
        return undefined;
      } else if (!date1 || !date1Valid) {
        return date2;
      } else if (!date2 || !date2Valid) {
        return date1;
      }

      return Dates.isSameOrBefore(date1, date2) ? date1 : date2;
    });
}

  public static maxDate(dates: string[]): string {
    if (!Array.isArray(dates) || !dates.length) {
      return undefined;
    }
    return dates.reduce((date1, date2) => {
      const date1Valid = dayjs(date1).isValid();
      const date2Valid = dayjs(date2).isValid();
      if ((!date1 || !date1Valid) && (!date2 || !date2Valid)) {
        return undefined;
      } else if (!date1 || !date1Valid) {
        return date2;
      } else if (!date2 || !date2Valid) {
        return date1;
      }

      return Dates.isSameOrAfter(date1, date2) ? date1 : date2;
    });
}

  /**
   * Converts from JavaScript Date to Dayjs without using timezone.
   * CAUTION: Do not use this unless you are *sure* you need to. Consider using fromDate() instead.
   * @param date JS Date
   */
  public static fromDateInLocalTime(date: Date): dayjs.Dayjs {
    return dayjs(date);
  }

  /**
   * Converts from ISO 8601 string to Dayjs with timezone.
   * @param date A date represented as an ISO 8601 string.
   */
  public static fromISOString(date: string): dayjs.Dayjs {
    return dayjs(date).tz(Dates.TIMEZONE);
  }

  /**
   * Returns a Dayjs representing the given date
   * @param date The string formatted date
   * @param format The format of the date string
   */
  public static fromString(date: string, format: string = Dates.DATE_FORMAT): dayjs.Dayjs {
    const strictlyParsedDate = dayjs(date, format, true);
    if (!strictlyParsedDate.isValid()) {
      return strictlyParsedDate;
    }

    return dayjs.tz(date, format, Dates.TIMEZONE);
  }

  /**
   * Returns a Dayjs representing the current date and time in the configured timezone
   */
  public static now(): dayjs.Dayjs {
    return dayjs().tz(Dates.TIMEZONE);
  }

  /**
   * Returns the UTC date and time string representation of right now
   */
  public static nowISO(): string {
    return Dates.now().toISOString();
  }

  /**
   * Convert Date in Ordinal format
   * @param date
   * @returns Ordinal formatted date string
   */
  public static ordinalDate(date: number): string {
    const ones = +date % 10;
    const tens = +date % 100 - ones;
    return date + ['th', 'st', 'nd', 'rd'][tens === 10 || ones > 3 ? 0 : ones];
  }

  /**
   * Get the names and index numbers for the weekdays (Monday - Friday).
   */
  public static workweekDays(): Weekday[] {
    return Weekdays.getWorkweekDays();
  }

  /**
   * Returns the string representation of today in the configured timezone
   */
  public static today(): string {
    return Dates.now().format(Dates.DATE_FORMAT);
  }

  /**
   * Returns the string representation of the last day of the passed dates month
   * @param date
   * @param format
   */
  public static lastDayOfMonth(date: string, format: string = Dates.UI_DATE_FORMAT): string {
    return dayjs(date, format, true).endOf('month').format(Dates.DATE_FORMAT);
  }

  /**
   * Returns date with time at 12am
   * @param date the date
   * @returns datetime with time set to 12am
   */
  public static startOfDay(date: string): string {
    return dayjs.tz(date).startOf('day').format();
  }

  /**
   * Returns date at last possible second of the provided day
   * @param date the date
   * @returns datetime provided, with timestamp at last second of the day
   */
  public static endOfDay(date: string): string {
    return dayjs.tz(date).endOf('day').format();
  }

  /**
   * Subtracts number of years from date provided
   * @param date date string to subtract from
   * @param years number of years to subtract from date
   * @returns new date string with # of years subtracted, in DATE_FORMAT
   */
  public static subtractYears(date: string, years: number): string {
    return dayjs.tz(date).subtract(years, 'year').format(Dates.DATE_FORMAT);
  }

  public static subtractMonths(date: string, months: number): string {
    return dayjs.tz(date).subtract(months, 'month').format(Dates.DATE_FORMAT);
  }

  private static toDayJS(date: dayjs.ConfigType): dayjs.Dayjs {
    if (dayjs.isDayjs(date)) {
      return date.tz(Dates.TIMEZONE);
    }
    return dayjs.tz(date, Dates.TIMEZONE);
  }
}
