import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/auth/services/authentication.service';
import { BrandService } from 'src/app/shared/services/brand.service';
import { PuxValidators } from 'src/app/shared/utils/validators';

@Component({
  selector: 'app-new-password-required',
  templateUrl: './new-password-required.component.html',
  styleUrls: ['./new-password-required.component.scss'],
})
export class NewPasswordRequiredComponent implements OnInit {
  public newPasswordForm: FormGroup;
  public readonly brand = this.brandService.getBrandResources();

  public constructor(
    private authenticationService: AuthenticationService,
    private brandService: BrandService,
    private formBuilder: FormBuilder,
  ) { }

  public ngOnInit(): void {
    this.newPasswordForm = this.formBuilder.group({
      password: new FormControl(null, [
        Validators.required,
        Validators.minLength(8),
        PuxValidators.hasUppercaseCharacter,
        PuxValidators.hasLowercaseCharacter,
        PuxValidators.hasNoSpaceCharacter,
        PuxValidators.hasSpecialCharacter,
        PuxValidators.hasNumericCharacter,
      ]),
    });
  }

  public async onSubmit(): Promise<void> {
    const newPassword = this.newPasswordForm.controls.password.value;
    const updatePasswordResult = await this.authenticationService.updatePasswordDuringSignIn(newPassword);

    await this.authenticationService.navigateBasedOnAuthentication(updatePasswordResult);
  }
}
