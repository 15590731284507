import {Injectable} from '@angular/core';
import {EntityState, Order, QueryConfig, QueryEntity} from '@datorama/akita';
import {
  PaymentAccountType,
  PaymentSourceAccount,
  PaymentSourceState,
  TransactionFrequencyIndicator,
} from '@models/account/model';
import {Observable} from 'rxjs';
import {filter, map, switchMap} from 'rxjs/operators';

import {PaymentSourceAccountStore} from './payment-source-account.store';

export interface PaymentSourceAccountState extends EntityState<PaymentSourceAccount, string> {}

@Injectable({ providedIn: 'root' })
@QueryConfig({
  sortBy: 'created',
  sortByOrder: Order.DESC,
})
export class PaymentSourceAccountQuery extends QueryEntity<PaymentSourceAccountState> {
  public constructor(protected store: PaymentSourceAccountStore) {
    super(store);
  }

  public selectActiveBankAccountPaymentSources(achFrequencies: TransactionFrequencyIndicator[]): Observable<PaymentSourceAccount[]> {
    return this.selectBankAccountPaymentSources()
      .pipe(
        map((accounts) => accounts.filter((account) => account.currentState !== PaymentSourceState.Inactive &&
          achFrequencies.includes(account.achFrequencyIndicator))),
      );
  }

  public selectAllWhenLoaded(): Observable<PaymentSourceAccount[]> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectAll()),
      );
  }

  public selectBankAccountPaymentSources(): Observable<PaymentSourceAccount[]> {
    return this.selectByPaymentAccountType(PaymentAccountType.BankAccount);
  }

  public selectByPaymentAccount(paymentAccountId: string, achFrequency: TransactionFrequencyIndicator): Observable<PaymentSourceAccount> {
    return this.selectAllWhenLoaded()
      .pipe(
        map((accounts) => accounts.find((account) => account.paymentAccountId === paymentAccountId && account.achFrequencyIndicator === achFrequency)),
      );
  }

  public selectEntityWhenLoaded(paymentSourceAccountId: string): Observable<PaymentSourceAccount> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectEntity(paymentSourceAccountId)),
      );
  }

  public selectPaperCheckPaymentSources(): Observable<PaymentSourceAccount[]> {
    return this.selectByPaymentAccountType(PaymentAccountType.Check);
  }

  public selectCardPaymentSources(): Observable<PaymentSourceAccount[]> {
    return this.selectByPaymentAccountType(PaymentAccountType.Card);
  }

  private selectByPaymentAccountType(paymentAccountType: PaymentAccountType): Observable<PaymentSourceAccount[]> {
    return this.selectAllWhenLoaded()
      .pipe(
        map((accounts) => accounts.filter((account) => account.paymentAccountType === paymentAccountType)),
      );
  }
}
