import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { EmploymentInfo } from 'src/app/shared/models/uba/profileConfiguration/model';

export interface EmploymentInfoState extends EntityState<EmploymentInfo, string>, ActiveState { }

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'EmploymentInfo' })
export class EmploymentInfoStore extends EntityStore<EmploymentInfoState> {
  public constructor() {
    super();
  }
}
