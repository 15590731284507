import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { withTransaction } from '@datorama/akita';
import { Customization, SearchCriteria } from '@models/profileConfiguration/model';
import { Observable, of, zip } from 'rxjs';
import { catchError, mapTo, switchMap } from 'rxjs/operators';
import { AttachmentQuery, ClientQuery, IndividualQuery } from 'src/app/state';
import { CustomizationStore } from 'src/app/state/customization';

import { CoreService } from '../../models/pux/enum';
import { SearchQuery } from '../../models/pux/model';
import { AttachmentService } from '../../utils/attachment.service';
import { ErrorHandlingService } from '../error-handling.service';
import { Uri } from '../uri';

@Injectable({
  providedIn: 'root',
})
export class CustomizationService {
  public constructor(
    private attachmentQuery: AttachmentQuery,
    private attachmentService: AttachmentService,
    private clientQuery: ClientQuery,
    private customizationStore: CustomizationStore,
    private errorHandlingService: ErrorHandlingService,
    private http: HttpClient,
    private individualQuery: IndividualQuery,
  ) { }

  public getClientCustomizationSettings(): Observable<Customization[]> {
    return zip(
      this.clientQuery.selectActiveWhenLoaded(),
      this.individualQuery.selectActiveWhenLoaded())
    .pipe(
      switchMap(([client, individual]) => {
        const searchCriteria: SearchCriteria = [
          {
            key: 'parentId',
            value: client.id,
          },
        ];
        const query: SearchQuery = {
          skip: 0,
          take: 1,
          orderBy: 'created',
          orderDirection: 'DESC',
        };
        const uri = new Uri(`/profile/${individual.id}/configuration/customization/search`, CoreService.ProfileConfiguration, query);

        return this.http.post<Customization[]>(uri.toString(), searchCriteria);
      }),
      withTransaction((customizations: Customization[]) => {
        this.customizationStore.set(customizations);

        // need to change if we ever expect user to have more than one customization
        if (customizations.length) {
          this.customizationStore.setActive(customizations[0].id);
        }
      }),
      switchMap((customizations) => {
        if (customizations.length > 0 && customizations[0].brandingEnabled && customizations[0].brandingLogoId) {
          return this.attachmentService.loadAttachment(customizations[0].brandingLogoId)
            .pipe(
              switchMap(() => this.attachmentQuery.selectEntity(customizations[0].brandingLogoId)),
              switchMap((attachment) => this.attachmentService.getAttachmentFile(attachment)),
              mapTo(customizations),
            );
        }
        return of(customizations);
      }),
      catchError(this.errorHandlingService.rxjsErrorHandler()),
    );
  }
}
